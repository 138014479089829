import { Events, Channels } from '@/broadcasting'

/**
 * Messaging service.
 * 
 * Manages multiple connectors (implementations).
 */
const Messaging = {

    connectors: {},

    /**
     * Create the service.
     * 
     * Installs the connectors set in the config.
     */
    construct(config, Device) {
        if (config.enabled) {
            let connectors = config.connectors(Device);
            if (connectors) {
                this._setConnectors(connectors);
            }
            else {
                this._log('No connectors set.');
            }
        }
        else {
            this._log('Disabled.');
        }
    },

    /**
     * Attempt to connect all disconnected connectors.
     */
    connectConnectors() {
        Object.keys(this.connectors).forEach(key => {
            let connector = this.getConnector(key);
            if (! connector.isConnected()) {
                connector.connect();
            }
        });
    },

    /**
     * Attempt to disconnect all connected connectors.
     */
    disconnectConnectors() {
        Object.keys(this.connectors).forEach(key => {
            let connector = this.getConnector(key);
            if (connector.isConnected()) {
                connector.disconnect();
            }
        });
    },

    /**
     * Get a single connector by name.
     */
    getConnector(connectorName) {
        return this.connectors[connectorName];
    },

    /**
     * Check if a connector exists.
     */
    connectorExists(connectorName) {
        return connectorName in this.connectors;
    },

    /**
     * Connect a single connector by name.
     */
    connect(connectorName, onConnectedCallback) {
        let connector = this.getConnector(connectorName);
        if (connector) {
            return connector.connect().then(() => {
                onConnectedCallback(connector);
            });
        }
    },

    /**
     * Disconnect a single connector by name.
     */
    disconnect(connectorName, onDisconnectedCallback) {
        let connector = this.getConnector(connectorName);
        if (connector) {
            return connector.disconnect().then(() => {
                typeof onDisconnectedCallback != 'undefined' ? onDisconnectedCallback(connector) : null;
            });
        }
    },

    /**
     * Get auth for a single connector by name.
     */
    getAuthForConnector(connectorName) {
        let connector = this.getConnector(connectorName);
        if (connector) {
            return connector.getAuth();
        }
        else {
            return null;
        }
    },

    /**
     * Add given connectors to the service.
     */
    _setConnectors(connectors) {
        this.connectors = connectors;
        Object.keys(connectors).forEach(key => {
            connectors[key].install(this, key);
        });
    },

    /**
     * Calls the appropriate listener for an event, by name.
     * 
     * Passed on to all connectors.
     */
    _callNotificationListener(eventName, data, type) {
        if (Events && typeof Events[eventName] != 'undefined') {
            Events[eventName](data, type);
        }
        else {
            Debug.warn('Messaging.service: Event `' + eventName + '` was received but no listener is attached.');
        }
    },

    /**
     * Calls the appropriate listener for an event, by name.
     * 
     * Passed on to all connectors.
     */
     _callChannelListener(channelName, data, type) {
        if (Channels && typeof Channels[channelName] != 'undefined') {
            Channels[channelName](data, type);
        }
        else {
            Debug.warn('Messaging.service: Channel `' + channelName + '` was received but no listener is attached.');
        }
    },

    _log(message) {
        Debug.info('Messaging.service: ' + message);
    }
}
export default Messaging;