/**
 * Animate.css
 * 
 * Is a library of ready-to-use, cross-browser animations for use in your web projects.
 * 
 * @link https://animate.style/#javascript
 */
const Animate = {
    /**
     * 
     * @param {String} element - HTML element class name
     * @param {String} animation - animation name without prefix
     * @param {String} repeat - set the iteration, without the prefix
     * @param {String} delay - add a delay, without prefix
     * @param {String} speed - control the speed, without prefix
     * 
     * @returns 
     * 
     * @link https://animate.style/
     */
    element: function ( element, animation, repeat = false, delay = false, speed = false ) {
        const prefix = 'animate__';

        return new Promise( ( resolve, reject ) => {
            const animationName = `${ prefix }${ animation }`;
            const animationRepeat = repeat ? ( repeat !== 'infinite' ? `${ prefix }repeat-${ repeat }` : `${ prefix }${ repeat }` ) : false;
            const animationDelay = delay ? `${ prefix }delay-${ delay }` : false;
            const animationSpeed = speed ? `${ prefix }${ speed }` : false;
            const node = document.querySelector( element );

            if ( node !== null ) {
                node.classList.add( `${ prefix }animated`, animationName );
                if ( animationRepeat ) {
                    node.classList.add( animationRepeat );
                }
                if ( animationDelay ) {
                    node.classList.add( animationDelay );
                }
                if ( animationSpeed ) {
                    node.classList.add( animationSpeed );
                }

                // When the animation ends, we clean the classes and resolve the Promise
                function handleAnimationEnd ( event ) {
                    event.stopPropagation();
                    node.classList.remove( `${ prefix }animated`, animationName );
                    resolve( 'Animation ended' );
                }

                node.addEventListener( 'animationend', handleAnimationEnd, ( !animationRepeat ? { once: true } : {} ) );
            }
        } );
    }
}
export default Animate;