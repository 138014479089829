<template>
           
    <v-list 
    v-if="Companies.init"
    one-line
    :subheader="subheader">
        
        <v-subheader v-if="subheader">{{ Companies.count() > 1 ? t("Companies") : t("Company") }}</v-subheader>
        
        <div 
        v-for="(company, key) in Companies"
        :key="key">
        
            <v-list-item :to="'/companies/'+company.uuid">

                <v-list-item-avatar>

                    <v-icon color="tzpOrange">mdi-office-building</v-icon>

                </v-list-item-avatar>

                <v-list-item-content>

                    <v-list-item-title>{{ company.name }}</v-list-item-title>

                </v-list-item-content>

                <v-list-item-action>

                    <v-icon>mdi-chevron-right</v-icon>

                </v-list-item-action>

            </v-list-item>

            <v-divider
            v-if="key !== Companies.count() - 1"
            inset></v-divider>
            
        </div>

    </v-list>

    <v-skeleton-loader
    v-else
    type="one-line one-line one-line"></v-skeleton-loader>

</template>

<script>
import { Companies } from "@/components/companies/api"

export default {
    name: "Companies",

    props: {
        subheader: {
            type: Boolean,
            default: true
        },
    },

    mixins: [ Companies.map() ],
};
</script>