import { mapState } from 'vuex';
import { store } from '../store/store';
import '../services/Debug.service';

/*
 * Acts as bridge to store.
 */
export default class Model {

    constructor(config) {

        this.config = config;

        if (store.getters.getStore(this.config.state) === false) {
            // Define structure if configured.
            let data = this.config.data ? this.config.data : [];

            // data = getOfflineData()

            //Setup store
            store.registerModule(this.config.state, {
                namespaced: true,
                state: data
            });
        }
    }

    getStateName() {
        return this.config.state;
    }

    map() {
        //Lazy mapping, available to Resources
        
        let config = this.config;
        let mapper = [
            config.state
        ];

        return {
            //Vue Mixin

            computed: {
                ...mapState(mapper)
            }
        }
    }

    // Get the collection.
    read() {
        return store.getters.getStore(this.config.state);
    }

    // Alias to read()
    store() {
        return this.read();
    }

    // Fill the collection.
    fill(data) {
        store.dispatch('fill', { 
            store: this.config.state, 
            data: data 
        });
    }

    merge(data) {
        store.dispatch('merge', { 
            store: this.config.state, 
            data: data 
        });
    }

    // Delete the collection.
    empty() {
        return store.dispatch('fill', { 
            store: this.config.state, 
            data: {} 
        });
    }

    // Set value of prop.
    set(k, v) {
        let data = [];
        data[k] = v;
        store.dispatch('setProp', { 
            store: this.config.state, 
            data: data 
        });
    }

    // Append item to collection.
    append(data) {
        store.dispatch('push', { 
            store: this.config.state, 
            data: data 
        });
    }

    // Prepend item to collection.
    prepend(data) {
        store.dispatch('prepend', { 
            store: this.config.state, 
            data: data 
        });
    }
    
    // Prepend multiple items to collection.
    appendMultiple(data) {
        store.dispatch('pushMultiple', { 
            store: this.config.state, 
            data: data 
        });
    }

    // Prepend multiple items to collection.
    prependMultiple(data) {
        store.dispatch('prependMultiple', { 
            store: this.config.state, 
            data: data 
        });
    }

    // Replace the collection.
    replace(data) {
        return store.dispatch('fill', { 
            store: this.config.state, 
            data: data 
        });
    }

    update(id, data) {
        store.dispatch('update', { 
            store: this.config.state, 
            data: data, 
            key: id
        });
    }

    remove(id) {
        store.dispatch('remove', { 
            store: this.config.state, 
            key: id
        });
    }

    removeAll() {
        store.dispatch('removeAll', { 
            store: this.config.state
        });
    }

    updateAll(data) {
        store.dispatch('updateAll', { 
            store: this.config.state,
            data: data
        });
    }

    //@override
    extendCollection() {
        return {};
    }

    //@override
    extendEntity() {
        return {};
    }

    //@override
    entitize(data) {
        //
    }

    setOfflineData() {
        // opslaan (volledige response) in offline data dmv Storage service
    }

    getOfflineData(data) {
        // omzetten naar entity this.entitize( data )
    }
}
