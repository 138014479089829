import { mapState } from 'vuex';
import Api from '../../services/Api.service';
import Ui from '../../services/Ui.service';
import Model from '../Model';
import RequestBuilder from '@/system/helpers/api/RequestBuilder';
import Entity from '@/system/helpers/api/Entity';
import EntityCollection from '@/system/helpers/api/EntityCollection';

/*
 * Acts as mediator between API and store.
 */
export default class Resource extends Model {

    constructor ( config ) {
        super( config );

        this.init = false;
        this.filled = false;
        this.originalLocation = this.config.location;

        // Task processing
        this.processing = false;
        this.callbacks = {
            allTasksCompleted: null,
            taskCompleted: null,
            taskFailed: null,
        };

        // Pagination
        this.pagination = null;
    }

    api () {
        return new RequestBuilder( {
            url: Api.resourceBaseURL + this.config.location,
            auth: ( typeof this.config.authenticate != 'undefined' ? this.config.authenticate : true ),
            callbacks: {
                onSuccess: ( request, response, options ) => {

                    // Actions on collection..
                    let methodMap = {
                        get: 'replace',
                        post: 'push',
                        put: 'updateAll',
                        patch: 'updateAll',
                        delete: 'removeAll'
                    };

                    // Take appropiate store action..
                    let storeAction = options && 'storeAction' in options ? options.storeAction : methodMap[ request.getMethod() ];

                    switch ( storeAction ) {
                        case 'updateAll':
                            let data = request.getBody();
                            this[ storeAction ]( data );
                            break;
                        case 'removeAll':
                            this[ storeAction ]();
                            break;
                        default:
                            let requestBody = this.entitize( response.data )
                            this[ storeAction ]( requestBody );
                    }

                    // setOfflineData(response.data)
                }
            }
        } );
    }

    // @override
    map () {
        // Lazy mapping using a vue component.

        let config = this.config;
        let mapper = [
            config.state
        ];

        let autoFill = () => {};

        if ( config.autoFill ) {
            if ( !this.init || config.forceRefill ) {
                this.init = true;

                autoFill = () => {
                    this.api().get().then( () => {
                        this.filled = true;
                        Debug.log( 'Helper/api/Resource: Auto-Fill <' + config.state + '>' );
                    },
                        ( error ) => {
                            Debug.error( 'Helper/api/Resource: Auto-Fill failed' );
                        } );
                }
            }
        }

        return {
            // Vue Mixin
            created: autoFill,

            computed: {
                ...mapState( mapper )
            }
        }
    }

    singleItemRequest ( item, method ) {
        let uri = this.originalLocation;
        var editor = item.getEditor();

        if ( !item.edit().isProcessing() ) {
            item.edit().processing();

            if ( !item.isPartOfCollection() ) {
                // Not a collection.
                var mapActions = {
                    get: 'update',
                    patch: 'merge',
                    put: 'merge',
                    delete: 'empty'
                };
            }
            else {
                var mapActions = {
                    get: 'update',
                    patch: 'update',
                    put: 'update',
                    delete: 'remove'
                };
            }

            let storeAction = mapActions[ method ];

            if ( !editor.options.awaitResult ) {
                var backup = Object.assign( {}, item );
                let changes = Object.assign( {}, editor.changes );
                this[ storeAction ]( item.getKey(), changes );
            }

            let identifierUri = item.getIdentifier() ? '/' + item.getIdentifier() : ''

            let request = new RequestBuilder( {
                url: Api.resourceBaseURL + uri + identifierUri,
                auth: ( typeof this.config.authenticate != 'undefined' ? this.config.authenticate : true ),
                callbacks: {
                    onSuccess: ( request, response ) => {

                        if ( editor.options.awaitResult ) {
                            if ( editor.options.storeResponse ) {
                                var changes = Object.assign( {}, response.data.data );
                            }
                            else {
                                var changes = Object.assign( {}, editor.changes );
                            }

                            this[ storeAction ]( item.getKey(), changes );
                            item.edit().completed();
                            item.edit().resetChanges();
                        }
                    },
                    onFailure: ( request, error ) => {

                        if ( !editor.options.awaitResult ) {
                            // Revert including pending changes..
                            this[ storeAction ]( item.getKey(), backup );
                        }

                        if ( 'response' in error ) {
                            Ui.error.show( ( error.response.data.message ? Ui.lang( error.response.data.message ) : Ui.lang( 'Network_failure_message' ) ) + ' (code R' + error.response.status + ')' );
                        }
                        else {
                            Ui.error.show( Ui.lang( 'Network_failure_message' ) );
                            throw error;
                        }

                        item.edit().failed();
                    },
                    onCompletion: request => {
                        item.edit().processed();
                    }
                }
            } );

            request.method( method );
            request.body( editor.changes );
            return request.call();
        }
        else {
            Debug.warn( 'Resource Warning: Prevented spawning new task. A pending task is already being processed for item ' + item.getKey() + ' in '
                + this.getStateName() + '. Recommendation: Prevent the user from issueing multiple tasks for the same item through the UI.' );
        }
    }

    onAllTasksCompleted ( callback ) {
        this.callbacks.allTasksCompleted = callback;
    }

    onTaskCompleted ( callback ) {
        this.callbacks.taskCompleted = callback;
    }

    doCallback ( key, context ) {
        if ( context ) {
            this.callbacks[ key ] ? this.callbacks[ key ]( context ) : null;
        }
        else {
            this.callbacks[ key ] ? this.callbacks[ key ]() : null;
        }
    }

    setPagination ( pagination ) {
        return this.pagination = pagination;
    }

    getPagination () {
        return this.pagination;
    }

    mayAdvanceToNextPage () {
        let pagination = this.pagination;
        let currentPageNumber = pagination.current_page;
        let nextPageNumber = pagination.current_page + 1;
        return nextPageNumber > currentPageNumber && nextPageNumber <= pagination.last_page;
    }

    // Loads the next page into the resource.
    loadNextPage () {
        if ( this.mayAdvanceToNextPage() ) {
            let nextPageNumber = this.pagination.current_page + 1;
            return this.api().query( {
                page: nextPageNumber
            } ).get( {
                storeAction: 'appendMultiple'
            } );
        }
    }

    loadNextBatch ( amount ) {
        let offsetAmount = this.read().count();
        let query = {
            offset: offsetAmount,
        }
        if ( amount ) {
            query[ 'limit' ] = amount;
        }
        return this.api().query( query ).get( {
            storeAction: 'appendMultiple'
        } );
    }

    loaded ( callback ) {
        this.callbacks.loaded = callback;
    }

    onInit () {
        this.doCallback( 'loaded' );
    }

    extendCollection () {
        return {
            __c: {
                local: {},
                meta: {},
                progression: {
                    total: 0,
                    processing: 0,
                    processed: 0,
                    completed: 0,
                    failed: 0,
                    tasks: {}
                }
            },

            onInit () {
                this.getModel().onInit();
            },

            getModel: () => {
                return this;
            },

            setPagination ( pagination ) {
                this.getModel().setPagination( pagination )
            },

            getPagination () {
                return this.getModel().getPagination();
            },

            getMeta () {
                return this.__c.meta;
            },

            setMeta ( meta ) {
                this.__c.meta = meta;
            },

            isProcessing () {
                return this.getModel().processing;
            },

            totalTasks () {
                return this.__c.progression.total;
            },

            totalRemainingTasks () {
                return this.__c.progression.processing;
            },

            totalCompletedTasks () {
                return this.__c.progression.completed;
            },

            totalFailedTasks () {
                return this.__c.progression.failed;
            },

            totalProcessedTasks () {
                return this.__c.progression.processed;
            },

            getTasks () {
                return this.__c.progression.tasks;
            },

            resetTasks () {
                this.__c.progression.total = 0;
                this.__c.progression.processed = 0;
                this.__c.progression.completed = 0;
                this.__c.progression.tasks = {}
            },

            setProcessingChild ( item ) {
                if ( !this.getModel().processing ) {
                    this.getModel().processing = true;
                    this.resetTasks();
                }

                this.__c.progression.total++;
                this.__c.progression.processing++;
                this.__c.progression.tasks[ item.getKey() ] = item;
            },

            setProcessedChild ( item ) {
                this.__c.progression.processed++;
                this.__c.progression.processing--;

                this.getModel().doCallback( 'taskCompleted', item );

                if ( this.totalRemainingTasks() == 0 ) {
                    // Reset
                    this.getModel().doCallback( 'allTasksCompleted' );
                    this.getModel().processing = false;
                }
            },

            setCompletedChild ( item ) {
                this.__c.progression.completed++;
                this.getModel().doCallback( 'taskFailed', item );
            },

            setFailedChild ( item ) {
                this.__c.progression.failed++;
                this.getModel().doCallback( 'taskFailed', item );
            },

            api () {
                return this.getModel().api();
            },

            local () {
                return this.__c.local;
            },
        }
    }

    extendEntity () {
        return {
            // Public accessors
            edit () {
                return this.__ed;
            },
            api () {
                return this.__api;
            },
            getEditor () {
                return this.edit();
            },
            isPartOfCollection () {
                if (this.init != 'object') {
                    return this.getEditor().parent ? true : false;
                }
                else {
                    return true;
                }
            },
            setCollection ( parent ) {
                return this.getEditor().parent = parent;
            },
            set () {
                return this.getEditor().set();
            },
            local () {
                return this.__ed.local;
            },
            getMeta () {
                return this.getEditor().getMeta();
            },
            setMeta ( meta ) {
                return this.getEditor().meta = meta;
            },

            // Run on initialisation of the entity.
            onInit () {
                this.edit().item = this;
                this.api().item = this;
            },

            __ed: {
                local: {},
                meta: {},
                item: null,
                parent: null,
                dirty: false,
                _processing: false,
                status: 'pending',
                options: {
                    awaitResult: true,
                    storeResponse: false
                },
                changes: {},

                // Accessors
                getCollection () {
                    return this.parent;
                },
                setCollection ( parent ) {
                    return this.parent = parent;
                },
                getResource: () => {
                    return this;
                },
                getMeta () {
                    return this.meta;
                },

                // Modifiers
                set () {
                    this.dirty = true;
                    return this.changes;
                },
                change () {
                    return this.set();
                },

                // Status
                hasChanges () {
                    return this.dirty;
                },
                getChanges () {
                    return this.changes;
                },
                resetChanges () {
                    this.changes = {};
                },
                getProcessStatus () {
                    return this.status;
                },
                processing () {
                    this.status = 'processing';
                    this.item.isPartOfCollection() ? this.getCollection().setProcessingChild( this.item ) : null;
                    this._processing = true;
                },
                processed () {
                    this.item.isPartOfCollection() ? this.getCollection().setProcessedChild( this.item ) : null;
                    this._processing = false;
                },
                completed () {
                    this.status = 'completed';
                    this.item.isPartOfCollection() ? this.getCollection().setCompletedChild( this.item ) : null;
                },
                failed () {
                    this.status = 'failed';
                    this.item.isPartOfCollection() ? this.getCollection().setFailedChild( this.item ) : null;
                },
                isProcessing () {
                    return this._processing;
                },
                isCompleted () {
                    return !this._processing;
                }
            },

            __api: {
                item: null,
                getResource: () => {
                    return this;
                },

                // Options
                storeResponse () {
                    this.options.storeResponse = true;

                    return this;
                },
                immediately () {
                    this.options.awaitResult = false;

                    return this;
                },

                // Remote actions
                get () {
                    return this.getResource().singleItemRequest( this.item, 'get' );
                },
                patch () {
                    return this.getResource().singleItemRequest( this.item, 'patch' );
                },
                put () {
                    return this.getResource().singleItemRequest( this.item, 'put' );
                },
                delete () {
                    return this.getResource().singleItemRequest( this.item, 'delete' );
                },
            },
        };
    }

    // Convert Coolweb API response to entity/collection.
    entitize ( cwResponse ) {
        if ( 'entity' in this.config ) {
            if ( this.config.entity instanceof Entity ) {
                if ( 'meta' in cwResponse ) {
                    if ( cwResponse.meta.type == 'collection' ) {
                        if ( 'collection' in this.config && this.config.collection instanceof EntityCollection ) {

                            let items = cwResponse.data;
                            let itemsCollection = Object.assign( this.config.collection, this.extendCollection() );
                            let itemsCollectionClone = Object.assign( {}, this.config.collection, this.extendCollection() );
                            let protoCol = Object.getPrototypeOf( itemsCollection );
                            Object.setPrototypeOf( itemsCollectionClone, protoCol );

                            let proto = Object.getPrototypeOf( this.config.entity );

                            if ( items.constructor.name == "Array" ) {
                                // Array..
                                items.forEach( ( item, index ) => {
                                    let entity = Object.assign( this.config.entity, this.extendEntity() );
                                    let state = Object.assign( item, entity );
                                    Object.setPrototypeOf( state, proto )

                                    state.setCollection( itemsCollectionClone );
                                    state.setInit( true );
                                } );
                            }
                            else {
                                // Object with objects..
                                for ( let index in items ) {
                                    let item = items[ index ];

                                    let entity = Object.assign( this.config.entity, this.extendEntity() );
                                    let state = Object.assign( item, entity );
                                    Object.setPrototypeOf( state, proto )

                                    state.setCollection( itemsCollectionClone );
                                    state.setInit( true );
                                }
                            }

                            itemsCollectionClone.items = items;

                            // Paginates..
                            if ( 'pages' in cwResponse.meta ) {

                                var pagination = cwResponse.meta.pages;

                                // Update pagination only if there are items
                                // on retrieved page.
                                if ( cwResponse.data.length > 0 ) {
                                    itemsCollectionClone.setPagination( pagination );
                                }
                                else {
                                    Debug.info( 'Model warning: Pagination halted ' + this.getStateName() + ' while there\'s no data on page ' + pagination.current_page + '.' );
                                }
                            }

                            itemsCollectionClone.setMeta( cwResponse.meta );

                            var result = itemsCollectionClone;
                            result.setInit( true );
                        }
                        else {
                            Debug.error( 'Model error: The collection must be an instance of EntityCollection.' );
                        }
                    }
                    else if ( cwResponse.meta.type == 'object' ) {
                        var result = this.toEntity( cwResponse.data );
                        result.setMeta( cwResponse.meta );
                    }
                }
                else {
                    Debug.warn( 'Model warning: Received response does not fulfill minimum requirements to entitize.' );
                }
            }
            else {
                Debug.error( 'Model error: The entity must be an instance of Entity.' );
            }
        }
        else {
            var result = cwResponse;
        }

        return result;
    }

    toEntity ( data ) {
        let proto = Object.getPrototypeOf( this.config.entity );
        let entity = Object.assign( this.config.entity, this.extendEntity() );
        let state = Object.assign( data, entity );
        Object.setPrototypeOf( state, proto )
        state.setInit( true );

        return state;
    }
}
