import Auth from '@/system/services/Auth.service'
import Device from '@/system/services/Device.service'
import Session from '@/system/services/Session.service'
import Ui from '@/system/services/Ui.service'

import Vuetify from '@/plugins/vuetify'
import { router } from '@/router'

import EventBus from '@/system/helpers/EventBus'
import TaskQueue from '@/system/helpers/TaskQueue'

import { MessagingUserPreferences } from '@/components/messaging/api';
import {
    Notifications,
    NotificationsChannels,
} from '@/components/notifications/api'
import NotificationSoundDecorative from '@/assets/sounds/notification_sound_decorative.wav'

/**
 * Listeners to be executed on receiving a specific channel
 * through a messaging connector.
 * 
 * Add as much listeners as you'd like.
 */
export const Channels = {

    'user.push.store': ( notification, type ) => {
        Notifications.prepend( Notifications.toEntity( notification ) );
        Notifications.read().increaseCounts();

        if ( type === 'pushNotificationActionPerformed' ) { //pushNotificationReceived
            NotificationHelper.handlePushNotification( notification );
        }
    },

    'user.push': ( notification, type ) => {
        // Direct dialog..
    },

    'user.account.new_login': ( notification, type ) => {
        Notifications.prepend( Notifications.toEntity( notification ) );
    }
};

/**
 * Listeners to be executed on receiving a specific event
 * through a messaging connector.
 * 
 * Add as much listeners as you'd like.
 */
export const Events = {

    'auth_device_locked': ( notification, type ) => {
        if ( Auth.isLoggedIn() ) {
            if ( Device.platform === 'web' && Vuetify.framework.breakpoint.smAndUp ) {
                Ui.alert.show( {
                    content: Ui.lang( 'Locked_multiple_active_devices_warning' ),
                } );
            }
            else {
                Ui.error.show( Ui.lang( 'Locked_multiple_active_devices_warning' ), -1 );
            }

            Session.halt();
        }
    },

    'auth_device_revoked': ( notification, type ) => {
        if ( Auth.isLoggedIn() ) {
            Ui.alert.show( {
                content: Ui.lang( 'Device_revoked_warning' ),
            } );
            router.push( { path: '/logout' } );
        }
    },

    'auth_new_login': ( notification, type ) => {
        if ( Auth.isLoggedIn() ) {
            NotificationHelper.playSound();
            NotificationHelper.createToast( notification );
            Notifications.api().get();
        }
    },

    'new_notification': ( notification, type ) => {
        if ( Auth.isLoggedIn() ) {
            NotificationHelper.playSound();
            NotificationHelper.createToast( notification );
        }
    },
};

/**
 * Logic used for all Channel and Event listeners.
 */
export const NotificationHelper = {
    checkPreference ( pref = false ) {
        let messagingPreferences = MessagingUserPreferences.read();

        if ( pref && pref in messagingPreferences ) {
            return messagingPreferences[ pref ];
        }

        return false;
    },

    shouldReceiveNotifications () {
        if ( this.checkPreference( 'do_not_disturb' ) ) {
            return false;
        }

        return true;
    },

    shouldPlaySound () {
        if ( this.checkPreference( 'desktop_sound_enabled' ) ) {
            return Device.platform === 'web' && Vuetify.framework.breakpoint.mdAndUp;
        }

        return false;
    },

    shouldCreateToast () {
        if ( this.shouldReceiveNotifications() ) {
            return Device.platform === 'web' && Vuetify.framework.breakpoint.mdAndUp;
        }

        return false;
    },

    playSound () {
        if ( this.shouldPlaySound() ) {
            let audio = new Audio( NotificationSoundDecorative )
            audio.addEventListener( "canplaythrough", event => {
                audio.play()
            } )
        }
    },

    createToast ( notification ) {
        if ( this.shouldCreateToast() ) {
            if ( !Ui.notificationDrawer.toggled ) {
                Ui.notification.toasts.push( notification )
            }
        }
    },

    handlePushNotification ( notification ) {
        if ( router.locked ) {
            TaskQueue.addToQueue( 'openNativeClickedNotifications', () => {
                EventBus.$emit( 'showNotification', notification, true );
            } );
        }
        else {
            // Open Notification direct
            Notifications.api().get().then( () => {
                EventBus.$emit( 'showNotification', notification, true );
            } );
        }
    }
};