/*
 * This file bootstraps the system in specific order.
 */

// Dependencies
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import { router } from '@/router'
import { store } from './store/store';
import App from '@/App.vue'
import VueHead from 'vue-head'
Vue.use( VueHead );
import { Plugins } from '@capacitor/core'
const { SplashScreen } = Plugins;
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
Vue.use( SequentialEntrance );
import Collect from 'collect.js'

// Services
import './services/Debug.service'
import Ui from './services/Ui.service'
import Guard from './services/Router.guard.service'
import Preferences from './services/Preferences.service'
import Session from './services/Session.service'
import Device from './services/Device.service'
import Api from './services/Api.service'
import Auth from './services/Auth.service'
import Storage from './services/Storage.service'
import Language from './services/Language.service'
import Time from '@/system/helpers/Time'
import Messaging from './services/Messaging.service'

// Components
import Tour from '@/components/Tour.vue'
Vue.component( 'Tour', Tour )
import Breadcrumbs from '@/system/components/Breadcrumbs'
Vue.component( 'Breadcrumbs', Breadcrumbs )

// Helpers
import EventBus from '@/system/helpers/EventBus'
import ApiListeners from '@/system/helpers/ApiListeners'
Vue.use( ApiListeners );

export default {

    boot: function ( config ) {

        Vue.config.productionTip = false

        Debug.construct( config.debug )

        // Make services available in all components
        Vue.prototype.$ui = Ui
        Vue.prototype.$prefs = Preferences
        Vue.prototype.$session = Session
        Vue.prototype.$api = Api
        Vue.prototype.$device = Device
        Vue.prototype.$storage = Storage
        Vue.prototype.$auth = Auth
        Vue.prototype.$time = Time
        Vue.prototype.$messaging = Messaging
        Vue.prototype.$eventBus = EventBus // EventBus for global events
        Vue.prototype.$collect = Collect

        this.reactiveData = {
            //Reactive data
            $ui: Vue.prototype.$ui,
            $prefs: Vue.prototype.$prefs,
            $session: Vue.prototype.$session,
            $device: Vue.prototype.$device,
            $time: Vue.prototype.$time,
            $messaging: Vue.prototype.$messaging
        };

        config.onBoot( Vue, this.reactiveData );

        Storage.construct( config.storage ).then( () => {

            Preferences.construct(
                config.preferences,
                {}
            ).then( () => {

                Guard.construct( config.guard )

                Device.construct( config.device ).then( () => {

                    Language.construct()

                    Auth.construct( config.auth ).then( () => {

                        Api.construct( config.api ).then( () => {

                            Session.construct( config.session, Device )
                            Messaging.construct( config.messaging, Device )

                            //Launch app
                            new Vue( {
                                router,
                                store,
                                vuetify,
                                render: h => h( App ),
                                data: this.reactiveData,
                                head: {
                                    title: {
                                        inner: config.app.title,
                                        separator: ' ',
                                        complement: ' '
                                    },
                                    meta: [
                                        {
                                            'http-equiv': 'Content-Security-Policy', content: config.app.csp
                                        }
                                    ]
                                },
                                created () {
                                    // set HTML language
                                    const getHTMLTag = document.documentElement;
                                    getHTMLTag.setAttribute( 'lang', Preferences.settings.languageCode );
                                },
                                mounted () {
                                    // Give it some time to render..
                                    setTimeout( () => {
                                        // After launch..
                                        SplashScreen.hide()
                                    }, 300 );
                                }
                            } ).$mount( '#app' );
                        } );
                    } );
                } );
            } );
        } );
    },

    app: function () {
        return Vue.prototype
    },
}