/**
 * Notifications API 
 * 
 * Contains API and Resource calls
 */
import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

let NotificationEntity = new Entity( {
    markToggle () {
        const read = this.read ? 0 : 1;
        this.edit().set().read = read;

        return this.api().patch();
    },
    setHandled () {
        this.edit().set().actioned = 1;
        // Check if we also have to mark it as read
        if ( !this.read ) {
            this.edit().set().read = 1;
        }

        return this.api().patch();
    }
} );

/* API */
export const notifications = {
    getTestNotifications: async () => {
        return Api.request( {
            method: 'GET',
            url: 'messaging/send-test-message',
        } )
    }
}

/* RESOURCES */

/**
 * Notifications
 * 
 *  All notifications from the authenticated user
 *  
 *  PUT - mark all as read
 *      data: {
 *          read: 1
 *      }
 */
export const Notifications = new Resource( {
    location: 'messaging/user/notifications',
    state: 'Notifications',
    autoFill: false,
    forceRefill: false,
    methods: [
        'delete',
        'get',
        'patch'
    ],
    data: {},

    collection: new EntityCollection( {
        getByUuid ( uuid ) {
            return this.clone().where( 'uuid', uuid ).first();
        },
        getTotalCount () {
            const meta = this.getMeta();
            return meta.total_items;
        },
        getUnread () {
            const unread = this.clone().where( 'read', 0 );
            return unread;
        },
        getUnreadCount () {
            const meta = this.getMeta();
            return meta.total_unread_items;
        },
        markAllAsRead () {
            let meta = this.getMeta();
            meta.total_unread_items = 0;

            return this.api().body( { read: 1 } ).patch();
        },
        deleteAll () {
            let meta = this.getMeta();
            meta.total_items = 0;
            meta.total_unread_items = 0;

            return this.api().delete();
        },
        increaseCounts () {
            let meta = this.getMeta();
            let totalCount = this.getTotalCount();

            ++totalCount;
            meta.total_items = totalCount;

            Notifications.read().setMeta( meta );
            this.increaseUnreadCount();
        },
        decreaseTotalCount () {
            let meta = this.getMeta();
            let count = this.getTotalCount();
            --count;
            meta.total_items = count;

            Notifications.read().setMeta( meta );
        },
        increaseUnreadCount () {
            let meta = this.getMeta();
            let unreadCount = this.getUnreadCount();

            ++unreadCount;
            meta.total_unread_items = unreadCount;

            Notifications.read().setMeta( meta );
        },
        decreaseUnreadCount () {
            let meta = this.getMeta();
            let count = this.getUnreadCount();
            --count;
            meta.total_unread_items = count;

            Notifications.read().setMeta( meta );
        }
    } ),
    entity: NotificationEntity
} )

/**
 * Unread Notifications
 */
export const UnreadNotifications = new Resource( {
    location: 'messaging/user/notifications?filter[read]=0',
    state: 'UnreadNotifications',
    autoFill: false,
    forceRefill: false,
    methods: [
        'get',
    ],
    data: {},
} )

/**
 * Notification Channels
 * 
 *  Get all public notification categories.
 *  Channels are attached to a category
 * 
 *  Optional query to get the categories with it's channels
 *      query: {
 *          include: 'channels'
 *      }
 */
export const NotificationsCategories = new Resource( {
    location: 'messaging/notifications/categories/public',
    state: 'NotificationsCategories',
    persist: true,
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},
    collection: new EntityCollection,
    entity: new Entity,
} )

/**
 * Notification Channels Subscriptions
 * 
 *  Get all subscribed notifications channels
 */
export const MySubscriptions = new Resource( {
    location: 'messaging/notifications/channels/subscriptions',
    state: 'MySubscriptions',
    persist: true,
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},
    collection: new EntityCollection( {
        getByUuid ( uuid ) {
            return this.clone().where( 'uuid', uuid ).first();
        }
    } ),
    entity: new Entity,
} )

export const SubscriptionManager = {
    subscribeByUuid: async ( uuid ) => {
        return Api.request( {
            method: 'PATCH',
            url: 'messaging/notifications/channels/' + uuid + '/subscription',
            data: {
                subscribed: 1
            }
        } )
    },
    unsubscribeByUuid: async ( uuid ) => {
        return Api.request( {
            method: 'PATCH',
            url: 'messaging/notifications/channels/' + uuid + '/subscription',
            data: {
                subscribed: 0
            }
        } )
    },
}