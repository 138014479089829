<template>
    
    <v-navigation-drawer
    v-model="$ui.navigationDrawer.toggled"
    app
    color="tzpNav"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :stateless="$vuetify.breakpoint.mdAndUp || $ui.navigationDrawer.stateless"
    width="270">

        <router-link to="/dashboard">
            
            <v-img
            v-if="$prefs.settings.darkModeEnabled"
            alt="Thuiszorgplanner logo"
            eager
            height="35px"
            src="img/dark/thuiszorgplanner_dark.svg"
            :transition="false"
            width="180px"
            :class="($vuetify.breakpoint.mdAndUp ? 'ma-4 mb-3' : 'mx-4 mt-3 mb-2')"></v-img>
            
            <v-img
            v-else
            alt="Thuiszorgplanner logo"
            eager
            height="35px"
            src="img/thuiszorgplanner.svg"
            :transition="false"
            width="180px"
            :class="($vuetify.breakpoint.mdAndUp ? 'ma-4 mb-3' : 'mx-4 mt-3 mb-2')"></v-img>
            
        </router-link>

        <v-divider></v-divider>

        <!-- Avatar -->
        <v-list
        v-if="$vuetify.breakpoint.xs && User.init && Companies.init"
        flat
        class="pb-0 pt-0">

            <template v-if="User.getFullnameInitials()">

                <avatar-list-initials
                :initials="User.getFullnameInitials()"
                :subtitle="t( User.getActiveRole() ) +' '+ Companies.all()[0].name"
                :title="User.full_name"
                to="/profile"></avatar-list-initials>

            </template>

            <v-skeleton-loader
            v-else
            type="list-item-avatar-two-line"></v-skeleton-loader>

            <v-divider></v-divider>

        </v-list>
        
        <!-- Status -->
        <status
        v-if="$messaging.connectorExists('websockets') && $messaging.getConnector('websockets').getStatus() === 'disconnected'"
        color="default"
        :description="t( 'Websockets_disconnected' )"
        icon="mdi-alert"></status>
        
        <v-divider v-if="$messaging.connectorExists('websockets') && $messaging.getConnector('websockets').getStatus() === 'disconnected'"></v-divider>

        <v-list
        v-if="Config.data && 'menu' in Config.data"
        :dense="$prefs.settings.navigationDrawerDense"
        class="pt-0 mb-12 muli--semibold">
            
            <div 
            v-for="(item, index) in Config.data.menu.items"
            :key="index">
                
                <div v-if="item.items">
                    
                    <v-list-group
                    v-model="item.active"
                    color="primary"
                    :key="item.title"
                    no-icon
                    :prepend-icon="item.icon">
                        
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-html="t(item.title)"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                        v-for="subItem in item.items"
                        color="tzpNavItem"
                        :key="subItem.title"
                        sub-group
                        :to="subItem.route"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="t(subItem.title)"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-group>

                </div>

                <div v-else>
                    
                    <v-list-item 
                    color="tzpNavItem"
                    :to="item.route">
                        
                        <v-list-item-icon>
                            <v-icon
                            v-text="item.icon"
                            :color="(item.route === $route.path ? 'tzpNavItem--text' : item.iconColor)"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-title v-html="t(item.title)"></v-list-item-title>

                    </v-list-item>

                    <v-divider v-if="'divider' in item && item.divider"></v-divider>

                </div>

            </div>

        </v-list>

        <v-skeleton-loader
        v-else
        type="v-list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar,list-item-avatar"></v-skeleton-loader>

        <div class="navigation-drawer--bottom bottom">
            
            <v-card 
            flat
            tile
            class="d-flex transparent px-1">

                <v-card 
                v-if="$vuetify.breakpoint.xs"
                flat
                tile
                class="transparent">
                    
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            block
                            color="transparent"
                            dark
                            elevation="0"
                            small
                            tile
                            to="/customer-support"
                            class="py-6">
                                <v-icon color="grey">mdi-help-circle-outline</v-icon>
                            </v-btn>

                        </template>
                        <span>{{ t("FAQ") }}</span>
                    </v-tooltip>

                </v-card>

                <v-card 
                flat 
                tile
                class="transparent">
                    
                    <lock-icon 
                    classes="py-6"
                    variant="outline"
                    to="/lock"></lock-icon>

                </v-card>

            </v-card>

        </div>

    </v-navigation-drawer>

</template>

<script>
import AvatarListInitials from "@/components/avatars/list/Initials.vue";
import { Companies } from "@/components/companies/api.js";
import {
    Config,
    User,
} from "@/components/user/api.js";
import LockIcon from "@/components/icons/LockIcon.vue";
import Status from '@/components/Status.vue';

export default {
    name: "NavigationDrawer",

    mixins: [ 
        Companies.map(),
        Config.map(),
        User.map(),
    ],

    components: { 
        AvatarListInitials,
        LockIcon,
        Status
    },

    data: () => ({ }),
};
</script>

<style lang="scss" scoped>
.navigation-drawer--bottom {
    width: 100%;
    background-color: #fff;
}

// Theme dark
.theme--dark {
    .navigation-drawer--bottom { background-color: #2e2e2e; }
    .v-navigation-drawer__content .empty { color: #9e9e9e; } // grey
}
</style>