<template>

    <span>
    
        <v-menu rounded="lg">
            
            <template v-slot:activator="{ on }">
                
                <v-btn
                v-on="on"
                icon>
                    <v-icon color="tzpViolet">mdi-dots-vertical</v-icon>
                </v-btn>

            </template>

            <v-list class="pa-0">
                
                <v-list-item @click="$refs.supportDialog.showDialog()">

                    <v-list-item-icon class="mr-4">

                        <v-icon>mdi-lifebuoy</v-icon>

                    </v-list-item-icon>

                    <v-list-item-title>{{ t('FAQ') }}</v-list-item-title>

                </v-list-item>

                <v-list-item @click="$eventBus.$emit('showTour', '/full')">

                    <v-list-item-icon class="mr-4">

                        <v-icon>mdi-compass-outline</v-icon>

                    </v-list-item-icon>

                    <v-list-item-title>{{ t('Tour') }}</v-list-item-title>

                </v-list-item>

                <v-list-item
                v-if="$auth.isLoggedIn()"
                @click="$eventBus.$emit('logout')">

                    <v-list-item-icon class="mr-4">

                        <v-icon>mdi-power-standby</v-icon>

                    </v-list-item-icon>

                    <v-list-item-title>{{ t('Logout') }}</v-list-item-title>
                    
                </v-list-item>

            </v-list>

        </v-menu>

        <support-dialog ref="supportDialog"></support-dialog>

    </span>
    
</template>

<script>
import SupportDialog from '@/components/support/SupportDialog.vue';

export default {
    name: 'SupportMenu',

    components: {
        SupportDialog,
    },

    data: () => ({ }),

}
</script>