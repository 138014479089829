<template>

    <router-link 
    v-if="to"
    :to="to"
    class="profile-wrap px-4">
        
        <div 
        v-if="initials"
        class="profile-avatar text-center mb-4">

            <avatar-initials
            :elevation="2"
            :initials="initials"
            :size="size"></avatar-initials>

        </div>

        <div 
        v-if="title"
        class="text-center">
            <div class="profile-title mb-2 muli--bold">{{ title }}</div>
            <div 
            v-if="subtitle"
            class="profile-subtitle muli">
            {{ subtitle }}
            </div>
        </div>

    </router-link>

    <div
    v-else
    class="profile-wrap px-4">
        
        <div 
        v-if="initials"
        class="profile-avatar text-center mb-4">
            
            <avatar-initials
            :elevation="2"
            :initials="initials"
            :size="size"></avatar-initials>
            
        </div>

        <div 
        v-if="title"
        class="text-center">

            <div class="profile-title mb-2">{{ title }}</div>
            
            <div 
            v-if="subtitle"
            class="profile-subtitle">
            {{ subtitle }}
            </div>

        </div>

    </div>

</template>

<script>
import AvatarInitials from '@/components/avatars/Initials.vue'

export default {
    name: 'AvatarProfileInitials',
    
    props: {
        initials: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false
        },
        size: {
            type: Number,
            default: 72,
        },
        to: {
            type: String,
            required: false
        }
    },

    components: { AvatarInitials },
}
</script>