import { Collection, } from 'collect.js';
import { collect } from 'collect.js';

export default class EntityCollection extends Collection {

    constructor ( extend ) {
        super();
        this.init = false;
        Object.assign(this, extend);
    }

    setInit(init) {
        this.init = init;
    }

    clone() {
        const clone = collect(this);
        return clone;
    }
}
