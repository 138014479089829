<template>
    
    <div>

        <v-card
        rounded="tzp"
        class="mb-3">
        
            <v-list
            subheader
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">

                <!-- <v-list-item v-if="$prefs.settings.fingerPrintAvailable">
                    
                    <v-list-item-avatar>
                        <v-icon color="tzpOrange">mdi-fingerprint</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ t("Fingerprint_recognition") }}</v-list-item-title>
                        <v-list-item-subtitle>{{ t("Fingerprint_recognition_description") }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-switch
                        v-model="$prefs.settings.fingerPrintEnabled"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>
                    </v-list-item-action>

                </v-list-item>

                <v-list-item v-else>

                    <v-list-item-content>
                        <v-list-item-title>{{ t("Add_fingerprint_recognition?") }}</v-list-item-title>
                        <v-list-item-subtitle>{{ t("Add_fingerprint_recognition_description") }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-switch
                        v-model="$prefs.settings.fingerPrintEnabled"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>
                    </v-list-item-action>

                </v-list-item> -->
                
            </v-list>
        
        </v-card>

    </div>

</template>

<script>
export default {
    name: "Security",

    data: () => ({ }),
};
</script>