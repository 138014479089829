<template>

    <v-main>
        
        <v-container>

            <v-row 
            cols="12"
            justify="center">
                
                <v-col  
                sm="6"
                md="6"
                lg="4"
                xl="3">
                    
                    <v-card 
                    align-center
                    color="transparent"
                    flat
                    justify-center
                    min-height="122px">
                        
                        <v-img 
                        v-if="!$prefs.settings.darkModeEnabled"
                        contain
                        eager
                        src="img/thuiszorgplanner.svg"
                        class="ma-6 mt-12"></v-img>

                        <v-img 
                        v-else
                        aspect-ratio="1"
                        contain
                        eager
                        src="img/dark/thuiszorgplanner_dark.svg"
                        class="ma-6 mt-12"></v-img>

                    </v-card>

                    <stepper
                    :flat="true"
                    ref="stepper"
                    :responseDescription="stepperResponseDescription"
                    :responseImage="stepperResponseImage"
                    :responseTitle="stepperResponseTitle"
                    :responseType="stepperResponseType"
                    :steps="[1]">

                        <template v-slot:step1>

                            <v-card
                            align-center
                            justify-center
                            loading>

                                <v-card-title class="headline">{{ t('Please_wait') }}</v-card-title>

                            </v-card>

                        </template>

                    </stepper>                  

                </v-col>
                
            </v-row>

        </v-container>

        <tzp-footer></tzp-footer>

    </v-main>

</template>

<script>
import Stepper from '@/components/Stepper.vue'
import TzpFooter from '@/system/components/Footer.vue'

export default {
    name: 'ConfirmationActions',

    components: { 
        Stepper,
        TzpFooter
    },

    data: () => ({
        stepperResponseDescription: false,
        stepperResponseImage: null,
        stepperResponseTitle: '',
        stepperResponseType: 'info',
        type: false,
        token: false,
    }),

    mounted() {
        this.type = this.$route.params.type;
        this.token = this.$route.params.token;
            
        switch (this.type) {
            case 'password-reset-email-verification':
                this.stepperResponseImage = this.type
                this.verifyPasswordEmail()
                break;
            case 'revoke-device':
                this.stepperResponseImage = this.type
                this.revokeDevice()
                break;
        }
    },

    methods: {
        verifyPasswordEmail() {
            this.$apiListeners.email_verify_password_reset(this.token).then((response) => {
                
                this.stepperResponseDescription = 'Confirmation_actions_email_verified_description' //response.data.message
                this.stepperResponseTitle = 'Confirmation_actions_email_verified_title'
                this.stepperResponseType = 'success'

                this.$refs.stepper.nextStep(1)
            }, (error) => {
                
                if( 'response' in error ) {
                    this.stepperResponseDescription = 'Confirmation_actions_'+error.response.data.message+'_description'
                    this.stepperResponseTitle = 'Confirmation_actions_'+error.response.data.message+'_title'                
                    this.stepperResponseType = 'error'
                }
                else {
                    this.stepperResponseDescription = error.stack
                    this.stepperResponseTitle = error.message               
                    this.stepperResponseType = 'error'
                }

                this.$refs.stepper.nextStep(1)
            })
        },
        revokeDevice() {
            this.$apiListeners.revoke_device(this.token).then((response) => {
                
                this.stepperResponseDescription = 'Confirmation_actions_device_revoke_description'
                this.stepperResponseTitle = 'Confirmation_actions_device_revoke_title'
                this.stepperResponseType = 'success'

                this.$refs.stepper.nextStep(1)                              
            }, (error) => {
                
                if( 'response' in error ) {
                    this.stepperResponseDescription = 'Confirmation_actions_'+error.response.data.message+'_description'
                    this.stepperResponseTitle = 'Confirmation_actions_'+error.response.data.message+'_title'
                    this.stepperResponseType = 'error'
                }
                else {
                    this.stepperResponseDescription = error.stack
                    this.stepperResponseTitle = error.message               
                    this.stepperResponseType = 'error'
                }

                this.$refs.stepper.nextStep(1)
            })
        }
    },
}
</script>