/*
 * Data Formats - Material Design
 * 
 * https://material.io/design/communication/data-formats.html#date-and-time
*/
import '../services/Debug.service';
import Preferences from '@/system/services/Preferences.service';
import VueTranslate from '@/system/services/Language.service';

const Time = {

    languages: VueTranslate.languages, // Get the VueTranslate languages

    day: {
        abbr: [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ],
        full: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saterday' ],
    },

    month: {
        abbr: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
        full: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    },

    dateFormat: {
        en: 'MM DD YYYY', // January 1, 1970
        nl_NL: 'DD MM YYYY', // 1 January, 1970
    },

    // Check if we found a language file (VueTranslate)
    getLang () {
        if ( this.languages[ Preferences.settings.languageCode ] ) {
            return this.languages[ Preferences.settings.languageCode ];
        }

        return false;
    },

    // Locale Time translate function :)
    t ( text ) {
        if ( this.getLang() !== false && ( text in this.getLang() ) ) {
            let lang = this.getLang()
            return lang[ text ];
        }

        return text;
    },

    // Set the date and time notation
    notation () {
        if ( this.dateFormat[ Preferences.settings.languageCode ] ) {
            return this.dateFormat[ Preferences.settings.languageCode ];
        }

        return this.dateFormat[ 'en' ]; // fallback notation to English
    },

    now ( mm = false ) {
        if ( mm ) { // return timestamp in miliseconds
            return new Date().getTime();
        }

        // return timestamp in seconds
        return Math.round( new Date().getTime() / 1000 );
    },

    // Return date in miliseconds
    dateByTimestamp ( timestamp = false ) {
        return new Date( ( timestamp ? timestamp * 1000 : this.now( true ) ) );
    },

    // Return difference between timestamp and today
    dateDifference ( timestamp ) {
        if ( !timestamp ) {
            return false;
        }

        return this.now() - timestamp;
    },

    // Check if we have date in the future
    checkFuture ( timestamp ) {
        if ( !timestamp ) {
            Debug.error( 'checkFuture \'Unknown\' > no timestamp provided.' );

            return false;
        }

        let days = this.days( timestamp );

        if ( days < 0 ) {
            return days;
        }

        return false;
    },

    // Check if it's in the same day
    checkToday ( timestamp ) {
        if ( !timestamp ) {
            Debug.error( 'checkToday \'Unknown\' > no timestamp provided.' );

            return false;
        }

        let date = this.dateByTimestamp( timestamp );
        let today = this.dateByTimestamp();

        if ( date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            this.checkYear( timestamp ) ) {
            return true;
        }

        return false;
    },

    // Check if it is less than a week ago
    checkWeek ( timestamp ) {
        if ( !timestamp ) {
            Debug.error( 'checkWeek \'Unknown\' > no timestamp provided.' );

            return false;
        }

        let days = this.days( timestamp );

        if ( days > -7 && days < 7 ) {
            return days;
        }

        return false;
    },

    // Check if it's the same year
    checkYear ( timestamp ) {
        if ( !timestamp ) {
            Debug.error( 'checkYear \'Unknown\' > no timestamp provided.' );

            return false;
        }

        let curYear = new Date().getFullYear();
        let date = this.dateByTimestamp( timestamp );
        let year = date.getFullYear();

        if ( curYear === year ) {
            return true;
        }

        return false;
    },

    // Calculate minutes
    getMinutes ( time ) {
        if ( !time ) {
            Debug.error( 'getMinutes \'Unknown\' > no time provided.' );

            return false;
        }

        let fixedMin = parseFloat( time ).toFixed( 1 );
        let decimal = fixedMin % 1;

        return decimal <= 0.5 ? Math.floor( time ) : Math.ceil( time );
    },

    // Calculate how many hour(s) and minute(s) ago
    hoursAgo ( timestamp, short = false ) {
        if ( !timestamp ) {
            Debug.error( 'hoursAgo \'Unknown\' > no timestamp provided.' );

            return this.t( 'Unknown' );
        }

        let diff = this.dateDifference( timestamp );
        let hours = Math.floor( diff / 60 / 60 ); // hours difference
        let hoursFormat = '';
        let minDiff = diff / 60; // minutes difference
        let minutes = minDiff <= 0 ? 0 : this.getMinutes( minDiff - 60 * hours ); // round minutes always to top
        let minutesFormat = '';

        if ( short ) {
            let shortFormat = '';

            if ( hours < 1 ) {
                if ( minutes === 0 ) {
                    shortFormat = this.t( 'just_now' );
                }
                else {
                    shortFormat = minutes + ' ' + this.t( 'min' ) + ' ' + this.t( 'ago' );
                }
            }
            else {
                shortFormat = this.time( timestamp );
            }

            return shortFormat;
        }
        else {
            // Minutes format
            if ( minutes == 0 ) {
                if ( hours < 1 ) {
                    minutesFormat = this.t( 'Just_now' );
                }
                else {
                    minutesFormat = 1 + ' ' + this.t( 'minute' ) + ' ' + this.t( 'ago' );
                }
            }
            else if ( minutes == 1 ) {
                minutesFormat = minutes + ' ' + this.t( 'minute' ) + ' ' + this.t( 'ago' );
            }
            else if ( minutes >= 1 ) {
                minutesFormat = minutes + ' ' + this.t( 'minutes' ) + ' ' + this.t( 'ago' );
            }

            // Hours format
            if ( hours == 1 ) {
                hoursFormat = hours + ' ' + this.t( 'hour' ) + ' ' + this.t( 'and' ) + ' ';
            }
            else if ( hours >= 1 ) {
                hoursFormat = hours + ' ' + this.t( 'hours' ) + ' ' + this.t( 'and' ) + ' ';
            }

            return hoursFormat + minutesFormat;
        }
    },

    // Calculate how many day(s) ago or in the future
    days ( timestamp = false ) {
        if ( !timestamp ) {
            Debug.error( 'days \'Unknown\' > no timestamp provided.' );

            return this.t( 'Unkown' );
        }

        let diff = this.dateDifference( timestamp );
        let days = Math.ceil( diff / 60 / 60 / 24 );

        if ( days == 0 && !this.checkToday( timestamp ) ) {
            days = 1;
        }

        return days;
    },

    // Show the date by the language notation format
    dateByNotation ( timestamp, short = false, separator = true, dayName = false, time = true ) {
        if ( !timestamp ) {
            Debug.error( 'dateByNotation \'Unknown\' > no timestamp provided.' );

            return this.t( 'Unknown' );
        }
        let date = this.dateByTimestamp( timestamp );
        let day = date.getDate();
        let month = this.t( ( short ? this.month.abbr[ date.getMonth() ] : this.month.full[ date.getMonth() ] ) );
        let notation = this.notation();
        let notationReplace = '';
        let ts = ( timestamp ? timestamp : this.now() );
        let sameYear = this.checkYear( ts );
        let sep = ( separator ? ', ' : ' ' );

        // Start creating the notation with checking the year
        if ( sameYear ) {
            notationReplace = notation.replace( 'DD', day ).replace( 'MM', month ).replace( ' YYYY', '' );
        }
        else {
            notationReplace = notation.replace( 'DD', day ).replace( 'MM', month ).replace( 'YYYY', date.getFullYear() );
        }

        // Do we need to add the name of the day?
        if ( dayName ) {

            let name = this.t( ( short ? this.day.abbr[ date.getDay() ] : this.day.full[ date.getDay() ] ) );
            notationReplace = name + sep + notationReplace;
        }

        // Do we need to add the time?
        if ( time ) {

            let HHmm = this.timeByNotation( ts );

            notationReplace = notationReplace + sep + HHmm;
        }

        return notationReplace;
    },

    // Time notation
    timeByNotation ( timestamp ) {
        if ( !timestamp ) {
            Debug.error( 'timeByNotation \'Unknown\' > no timestamp provided.' );

            return this.t( 'Unknown' );
        }
        let date = this.dateByTimestamp( timestamp );
        let hours = ( date.getHours() < 10 ? '0' : '' ) + date.getHours(); // getHours() show hours without leading zero
        let minutes = ( date.getMinutes() < 10 ? '0' : '' ) + date.getMinutes(); // getMinutes() show minutes without leading zero

        return hours + ':' + minutes;
    },

    /*
     * Date & Time
     * 
     * show the date and time by language date time notation
     * 
     * NOTE: timestamp not set, shows the current date and time
    */
    dateTime ( timestamp = false, short = false, separator = true, dayName = false, time = true ) {
        if ( timestamp ) {

            let date = this.dateByTimestamp( timestamp );
            let day = 0;
            let dayFormat = '';
            let HHmm = this.timeByNotation( timestamp );
            let sep = ( separator ? ', ' : ' ' );

            if ( this.checkFuture( timestamp ) !== false ) {
                if ( this.checkToday( timestamp ) ) {
                    return this.t( 'today' ) + sep + HHmm;
                }
                else if ( ( day = this.checkWeek( timestamp ) ) < 0 &&
                    ( day = this.checkWeek( timestamp ) ) > -7 ) {
                    if ( day == -1 ) {
                        dayFormat = this.t( 'tomorrow' );
                    }
                    else {
                        dayFormat = this.t( this.day.abbr[ date.getDay() ] );
                    }

                    return dayFormat + sep + HHmm;
                }
                else {
                    return this.dateByNotation( timestamp, short, separator, dayName, time );
                }
            }
            else if ( this.checkToday( timestamp ) ) {

                return this.hoursAgo( timestamp, short );
            }
            else if ( this.checkWeek( timestamp ) !== false && ( day = this.checkWeek( timestamp ) ) >= 0 && ( day = this.checkWeek( timestamp ) ) < 7 ) {
                if ( day == 1 ) {
                    dayFormat = this.t( 'yesterday' );
                }
                else if ( short ) {
                    dayFormat = this.t( this.day.abbr[ date.getDay() ] );
                }
                else {
                    dayFormat = day + ' ' + this.t( 'days' ) + ' ' + this.t( 'ago' );
                }

                return dayFormat + sep + HHmm;
            }
            else {
                return this.dateByNotation( timestamp, short, separator, dayName );
            }
        }

        return this.dateByNotation( short, separator, dayName );
    },

    /*
     * Date
     * 
     * show the date by language date notation
     * 
     * NOTE: timestamp not set, shows the current date
    */
    date ( timestamp = false, short = false, dayName = false ) {
        return this.dateByNotation( timestamp, short, false, dayName, false );
    },

    /*
     * Time
     * 
     * show the time by language time notation
     * 
     * NOTE: timestamp not set, shows the current time
    */
    time ( timestamp = false ) {
        return this.timeByNotation( timestamp );
    },

    /*
     * Day Part
     * 
     * show the naming of the current part of the day
    */
    dayPart () {
        let date = new Date( this.now( true ) );
        let hours = date.getHours();

        return ( hours < 12 ? 'morning' : ( hours < 18 ? 'afternoon' : 'evening' ) );
    },

    /**
     * Compare date
     */
    dateCompare ( date, date2, comparison, type ) {
        let firstDate = {
            day: new Date( this.dateByTimestamp( date ) ).getDate(),
            month: new Date( this.dateByTimestamp( date ) ).getMonth(),
            year: new Date( this.dateByTimestamp( date ) ).getFullYear(),
            hours: new Date( this.dateByTimestamp( date ) ).getHours(),
            minutes: new Date( this.dateByTimestamp( date ) ).getMinutes(),
            seconds: new Date( this.dateByTimestamp( date ) ).getSeconds(),
        }
        let secondDate = {
            day: new Date( this.dateByTimestamp( date2 ) ).getDate(),
            month: new Date( this.dateByTimestamp( date2 ) ).getMonth(),
            year: new Date( this.dateByTimestamp( date2 ) ).getFullYear(),
            hours: new Date( this.dateByTimestamp( date2 ) ).getHours(),
            minutes: new Date( this.dateByTimestamp( date2 ) ).getMinutes(),
            seconds: new Date( this.dateByTimestamp( date2 ) ).getSeconds(),
        }

        switch ( type ) {
            case 'year':
                this.compareYear( firstDate.year, secondDate.year, comparison );
                break;
            case 'month':
                if ( this.compareYear( firstDate.year, secondDate.year, '==' ) ) {
                    this.compareMonth( firstDate, secondDate, comparison );
                }
                break;
            case 'day':
                if ( this.compareYear( firstDate.year, secondDate.year, '==' ) &&
                    this.compareMonth( firstDate.month, secondDate.month, '==' ) ) {
                    this.compareDay( firstDate.day, secondDate.day, comparison );
                }
                break;
            case 'hour':
            case 'hours':
                if ( this.compareYear( firstDate.year, secondDate.year, '==' ) &&
                    this.compareMonth( firstDate.month, secondDate.month, '==' ) &&
                    this.compareDay( firstDate.day, secondDate.day, '==' ) ) {
                    this.compareHours( firstDate.hours, secondDate.hours, comparison );
                }
                break;
            case 'minute':
            case 'minutes':
                if ( this.compareYear( firstDate.year, secondDate.year, '==' ) &&
                    this.compareMonth( firstDate.month, secondDate.month, '==' ) &&
                    this.compareDay( firstDate.day, secondDate.day, '==' ) &&
                    this.compareHours( firstDate.hours, secondDate.hours, comparison ) ) {
                    this.compareMinutes( firstDate.minutes, secondDate.minutes2, comparison );
                }
                break;
            case 'second':
            case 'seconds':
                if ( this.compareYear( firstDate.year, secondDate.year, '==' ) &&
                    this.compareMonth( firstDate.month, secondDate.month, '==' ) &&
                    this.compareDay( firstDate.day, secondDate.day, '==' ) &&
                    this.compareHours( firstDate.hours, secondDate.hours, comparison ) &&
                    this.compareMinutes( firstDate.minutes, secondDate.minutes2, comparison ) ) {
                    this.compareSeconds( firstDate.seconds, secondDate.seconds2, comparison );
                }
                break;
            default:
                console.warn( 'No correct type given.' );
                return false;
        }
    },

    compareYear ( year, year2, operator ) {
        return this.compareByOperator( year, year2, operator );
    },
    compareMonth ( month, month2, operator ) {
        return this.compareByOperator( month, month2, operator );
    },
    compareDay ( day, day2, operator ) {
        return this.compareByOperator( day, day2, operator );
    },
    compareHours ( hour, hour2, operator ) {
        return this.compareByOperator( hour, hour2, operator );
    },
    compareMinutes ( minutes, minutes2, operator ) {
        return this.compareByOperator( minutes, minutes2, operator );
    },
    compareSeconds ( seconds, seconds2, operator ) {
        return this.compareByOperator( seconds, seconds2, operator );
    },
    compareByOperator ( a, b, operator ) {
        let result = false;

        switch ( operator ) {
            case '==':
                result = a == b;
                break;
            case '===':
                result = a == b;
                break;
            case '!=':
                result = a != b;
                break;
            case '!==':
                result = a != b;
                break;
            case '>':
                result = a > b;
                break;
            case '<':
                result = a < b;
                break;
            case '>=':
                result = a >= b;
                break;
            case '<=':
                result = a <= b;
                break;
            default:
                break;
        }

        return result;
    }
}
export default Time;