import Vue from 'vue';
import Router from 'vue-router';

// Views & components.
import Authentication from '@/views/Authentication.vue';

import ForgotPassword from '@/views/ForgotPassword.vue';
import ConfirmationActions from '@/views/ConfirmationActions.vue';

import Support from '@/views/Support.vue';
import Lockscreen from '@/views/gated/Lockscreen.vue';

import Main from '@/views/gated/Main.vue';
import Dashboard from '@/views/gated/Dashboard.vue';
import CustomerSupport from '@/views/gated/CustomerSupport.vue';
import Settings from '@/views/gated/Settings.vue';
import News from '@/views/gated/News.vue';
import NewsItem from '@/views/gated/NewsItem.vue';
import CompaniesView from '@/views/gated/Company.vue';
import Profile from '@/components/user/Profile.vue';
import DevicesList from '@/components/devices/DevicesList.vue';
import Docs from '@/packages/docs/components/Docs.vue';
import Account from '@/components/user/Account.vue';
import DevicesSettings from '@/views/gated/Settings/Devices.vue';
import NotificationsSettings from '@/views/gated/Settings/Notifications.vue';
import InterfaceSettings from '@/views/gated/Settings/Interface.vue';
import SecuritySettings from '@/views/gated/Settings/Security.vue';
import ToolsSettings from '@/views/gated/Settings/Tools.vue';
import FeedbackForm from '@/components/support/FeedbackForm.vue';
import AboutSettings from '@/views/gated/Settings/About.vue';
import TermsSettings from '@/views/gated/Settings/Terms.vue';
import Page from '@/views/gated/Page.vue';
import Frame from '@/views/gated/Frame.vue';
import Playground from '@/views/Playground.vue';

Vue.use( Router );

/**
 * Lazyloading Components
 * 
 * @link https://blog.logrocket.com/vue-lazy-loading-components-code-splitting/
 * @link https://router.vuejs.org/guide/advanced/lazy-loading.html#grouping-components-in-the-same-chunk
 */
// function lazyLoad ( view ) {
//     return () => import( `@/views/${ view }.vue` )
// }

// Router (local) translate function
function t ( key ) {
    return '/' + Vue.prototype.$translate.text( 'Route_' + key );
}

export const router = new Router( {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: Authentication,
            meta: {
                public: true,
            },
        },
        {
            path: '/support',
            name: 'Support',
            component: Support,
            meta: {
                appbar: {
                    title: {
                        text: 'FAQ',
                    }
                },
                forceAllowNavigation: true,
            },
        },
        {
            path: '/forgot-password',
            name: 'Forgot_password',
            component: ForgotPassword,
            meta: {
                public: true,
            },
        },
        {
            path: '/confirmation-actions/api/:type/:token',
            name: 'Confirmation_actions',
            component: ConfirmationActions,
            meta: {
                anonymous: true,
                public: true
            },
        },
        {
            path: '/lock',
            component: Lockscreen,
            meta: {
                caged: true,
            },
        },
        {
            path: '',
            component: Main,
            children: [
                {
                    path: '/',
                    redirect: {
                        name: 'Dashboard'
                    },
                },
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        active: true,
                        appbar: {
                            intersector: true,
                            title: {
                                show: false,
                                text: 'Dashboard'
                            },
                        },
                        icon: 'mdi-home-analytics',
                        public: false,
                    },
                },
                {
                    path: '/customer-support',
                    name: 'FAQ',
                    component: CustomerSupport,
                    meta: {
                        appbar: {
                            title: {
                                text: 'FAQ',
                            }
                        },
                        public: false,
                    },
                },
                {
                    path: '/news', // news overview
                    name: 'News',
                    component: News,
                    meta: {
                        appbar: {
                            title: {
                                text: 'News',
                            }
                        },
                        public: false,
                    },
                },
                {
                    path: '/news/:slug', // single news item
                    name: 'News_item',
                    component: NewsItem,
                    meta: {
                        appbar: {
                            back: true,
                            title: {
                                text: 'News_item',
                            }
                        },
                        // breadcrumbs: {
                        //     items: {
                        //         0: 'News'
                        //     },
                        //     show: true,
                        // },
                        public: false,
                    },
                },
                {
                    path: '/timeline', //Virtual
                    name: 'Timeline',
                    meta: {
                        appbar: {
                            title: {
                                text: 'Timeline',
                            }
                        },
                        icon: 'mdi-routes-clock',
                        public: false,
                    },
                },
                {
                    path: '/agenda', //Virtual
                    name: 'Agenda',
                    meta: {
                        appbar: {
                            title: {
                                text: 'Agenda',
                            }
                        },
                        icon: 'mdi-calendar-month',
                        public: false,
                    },
                },
                {
                    path: '/clients', //Virtual
                    name: 'Clients',
                    meta: {
                        appbar: {
                            title: {
                                text: 'Clients',
                            }
                        },
                        icon: 'mdi-clipboard-account',
                        public: false,
                    },
                },
                {
                    path: '/info', //Virtual
                    name: 'Info',
                    meta: {
                        appbar: {
                            title: {
                                text: 'My_details',
                            }
                        },
                        icon: 'mdi-badge-account-horizontal',
                        public: false,
                    },
                },
                {
                    path: '/documents', //Virtual
                    name: 'Documents',
                    meta: {
                        appbar: {
                            title: {
                                text: 'Documents',
                            }
                        },
                        icon: 'mdi-file-multiple',
                        public: false,
                    },
                },
                {
                    path: '/settings',
                    name: 'Settings',
                    component: Settings,
                    alias: '/instellingen', // Alias for translate, can be a Array
                    meta: {
                        appbar: {
                            title: {
                                text: 'Settings',
                            }
                        },
                        icon: 'mdi-cog',
                        public: false,
                    },
                    children: [
                        {
                            path: 'account',
                            name: 'Account',
                            component: Account,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                public: false
                            },
                        },
                        // {
                        //     path: 'profile',
                        //     name: 'Profile',
                        //     component: Profile,
                        //     meta: {
                        //         appbar: {
                        //             title: {
                        //                 text: 'Settings',
                        //             }
                        //         },
                        //         public: false,
                        //     },
                        // },
                        {
                            path: 'devices',
                            name: 'Devices',
                            component: DevicesSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                description: 'Settings_page_devices_description',
                                public: false,
                            },
                        },
                        {
                            path: 'notifications',
                            name: 'Notifications',
                            component: NotificationsSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                description: 'Settings_page_notifications_description',
                                public: false,
                            },
                        },
                        {
                            path: 'interface',
                            name: 'Interface',
                            component: InterfaceSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                description: 'Settings_page_interface_description',
                                public: false,
                            },
                        },
                        // {
                        //     path: 'security',
                        //     name: 'Security',
                        //     component: SecuritySettings,
                        //     meta: {
                        //         appbar: {
                        //             title: {
                        //                 text: 'Settings',
                        //             }
                        //         },
                        //         description: 'Settings_page_security_description',
                        //         public: false,
                        //     },
                        // },
                        {
                            path: 'tools',
                            name: 'Tools',
                            component: ToolsSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                description: 'Settings_page_tools_description',
                                public: false,
                            },
                        },
                        {
                            path: 'feedback',
                            name: 'Feedback',
                            component: FeedbackForm,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                public: false,
                            },
                        },
                        {
                            path: 'about',
                            name: 'About',
                            component: AboutSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                public: false,
                            },
                        },
                        {
                            path: 'terms',
                            name: 'Terms',
                            component: TermsSettings,
                            meta: {
                                appbar: {
                                    title: {
                                        text: 'Settings',
                                    }
                                },
                                public: false,
                            },
                        },
                    ],
                },
                {
                    path: 'companies/:uuid',
                    name: 'Company',
                    component: CompaniesView,
                    meta: {
                        appbar: {
                            back: true,
                            title: {
                                text: 'Company',
                            }
                        },
                        public: false,
                    },
                },
                {
                    path: '',
                    name: 'SinglePage',
                    component: Page, // Default page view
                    children: [
                        {
                            path: 'devices',
                            name: 'Devices',
                            component: DevicesList,
                            meta: {
                                appbar: {
                                    back: true,
                                    title: {
                                        text: 'Devices',
                                    }
                                },
                                description: 'Settings_page_devices_description',
                                page: {
                                    width: 'sm',
                                    wrapper: true,
                                },
                                public: false,
                            },
                        },
                        {
                            path: 'feedback',
                            name: 'Feedback',
                            component: FeedbackForm,
                            meta: {
                                appbar: {
                                    back: true,
                                    title: {
                                        text: 'Feedback',
                                    }
                                },
                                page: { // only needed for 'single' page display
                                    width: 'md',
                                },
                                public: false,
                            },
                        },
                        {
                            path: 'profile',
                            name: 'Profile',
                            component: Profile,
                            meta: {
                                appbar: {
                                    back: true,
                                    title: {
                                        text: 'Profile',
                                    }
                                },
                                page: {
                                    width: 'sm',
                                },
                                public: false,
                            },
                        },
                    ]
                }
            ],
        },
        {
            path: '/docs/:version',
            name: 'Docs',
            component: Docs,
        },
        {
            path: '/docs/:version/:category',
            name: 'Docs',
            component: Docs,
        },
        {
            path: '/logout', //Virtual
            name: 'Logout',
        },
        // {
        //     path: '/playground',
        //     name: 'Playground',
        //     component: Playground,
        // },
        {
            path: '*',
            redirect: '/login', //Catch other
        },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 };
    },
} );

export default router;
