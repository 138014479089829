/**
 * API Click handling
 * 
 * method to handle the click on a API item
 */
const apiClick = {
    data: () => ( {
        loading: false,
    } ),

    methods: {
        handleClick ( click ) {
            switch ( click.type ) {
                case 'client.event':
                    this.lock();

                    this.$apiListeners[ click.action ]( click.to ).then( ( response ) => {
                        this.$emit( 'onSuccess', response.data );
                        this.unlock();
                    },
                        ( error ) => {
                            this.$emit( 'onFail', error.response.data );
                            this.unlock();

                            Debug.error( error );
                        } );

                    break;
                case 'client.nav':
                    let to = ( click.to.startsWith( '/' ) ? click.to : '/' + click.to );
                    this.closeNotificationDrawer();

                    if ( this.$route.fullPath !== to ) {
                        this.$router.push( to );
                    }

                    break;
                case 'client.nav.tzp8':
                    this.closeNotificationDrawer();
                    this.$proxy.open( process.env.VUE_APP_PROXY_URL + click.to );

                    break;
                case 'url':
                    window.open( click.to, '_blank' );

                    break;
            }
        },
        lock () {
            this.$emit( 'onBusy' );
            this.loading = true;
        },
        unlock () {
            this.loading = false;
        },
        closeNotificationDrawer () {
            if ( this.$ui.notificationDrawer.toggled ) {
                this.$ui.notificationDrawer.toggle();
            }
        }
    }
}

export default apiClick