<template>

    <div>
    
        <v-main>

            <v-app-bar 
            absolute
            color="transparent"
            flat>
                
                <v-btn 
                v-if="$vuetify.breakpoint.xs && stepperProgress !== 1 && ! $auth.isLoggedIn()"
                @click="showDialog()"
                color="tzpViolet"
                icon
                class="ml-0">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-spacer></v-spacer>

                <support-menu></support-menu>

            </v-app-bar>
            
            <v-container>

                <v-row 
                cols="12"
                justify="center">
                
                    <v-col  
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3">
                        
                        <v-card 
                        elevation="0"
                        color="transparent">
                            
                            <v-layout 
                            column
                            center>

                                <v-img 
                                v-if="!$prefs.settings.darkModeEnabled"
                                aspectRatio="5.17"
                                eager
                                src="img/thuiszorgplanner.svg"
                                class="ma-6 mt-12"></v-img>
                                
                                <v-img 
                                v-else
                                aspect-ratio="5.17"
                                eager
                                src="img/dark/thuiszorgplanner_dark.svg"
                                class="ma-6 mt-12"></v-img>

                                <v-container 
                                v-if="stepperProgress !== 5"
                                class="mb-2">

                                    <v-progress-linear 
                                    color="tzpViolet"
                                    :value="$ui.progressBar.count"
                                    height="3"></v-progress-linear>

                                </v-container>

                                <stepper
                                @lastStep="lastStep()"
                                contentClass="pa-0"
                                :flat="true"
                                ref="stepper"
                                :responseButton="{
                                    action: true,
                                    text: 'I_understand',
                                }"
                                responseDescription="Sensitive_data_dialog_text"
                                responseImage="sensitive-data-warning"
                                responseTitle="Sensitive_data_dialog_title"
                                responseType="warning"
                                :steps="[1,2,3,4]">

                                    <template v-slot:step1>

                                        <login 
                                        @nextStep="nextStep"
                                        ref="login"></login>

                                    </template>

                                    <template v-slot:step2>

                                        <sms 
                                        @nextStep="nextStep"
                                        ref="sms"
                                        class="pa-3"></sms>

                                    </template>

                                    <template v-slot:step3>

                                        <pin 
                                        @nextStep="nextStep"
                                        mode="setup"
                                        ref="pin"></pin>

                                    </template>

                                    <template v-slot:step4>

                                        <pin-verified 
                                        @nextStep="nextStep"
                                        mode="setup"
                                        ref="pinVerified"></pin-verified>

                                    </template>

                                </stepper>

                                <div 
                                v-if="! $vuetify.breakpoint.xs && stepperProgress !== 1 && ! $auth.isLoggedIn()"
                                class="mt-6 text-center ">
                                    <span 
                                    @click="showDialog()"
                                    class="primary--text body-1 cursor-pointer">{{ t('Cancel') }}</span>
                                </div>
                                
                            </v-layout>

                        </v-card>

                    </v-col>
                    
                </v-row>

            </v-container>

        </v-main>

        <tzp-footer v-if="$vuetify.breakpoint.mdAndUp && stepperProgress == 1"></tzp-footer>

    </div>

</template>

<script>
import Login from '@/system/components/authentication/Login.vue'
import Pin from '@/system/components/authentication/Pin.vue'
import PinVerified from '@/system/components/authentication/PinVerified.vue'
import Setup from '@/classes/Setup.js'
import Sms from '@/system/components/authentication/SMS.vue'
import Stepper from '@/components/Stepper.vue'
import SupportMenu from '@/components/support/SupportMenu.vue'
import TzpFooter from '@/system/components/Footer.vue'

export default {
    name: 'Authentication',

    components: {
        Login,
        Pin,
        PinVerified,
        Stepper,
        Sms,
        SupportMenu,
        TzpFooter,
    },

    data: () => ({
        stepperProgress: 1
    }),

    created () {
        document.addEventListener( 'keyup', this._handleEnter );
    },

    mounted() {
        // Progressbar start
        this.$ui.progressBar.setValue(0)

        // Get the progress from the Stepper (just to be sure) 
        this.setProgress()
    },

    beforeDestroy () {
        document.removeEventListener( 'keyup', this._handleEnter );
    },

    methods: {
        nextStep( val ) {
            // Go to the next step
            this.$refs.stepper.nextStep( val )
            if( val === 1 ) {
                setTimeout(() => {
                    this.$refs.sms.setFocusOnOTC();
                }, 500);
            }
            else if( val === 2 ) {
                setTimeout(() => {
                    this.$refs.pin.showKeyboard()
                }, 500);
            }

            // Update the progress
            this.setProgress()
        },
        showDialog() {
            this.$ui.dialog.show({
                buttons: [
                    {
                        text: 'Cancel',
                        click: () => {
                            this.$ui.dialog.hide() // Close the dialog
                        } 
                    },
                    {
                        text: 'Abort',
                        click: () => {
                            Setup.cancelDevice().then(() => {
                                // Hide toast
                                this.$root.$refs.toast.hide();
                                
                                // Close the dialog
                                this.$ui.dialog.hide();
                                
                                // Reset all fields to default
                                this.$refs.login.reset();
                                this.$refs.sms.reset();
                                this.$refs.pin.reset();

                                // Start over
                                this.stepperProgress = 1;
                                this.$refs.stepper.reset();

                                // Progressbar reset
                                this.$ui.progressBar.setValue(0);
                            })
                        }
                    }
                ],
                description: 'Abort_setup_dialog_description',
                title: 'Abort_setup_dialog_title',
            });
        },
        setProgress() {
            this.stepperProgress = this.$refs.stepper.getProgress();
        },
        lastStep() {
            this.$prefs.settings.unacknowledgedSensitiveData = false; 
            this.$prefs.save();
            this.$router.push( '/dashboard' );
        },
        _handleEnter( evt ) {
            if (evt.key === 'Enter') {
                evt.preventDefault(); // be sure no other action is triggered
                if( this.stepperProgress === 4 ) {
                    this.$refs.pinVerified.click();
                }
                else if( this.stepperProgress === 5 ) {
                    this.lastStep();
                }
            }
        }
    }
}
</script>