<template>

    <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    :transition="false">

        <v-card
        rounded="0"
        class="basic">

            <v-toolbar
            color="tzpAppBar"
            dark
            class="v-toolbar--fixed">

                <v-btn
                icon
                dark
                @click="hideDialog()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-toolbar-title>{{ t('FAQ') }}</v-toolbar-title>

            </v-toolbar>

            <customer-support ref="customerSupport"></customer-support>

        </v-card>

    </v-dialog>

</template>

<script>
import CustomerSupport from '@/views/gated/CustomerSupport.vue'

export default {
    name: 'SupportDialog',

    components: { 
        CustomerSupport,
    },

    data: () => ({
        dialog: false
    }),

    methods: {
        hideDialog() {
            this.dialog = false
            // delay support reset, because of dialog close animation
            setTimeout(() => {
                this.$refs.customerSupport.resetSupport()
            }, 500);            
        },
        showDialog() {
            this.dialog = true
        },
    },
}
</script>