/**
 * Devices API 
 * 
 * Contains API and Resource calls
 */
import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

const DeviceEntity = new Entity( {
    getTitle () {
        let title = '';
        let titleSuffix = '';

        if ( this.name === 'Apple Mac' ) {
            // iPad UserAgent default is set to desktop
            if ( this.browser.family === 'Apple Mail' ) {
                title = 'Apple iPad';
            }
            else {
                title = this.browser.family;
            }
        }
        else {
            title = this.name;
        }

        if ( this.type === 'desktop' && title !== 'Apple iPad' ) {
            titleSuffix = ' (' + this.platform.name + ')';
        }

        return title + titleSuffix;
    },
} )

/* API */
export const device = {
    /**
     * Device revoke by Token
     * 
     * @async
     * @param {string} revokeToken - The device token to revoke the device
     * @returns {promise}
     */
    revokeByToken: async ( revokeToken ) => {
        return Api.request( {
            method: 'POST',
            url: 'auth/device/app/revoke-by-token',
            token: false,
            data: {
                revoke_token: revokeToken
            }
        } )
    },
    /**
     * 
     * @param {object} updateData - Data to update the device status
     * @returns {promise}
     */
    updateStatus: async ( updateData ) => {
        return Api.request( {
            method: 'patch',
            url: 'auth/device/status',
            data: updateData,
            skipAuthRefresh: true
        } )
    },
}

/* RESOURCES */

/**
 * Device status
 * 
 *  Perform an action on the current device of the authenticated user
 * 
 *  PATCH - modify the device status
 *      data: {
 *          revoked: 1  -> revoke device
 *          locked: 1 -> lock device
 *      }
 */
export const DeviceStatus = new Resource( {
    location: 'auth/device/status',
    state: 'DeviceStatus',
    autoFill: false,
    forceRefill: false,
    methods: [
        'patch'
    ],
    data: {},

    entity: DeviceEntity
} )

/**
 * Devices
 * 
 *  Get all devices of the authenticated user
 */
export const Devices = new Resource( {
    location: 'auth/devices',
    state: 'Devices',
    autoFill: false,
    forceRefill: false,
    methods: [
        'get',
        'delete'
    ],
    data: {},

    collection: new EntityCollection( {

    } ),
    entity: DeviceEntity
} )

export const UserDevice = new Resource( {
    location: 'auth/user/devices/{{uuid}}',
    state: 'Devices',
    autoFill: false,
    forceRefill: false,
    methods: [
        'patch',
        'delete'
    ],
    data: {},

    entity: DeviceEntity
} )

/**
 * Devices status
 * 
 *  Perform an action on a registered device of the authenticated user
 * 
 *  Patch - modify the device status
 *      params: {
 *          uuid: ...
 *      }
 *      data: {
 *          revoked: 1  -> revoke device
 *          locked: 1 -> lock device
 *      }
 */
export const DevicesStatus = new Resource( {
    location: 'auth/devices/{{uuid}}/status',
    state: 'DevicesStatus',
    autoFill: false,
    forceRefill: false,
    methods: [
        'patch'
    ],
    data: {}
} )