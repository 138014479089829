/**
 * Vuetify theme
 * 
 * @link https://vuetifyjs.com/en/features/theme/
 */

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use( Vuetify );

const vuetify = new Vuetify( {
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: false,
        options: {
            variations: false
        },
        //https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=556bb2&secondary.color=f26539
        themes: {
            light: {
                primary: '#556bb2',
                primaryD: '#204182',
                primaryL: '#8799e4', // #c2cae7 / #dee1f2
                secondary: '#f26539',
                secondaryL: '#ff9666',
                secondaryD: '#b9330C',
                accent: '#f26539',
                error: '#d50000',
                warning: '#f39c12',
                info: '#1e88e5',
                success: '#43a047',
                basic: '#e1e4ec',
                tzpAppBar: '#556bb2',
                tzpBG: '#8d9bcc',
                tzpNav: '#ffffff',
                tzpNavItem: '#556bb2',
                tzpGrey: '#dee1f2',
                tzpOrange: '#f26539',
                tzpOrangeDisabled: '#ebc1b4',
                tzpRed: '#e24331',
                tzpPurple: '#556bb2',
                tzpViolet: '#c2cae7',
                tzpPurpleLight: '#7188c5',
                tzpPurpleDark: '#354d89',
                tzpInfoBox: '#556bb2',
                tzpKeyboard: '#556bb2',
                tzpKeyboardButton: '#556bb2',
                tzpPinText: '#556bb2',
            },
            dark: {
                primary: '#ffffff',
                primaryD: '#ffffff', //#204182
                primaryL: '#ffffff',
                secondary: '#000000',
                secondaryD: '#b9330c',
                secondaryL: '#ff9666',
                accent: '#f26539',
                error: '#c62828',
                warning: '#ef6c00',
                info: '#1565c0',
                success: '#2e7d32',
                basic: '#121212',
                tzpAppBar: '#272727',
                tzpBG: '#121212',
                tzpNav: '#2e2e2e',
                tzpNavItem: '#6a779a',
                tzpCard: '#1e1e1e',
                tzpGrey: '#4f4f4f',
                tzpOrange: '#b45b2e',
                tzpOrangeDisabled: '#b45b2e',
                tzpRed: '#e24331',
                tzpPurple: '#6a779a',
                tzpViolet: '#7f7f7f',
                tzpPurpleLight: '#6a779a',
                tzpPurpleDark: '#363d51',
                tzpInfoBox: '#ffffff',
                tzpKeyboard: '#363636',
                tzpKeyboardButton: '#363636',
                tzpPinText: '#ffffff',
            }
        }
    }
} )

export default vuetify
