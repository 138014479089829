import Api from '../../services/Api.service';

/**
 * RequestBuilder
 */
export default class RequestBuilder {

    constructor ( options ) {
        this.mustAuth = options.auth;
        this.url = options.url;
        this._data = false;
        this._method = '';
        this.callbacks = options.callbacks;
    }

    params ( params ) {
        // Loop through all params to build our 'new' location
        Object.keys( params ).forEach( ( paramKey ) => {
            let paramValue = params[ paramKey ]
            let parsedLocation = this.url.replace( '{{' + paramKey + '}}', paramValue ); // replace found key with the value
            this.url = parsedLocation; // set our new location as config location
        } );
        
        return this;
    }

    query ( params ) {
        let location = this.url;
        let queryString = '?';

        Object.keys( params ).forEach( ( paramKey ) => {
            let paramValue = params[ paramKey ];
            if ( typeof paramValue === 'object' && paramValue !== null ) {
                let valValue = '';
                Object.keys( paramValue ).forEach( ( valKey ) => {
                    valValue = paramValue[ valKey ];
                    queryString += paramKey + '[' + valKey + ']' + '=' + valValue + '&';
                } );
            }
            else {
                queryString += paramKey + '=' + paramValue + '&';
            }
        } );

        // Cut-off last `&`
        let result = queryString.slice( 0, -1 );
        this.url = location + result;

        return this;
    }

    body (data) {
        this._data = data;

        return this;
    }

    method (method) {
        this._method = method;

        return this;
    }

    getBody () {
        return this._data;
    }

    getMethod () {
        return this._method;
    }

    call (options) {
        return new Promise( ( resolve, reject ) => {
            
            var requestOptions = {
                url: this.url,
                method: this._method,
                token: this.mustAuth
            };

            if (this.getBody()) {
                requestOptions.data = this.getBody()
            }
            
            return Api.request( requestOptions ).then( response => {
                this.callbacks.onSuccess ? this.callbacks.onSuccess(this, response, options) : null;
                resolve(true);
            } ).catch(error => {
                this.callbacks.onFailure ? this.callbacks.onFailure(this, error, options) : null;
                reject(error);
                throw error;
            }).finally(() => {
                this.callbacks.onCompletion ? this.callbacks.onCompletion(this, options) : null;
            });
        });
    }

    get (options) {
        this.method('get');
        return this.call(options);
    }

    post (options) {
        this.method('post');
        return this.call(options);
    }

    put (options) {
        this.method('put');
        return this.call(options);
    }

    patch (options) {
        this.method('patch');
        return this.call(options);
    }

    delete (options) {
        this.method('delete');
        return this.call(options);
    }
}
