<template>
        <div>
            <v-chip 
            small 
            :to="$route.path + '#' + endpoint.slug" 
            @click="scrollToArticle()"
            label
            class="title font-weight-bold"
            :ripple="false"
            color="transparent">
                <v-icon left>
                    mdi-link-variant
                </v-icon>
                {{ endpoint.title }}
            </v-chip>

            <div class="body-2 mt-3 mb-5">{{ endpoint.description }}</div>
            
            <v-alert
            v-if="endpoint.status =='dev'"
            color="orange darken-3"
            border="left"
            colored-border
            type="error"
            elevation="1">
                This endpoint is still <b>under development</b> and may be subject to change.
            </v-alert>

            <v-alert
            v-else-if="endpoint.status =='test'"
            color="yellow darken-3"
            border="left"
            colored-border
            type="error"
            elevation="1">
                This endpoint has entered it's <b>experimental test phase</b>. Changes are not very likely.
            </v-alert>

            <v-alert
            v-else-if="endpoint.status == 'deprecated'"
            color="red"
            border="left"
            colored-border
            type="error"
            elevation="1">
                This endpoint has been <b>deprecated</b> and will be removed in the future. See the description for additional information or recommendations.
            </v-alert>
            
            <div class="py-2">
                <v-chip 
                v-for="requirement in endpoint.requirements" :key="requirement"
                small 
                dark 
                label
                class="mr-1 mb-2"
                color="grey">
                    <v-icon small left v-if="requirement == 'authorized'">
                        mdi-shield-lock
                    </v-icon>
                    {{ requirement }}
                </v-chip>
                
                <v-chip 
                v-for="hint in endpoint.hints" :key="hint"
                small 
                dark 
                label
                class="mr-1 mb-2"
                color="grey">
                    {{ hint }}
                </v-chip>
            </div>

            <!-- <div 
            v-for="hint in endpoint.hints" 
            :key="hint" 
            class="title">
                <v-alert
                dense
                color="blue darken-1"
                class="caption my-3"
                outlined
                type="info">
                    Hint: {{ hint }}.
                </v-alert>
            </div> -->

            <v-sheet outlined color="grey lighten-2" rounded>
                <v-card 
                flat 
                style="border-color: red;"
                color="grey lighten-5" 
                class="px-5 py-4">
                    <v-chip 
                    small 
                    label 
                    dark 
                    class="body-2"
                    :color="methodColors[endpoint.method]">
                        {{ endpoint.method }}
                    </v-chip>
                    <span class="body-2 pl-4">/{{ endpoint.uri }}</span>
                </v-card>
            </v-sheet>

            <!-- <div 
            v-for="hint in endpoint.hints" 
            :key="hint" 
            class="title">
                <v-alert
                dense
                color="blue darken-1"
                class="caption my-3"
                outlined
                type="info">
                    Hint: {{ hint }}.
                </v-alert>
            </div> -->

            <v-card 
             v-if="endpoint.urlParams[0]"
            flat 
            class="mt-6">
                <span class="subtitle-1 pa-3">URI params</span>
                <v-simple-table class="font-weight-regular black--text">
                    <thead>
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Type
                            </th>
                            <th class="text-left">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="urlParams in endpoint.urlParams"
                        :key="urlParams.name">
                            <td>
                                <v-chip 
                                small 
                                label 
                                color="grey lighten-3">
                                    {{ urlParams.name }}
                                </v-chip>
                            </td>
                            <td>{{ urlParams.type }}</td>
                            <td>{{ urlParams.description }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>

            <v-card 
            v-if="endpoint.queryParams[0]" 
            flat  
            class="mt-6">
                <span class="subtitle-1 pa-3">Query params</span>
                <v-simple-table class="font-weight-regular black--text">
                    <thead>  
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Type
                            </th>
                            <th class="text-left">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(queryParam, index) in endpoint.queryParams"
                        :key="index">
                            <td>
                                <v-chip 
                                small 
                                label 
                                color="grey lighten-3">
                                    {{ queryParam.name }}
                                </v-chip>
                            </td>
                            <td>{{ queryParam.type }}</td>
                            <td>{{ queryParam.description }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
            
            <v-card 
            v-if="endpoint.bodyParams[0]"
            flat  
            class="mt-6">
                <span class="subtitle-1 pa-3">Body params</span>
                <v-simple-table class="font-weight-regular black--text">
                    <thead>
                        <tr>
                            <th class="text-left">
                                Name
                            </th>
                            <th class="text-left">
                                Type
                            </th>
                            <th class="text-left">
                                Description
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="(bodyParams, index) in endpoint.bodyParams"
                        :key="index">
                            <td>
                                <v-chip 
                                small 
                                label 
                                :pill="! bodyParams.required"
                                color="grey lighten-3">
                                    {{ bodyParams.name }}
                                </v-chip>
                            </td>
                            <td>{{ bodyParams.type }}</td>
                            <td>{{ bodyParams.description }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card>
        </div>
</template>

<script>
export default {

    name: 'Endpoint',

    props: {
        endpoint: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        methodColors: {
            POST: 'primary',
            PUT: 'primary',
            PATCH: 'primary',
            GET: 'primary',
            DELETE: 'primary'
        },
    }),

    methods: {
        scrollToArticle() {
            if(this.$route.hash) {
                const el = document.querySelector(this.$route.hash);
                el && el.scrollIntoView();
                this.subNavSelect = this.$route.hash;
            }
        },
    }
}
</script>