<template>

    <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    :max-width="$vuetify.breakpoint.smAndDown ? 280 : 560"
    :overlay-color="!$prefs.settings.darkModeEnabled ? 'tzpPurple' : undefined"
    overlay-opacity="0.7">
        
        <v-card rounded="tzp">

            <stepper
            @closeDialog="dialog = false"
            :inDialog="true"
            ref="stepper"
            :responseClose="true"
            :responseDescription="stepperResponseDescription"
            :responseTitle="stepperResponseTitle"
            :responseType="stepperResponseType"
            :steps="[1]">

                <template v-slot:step1>

                    <v-card>

                        <card-content
                        center="none"
                        :content="t('Error_report_dialog_description')"
                        :noPadding="true"
                        :title="t('Error_report_dialog_title')"></card-content>

                        <v-card-actions class="pa-0 mt-4">
                            
                            <v-spacer></v-spacer>

                            <v-btn
                            @click="dialog = false"
                            color="primary"
                            dark
                            text>
                                {{ t('Cancel') }}
                            </v-btn>

                            <v-btn
                            @click="sendReport()"
                            color="primary"
                            dark
                            text>
                                {{ t('Send') }}
                            </v-btn>

                        </v-card-actions>

                    </v-card>

                </template>

            </stepper>

        </v-card>

    </v-dialog>

</template>

<script>
import CardContent from '@/components/CardContent'
import { support } from "@/components/support/api"
import Stepper from '@/components/Stepper'

export default {
    name: "ErrorReport",

    components: { 
        CardContent,
        Stepper
    },

    data: () => ({
        dialog: false,
        report: [],
        reportEncoded: false,
        stepperResponseDescription: 'Error_report_thank_you_description',
        stepperResponseType: 'success',
        stepperResponseTitle: 'Error_report_thank_you_title',
    }),

    mounted() {
        this.report = Debug.logArray();
        this.reportEncoded = window.btoa(JSON.stringify(this.report)); // encoded + base64 formatting
    },

    methods: {
        closeDialog(){
            this.dialog = false
        },
        sendReport() {
            this.$ui.overlay.show(true)

            // Collect the data to send
            let reportData = {
                report: this.reportEncoded,
            }

            // Send it to our API endpoint
            support.sendErrorReport(reportData).then( () => {
                this.$ui.overlay.hide()
                this.$refs.stepper.nextStep(1) // trigger our stepper to the next step
            },(error) => {
                this.stepperResponseType = 'error'
                this.stepperResponseTitle = error.response.data.message
                this.stepperResponseDescription = 'Error_report_error_description'
                this.$ui.overlay.hide()
                this.$refs.stepper.nextStep(1)
            })
        }
    },
}
</script>