<template>

    <v-alert
    v-model="$ui.alert.model"
    v-html="$ui.alert.content"
    :border="$ui.alert.border"
    :color="$ui.alert.color"
    :colored-border="$ui.alert.coloredBorder"
    :dense="$ui.alert.dense"
    :elevation="$ui.alert.coloredBorder ? 1 : undefined"
    :icon="$ui.alert.icon"
    :outlined="$ui.alert.outlined"
    :text="$ui.alert.text && ! $ui.alert.coloredBorder ? true : false"
    :type="$ui.alert.type && $ui.alert.color == undefined ? $ui.alert.type : undefined">
    </v-alert>

</template>

<script>
export default {
    name: 'TzpAlert',
}
</script>