<template>

    <v-list
    v-if="items"
    flat
    tile>
        
        <div 
        v-for="(item, index) in items"
        :key="index">
        
            <v-list-item class="pa-0">
                
                <news-item-preview
                :inWidget="true"
                :item="item"
                :path="item.slug"></news-item-preview>

            </v-list-item>

            <v-divider 
            v-if="index != items.length - 1"
            class="mx-4"></v-divider>

        </div>

    </v-list>

</template>

<script>
import NewsItemPreview from '@/components/news/NewsItemPreview'

export default {
    name: 'NewsItems',

    props: {
        items: {
            type: Object|Array,
            default: () => {},
        }
    },

    components: { NewsItemPreview },
};
</script>