<template>
    
    <div>
       
        <v-card
        rounded="tzp"
        class="mb-3">
           
            <v-list
            subheader
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">
                
                <v-subheader>{{ t("General") }}</v-subheader>

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-brightness-4</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Dark_mode") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Dark_mode_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch   
                        v-model="$prefs.settings.darkModeEnabled"
                        @change="$prefs.save('darkModeEnabled')"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-view-list</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Compact_menu") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Compact_menu_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch  
                        v-model="$prefs.settings.navigationDrawerDense"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>

            </v-list>

        </v-card>

        <v-card rounded="tzp">
            
            <v-list 
            subheader
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">
                
                <v-subheader>{{ t("Dashboard") }}</v-subheader>

                <v-list-item v-if="$vuetify.breakpoint.smAndDown">

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-share-circle </v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Show_shortcuts") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Show_shortcuts_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="$prefs.settings.dashboard.shortcuts"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>

                <v-divider 
                 v-if="$vuetify.breakpoint.smAndDown"
                inset></v-divider>

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-message-bulleted</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Show_notices") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Show_notices_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="$prefs.settings.dashboard.notices"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-newspaper-variant</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Show_news") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Show_news_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="$prefs.settings.dashboard.news"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-bell</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Show_notifications") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t("Show_notifications_description") }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="$prefs.settings.dashboard.notifications"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>

                <!--<v-divider inset></v-divider>
                
                <v-list-item>
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-lightbulb-on</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t('Show_tips') }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t('Show_tips_description') }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="$prefs.settings.dashboard.tips"
                        @change="$prefs.save()"
                        :ripple="false"
                        class="ma-4"></v-switch>

                    </v-list-item-action>

                </v-list-item>-->

            </v-list>

        </v-card>

    </div>

</template>

<script>
export default {
    name: "Interface",

    data: () => ({}),
};
</script>