<template>

    <v-row
    dense
    class="mb-2">
        <v-col
        v-for="(item, key) in items"
        :key="key"
        :class="$vuetify.breakpoint.xs ? 'col-6' : 'col-3'">
            <v-btn
            block
            depressed
            elevation="1"
            :title="t(item.title)"
            :to="item.route"
            :class="item.class">
                <v-icon 
                left
                small>
                    {{ item.icon }}
                </v-icon>
                <span v-html="t(item.title)"></span>
            </v-btn>
        </v-col>
    </v-row>

</template>

<script>
export default {
    
    data: () => ({
        docsColor: 'black',
        items: []
    }),

    mounted() {
        this.items = [
            {
                icon: 'mdi-routes-clock',
                title: 'Planning',
                route: '/timeline',
                class: 'green '+(this.$prefs.settings.darkModeEnabled ? 'lighten-1' : 'darken-3')+' white--text',
            },
            {
                icon: 'mdi-calendar-month',
                title: 'Agenda',
                route: '/agenda',
                class: 'indigo '+(this.$prefs.settings.darkModeEnabled ? 'lighten-1' : 'darken-3')+' white--text',
            },
            {
                icon: 'mdi-clipboard-account',
                title: 'Clients',
                route: '/clients',
                class: 'purple '+(this.$prefs.settings.darkModeEnabled ? 'lighten-1' : 'darken-3')+' white--text',
            },
            {
                icon: 'mdi-file-multiple',
                title: 'Documents',
                route: '/documents',
                class: (this.$prefs.settings.darkModeEnabled ? 'grey darken-3' : 'black')+' white--text',
            },
        ];
    }
}
</script>