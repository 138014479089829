import Vue from 'vue'
import Vuex from 'vuex'
Vue.use( Vuex )

export const store = new Vuex.Store( {

    // Only declare it here.
    state: {},

    mutations: {
        fill: ( state, io ) => {
            state[ io.store ] = io.data;
        },
        setProp: ( state, io ) => {
            state[ io.store ] = Object.assign( state[ io.store ], io.data );
        },
        merge: ( state, io ) => {
            state[ io.store ] = Object.assign( state[ io.store ], io.data );
        },
        pushMultiple: ( state, io ) => {
            io.data.each( ( item ) => {
                item.setCollection( state[ io.store ] );
                state[ io.store ].push( item )
            } );
        },
        prependMultiple: ( state, io ) => {
            io.data.each( ( item ) => {
                item.setCollection( state[ io.store ] );
                state[ io.store ].prepend( item )
            } );
        },
        updateAll: ( state, io ) => {
            state[ io.store ].each( ( item ) => {
                item = Object.assign( item, io.data );
            } );
        },
        removeAll: ( state, io ) => {
            state[ io.store ].items = [];
        },
        push: ( state, io ) => {
            io.data.setCollection( state[ io.store ] );
            state[ io.store ].push( io.data )
        },
        prepend: ( state, io ) => {
            io.data.setCollection( state[ io.store ] );
            state[ io.store ].prepend( io.data )
        },
        update: ( state, io ) => {
            let foundItem = state[ io.store ].where( 'uuid', io.key );

            if ( 0 in foundItem ) {
                let item = foundItem[ 0 ];
                item = Object.assign( item, io.data );
            }
            else {
                console.error( 'Error: Failed updating item in store, it does not exist.' );
            }
        },
        remove: ( state, io ) => {
            state[ io.store ].each( ( item, index ) => {

                if ( item && item.getKey() === io.key ) {
                    Vue.delete( state[ io.store ].items, index );
                    return;
                }
            } );
        }
    },

    actions: {
        fill ( { commit }, io ) {
            commit( 'fill', io );
        },
        merge ( { commit }, io ) {
            commit( 'merge', io );
        },

        // Props
        setProp ( { commit }, io ) {
            commit( 'setProp', io );
        },

        // Single items in collection
        push ( { commit }, io ) {
            commit( 'push', io );
        },
        prepend ( { commit }, io ) {
            commit( 'prepend', io );
        },
        remove ( { commit }, io ) {
            commit( 'remove', io );
        },
        update ( { commit }, io ) {
            commit( 'update', io );
        },
        none ( { commit }, io ) {
            //
        },

        // Multiple items in collection
        pushMultiple ( { commit }, io ) {
            commit( 'pushMultiple', io );
        },
        prependMultiple ( { commit }, io ) {
            commit( 'prependMultiple', io );
        },
        updateAll ( { commit }, io ) {
            commit( 'updateAll', io );
        },
        removeAll ( { commit }, io ) {
            commit( 'removeAll', io );
        }
    },

    getters: {
        getItemFromStore: ( state ) => ( store, id ) => {
            return ( typeof state[ store ][ 'data' ][ id ] != 'undefined' ? state[ store ][ 'data' ][ id ] : false );
        },
        getStore: ( state ) => ( store ) => {
            return ( typeof state[ store ] != 'undefined' ? state[ store ] : false );
        }
    },
} )
