import { router } from '@/router'
import Vue from 'vue'

const ui = {

    preventDefault: function ( event ) {

        event.preventDefault();

        return false;
    },

    navigate: {
        back () {
            router.go( -1 );
        }
    },

    overlay: {

        loader: false,
        toggled: false,

        show ( loader ) {
            this.loader = ( loader ? true : false );
            this.toggled = true;
        },

        hide () {
            //Reset loader state
            this.loader = false;
            this.toggled = false;
        }
    },

    error: {

        bottom: false,
        message: '',
        toggled: false,
        timeout: 5000,
        top: true,

        show ( message, timeout = this.timeout, top = this.top ) {
            this.bottom = top ? false : true;
            this.message = message;
            this.timeout = timeout;
            this.toggled = true;
            this.top = top;
        },

        hide () {
            this.bottom = false;
            this.message = '';
            this.toggled = false;
            this.top = true;
        }
    },

    notification: {
        toasts: []
    },

    dialog: {

        buttons: false,
        description: false,
        fullscreen: false,
        hideOverlay: false,
        icon: false,
        persistent: false,
        textCenter: false,
        title: false,
        toggled: false,
        translate: true,
        translateParams: false,

        show ( data ) {
            this.toggled = true;
            if ( 'buttons' in data ) {
                this.buttons = data.buttons;
            }
            if ( 'description' in data ) {
                this.description = data.description;
            }
            if ( 'fullscreen' in data ) {
                this.fullscreen = data.fullscreen;
                if ( data.fullscreen ) { // we don't need a overlay
                    this.hideOverlay = true
                }
            }
            if ( 'icon' in data ) {
                this.icon = data.icon;
            }
            if ( 'persistent' in data ) {
                this.persistent = data.persistent;
            }
            if ( 'textCenter' in data ) {
                this.textCenter = data.textCenter;
            }
            if ( 'title' in data ) {
                this.title = data.title;
            }
            if ( 'translate' in data ) {
                this.translate = data.translate;
            }
            if ( 'translateParams' in data ) {
                this.translateParams = data.translateParams;
            }
        },

        hide () {
            // Reset all values to default
            this.buttons = false;
            this.description = false;
            this.fullscreen = false;
            this.hideOverlay = false;
            this.icon = false;
            this.persistent = false;
            this.textCenter = false;
            this.title = false;
            this.toggled = false;
            this.translate = true;
            this.translateParams = false;
        },
    },

    dialogError: {

        message: '',
        toggled: false,
        top: false,

        show ( message ) {
            this.message = message;
            this.toggled = true;
            this.top = ( top ? true : false );
        },

        hide () {
            this.message = '';
            this.toggled = false;
            this.top = false;
        }
    },

    hideAll () {
        // Toasts
        this.error.hide();

        // Dialgos
        this.dialog.hide();
        this.dialogError.hide();
    },

    alert: {
        border: undefined,
        color: undefined,
        coloredBorder: false,
        content: '',
        dense: false,
        icon: undefined,
        model: false,
        outlined: false,
        prominent: false,
        text: false,
        type: 'error',

        show ( data ) {
            if ( 'border' in data ) {
                this.border = data.border;
            }
            if ( 'color' in data ) {
                this.color = data.color;
            }
            if ( 'coloredBorder' in data ) {
                this.coloredBorder = data.coloredBorder;
            }
            if ( 'content' in data ) {
                this.model = true;
                this.content = data.content;
            }
            if ( 'dense' in data ) {
                this.dense = data.dense;
            }
            if ( 'icon' in data ) {
                this.icon = data.icon;
            }
            if ( 'outlined' in data ) {
                this.outlined = data.outlined;
            }
            if ( 'prominent' in data ) {
                this.prominent = data.prominent;
            }
            if ( 'text' in data ) {
                this.text = data.text;
            }
            if ( 'type' in data ) {
                this.type = data.type;
            }
        },
        hide () {
            this.border = undefined;
            this.color = undefined;
            this.coloredBorder = false;
            this.content = '';
            this.dense = false;
            this.icon = undefined;
            this.model = false;
            this.outlined = false;
            this.prominent = false;
            this.text = false;
            this.type = 'error';
        }
    },

    navigationDrawer: {

        message: '',
        toggled: false,
        stateless: false,

        show ( message ) {
            this.message = message;
            this.toggled = true;
        },

        hide () {
            this.message = '';
            this.toggled = false;
        },

        toggle () {
            this.toggled = ( this.toggled ? false : true );
        }
    },

    notificationDrawer: {

        message: '',
        toggled: false,

        show ( message ) {
            this.message = message;
            this.toggled = true;
        },

        hide () {
            this.message = '';
            this.toggled = false;
        },

        toggle () {
            this.toggled = ( this.toggled ? false : true );
        }
    },

    breadcrumbs: {
        show: false
    },

    progressBar: {

        count: 0,

        setValue ( v, d ) {
            setTimeout( () => {
                this.count = v;
            }, ( d ? d : 500 ) );
        }
    },

    // Shortcut to a translation string.
    lang ( param ) {
        return Vue.prototype.$translate.text( param );
    }
}

export default ui;