<template>

    <router-link 
    v-if="to && initials"
    :to="to">

        <v-avatar
        :color="color"
        :size="size"
        :class="'elevation-' + elevation">
            
            <span
            :style="'font-size: calc(' + size + 'px * 0.4)'"
            class="font-weight-regular white--text">
                {{ initials }}
            </span>

        </v-avatar>

    </router-link>

    <v-avatar
    v-else-if="initials"
    :color="color"
    :size="size"
    :to="to"
    :class="'elevation-' + elevation">
        
        <span
        :style="'font-size: calc(' + size + ' px * 0.35)'"
        class="white--text">
            {{ initials }}
        </span>

    </v-avatar>

</template>

<script>
export default {
    name: "AvatarInitials",

    props: {
        color: {
            type: String,
            default: 'tzpOrange'
        },
        elevation: {
            type: Number,
            default: 0,
        },
        initials: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: 40,
        },
        to: {
            type: String,
            required: false
        },
    },
};
</script>