<template>
    
    <div>

        <v-card 
        rounded="tzp"
        class="mb-3">
            
            <v-list
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">

                <v-list-item @click="$refs.resetSettingsDialog.dialog = true">
                    <v-list-item-avatar>
                        <v-icon color="tzpOrange">mdi-cog-refresh</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ t("Settings_reset_title") }}</v-list-item-title>
                        <v-list-item-subtitle>{{ t("Settings_reset_description") }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

        </v-card>

        <v-card rounded="tzp">
            
            <v-list
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">

                <v-list-item @click="$refs.errorReportDialog.dialog = true">
                    <v-list-item-avatar>
                        <v-icon color="tzpOrange">mdi-bug</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ t("Error_report_send_title") }}</v-list-item-title>
                        <v-list-item-subtitle>{{ t("Error_report_send_description") }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

        </v-card>

        <ErrorReportDialog ref="errorReportDialog"></ErrorReportDialog>

        <ResetSettingsDialog ref="resetSettingsDialog"></ResetSettingsDialog>

    </div>

</template>

<script>
import ErrorReportDialog from '@/components/support/ErrorReportDialog.vue'
import ResetSettingsDialog from '@/components/settings/ResetSettingsDialog.vue'

export default {
    name: "Tools",

    components: { 
        ErrorReportDialog,
        ResetSettingsDialog
    },

    data: () => ({ }),

    mounted () { },

    methods: { },
};
</script>