var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Notifications.init)?_c('widget',{attrs:{"buttons":{
    0: {
        disable: ! _vm.Notifications.getUnreadCount(),
        icon: 'mdi-email-open',
        ref: 'mark-as-read',
    }
},"emptyIcon":"mdi-email-fast-outline","emptyTitle":"No_unread_notifications","showEmpty":! _vm.Notifications.getUnreadCount(),"title":_vm.t( _vm.heading )},on:{"onClick":function($event){return _vm.btnClick( $event )}},scopedSlots:_vm._u([{key:"create",fn:function(){return [_c('create-notifications',{staticClass:"my-4 text-center"})]},proxy:true}],null,false,2755822667)},[_c('div',{style:((_vm.height ? 'height:'+_vm.height+'px;overflow-y:auto;' : ''))},[_c('notifications-list',{ref:"notificationsList",attrs:{"headers":false,"iconColor":"tzpPurple","items":_vm.Notifications.getUnread(),"swipe":_vm.$device.isNative() || _vm.$vuetify.breakpoint.smAndDown,"widget":true},on:{"itemClicked":function($event){return _vm.$root.$refs.notificationsManager.showNotification( $event, true )}}})],1)]):_c('widget',{attrs:{"buttons":{
    0: {
        disable: true,
        icon: 'mdi-email-open',
    }
},"title":_vm.t( _vm.heading )}},[_c('v-skeleton-loader',{staticClass:"pa-3",attrs:{"type":"list-item-avatar-two-line@2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }