<template>

    <v-container>
        
        <tzp-alert class="mt-n2"></tzp-alert>

        <ValidationObserver ref="loginForm">
            
            <v-form
            @submit.prevent="submit"
            action="">

                <ValidationProvider
                v-slot="{ errors }"
                mode="eager"
                :name="t('Username')"
                :rules="{ 
                    required: true,
                }"
                slim
                tag="div">
                
                    <v-text-field
                    v-model="username"
                    autocapitalize="off"
                    autocomplete="username"
                    color="primary"
                    :error-messages="errors"
                    :label="t('Username')"
                    outlined
                    tabindex="1"
                    type="text"></v-text-field>

                </ValidationProvider>

                <ValidationProvider
                v-slot="{ errors }"
                mode="eager"
                :name="t('Password')"
                :rules="{
                    required: true
                }"
                slim
                tag="div">

                    <password-field
                    v-model="password"
                    autocomplete="current-password"
                    :errors="errors"
                    :label="t('Password')"
                    :password="password"
                    :success="false"
                    tabindex="2"></password-field>

                </ValidationProvider>

                <v-row 
                align="center"
                no-gutters>

                    <v-col
                    cols="12"
                    sm="6">
                        
                        <v-btn
                        block
                        color="primary"
                        dark
                        depressed
                        large
                        :loading="$ui.overlay.toggled"
                        ripple
                        type="submit"
                        class="muli--bold">
                            {{ t('Login') }}
                        </v-btn>

                    </v-col>

                    <v-col
                    cols="12"
                    sm="6">
                        
                        <div :class="$vuetify.breakpoint.xs ? 'mt-6 text-center' : 'text-right'">
                            <router-link to="/forgot-password">
                                <span class="body-2 grey--text">{{ t('Forgot_password_question') }}</span>
                            </router-link>
                        </div>

                    </v-col>

                </v-row>

            </v-form>

        </ValidationObserver>

    </v-container>
    
</template>

<script>
import Storage from '@/system/services/Storage.service'
import NativeDevice from '@/system/services/Device.service'
import PasswordField from '@/components/form/Password'
import Setup from '@/classes/Setup'
import TzpAlert from '@/system/components/TzpAlert'
import { 
    extend,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate';
import {
    required,
} from 'vee-validate/dist/rules'

// Use VeeValidate rules
extend('required', required);

export default {
    name: 'Login',

    components: {
        PasswordField,
        TzpAlert,
        ValidationObserver,
        ValidationProvider
    },
    
    data: () => ({
        username: '',
        password: '',
        currentDevice: false,
    }),

    created() {
        this.currentDevice = NativeDevice
    },

    mounted() {
        this.$ui.progressBar.setValue(0);
    },

    methods: {
        submit() { 
            // hide UI and System components
            this.$root.$refs.toast.hide();
            this.$ui.alert.hide();

            // Validation starts here
            this.$refs.loginForm.validate().then( valid => {

                if (valid) {

                    // Show overlay
                    this.$ui.overlay.show();

                    // Setup - login
                    Setup.loginWithCredentials(this.username, this.password).then((response) => {
                        
                        // Save device id
                        Storage.write( 'device', response.data.data )
                        NativeDevice.setUuid( response.data.data.uuid )
                        
                        // Update the progress bar
                        this.$ui.progressBar.setValue( (100/6) );
                        
                        // Hide overlay
                        this.$ui.overlay.hide();
                        
                        // Resetting values
                        this.username = '';
                        this.password = '';
                        
                        // Go to next step
                        this.$emit('nextStep', 1)
                    },
                    (error) => {
                        // Show the API error respons in our 'snackbar'
                        this.$root.$refs.toast.error( this.t(error.response.data.message) );
                        
                        // Empty password when we receive a error
                        this.password = '';
                        
                        // Hide overlay
                        this.$ui.overlay.hide();
                    });
                }
                else {
                    this.$root.$refs.toast.error( this.t('Check_the_fields') );
                }
            });
        },
        reset() {
            this.password = '';
            this.$refs.loginForm.reset();
        }
    }
}
</script>