<template>
    
    <div v-if="visible">
        
        <v-app-bar
        app
        :color="color"
        dark
        :elevation="isIntersecting ? 0 : 3"
        class="no-transition--background-color">
            
            <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown && $route.path === '/dashboard'"
            @click="$ui.navigationDrawer.toggle()"></v-app-bar-nav-icon>
            
            <v-btn
            v-else-if="$route.meta.appbar.back || ($vuetify.breakpoint.smAndDown && $route.path !== '/dashboard')"
            @click="$ui.navigate.back()"
            icon>

                <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title v-if="title">{{ $vuetify.breakpoint.smAndDown ? t($route.name) : t(data.title.text) }}</v-toolbar-title>
            
            <v-spacer></v-spacer>

            <div v-if="!$vuetify.breakpoint.xs">

                <v-menu
                bottom
                left
                offset-y
                rounded="lg">

                    <template v-slot:activator="{ on }">

                        <v-btn
                        v-on="on"
                        dark
                        icon>

                            <v-icon>mdi-help-circle-outline</v-icon>

                        </v-btn>

                    </template>

                    <v-list class="py-0">
                        
                        <v-list-item to="/customer-support">

                            <v-list-item-icon class="mr-4">

                                <v-icon>mdi-lifebuoy</v-icon>

                            </v-list-item-icon>

                            <v-list-item-title>{{ t('FAQ') }}</v-list-item-title>

                        </v-list-item>

                        <v-list-item 
                        href="https://help.thuiszorgplanner.nl/"
                        target="_blank">

                            <v-list-item-icon class="mr-4">

                                <v-icon>mdi-file-question-outline</v-icon>

                            </v-list-item-icon>

                            <v-list-item-title>{{ t('Knowledge_base') }}</v-list-item-title>

                        </v-list-item>

                        <v-list-item @click="$eventBus.$emit('showTour', '/full')">

                            <v-list-item-icon class="mr-4">

                                <v-icon>mdi-gift-outline</v-icon>

                            </v-list-item-icon>

                            <v-list-item-title>{{ t('Product_updates') }}</v-list-item-title>

                        </v-list-item>

                        <v-list-item to="/feedback">

                            <v-list-item-icon class="mr-4">

                                <v-icon>mdi-message-text-outline</v-icon>

                            </v-list-item-icon>

                            <v-list-item-title>{{ t('Feedback') }}</v-list-item-title>

                        </v-list-item>

                    </v-list>

                </v-menu>

            </div>

            <v-btn
            v-if="($vuetify.breakpoint.smAndUp && $vuetify.breakpoint.xl === false) || ($vuetify.breakpoint.smAndDown && $route.path === '/dashboard')"
            @click="$ui.notificationDrawer.toggle()"
            dark
            icon>
            
                <v-badge
                v-if="Notifications.init && Notifications.getUnreadCount() > 0"
                color="tzpOrange"
                :content="Notifications.getUnreadCount() > 99 ? '99+' : Notifications.getUnreadCount()"
                overlap>

                    <v-icon class="icon--unread">mdi-bell</v-icon>

                </v-badge>

                <v-icon v-else>mdi-bell</v-icon>

            </v-btn>

            <div v-if="!$vuetify.breakpoint.xs && User.init && Companies.init">
                
                <v-menu
                bottom
                left
                offset-y
                rounded="lg">

                    <template v-slot:activator="{ on }">
                        
                        <v-btn
                        v-on="on"
                        color="tzpAppBar"
                        icon
                        class="ml-2">
                            
                            <template v-if="User.getFullnameInitials()">

                                <avatar-initials
                                :initials="User.getFullnameInitials()"
                                :size="32"></avatar-initials>

                            </template>

                            <v-skeleton-loader
                            v-else
                            type="avatar"></v-skeleton-loader>

                        </v-btn>

                    </template>

                    <v-card max-width="340px">

                        <template v-if="User.getFullnameInitials()">

                            <avatar-list-initials
                            :initials="User.getFullnameInitials()"
                            :subtitle="t( User.getActiveRole() ) +' '+ Companies.all()[0].name"
                            :title="User.full_name"></avatar-list-initials>

                        </template>

                        <v-skeleton-loader
                        v-else
                        type="list-item-avatar-two-line"></v-skeleton-loader>

                        <div class="ml-15">

                            <v-btn
                            color="primary"
                            small
                            text
                            to="/settings/account"
                            class="mt-n3">
                                {{ t('Account') }}
                            </v-btn>

                            <v-btn
                            color="primary"
                            small
                            text
                            to="/profile"
                            class="mt-n3">
                                {{ t('Profile') }}
                            </v-btn>

                        </div>

                        <v-divider></v-divider>

                        <v-card
                        flat
                        tile
                        class="d-flex flex-row-reverse">

                            <v-tooltip bottom>
                                
                                <template v-slot:activator="{ on, attrs }">
                                    
                                    <v-btn
                                    @click="$eventBus.$emit('logout')"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="grey"
                                    fab
                                    icon
                                    small
                                    class="ma-1">

                                        <v-icon>mdi-power</v-icon>

                                    </v-btn>

                                </template>

                                <span>{{ t("Logout") }}</span>

                            </v-tooltip>

                            <lock-icon 
                            classes="ma-1"
                            variant="outline"
                            to="/lock"
                            tooltipPos="bottom"></lock-icon>

                        </v-card>

                    </v-card>

                </v-menu>
            </div>

        </v-app-bar>

        <div 
        v-if="data.intersector"
        v-intersect="onIntersect"
        style="position:absolute;top:30px"></div>

    </div>

</template>

<script>
import AvatarInitials from "@/components/avatars/Initials"
import AvatarListInitials from "@/components/avatars/list/Initials"
import { Companies } from "@/components/companies/api"
import LockIcon from "@/components/icons/LockIcon"
import { Notifications } from "@/components/notifications/api"
import { User } from "@/components/user/api"
import Animate from "@/system/helpers/Animate"

export default {
    name: 'AppBar',

    components: {
        AvatarInitials,
        AvatarListInitials,
        LockIcon,
    },

    props: {
        data: {
            type: Object
        }
    },

    mixins: [ 
        Companies.map(),
        Notifications.map(),
        User.map()
    ],

    data: () => ({
        color: '',
        isIntersecting: false,
        title: '',
        unread: 0,
        visible: true,
    }),

    mounted() {
        this.transparent();
    },

    updated() {
        if (! this.intersectorIsEnabled()) {
            this.colored()
        }

        if (! this.checkVisibility()) {
            this.hide()
        }

        this.checkTitle()
    },

    watch: {
        Notifications: {
            deep: true,
            handler: 'checkUnreadNotifications',
        },
    },

    methods: {
        show () {
            this.visible = true;
        },

        hide () {
            this.visible = false;
        },

        colored () {
            this.color = 'tzpAppBar';
        },

        transparent () {
            this.color = 'transparent';
        },

        checkTitle() {
            this.title = (typeof this.data.title.show != 'undefined' && this.data.title.show == false ? false : true);
        },

        checkVisibility() {
            return (typeof this.data.show != 'undefined' && this.data.show == false ? false : true);
        },

        intersectorIsEnabled () {
            return typeof this.data.intersector != 'undefined' && this.data.intersector;
        },

        /*
         * Detect visibility on a given element
         * 
         * @link https://vuetifyjs.com/en/directives/intersect/
        */
        onIntersect (entries, observer) {
            if ( this.intersectorIsEnabled() ) {

                this.isIntersecting = entries[0].isIntersecting;
                
                if (this.isIntersecting) {
                    this.transparent();
                }
                else {
                    this.colored();
                }
            }
        },

        checkUnreadNotifications() {
            if( this.Notifications.getUnreadCount() > this.unread ) {
                Animate.element( '.icon--unread', 'swing');
            }

            this.unread = this.Notifications.getUnreadCount();
        },
    }
}
</script>