var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('tzp-alert',{staticClass:"mt-n2"}),_c('ValidationObserver',{ref:"loginForm"},[_c('v-form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":_vm.t('Username'),"rules":{ 
                required: true,
            },"slim":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocapitalize":"off","autocomplete":"username","color":"primary","error-messages":errors,"label":_vm.t('Username'),"outlined":"","tabindex":"1","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('ValidationProvider',{attrs:{"mode":"eager","name":_vm.t('Password'),"rules":{
                required: true
            },"slim":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('password-field',{attrs:{"autocomplete":"current-password","errors":errors,"label":_vm.t('Password'),"password":_vm.password,"success":false,"tabindex":"2"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])}),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"muli--bold",attrs:{"block":"","color":"primary","dark":"","depressed":"","large":"","loading":_vm.$ui.overlay.toggled,"ripple":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.t('Login'))+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'mt-6 text-center' : 'text-right'},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_c('span',{staticClass:"body-2 grey--text"},[_vm._v(_vm._s(_vm.t('Forgot_password_question')))])])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }