<template>

    <div>
        
        <v-container class="px-2 pt-2 pb-0">
            
            <v-alert 
            v-if="mode == 'setup'"
            color="tzpPurple"
            text
            type="info">
                {{ feedback }}
            </v-alert>

        </v-container>
        
        <div v-if="fingerScanner === false">
            
            <v-container class="max-height">
                
                <v-card 
                v-if="fieldsVisible"
                flat
                text-center
                class="mx-auto mb-4 animate__animated animate__faster animate__fadeIn transparent"
                max-width="300">
                    
                    <v-row 
                    no-gutters 
                    :class="'ml-'+($vuetify.breakpoint.width <= 320 ? 2 : 1)">

                        <v-col
                        v-for="n in numberOfFields"
                        :key="n">

                            <div
                            class="pinfield tzpGrey tzpPinText--text">
                                <span>{{ getPincodePiece(n) }}</span>
                            </div>

                        </v-col>

                    </v-row>

                </v-card>

                <v-alert
                v-if="mode !== 'setup'"
                color="warning"
                dense
                outlined
                text
                class="alert--text-s mx-auto my-0 text-center"
                style="max-width:350px">
                    <span v-html="t('Sensitive_data_lock_text')"></span>
                </v-alert>

                <Keyboard
                :mode="mode"
                ref="keyboard"></Keyboard>
                
            </v-container>

        </div>

        <!-- <div v-else>
            <v-btn 
            @click="startFingerScan()"
            block
            bottom
            color="tzpPurple"
            dark
            large
            elevation="0"
            ripple
            class="muli--bold">
                {{ t('Switch_on') }}
            </v-btn>

            <v-btn 
            @click="skip()"
            block
            bottom
            color="tzpPurple"
            dark
            elevation="0"
            large
            :loading="$ui.overlay.toggled"
            :outlined="!$ui.overlay.toggled"
            ripple
            class="mt-4 muli--bold">
                {{ t('Skip') }}
            </v-btn>
        </div> -->
        
    </div>

</template>

<script>
import Setup from '@/classes/Setup'
import Keyboard from '@/system/components/Keyboard'

export default {
    name: 'Pin',

    props: {
        mode: {
            type: String,
            default: ''
        },
    },

    components: { Keyboard },

    data: () => ({
        fieldsVisible: true,
        feedback: '',
        fingerScanner: false,
        numberOfFields: 5,
        pincode: '',
        pincodeCache: '',
        pincodeTimer: null,
        phase: 0,
    }),

    mounted() {

        this.pincode = '';
        this.pincodeCache = '';
        this.$ui.overlay.hide();

        //Attach numeric keyboard
        this.$refs.keyboard.addListener('input', this.setPincode);
        this.$refs.keyboard.addListener('hide', this.hidePinFields);
        this.$refs.keyboard.addListener('show', this.showPinFields);
        this.$refs.keyboard.addListener('remove', this.removePin);
        //this.$refs.keyboard.addListener('extra', this.fpDecrypt);
        
        if (this.mode !== 'setup') {
            this.$refs.keyboard.show();
        }

        this.setFeedback();
    },

    methods: {

        //Get a piece of pincode
        getPincodePiece(position) {

            let pins = ('' + this.pincode).split('');

            if (typeof pins[(position - 1)] != 'undefined') {

                return '*';
            }

            return '';
        },

        //Validates the input pincode
        setPincode(input) {

            let inputPincode = this.pincode + input;
            
            if (this.mode !== 'setup') {

                if( this.phase !== 0 ) {
                    this.stopTimining();
                }
                this.startTiming();

                this.phase++;
            }

            if (inputPincode <= 99999) {

                //validate

                this.pincode = inputPincode;

                if (inputPincode.length === this.numberOfFields) {

                    if (this.pincodeCache != this.pincode && this.mode === 'setup') {

                        setTimeout(() => {

                            //Cache first pin
                            this.pincodeCache = this.pincode;

                            //Remove reset fields & data
                            this.pincode = '';

                            if (this.phase === 0) {

                                this.phase++;
                                this.feedback = this.t('Repeat_pin');

                                this.$ui.progressBar.setValue( (100/6) * 3 );
                            }
                            else {

                                this.feedback = this.t('Pins_do_not_match_retry');

                                this.$ui.progressBar.setValue( (100/6) * 2 );
                                
                                this.phase = 0;
                            }
                        }, 300);
                    }
                    else {

                        setTimeout(() => {
                            this.approvePin();
                        }, 200);
                    }
                }
            }
        },

        approvePin() {

            this.stopTimining();
            this.$root.$refs.toast.hide();
            this.$ui.overlay.show(true);
            this.$refs.keyboard.hide();

            // setTimeout(() => {

                if (this.mode === 'setup') {

                    this.$ui.progressBar.setValue( (100/6) * 4 );

                    Setup.createPin(this.pincode).then(() => {

                        
                        this.attempts = 0;

                        // Fingerprint
                        // if (typeof FingerprintAuth != 'undefined') {

                        //     FingerprintAuth.isAvailable(() => {
                        //         //Start fingerprint setup

                        //         this.$prefs.settings.fingerPrintAvailable = true;
                        //         this.$prefs.save();

                        //         this.feedback = this.t('Fingerprint_recognition_enable_description');

                        //         this.$ui.overlay.hide();
                        //         this.fingerScanner = true;

                        //     }, () => {

                        //         this.skip();
                        //     });
                        // }
                        // else {
                            
                            this.skip();
                                
                        //}
                    },(e) => {
                        Debug.error( e );
                        this.$ui.overlay.hide();
                        this.$root.$refs.toast.error( this.t('Network_request_failed') );
                        
                    });
                }
                else {

                    this.$auth.refresh(this.pincode).then(() => {
                        this.attempts = 0;
                        this.$session.setActive();
                        this.$ui.overlay.hide();
                        this.$router.locked = false;
                    }, (error) => {
                        this.$ui.overlay.hide();
                        Debug.error( error );

                        if ('status' in error.response) {
                            if (error.response.status != 403) {

                                let errorMessage = error.response.data.message;

                                if (errorMessage.includes('Pins_do_not_match')) {
                                    let splitMessage = errorMessage.split(':');
                                    let errorRemainingAttempts = splitMessage[1];
                                    this.$root.$refs.toast.error( this.tWithParams('Pins_do_not_match', { remainingAttempts: errorRemainingAttempts}) );
                                }
                                else {
                                    if (error.response.data.message === 'Max_attemps_reached') {
                                        this.$root.$refs.toast.warning( this.t('Logged_out_confirmation_max_login_attempts') );
                                        this.$router.push({ path: '/logout' });
                                    }
                                    else {
                                        this.$root.$refs.toast.error( this.t(error.response.data.message) );
                                    }
                                }
                            }
                            else {
                                this.$ui.alert.show( {
                                    content: this.t('Logged_out_confirmation_access_denied')
                                } );
                                this.$router.push({ path: '/logout' });
                            }
                        }
                            
                        this.$refs.keyboard.show()
                    });
                }
            // }, 300);
        },

        startTiming() {

            this.pincodeTiming = setTimeout(() => {
                this.reset();
            }, 5000);
        },
        stopTimining() {

            clearTimeout(this.pincodeTiming);
        },

        setFeedback() {

            if (this.mode === 'setup') {
                this.feedback = this.t('Choose_your_pin');
            }
            else {
                this.feedback = this.t('Enter_your_pin');
            }
        },

        hidePinFields() {

            this.fieldsVisible = false;
        },

        showPinFields() {

            this.pincode = '';
            this.pincodeCache = '';
            this.fieldsVisible = true;
        },

        removePin() {

            if (this.pincode.length > 0) {

                let pin = this.pincode;

                this.pincode = pin.substring(0, pin.length - 1);
            }
        },

        reset() {

            this.pincode = '';
            this.pincodeCache = '';
            this.phase = 0;
            this.setFeedback();
            this.$refs.keyboard.hide();
        },

        // startFingerScan() {

        //     FingerprintAuth.delete({
        //         clientId: "TZP",
        //         username: this.$device.uuid
        //     }, () => { }, () => { });
            
        //     FingerprintAuth.encrypt({
        //         clientId: "TZP",
        //         username: this.$device.uuid,
        //         password: this.pincode,
        //         dialogTitle: this.t('Fingerprint_dialog_title'),
        //         dialogMessage: this.t('Fingerprint_dialog_description'),
        //         dialogHint: this.t('Fingerprint_dialog_hint'),
        //     }, 
        //     (result) => {
        //         this.$storage.write('fingerPrint', result.token);

        //         this.$ui.progressBar.setValue(100);

        //         this.$prefs.settings.fingerPrintEnabled = true;
        //         this.$prefs.save();

        //         setTimeout(() => {

        //             this.$router.push({ path: '/dashboard' });
        //         }, 1000);
        //     },
        //     (error) => {


        //     });
        // },

        skip() {

            //this.$ui.overlay.show();

            // Update the progress bar
            this.$ui.progressBar.setValue( (100/6) * 5 );

            setTimeout(() => {

                this.$ui.overlay.hide();

                // Go to next step
                this.$emit('nextStep', 3)

            }, 1000);
        },

        // async fpDecrypt() {

        //     if (this.$prefs.settings.fingerPrintEnabled) {

        //         try {

        //             const token = await this.$storage.read('fingerPrint');

        //             FingerprintAuth.decrypt({
        //                 clientId: "TZP",
        //                 username: this.$device.uuid,
        //                 token: token,
        //                 dialogTitle: this.t('Fingerprint_dialog_title'),
        //                 dialogMessage: this.t('Fingerprint_dialog_description'),
        //                 dialogHint: this.t('Fingerprint_dialog_hint')
        //             },
        //                 (result) => {

        //                     this.pincode = result.password;
        //                     this.approvePin();
        //                 },
        //                 (error) => {

        //                 });
        //         }
        //         catch (e) {

        //             console.log(e);
        //         }
        //     }
        // },

        swipe(direction) {

            if (direction === 'Down') {

                this.$refs.keyboard.hide();
            }
            else {

                this.$refs.keyboard.show();
            }
        },

        showKeyboard() {
            
            this.$refs.keyboard.show();
        }
    }
}
</script>
<style lang="scss" scoped>
.pinfield {
    position: relative;
    display: inline-block;
    padding: 0;
    height: 52px;
    width: 52px;
    border-radius: 4px;
    font-size: 52px;
    line-height: 1.35;
    text-align: center;
    overflow: hidden;
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Safari
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // Internet Explorer/Edge
    user-select: none;

    @media screen and (max-width:320px) {
        width: 44px;
        height: 44px;
        font-size: 44px;
    }

    span {
        position: absolute;
        right: 0;
        left: 0;
        display: block;
    }
}
</style>