import Vue from 'vue'
import { device } from '@/components/devices/api'
import { user } from '@/components/user/api'

// We need a vue instance to handle reactivity
var vm = null;

// The plugin
const ApiListeners = {
    // Install the method
    install ( Vue ) {
        if ( !vm ) {
            vm = new Vue( {
                // Data
                data: () => ( {

                } ),

                // Methods
                methods: {
                    /**
                     * 
                     * @param {String} token - token from the email
                     * @returns 
                     */
                    email_verify_password_reset ( token ) {
                        return user.passwordResetEmailVerification( token )
                    },
                    /**
                     * Revoke Device
                     * 
                     * @param {String} token - token to detect the device
                     * @returns
                     */
                    revoke_device ( token ) {
                        return device.revokeByToken( token )
                    },
                    /**
                     * Update current Device status
                     * 
                     * @param {Object} updateData - required data to update the device status
                     * @returns
                     */
                    update_current_device_status ( updateData ) {
                        return device.updateStatus( updateData )
                    }
                },
            } )

            Vue.prototype.$apiListeners = vm;
        }
    }
}

export default ApiListeners;