<template>

    <v-card 
    v-if="type"
    :flat="flat"
    rounded="tzp">
    
        <div class="text-center">
            
            <decorative-image
            v-if="image"
            :classes="imageClasses"
            :name="image"></decorative-image>
            
            <v-icon
            v-else
            :color="type"
            size="80"
            :class="'mb-4'+(!waiting ? ' animate__animated '+animation[type] : '')">
                {{ icon[type] }}
            </v-icon>

        </div>

        <card-content
        :buttons="buttons"
        :center="descriptionAlign == 'center' ? 'all' : 'title'"
        :content="t(description)"
        :noPadding="flat"
        :title="t(title)"
        :titleColor="type !== 'success' ? type : titleColor"></card-content>

    </v-card>

</template>

<script>
import CardContent from '@/components/CardContent.vue';
import DecorativeImage from '@/components/DecorativeImage.vue';

export default {
    name: 'ResponseMessage',

    props: {
        buttons: {
            type: Boolean|Array,
            default: false
        },
        description: {
            type: Boolean|String,
            default: false
        },
        descriptionAlign: {
            type: Boolean|String,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        image: {
            type: Boolean|String,
            default: false
        },
        imageClasses: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true
        },
        titleColor: {
            type: String,
            default: 'primary',
        },
        type: {
            type: String,
            required: true,
            validator: (prop) => [
                'error',
                'info',
                'success',
                'warning'
            ].includes(prop)
        },
        waiting: {
            type: Boolean,
            default: true,
        },
    },

    components: { 
        CardContent,
        DecorativeImage,
    },

    data: () => ({
        icon: {
            error: 'mdi-alert-octagon',
            info: 'mdi-information',
            success: 'mdi-check-decagram',
            warning: 'mdi-alert'
        },
        animation: {
            error: 'animate__wobble',
            info: 'animate__rubberBand',
            success: 'animate__tada',
            warning: 'animate__heartBeat'
        }
    })
}
</script>