<template>
    
    <v-card
    :color="$vuetify.breakpoint.smAndDown ? 'transparent' : undefined"
    :flat="$vuetify.breakpoint.smAndDown"
    rounded="tzp">
        
        <v-card
        v-for="(category, index) in settings"
        :elevation="$vuetify.breakpoint.mdAndUp ? 0 : 2"
        :key="index"
        :class="
            $vuetify.breakpoint.mdAndUp
                ? index == 0
                    ? 'mb-0 rounded-0 rounded-t-tzp '
                    : index == settings.length - 1
                    ? 'mb-0 rounded-0 rounded-b-tzp '
                    : 'mb-0 rounded-0'
                : (index == settings.length - 1 ? '' : 'mb-3')+' rounded-tzp'
        ">
            
            <v-list 
            one-line
            subheader
            class="muli--semibold">

                <v-subheader>{{ t(category.title) }}</v-subheader>

                <div 
                v-for="(item, index) in category.items"
                :key="index">
                    
                    <v-divider 
                    v-if="index !== 0"
                    inset></v-divider>

                    <v-list-item
                    v-if="item.to.startsWith('#')"
                    @click="dynamicMethod(item.to.replace('#', ''))"
                    color="tzpNavItem">

                        <v-list-item-avatar>

                            <v-icon>{{ item.icon }}</v-icon>

                        </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title>{{ t(item.title) }}</v-list-item-title>

                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item
                    v-else
                    color="tzpNavItem"
                    :replace="$vuetify.breakpoint.mdAndUp"
                    :to="item.to.startsWith('/') ? item.to : '/settings/' + item.to">

                        <v-list-item-avatar>

                            <v-icon>{{ item.icon }}</v-icon>

                        </v-list-item-avatar>

                        <v-list-item-content>

                            <v-list-item-title>{{ t(item.title) }}</v-list-item-title>

                        </v-list-item-content>
                        
                    </v-list-item>

                </div>

                <v-divider v-if="index !== settings.length - 1 && $vuetify.breakpoint.mdAndUp"></v-divider>

            </v-list>
            
        </v-card>

    </v-card>

</template>

<script>
export default {
    name: "Menu",

    data: () => ({
        settings: [
            {
                items: [
                    {
                        icon: "mdi-account",
                        title: "Account",
                        to: "account",
                    },
                    // {
                    //     icon: "mdi-badge-account-horizontal",
                    //     title: "Profile",
                    //     to: "/profile",
                    // },
                    {
                        icon: "mdi-cellphone-link",
                        title: "Devices",
                        to: "devices",
                    },
                ],
                title: "My",
            },
            {
                items: [
                    {
                        title: "Notifications",
                        icon: "mdi-bell",
                        to: "notifications",
                    },
                    {
                        title: "Interface",
                        icon: "mdi-palette",
                        to: "interface",
                    },
                    // {
                    //     icon: 'mdi-security',
                    //     title: 'Security',
                    //     to: 'security',
                    // },
                ],
                title: "App",
            },
            {
                items: [
                    {
                        icon: "mdi-tools",
                        title: "Tools",
                        to: "tools",
                    },
                    {
                        icon: "mdi-message-text",
                        title: "Share_feedback",
                        to: "feedback",
                    },
                ],
                title: "Support",
            },
            {
                items: [
                    {
                        icon: "mdi-gift",
                        title: "Product_updates",
                        to: "#showTour",
                    },
                    {
                        icon: "mdi-information",
                        title: "About_the_TZP",
                        to: "about",
                    },
                    {
                        icon: "mdi-file-document",
                        title: "Terms",
                        to: "terms",
                    },
                ],
                title: "About",
            },
        ],
    }),

    mounted() { },

    methods: {
        // Trigger a method dynamic by it's name
        dynamicMethod(methodName) {
            this[methodName]();
        },
        //Show the tour manually
        showTour() {
            this.$eventBus.$emit("showTour", "/full");
        },
    },
};
</script>