import { router } from '@/router'
import Auth from './Auth.service'

const Guard = {

    addedRoutes: {},

    construct ( config ) {

        this.onRoutes = ( config.on.route ? config.on.route : {} );
        this.onNavigate = ( config.on.navigate ? config.on.navigate : {} );
        router.previousRoute = null;

        // Allow navigation or not.
        router.locked = false;

        router.beforeEach( ( to, from, next ) => {
            let anonymous = typeof to.meta.anonymous == 'undefined' ? false : to.meta.anonymous;

            if ( anonymous == false ) {
                if ( to.path === '/login' && from.path === '/dashboard' ) {
                    Auth.lockNotice();

                    window.history.forward( 1 ); // prevent URL bar path change
                    return
                }
                else {
                    Auth.bootIfNotBoot().finally( () => {
                        this.middleware( to, from, next, config );
                    } )
                }
            }
            else {
                this.middleware( to, from, next, config );
            }
        } )
    },

    middleware ( to, from, next, config ) {
        // navigate action
        if ( typeof this.onNavigate.away != 'undefined' ) {
            this.onNavigate.away( to, from );
        }

        router.previousRoute = from;

        if ( router.locked === false || to.meta.forceAllowNavigation === true || to.path === '/logout' ) {
            //Can navigate


            //Execute custom injected pre-route actions
            if ( typeof this.onRoutes[ to.path ] != 'undefined' ) {

                let ar = this.onRoutes[ to.path ];

                ar.actions();

                if ( ar.next ) {

                    return next( ar.next );
                }
                else {

                    return next( false );
                }
            }

            //reset
            router.locked = false;

            let isPublic = to.meta.public;
            let lockBehind = to.meta.lockBehind;

            //Prevents going back
            if ( lockBehind === from.path && typeof to.query.force == 'undefined' ) {

                window.history.forward( 1 );

                return;
            }

            //Redirect to login page if not logged in and trying to access a restricted page
            if ( typeof isPublic != 'undefined' ) {
                if ( !isPublic && Auth.loggedIn === false ) {
                    return next( config.client.is.unauthenticated.route );
                }
                else {

                    //Redirect to dashboard on any public route when the user is logged in
                    if ( isPublic && Auth.loggedIn === true ) {
                        return next( config.client.is.authenticated.route );
                    }
                }
            }

            return next();
        }
        else {
            //Navigation prevent
            window.history.forward( 1 );

            return;
        }
    },
}

export default Guard;

