<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0 ' : ''+'basic'">
        
        <decorative-image
        :animate="smallImage"
        name="support"></decorative-image>

        <v-container class="container--tzp transparent">
            
            <faq 
            @search="imageView"
            ref="faq"></faq>

            <v-divider class="my-8"></v-divider>

            <v-row no-gutters>

                <v-col
                cols="12"
                md="8"
                lg="9">
                    
                    <div v-html="t('Support_knowledgebase_description')"></div>

                </v-col>

                <v-col
                cols="12"
                md="4"
                lg="3"
                class="text-center">
                    
                    <v-btn 
                    color="primary"
                    href="https://help.thuiszorgplanner.nl/"
                    outlined
                    target="_blank"
                    :class="$vuetify.breakpoint.smAndDown ? 'my-4': ''">
                        {{ t('To_knowledge_base') }}
                    </v-btn>

                </v-col>

            </v-row>

        </v-container>

    </v-main>

</template>

<script>
import DecorativeImage from '@/components/DecorativeImage.vue'
import Faq from '@/components/faqs/Faqs.vue'

export default {
    name: 'CustomerSupport',

    components: { 
        DecorativeImage,
        Faq,
    },

    data: () => ({
        smallImage: false
    }),

    methods: {
        imageView(val) {
            this.smallImage = this.$vuetify.breakpoint.mdAndUp ? false : val
        },
        resetSupport() {
            this.smallImage = false
            this.$refs.faq.clearSearch()
        }
    },
}
</script>
