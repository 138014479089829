<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">

        <div 
        class="frame-wrap"
        :style="'display: '+(!$proxy.frameUrl ? 'none' : 'block')">

            <iframe
            v-if="$proxy.frameUrl"
            id="frame"
            scrolling="no"
            :src="$proxy.frameUrl"></iframe>

        </div>

        <div
        :class="'frame-loader pa-4 bg--basic'+(!$proxy.frameLoaded ? '' : ' animate__animated animate__fadeOut animate__faster')"
        :style="'display: '+(!$proxy.frameLoaded ? 'block' : '')">

            <v-skeleton-loader
            type="heading"
            class="mb-4"></v-skeleton-loader>

            <v-skeleton-loader 
            type="list-item-three-line"
            class="mb-4"></v-skeleton-loader>
            
            <v-skeleton-loader type="table"></v-skeleton-loader>

            Proxy frameUrl: {{ $proxy.frameUrl }}
            <br/>Proxy frameLoaded: {{ $proxy.frameLoaded }}

        </div>

        Proxy frameUrl: {{ $proxy.frameUrl }}
        <br/>Proxy frameLoaded: {{ $proxy.frameLoaded }}

    </v-main>

</template>

<script>
export default {
    name: 'Frame',

    data: () => ({ 
        
    }),

    mounted () {
        this.hideSkeleton( this.$proxy.frameLoaded )
    },

    watch: {
        '$proxy.frameUrl': {
            handler(value) {
                Debug.log(`Proxy frameURL: ${value}`)
                if( value ) {
                    this.$proxy.frameLoaded = true
                }
            }
        },
        '$proxy.frameLoaded': {
            handler(value) {
                this.hideSkeleton( value )
            }
        },
    },

    methods: {
        hideSkeleton( value ) {
            if( value) {
                const frameSkeleton = document.querySelector('.frame-loader')
                frameSkeleton.addEventListener('animationend', () => {
                    frameSkeleton.style.display = 'none'
                });
            }
        }
    },
};
</script>
<style lang="scss">
.frame-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc( 100vh - 56px );

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        height: calc( 100vh - 64px );
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        min-height: calc( 100vh - 56px );
        border: 0;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            min-height: calc( 100vh - 64px );
        }
    }
}
.frame-loader {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: calc( 100vh - 56px );

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        height: calc( 100vh - 64px );
    }
}
</style>