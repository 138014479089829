<template>

    <div>

        <v-card-title
        v-if="title"
        v-html="title"
        :class="'headline font-weight-regular '+(titleColor)+'--text'+(center !== 'none' ? ' text-center justify-center' : '')+(noPadding ? ' px-0 pt-0' : '')+(!content ? ' pb-0' : '')+($vuetify.breakpoint.lgAndUp ? ' mx-auto' : '')"
        :style="$vuetify.breakpoint.lgAndUp ? 'max-width:600px' : ''"></v-card-title>

        <v-card-text
        v-if="content"
        v-html="content"
        :class="(noPadding ? 'pa-0 ' : '')+(center === 'all' ? 'text-center ' : '')+($vuetify.breakpoint.lgAndUp ? 'mx-auto' : '')"
        :style="$vuetify.breakpoint.lgAndUp ? 'max-width:600px' : ''"></v-card-text>

        <v-card-actions
        v-if="buttons && showActions"
        :class="center === 'all' ? 'justify-center' : (buttonAlign !== 'none' ? buttonAlign : '')">

            <template v-for="(button, index) in buttons">
                
                <v-btn
                v-if="'onClick' in button"
                @click="'to' in button.onClick ? $router.push( button.onClick.to ) : $emit('handleBtnFunction', button.onClick.action)"
                :color="'style' in button && 'color' in button.style ? button.style.color : undefined"
                dark
                :depressed="'style' in button && 'depressed' in button.style ? button.depressed : false"
                :key="index"
                :plain="'style' in button && 'plain' in button.style ? button.plain : false"
                :text="'style' in button && 'text' in button.style ? button.style.text : false">
                    {{ button.label }}
                </v-btn>

            </template>

        </v-card-actions>

    </div>

</template>

<script>
export default {
    name: 'CardContent',

    props: {
        buttons: {
            type: Boolean|Object,
            default: false,
        },
        buttonAlign: {
            type: String,
            default: 'none',
            validator: ( prop ) => {
                let check = [
                    'center',
                    'left',
                    'none',
                    'right',
                ].includes( prop );

                if( check ) {
                    let align = 'none';
                    switch( prop ) {
                        case 'center':
                            align = 'justify-center';
                            break;
                        case 'left':
                            align = 'justify-start';
                            break;
                        case 'right':
                            align = 'justify-end';
                            break;
                        default:
                            break;
                    }

                    return align;
                }
                
                return false;
            }
        },
        center: {
            type: String,
            default: 'none',
            validator: ( prop ) => [
                'all',
                'none',
                'title',
            ].includes( prop )
        },
        content: {
            type: Boolean|String,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        showActions: {
            type: Boolean,
            default: true,
        },
        title: {
            type: Boolean|String,
            required: true
        },
        titleColor: {
            type: String,
            default: 'primary',
        },
    },

    data: () => ({ }),
}
</script>