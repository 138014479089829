<template>

    <v-layout
    align-center
    justify-center
    :class="animate ? 'animate__animated animate__zoomOutUp' : 'pt-6 pb-2'">
        
        <div class="smoothen animate__animated animate__faster animate__fadeInDown">
        
            <div 
            class="pa-6 mx-auto rounded-50p decorative-img__wrap"
            :style="animate ? 'width:10px;height:10px;' : 'width:174px;height:174px;'">
                
                <v-img
                contain
                eager
                role="presentation"
                :src="'img/'+($prefs.settings.darkModeEnabled ? 'dark/' : '')+name+($prefs.settings.darkModeEnabled ? '_dark' : '')+'.svg'"
                :height="animate ? '10px' : '124px'"
                :width="animate ? '10px' : '124px'"></v-img>

            </div>

            <div 
            v-if="title"
            class="mt-8 text-h5 font-weight-light grey--text text--darken-2 text-center">
                {{ t(title) }}
            </div>

        </div>

    </v-layout>

</template>

<script>
export default {
    name: 'DecorativeImage',

    props: {
        animate: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true,
            // validator: (prop) => [
            //     'forgot-password-completed',
            //     'list-bulleted-completed',
            //     'password-reset-email-verification',
            //     'revoke-device',
            //     'settings',
            //     'support',
            // ].includes(prop)
        },
        title: {
            type: String|Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
.decorative-img__wrap {
    position:relative; 
    right:0; 
    left:0; 
    background-color:#FFFFFF;
}
.theme--dark {
    .decorative-img__wrap { background-color:#1E1E1E }
}
</style>