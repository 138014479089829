import collect from 'collect.js';

const TaskQueue = {
    _tasks: {
        unlockTasks: []
    },

    _setTask ( category, func ) {
        this._tasks[ category ].push( func );
    },

    _categoryExists ( category, create = true ) {
        const tasksCollection = this._getTasksCollection();

        if ( !tasksCollection.has( category ) ) {
            if ( create ) {
                this._tasks[ category ] = [];

                return true;
            }
        }

        return false;
    },

    addToQueue ( category, func ) {
        if ( !category ) {
            Debug.error( `TaskQueue: addToQueue -> no task category set` );

            return false;
        }
        else if ( !this._categoryExists( category ) ) {
            return false;
        }

        this._setTask( category, func );
    },

    _getTasksCollection () {
        return collect( this._tasks );
    },

    hasPendingTasks ( category ) {
        if ( !category ) {
            Debug.error( `TaskQueue: hasPendingTasks -> No task category set` );

            return false;
        }
        else if ( !this._categoryExists( category ) ) {
            return false;
        }

        let count = 0;
        const tasksCollection = this._getTasksCollection();
        const keys = tasksCollection.keys();

        keys.each( ( cat, index ) => {
            if ( this._tasks[ cat ].length > 0 ) {
                count = this._tasks[ cat ].length;

                return false;
            }
        } );

        return count > 0;
    },

    dispatchTasks ( category ) {
        if ( !category ) {
            Debug.error( `TaskQueue: dispatchTasks -> no task category set` );

            return false;
        }
        else if ( !this._categoryExists( category, false ) ) {
            return false;
        }

        const tasksCategoryCollection = collect( this._tasks[ category ] );
        tasksCategoryCollection.each( ( item ) => {
            item();
        } );

        this._tasks[ category ] = [];
    }
};
export default TaskQueue;