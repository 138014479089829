<template>
    
    <v-overlay
    v-if="item"
    color="grey darken-3"
    :opacity="1"
    :value="itemState"
    class="notification-dialog">
        
        <v-card
        elevation="0"
        color="grey darken-3"
        tile
        width="100%">

            <v-toolbar
            dark
            color="tzpPurpleDark">

                <v-btn
                icon
                dark
                @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title class="muli">{{ t('Notification') }}</v-toolbar-title>

                <v-spacer></v-spacer>
                
                <v-btn
                @click="mark()"
                icon>
                    <v-icon size="20">mdi-email-{{ item.read ? 'mark-as-unread' : 'open' }}</v-icon>
                </v-btn>
                
                <v-btn
                @click="remove()"
                icon>
                    <v-icon size="20">mdi-delete</v-icon>
                </v-btn>
        
            </v-toolbar>

            <v-card-title 
            v-if="item.message.subject"
            class="headline">
                {{ item.message.subject }}
            </v-card-title>

            <v-card-text v-if="item.message.lines">
                <template  v-for="(line, index) in item.message.lines">
                    <p :key="index">{{ line }}</p>
                </template>
            </v-card-text>

            <v-card-text 
            v-if="item.message.footer"
            v-html="item.message.footer"></v-card-text>

            <v-divider v-if="item.message.buttons"></v-divider>

            <v-card-actions v-if="item.message.buttons">

                <v-spacer></v-spacer>
                
                <template v-for="(button, index) in $collect(item.message.buttons).reverse().all()">
                    
                    <api-button
                    @onBusy="eventBusy($event)"
                    @onFail="eventFailed($event)"
                    @onSuccess="eventSucceeded($event)"
                    :disable="item.actioned == 1 ? true : disableButtons"
                    :key="index"
                    :button="button"></api-button>
                    
                </template>

            </v-card-actions>

        </v-card>

    </v-overlay>

</template>

<script>
import ApiButton from '@/components/form/ApiButton.vue'
import { Notifications } from '@/components/notifications/api'

export default {
    name: 'Notification',

    props: {
        from: {
            type: String,
        },
        item: {
            type: Object,
            default: () => {}
        },
        itemState: {
            type: Boolean,
            default: false
        }
    },

    components: { ApiButton },

    mixins: [ Notifications.map() ],

    data: () => ({
        disableButtons: false,
    }),

    methods: {
        close() {
            // Delay close check, because of the NavigationDrawer transition (if it's not permanent)
            if( this.from !== 'manager' && ! this.$vuetify.breakpoint.xl ) {
                this.$ui.notificationDrawer.toggled = false;
                this.$emit('close', true);
                this.$emit('update:from', 'manager'); // reset to default
                this.disableButtons = false; // reset button(s) state
            }
            else {
                this.$emit('close', false);
                this.disableButtons = false; // reset button(s) state
            }
        },
        remove() {
            this.item.api().delete();
            this.Notifications.decreaseTotalCount();
            this.close();
        },
        mark() {
            this.item.markToggle().then( () => {
                if( this.item.read ) {
                    this.Notifications.decreaseUnreadCount();
                }
                else {
                    this.Notifications.increaseUnreadCount();
                }
            });
            this.close();
        },
        // Button Listeners
        eventBusy() {
            this.disableButtons = true;
        },
        eventFailed( response ) {
            this.$root.$refs.toast.error( this.t(response.message), this.$vuetify.breakpoint.smAndDown ? 'bottom' : 'top' ); // Show v-snackbar
            this.disableButtons = false; // Enable buttons, to try again
        },
        eventSucceeded( response ) {
            this.$root.$refs.toast.success( this.t(response.message), this.$vuetify.breakpoint.smAndDown ? 'bottom' : 'top' ); // Show v-snackbar
            this.item.setHandled();
        },
    }
}
</script>

<style lang="scss">
.notification-dialog {
    align-items: inherit;
    justify-content: inherit;
    transition: none !important;

    .v-overlay__content { width: 100%; }
} 
</style>