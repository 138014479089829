import Storage from './Storage.service'
import Api from './Api.service'
import Time from '../helpers/Time'
import Session from './Session.service'
import Ui from './Ui.service'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const Auth = {

    boot: false,
    loggedIn: false,
    accessToken: '',
    refreshToken: '',
    atExpireTime: 0,
    rtExpireTime: 0,
    rtgExpireTime: 0,
    rtgExpireTimeSeconds: 0,
    callbackAction: [],
    refreshInstance: false,

    async construct ( config ) {
        if ( typeof config.on != 'undefined' ) {
            this.callbackAction = config.on;
        }

        await this.callback( 'authConstruct' );
    },

    async bootIfNotBoot () {
        if ( !this.isBoot() ) {
            const tokens = await Storage.secure.read( 'tokens' );

            if ( tokens ) {

                this.setLoggedIn( tokens );

                if ( this.rtExpired() ) {
                    this.callback( 'refreshTokenExpiration' );
                }
                else if ( this.rtGapExpired() ) {
                    this.callback( 'accessTokenExpiration' );
                }

                this.mountInterceptor();
                this.boot = true;
            }
            else {
                this.callback( 'noTokensFound' );
            }
        }
    },

    isBoot () {
        return this.boot;
    },

    async syncTokensFromStorage () {
        await new Promise( ( resolve, reject ) => {
            Storage.secure.read( 'tokens' ).then( tokens => {
                if ( tokens ) {
                    this.setLoggedIn( tokens, false );
                    resolve( tokens );
                }
                else {
                    reject();
                }
            } ).catch( error => {
                reject( error );
            } );
        } );
    },

    getRefreshInstance () {
        return this.refreshInstance ? this.refreshInstance : this.refresh();
    },

    refresh ( pin ) {

        this.refreshInstance = new Promise( ( resolve, reject ) => {
            this.syncTokensFromStorage().then( () => {

                let requestData = {
                    method: 'post',
                    url: 'auth/device/app/refresh',
                    token: false,
                    data: {
                        refresh_token: this.refreshToken
                    },
                    skipAuthRefresh: true
                }

                if ( pin ) {
                    requestData.data.pin = pin;
                }

                Api.request( requestData ).then( response => {
                    this.setLoggedIn( response.data );
                    resolve( response.data );
                    this.callback( 'tokensRefreshed' );
                } ).catch( ( error ) => {
                    reject( error );
                } ).finally( () => {
                    this.refreshInstance = false;
                } );
            } );
        } );

        return this.refreshInstance;
    },

    logout () {
        if ( this.isLoggedIn() ) {
            Session.destroy( true );
            Storage.secure.remove( 'tokens' );

            this.loggedIn = false;
            this.rtExpireTime = 0;
            this.atExpireTime = 0;

            this.callback( 'forTokenInvalidation' ).finally( () => {
                this.accessToken = '';
                this.refreshToken = '';
                this.callback( 'loggedOut' );
            } );
        }
    },

    setLoggedIn ( tokens, startSession = true ) {
        if ( tokens.access_token != '' ) {

            this.accessToken = tokens.access_token;

            if ( tokens.refresh_token ) {
                this.refreshToken = tokens.refresh_token;
            }
        }

        axios.defaults.headers.common[ 'Authorization' ] = 'Bearer ' + this.accessToken;

        let sessionEncoded = tokens.access_token.split( '.' )[ 1 ];
        let sessionData = window.atob( sessionEncoded ); // base64 decode our token

        Storage.secure.write( 'tokens', tokens );

        let rtExp = tokens.rt_expires_in;
        let atExp = tokens.at_expires_in;

        this.loggedIn = true;
        this.rtExpireTime = rtExp;
        this.atExpireTime = atExp;
        this.rtgExpireTimeSeconds = tokens.rtg_expires_in_sec;
        this.rtgExpireTime = tokens.rtg_expires_in;

        Session.setData( sessionData );

        if ( startSession ) {
            Session.start();
        }

    },

    isLoggedIn () {
        return this.loggedIn;
    },

    async lock () {
        const requestData = {
            method: 'patch',
            url: 'auth/device',
            data: {
                locked: 1
            },
            skipAuthRefresh: true
        }

        await Api.request( requestData );
    },

    getRtGap () {
        return this.rtgExpireTime - Time.now();
    },

    rtGapExpired () {
        return ( this.rtgExpireTime - Time.now() ) < 0;
    },

    atExpiresIn () {
        return this.atExpireTime - Time.now();
    },

    atExpired () {
        return ( Time.now() > this.atExpireTime ? true : false )
    },

    rtExpired () {
        return ( Time.now() > this.rtExpireTime ? true : false )
    },

    callback ( action ) {
        return this.callbackAction[ action ] ? this.callbackAction[ action ]() : null;
    },

    mountInterceptor () {

        var refreshAuthLogic = failedRequest => this.getRefreshInstance();

        // Instantiate the interceptor
        createAuthRefreshInterceptor( axios, refreshAuthLogic, { pauseInstanceWhileRefreshing: false } );

        axios.interceptors.request.use( request => {
            if ( request.token === false ) {
                // Remove token from pending request.
                request.headers[ 'Authorization' ] = null;
            }
            else {
                // Update pending requests with the new token
                request.headers[ 'Authorization' ] = 'Bearer ' + this.accessToken;
            }
            return request;
        } );

        // Watch for pre-condition (pin)
        // axios.interceptors.response.use( function ( response ) {
        //     return response;
        // }, ( error ) => {
        //     if ( 428 === error.response.status ) {
        //         if ( error.response.config.skipAuthRefresh != true ) {
        //             this.callback( 'accessTokenExpiration' );
        //         }
        //     }
        //     else {
        //         return Promise.reject( error );
        //     }
        // } );
    },

    lockNotice () {
        Ui.dialog.show( {
            buttons: [
                {
                    text: 'Cancel',
                    click: () => {
                        Ui.dialog.hide();
                    },
                },
                {
                    text: 'Continue',
                    click: () => {
                        router.push( '/lock' );
                        Ui.dialog.hide();
                    },
                },
            ],
            description: 'Lock_dialog_description',
            icon: {
                color: 'tzpPurple',
                key: 'mdi-lock'
            },
            title: 'Lock_dialog_title',
        } );
    }
}
export default Auth;