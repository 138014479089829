export default class Entity {

    constructor (extend) {
        this.init = false;
        Object.assign(this, extend);
    }

    setInit(init) {
        this.init = init;
        'onInit' in this ? this.onInit(this) : null;
    }

    primaryKey() {
        return 'uuid';
    }

    getKey() {
        return this[this.primaryKey()];
    }

    getIdentifier() {
        return this.getKey();
    }
}
