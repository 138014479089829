<template>
    
    <v-card
    :flat="inWidget"
    :rounded="inWidget ? false : 'tzp'"
    :class="'news-item--preview'+(!inWidget ? ' mb-4' : '')">
        
        <v-card-title class="subtitle-1 font-weight-medium pt-2">{{ item.title }}</v-card-title>

        <v-card-subtitle class="subtitle-2 font-weight-regular pb-1">{{ item.description_short }}</v-card-subtitle>

        <v-card-text class="pb-2">
            <small
            v-html="tWithParams('News_item_published_at', { publisher: ('author' in item ? item.author : 'Coolweb'), datetime: $time.date( item.created_at_timestamp ) })"
            class="grey--text lighten-2--text"></small>
        </v-card-text>

        <v-card-actions 
        v-if="path"
        class="pt-0">
            
            <v-btn
            color="tzpOrange"
            class="muli--bold"
            text
            :to="'/news/' + path">
                {{ t('Read_more') }}
            </v-btn>

        </v-card-actions>

    </v-card>

</template>

<script>
export default {
    name: 'NewsItemPreview',

    props: {
        inWidget: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: true,
        },
        path: {
            type: String|Number,
            required: true,
        }
    }
}
</script>

<style>
.news-item--preview { width: 100%; }
</style>