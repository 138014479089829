/**
 * Notifications API 
 * 
 * Contains API and Resource calls
 */
import Resource from '@/system/helpers/api/Resource'
import Entity from '@/system/helpers/api/Entity'

export const MessagingUserPreferences = new Resource( {
    location: 'messaging/user/preferences',
    state: 'MessagingUserPreferences',
    persist: false,
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},
    entity: new Entity,
} )