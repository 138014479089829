 <template>

    <div>
        
        <v-card
        rounded="tzp"
        class="mb-4">

            <v-card-text v-html="t('About_intro')"></v-card-text>

            <v-card-text>
                <strong class="primary--text">{{ t('Current_version') }} v{{ $prefs.settings.version }} <small>({{ getEnvironment() }})</small></strong>
            </v-card-text>
            
        </v-card>

        <v-card 
        rounded="tzp"
        class="mb-4">

            <v-card-text>
                
                <h2 class="headline mb-3">{{ t("Roadmap") }}</h2>

                <roadmap></roadmap>

            </v-card-text>

        </v-card>

        <v-card rounded="tzp">

            <v-card-text>
                
                <h2 class="headline mb-3">{{ t("Changelog") }}</h2>

                <changelog></changelog>

            </v-card-text>

        </v-card>

    </div>

</template>

<script>
import Changelog from "@/components/Changelog.vue"
import Roadmap from "@/components/Roadmap.vue"

export default {
    name: "About",

    components: { 
        Changelog,
        Roadmap
    },
    
    data: () => ({ }),
    
    methods: {
        getEnvironment() {
            return process.env.VUE_APP_ENVIRONMENT
        },
    },
};
</script>