import axios from 'axios'
import Device from './Device.service';
import Session from './Session.service';
import Auth from './Auth.service';
//import { Http } from '@capacitor/http'; --- web
//import { Plugins } from '@capacitor/core';
//const Http = Plugins.Http;

const Api = {

    headers: {},
    endpoints: {},
    authBaseURL: '',
    resourceBaseURL: '',
    bearerToken: '',

    async construct ( config ) {
        // Axios default headers.
        axios.defaults.withCredentials = true;
        axios.defaults.timeout = 4000;
        axios.defaults.headers.common = {
            'Authorization': 'Bearer ' + Auth.accessToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
    },

    /**
     * Perform a custom request.
     *
     * data is an object containing the following properties:
     *  - method | string
     *  - url | string 
     *  - data | object | Request payload
     *  - token | bool | Add authorization header
     */
    request ( data ) {
        data.url = process.env.VUE_APP_API_LOCATION + data.url;

        if ( Device.platform == 'web' ) {
            return this.webRequest( data );
        }
        else {
            return this.webRequest( data );
            // return this.nativeRequest(data);
        }
    },

    // Axios implementation.
    webRequest ( data ) {
        //data.headers = this.headers;

        return new Promise(( resolve, reject ) => {
            axios( data ).then( response => {
                resolve( response );
            }, error => {
                this.handleHttpExceptions(error);
                reject( error );
            } );
        } );
    },

    /**
     * Handles http exceptions for all API calls.
     * Here you may take additional application global 
     * actions on API error responses.
     */
    handleHttpExceptions(error) {
        switch (error.response.status) {
            case 428:
                // Lock
                Session.showTooLongAway();
            break;

            case 403:
                if (error.response.data.message == 'Account_disabled') {
                    // Lock
                    Session.showTooLongAway();
                }
            break;
        }
    },

    // Native http implementation simulating axios request.
    nativeRequest ( data ) {
        data.method = data.method.toUpperCase();
        data.headers = this.getHeaders();

        // Model it to axios promise.
        let promise = new Promise( function ( resolve, reject ) {
            Http.request( data ).then( response => {
                if ( response.status == 200 ) {
                    resolve( response );
                }
                else {
                    // Error.
                    if ( response.status == 428 ) {
                        Session.halt();
                    }
                    let error = {};
                    error.response = response;
                    Debug.log( 'HTTP-ERROR (' + response.status + '): ' + JSON.stringify( error.response.data ) );
                    //alert('Http error: ' + JSON.stringify(error));
                    reject( error );
                }
            }, error => {
                Debug.log( 'Network Error: ' + JSON.stringify( error ) );
                reject( error );
            } );
        } );

        return promise;
    },

    getHeaders () {
        return this.headers;
    },

    setHeader ( key, value ) {
        this.headers[ key ] = value;
    },

    removeHeader ( key ) {
        delete this.headers[ key ];
    }
}

export default Api;