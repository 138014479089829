<template>

    <v-tooltip
    v-if="! $device.isNative()"
    :bottom="tooltipPos == 'bottom' ? true : false"
    :left="tooltipPos == 'left' ? true : false"
    :right="tooltipPos == 'right' ? true : false"
    :top="tooltipPos == 'top' ? true : false">

        <template v-slot:activator="{ on, attrs }">
            
            <v-btn
            v-bind="attrs"
            v-on="on"
            @click="click ? $emit(click) : ''"
            @mouseenter="locked = true"
            @mouseleave="locked = false"
            :color="color"
            fab
            icon
            small
            :to="to"
            :class="classes">

                <v-icon>
                    {{ locked ? 'mdi-lock'+(variant ? '-'+variant : '') : 'mdi-lock-open'+(variant ? '-'+variant : '') }}
                </v-icon>

            </v-btn>

        </template>

        <span>{{ t("Lock") }}</span>

    </v-tooltip>

    <v-btn
    v-else
    @click="click ? $emit(click) : ''"
    @mouseenter="locked = true"
    @mouseleave="locked = false"
    fab
    icon
    small
    :to="to">
        
        <v-icon color="grey lighten-1">
            {{ locked ? 'mdi-lock'+(variant ? '-'+variant : '') : 'mdi-lock-open'+(variant ? '-'+variant : '') }}
        </v-icon>

    </v-btn>

</template>

<script>
export default {
    name: 'LockIcon',

    inheritAttrs: false,

    props: {
        classes: {
            type: String,
            default: ''
        },
        click: {
            type: Boolean|String,
            default: false
        },
        color: {
            type: String,
            default: 'grey'
        },
        to: {
            type: String|Object,
            default: undefined
        },
        tooltipPos: {
            type: String,
            default: 'top'
        },
        variant: {
            type: String,
            default: ''
        },
    },

    data: () => ({
        locked: false,
    })
}
</script>