<template>
    
    <router-link 
    v-if="to && icon"
    :to="to">
        
        <v-avatar
        :color="color"
        :size="size"   
        :class="'elevation-'+elevation">
            
            <v-icon color="white">{{ icon }}</v-icon>

        </v-avatar>

    </router-link>

    <v-avatar
    v-else-if="icon"
    :color="color"
    :size="size"   
    :class="'elevation-'+elevation">
        
        <v-icon color="white">{{ icon }}</v-icon>
        
    </v-avatar>
            
</template>

<script>
export default {
    name: 'AvatarIcon',
    
    props: {
        color: {
            type: String,
            default: 'tzpOrange'
        },
        elevation: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            required: true
        },
        size: {
            type: Number,
            default: 40,
        },
        to: {
            type: String,
        },
    },
}
</script>