var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notifications"},[(_vm.items)?_c('div',{staticClass:"rounded-b-tzp",attrs:{"data-updated":_vm.notificationsTimeUpdated}},[(!_vm.swipe)?_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(_vm.headers)?[(index === _vm.indexToday)?_c('v-subheader',{staticClass:"notifications__subheader"},[_vm._v(_vm._s(_vm.t('Today')))]):_vm._e(),(index === _vm.indexEarlier)?_c('v-subheader',{staticClass:"notifications__subheader"},[_vm._v(_vm._s(_vm.t('Older')))]):_vm._e()]:_vm._e(),_c('v-list-item',{class:'notification__wrap'+(item.read ? ' opacity--6' : ''),attrs:{"three-line":""}},[_c('v-list-item-avatar',{on:{"click":function($event){return _vm.itemClicked( item )}}},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.getIcon(item.channel))+" ")])],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.itemClicked( item )}}},[_c('v-list-item-title',{class:!item.read ? 'font-weight-medium' : ''},[_vm._v(_vm._s(item.message.subject))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.message.title))]),_c('v-list-item-subtitle',{class:'caption text-right'},[_vm._v(" "+_vm._s(item.local().received_time ? item.local().received_time : _vm.$time.dateTime(item.created_at_timestamp, true, false))+" ")])],1),(! _vm.swipe)?_c('v-list-item-action',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{attrs:{"color":hover ? 'red': '',"icon":""},on:{"click":function($event){return _vm.deleteItem( item )}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1)]}}],null,true)})],1):_vm._e()],1),(index != _vm.items.count() - 1)?_c('v-divider',{staticClass:"mx-4"}):_vm._e()],2)}),0):(_vm.swipe)?_c('swipe-list',{ref:"swipeList",staticClass:"mt-2",attrs:{"items":_vm.items.toArray()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{class:'notification__wrap'+(item.read ? ' opacity--6' : '')},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-avatar',{on:{"click":function($event){return _vm.itemClicked( item )}}},[_c('v-icon',{attrs:{"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.getIcon(item.channel))+" ")])],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.itemClicked( item )}}},[_c('v-list-item-title',{class:!item.read ? 'font-weight-medium' : ''},[_vm._v(_vm._s(item.message.subject))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.message.title))]),_c('v-list-item-subtitle',{class:'caption text-right'},[_vm._v(" "+_vm._s(item.local().received_time ? item.local().received_time : _vm.$time.dateTime(item.created_at_timestamp, true, false))+" ")])],1)],1)],1),(index != _vm.items.count() - 1)?_c('v-divider',{staticClass:"mx-4"}):_vm._e()]}},{key:"left",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('div',{staticClass:"swipeout-action green darken-1",on:{"click":function($event){return _vm.markBySwipe( index, item )}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(item.read ? 'mdi-email' : 'mdi-email-open')+" ")])],1)]}},{key:"right",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"swipeout-action red",on:{"click":function($event){return _vm.deleteItem( item )}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-delete ")])],1)]}}],null,false,3664982969)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }