export default class Connector {

    name = 'Connector';
    _eventPusher = null;
    _channelEventPusher = null;
    status = 'disconnected';
    auth = null;

    /**
     * Prepare the connector.
     */
    async install(MessagingService, connectorName) {
        this.setName(connectorName);
        this.setEventListener(MessagingService._callNotificationListener);
        this.setChannelListener(MessagingService._callChannelListener);
        this.log('Installed');
    }

    /**
     * Set the connector's name.
     */
    setName(name) {
        this.name = name;
    }

    /**
     * Get the connector's name.
     */
    getName() {
        return this.name;
    }

    /**
     * Set the required authorization data.
     */
    setAuth(auth) {
        this.auth = auth;
    }

    /**
     * Get the authorization data.
     */
    getAuth() {
        return this.auth;
    }

    /**
     * Set the event listener. 
     */
    setEventListener(listener) {
        this._eventPusher = listener;
    }

    /**
     * Set the channel listener. 
     */
     setChannelListener(listener) {
        this._channelEventPusher = listener;
    }

    /**
     * Set status to connected. 
     */
    setConnectedStatus() {
        this.log('Connected');
        this.status = 'connected';
    }

    /**
     * Set status to disconnected. 
     */
    setDisconnectedStatus() {
        this.log('Disconnected');
        this.status = 'disconnected';
    }

    /**
     * Get the connection status.
     */
    getStatus() {
        return this.status;
    }

    /**
     * Publish an event to the listener.
     */
    publishEvent(eventName, notificationData, type) {
        this._eventPusher(eventName, notificationData, type);
    }

    /**
     * Publish an event to the channel's listener.
     */
     publishChannelEvent(channelName, notificationData, type) {
        this._channelEventPusher(channelName, notificationData, type);
    }

    /**
     * Check if the connector is currently connected.
     */
    isConnected() {
        return this.status === 'connected';
    }

    //@override
    async connect(onConnectedCallback) {}

    //@override
    async disconnect() {}

    //@override
    async subscribeToChannels(channels) {}

    //@override
    async unsubscribeFromChannels() {}

    //@override
    async subscribeToChannelByID(id) {}

    //@override
    async unsubscribeFromChannelByID(id) {}

    log(message) {
        Debug.info('Messaging.service.connector.' + this.getName() + ': ' + message + '.');
    }
}
