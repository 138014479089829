<template>

    <div>
        <v-tabs v-model="tab">

            <v-tab
            v-for="(version, k) in versions"
            :key="k">
                {{ t('Version')+' '+version.title }}
            </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">

            <v-tab-item
            v-for="(version, k) in versions"
            :key="k"
            :reverse-transition="false"
            :transition="false">
                
                <v-card flat>
                
                    <v-card-text>

                        <div class="tzpOrange--text mb-3"><strong>{{ version.tag }}</strong> - {{ version.release_date }}</div>

                        <div v-html="version.description"></div>

                    </v-card-text>

                </v-card>

            </v-tab-item>

        </v-tabs-items>
    
    </div>

</template>

<script>
export default {
    name: 'Changelog',

    data: () => ({
        tab: null,
        versions: {
            0: {
                title: '1.0.1',
                tag: 'v1.0.1',
                release_date: '10-01-2022',
                description: '<p>Kleine bugfixes waardoor zaken op de achtergrond nog beter draaien en verwerkt worden.</p>',
            },
            1: {
                title: '1.0.0',
                tag: 'v1.0.0',
                release_date: '02-01-2022',
                description: '<p>Het wachten is voorbij, het is eindelijk zover; de eerste release van de <strong>nieuwe Thuiszorgplanner</strong>.<br>De Thuiszorgplanner is van de grond af opnieuw opgebouwd en legt de basis voor een mooie toekomst die voor ons ligt.</p><h3 class="my-3">Toegevoegd</h3><ul><li>Apparatenbeheer; geeft je een overzicht van de gekoppelde apparaten op jouw account en deze kunnen hier nu beheren.</li><li>Notificaties; ontvang een notificatie bij jouw account activity op een ander apparaat, maar dit is pas het begin!</li><li>Feedback; deel jouw ervaring(en), zo dat wij de Thuiszorgplanner kunenn blijven door ontwikkelen met jullie steun.</li><li>Tour; laat nieuwe functionaliteiten en opties zijn die zijn toegevoegd aan de Thuiszorgplanner.</li><li>Changelog/Over de Thuiszorgplanner; updates en informatie over de wijzigingen van iedere nieuwe versie van de Thuiszorgplanner.</li></ul><h3 class="my-3">Gewijzigd</h3><ul><li>Login proces volledig vernieuwd.</li><li>Compleet vernieuwde en uitgebreide instellingen weergave.</li><li>Eerste stappen gezet om de Thuiszorgplanner te personaliseren.</li><li>Account en profiel als losse weergave neergezet, omdat dit vanaf nu aparte onderdelen gaan zijn.</li></ul><h3 class="my-3">Beveiliging</h3><ul><li>Stabiliteitsverbeteringen door het nieuwe TZP-X platform\</li><li>De Thuiszorgplanner voldoet aan de laatste release standaarden voor Android en iOS.</li></ul>',
            },
        },
    }),
}
</script>