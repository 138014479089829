/**
 * Companies API 
 * 
 * Contains API and Resource calls
 */
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

const CompanyEntity = new Entity( {

} );

/* API */
export const companies = {

}

/* RESOURCES */
/**
 * Companies
 * 
 *  Get all companies of the authenticated user
 */
export const Companies = new Resource( {
    location: 'auth/companies',
    state: 'Companies',
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {
        getPrimary () {
            return this.clone().first();
        }
    } ),
    entity: CompanyEntity
} )

/**
 * Company
 * 
 *  Single company information of the authenticaded user 
 * 
 *  GET - a single company
 *      params: {
 *          uuid: ...
 *      }
 */
export const Company = new Resource( {
    location: 'auth/companies/{{uuid}}',
    state: 'Company',
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    entity: CompanyEntity
} )