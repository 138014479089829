<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">

        <v-container class="container--tzp-sm mt-4">

            <company-profile 
            v-if="Companies.init"
            :company="Companies.getPrimary()"></company-profile>

        </v-container>

    </v-main>

</template>

<script>
import { Companies } from "@/components/companies/api"
import CompanyProfile from "@/components/companies/CompanyProfile";

export default {
    name: "CompanyView",

    components: { CompanyProfile },

    mixins: [ Companies.map() ],

    data: () => ({}),

    mounted() {},
};
</script>