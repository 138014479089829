<template>

    <router-link 
    v-if="to"
    :to="to"
    class="profile-wrap px-4">
        
        <div 
        v-if="icon"
        class="profile-avatar text-center mb-4">
            
            <avatar-icon
            :icon="icon"
            :size="size"></avatar-icon>

        </div>

        <div
        v-if="title"
        class="text-center">
            
            <div class="profile-title mb-2">{{ title }}</div>
            
            <div
            v-if="subtitle"
            class="profile-subtitle muli">
            {{ subtitle }}
            </div>

        </div>

    </router-link>

    <div 
    v-else
    class="profile-wrap px-4">
        
        <div 
        v-if="icon"
        class="profile-avatar text-center mb-4">
            
            <avatar-icon
            :icon="icon"
            :size="size"></avatar-icon>

        </div>

        <div
        v-if="title"
        class="text-center">
            
            <div class="profile-title mb-2 muli--bold">{{ title }}</div>
            
            <div
            v-if="subtitle"
            class="profile-subtitle muli">
            {{ subtitle }}
            </div>

        </div>

    </div>

</template>

<script>
import AvatarIcon from '@/components/avatars/Icon'

export default {
    name: 'AvatarProfileIcon',
    
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        subtitle: {
            type: String,
            required: false
        },
        size: {
            type: Number,
            default: 72,
        },
        to: {
            type: String,
            required: false
        }
    },

    components: { AvatarIcon },
}
</script>