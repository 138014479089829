/**
 * User API 
 * 
 * Contains API and Resource calls
 */
import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import Resource from '@/system/helpers/api/Resource'

/* API */
export const user = {
    /**
     * Password reset email verification
     * 
     * @async
     * @param {String} token - The token to verify that the email is received and clicked
     * @returns {Promise}
     */
    passwordResetEmailVerification: async ( token ) => {
        return Api.request( {
            method: 'POST',
            url: 'auth/password/email/verification',
            token: false,
            data: {
                token: token
            }
        } )
    }
}

/* RESOURCES */

/**
 * User
 * 
 * @example - Complete description of all the options in the Resource
 */
export const User = new Resource( {

    /*
     * Relative location of the API endpoint without begin slash.
     */
    location: 'auth/user',

    /* 
     * Unique name of the store that will be attached to this resource, 
     * after being mapped it will be available through this name.
     */
    state: 'User',

    /* 
     * Set to true if you want to enable local storage.
     */
    persist: false,

    // Send with or without authorization headers.
    // Defaults to true if not set.
    authenticate: true,

    // If enabled, attempts to fill the store by default.
    autoFill: false,

    // Force refill on Vue Created().
    forceRefill: false,

    // Available methods
    methods: [
        'get'
    ],

    // Empty response structure; prevents Vue render errors when the request is delayed.
    data: {
        data: {}
    },

    entity: new Entity( {
        getActiveRole () {
            return this.active_role;
        },
        getFullnameInitials () {
            return this.initials[ 0 ] + this.last_name[ 0 ];
        },
    } )
} )

/**
 * Menu
 * 
 *  Get the menu based on the authorization
 */
export const Config = new Resource( {
    location: 'auth/app/config',
    state: 'Config',
    persist: true,
    autoFill: true,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {
        menu: {}
    }
} )