<template>

    <v-main class="tzp-dashboard">

        <div 
        v-if="$vuetify.breakpoint.mdAndUp"
        class="tzp-dashboard__bg--blob"></div>
        
        <div class="tzp-dashboard__bg--gradient"></div> 
        
        <v-container class="container--tzp tzp-dashboard__container">

            <div :class="$vuetify.breakpoint.lgAndUp ? 'pt-6 pb-12' : 'pt-9 pb-12'">

                <div class="dashboard-greeting__title muli white--text mb-3 ml-4">{{ t("Greeting_" + $time.dayPart()) }}</div>
                <div v-if="User.init" class="dashboard-greeting__subtitle muli white--text ml-4">{{ User.full_name }}</div>

            </div>

            <!-- Shortcuts -->
            <div v-if="$vuetify.breakpoint.smAndDown && $prefs.settings.dashboard.shortcuts">
                
                <shortcuts></shortcuts>

            </div>

            <!-- Notices -->
            <v-row 
            v-if="$prefs.settings.dashboard.notices"
            :no-gutters="$vuetify.breakpoint.mdAndDown">

                <v-col
                cols="12"
                class="pb-0">

                    <notices></notices>

                </v-col>
            
            </v-row>

            <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
                
                <!-- News -->
                <v-col
                v-if="$prefs.settings.dashboard.news"
                cols="12"
                lg="6"
                :class="$vuetify.breakpoint.mdAndDown ? 'mb-3' : ''">

                    <news-widget></news-widget>

                </v-col>

                <!-- Notifications -->
                <v-col 
                v-if="$prefs.settings.dashboard.notifications"
                cols="12"
                lg="6">
                        
                    <v-card 
                    rounded="tzp"
                    style="overflow:hidden">
                        
                        <notifications-widget 
                        heading="Unread_notifications"
                        :height="300"></notifications-widget>

                    </v-card>

                </v-col>

            </v-row>

        </v-container>

    </v-main>
    
</template>

<script>
import NewsWidget from '@/components/news/NewsWidget'
import Notices from '@/components/notices/Notices'
import NotificationsWidget from '@/components/notifications/NotificationsWidget'
import Shortcuts from '@/components/dashboard/Shortcuts'
import { User } from '@/components/user/api'

export default {
    name: 'Dashboard',

    mixins: [ User.map() ],

    components: {
        NewsWidget,
        Notices,
        NotificationsWidget,
        Shortcuts,
    },

    mounted () {},
};
</script>

<style lang="scss">
.tzp-dashboard{
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
    background-image: url('~@/assets/img/thuiszorgplanner-dashboard-bg.png');
    background-position: 100% 75px;
    background-size: auto 35vh;
    transition: 0ms;

    @media #{map-get($display-breakpoints, 'xl-only')} {
        background-position-x: calc(100% - 420px); // minus Messenger width;
    }

    .tzp-dashboard__bg--blob {
        position: fixed;
        bottom:0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-attachment: fixed;
        background-image: url('~@/assets/img/thuiszorgplanner-blob.png');
        background-position: bottom left;
        background-size: auto 40vh;
    }
    .tzp-dashboard__bg--gradient {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        background-image: linear-gradient(to top left, rgb(194, 202, 231), rgba(85, 107, 178));
        background-position: top left;
        opacity: 0.85;
    }
}
.tzp-dashboard__container {
    position: relative;
    z-index: 1;
}
.theme--dark {
    .tzp-dashboard { background-image: url('~@/assets/img/dark/thuiszorgplanner-dashboard-bg_dark.png'); }
    .tzp-dashboard__bg--blob { background-image: url('~@/assets/img/dark/thuiszorgplanner-blob_dark.png'); }
    .tzp-dashboard__bg--gradient { 
        background-color: rgb(18, 18, 18);
        background-image: none;
    }
}
.dashboard-greeting__title { 
    font-size: 2.125rem; //text-h4 (h4 heading) inherit styling
    line-height: 2rem;
}
.dashboard-greeting__subtitle { 
    font-size: 1.25rem; //text-h6 (h6 heading) inherit styling
    line-height: 2rem;
}
</style>