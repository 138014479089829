<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">

        <v-container :class="($vuetify.breakpoint.mdAndUp ? 'animate__animated animate__fadeIn animate__faster pt-6' : '')+ ' container--tzp-sm'">
           
            <v-card 
            v-if="item"
            class="pb-3"
            rounded="tzp">

                <v-parallax
                src="img/oma3.png"
                class="ma-0 pa-0"
                height="200">

                    <div 
                    v-intersect="onIntersect"
                    :class="'tzp-parallax-gradient gradient-' + item.color"></div>
                    
                </v-parallax>
                
                <v-alert
                :color="item.color"
                colored-border
                border="left"
                elevation="0"
                :icon="'mdi-' + item.icon"
                tile
                class="mb-0"
                style="border-radius: 0;">
                    
                    <div 
                    v-intersect="onIntersect"
                    class="title">
                        {{ item.title }}
                    </div>

                    {{ item.description_short }}

                </v-alert>

                <v-divider></v-divider>

                <v-card-text class="px-7 pb-0">
                    <small
                    v-html="tWithParams('News_item_published_at', { publisher: ('author' in item ? item.author : 'Coolweb'), datetime: $time.date( item.created_at_timestamp ) })"
                    class="grey--text lighten-2--text"></small>
                    <small
                    v-if="showUpdatedTime( item.created_at_timestamp, item.updated_at_timestamp)"
                    v-html="', '+t('Updated_at').toLowerCase()+' <strong>'+$time.date( item.updated_at_timestamp )+'</strong>'"
                    class="grey--text lighten-2--text"></small>
                </v-card-text>

                <v-card-text class="px-7">
                    {{ item.description }}
                </v-card-text>
                
                <template v-for="(content, index) in item.contents">
                    
                    <v-card-text 
                    :key="index"
                    class="px-7">
                    
                        <v-card-title class="subtitle pa-0">{{ content.header }}</v-card-title>
                    
                        {{ content.paragraph }}

                        <v-row
                        v-if="content.button"
                        justify="center" 
                        class="py-10">
                            
                            <v-btn 
                            small
                            outlined
                            :to="content.button.to"
                            :color="item.color">
                                {{ content.button.value }}
                            </v-btn>
                            
                        </v-row>
                        
                    </v-card-text>

                </template>

            </v-card>

            <v-skeleton-loader
            v-else
            type="card"></v-skeleton-loader>

        </v-container>

    </v-main>
    
</template>
<script>
import { News } from '@/components/news/api'

export default {
    name: 'NewsItem',

    mixins: [ News.map() ],

    data: () => ({
        item: null,
        isIntersecting: true,
    }),

    beforeRouteEnter (to, from, next) {
        if( from.fullPath === '/' || from.fullPath === '/lock' ) {
            News.api().get().then(() => {
                next( vm => {
                    vm.item = vm.News.getBySlug(to.params.slug);
                });
            });
        }
        else {
            next( vm => {
                vm.item = vm.News.getBySlug(to.params.slug);
            });
        }      
    },

    methods: {
        onIntersect (entries, observer) {
            this.isIntersecting = entries[0].isIntersecting
        },
        showUpdatedTime( created_at, updated_at ) {
            return this.$time.dateCompare( created_at, updated_at, '<', 'day' )
        }
    }
}
</script>
<style lang="scss">
.v-parallax__image-container { width: 100%; }
.tzp-parallax-gradient {
    position: absolute;
    width: 110%;
    height: 100%;
    margin-left: -10%;
    background: linear-gradient(
        343deg,
        rgba(78, 110, 224, 0) 0%,
        rgba(0, 0, 0, 0.9) 90%
    );

    &.gradient-tzpPurple {
        background: linear-gradient(
            343deg,
            rgba(78, 110, 224, 0) 0%,
            rgba(85, 107, 178, 0.9) 90%
        );
    }
    &.gradient-tzpOrange {
        background: linear-gradient(
            343deg,
            rgba(78, 110, 224, 0) 0%,
            rgba(228, 108, 48, 0.9) 90%
        );
    }
}
</style>
