<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">
        
        <v-container class="container--tzp">

            <v-row v-if="News.init">
            
                <v-col
                v-for="(item, index) in News.all()"
                :key="index"
                cols="12"
                lg="6">
                    
                    <news-item-preview
                    :item="item"
                    :path="item.slug"></news-item-preview>
                    
                </v-col>
            
            </v-row>

            <v-row v-else>

                <v-col
                cols="12"
                lg="6">

                    <v-skeleton-loader
                    elevation="2"
                    type="article"
                    class="rounded-tzp"></v-skeleton-loader>

                </v-col>

                <v-col
                cols="12"
                lg="6">

                    <v-skeleton-loader
                    elevation="2"
                    type="article"
                    class="rounded-tzp"></v-skeleton-loader>

                </v-col>

                <v-col
                cols="12"
                lg="6">

                    <v-skeleton-loader
                    elevation="2"
                    type="article"
                    class="rounded-tzp"></v-skeleton-loader>

                </v-col>

                <v-col
                cols="12"
                lg="6">

                    <v-skeleton-loader
                    elevation="2"
                    type="article"
                    class="rounded-tzp"></v-skeleton-loader>

                </v-col>

            </v-row>

        </v-container>

    </v-main>
    
</template>
<script>
import { News } from '@/components/news/api'
import NewsItemPreview from '@/components/news/NewsItemPreview'

export default {
    name: 'News',

    mixins: [ News.map() ],

    components: { NewsItemPreview },

    data: () => ({ }),

    mounted () {
        News.api().get();
    },
}
</script>
