import Storage from './Storage.service'

const Preferences = {

    settings: {},
    hooks: [],

    async construct ( config, requireRemoval ) {

        this.config = Object.assign( {}, config );
        let defaults = Object.assign( {}, config.defaults );
        this.settings = ( defaults ? defaults : {} );
        this.hooks = ( typeof config.hooks != 'undefined' ? config.hooks : {} );

        await Storage.read( 'settings' ).then( ( storedSettings ) => {

            //Settings manager
            if ( storedSettings ) {
                this.build( storedSettings );
            }
            else {
                this.reset();
            }
        } );

        //requireRemoval forces current settings to be overwritten by custom values, patch & save
        this.patch( requireRemoval );
    },

    build ( inputSettings ) {
        Object.keys( inputSettings ).forEach( ( key ) => {

            //If a value is removed from default template, remove it from storage
            if ( typeof this.settings[ key ] == 'undefined' ) {

                Debug.log( 'Removed setting: ' + key );

                delete this.settings[ key ];
            }
            else {

                Debug.log( 'Restored setting: ' + key + ' - ' + JSON.stringify( inputSettings[ key ] ) )

                //Overwrite local settings by stored values
                this.settings[ key ] = inputSettings[ key ];

                //Run hook
                ( this.hooks[ key ] ? this.hooks[ key ]( inputSettings[ key ] ) : null );
            }
        } )
    },

    // Update multiple values.
    patch ( requireRemoval ) {

        if ( requireRemoval ) {
            Object.keys( requireRemoval ).forEach( ( key ) => {

                //If a value is removed from default template, remove it from storage
                if ( typeof this.settings[ key ] != 'undefined' ) {

                    Debug.log( 'Updated setting by request: ' + key + ' - ' + JSON.stringify( requireRemoval[ key ] ) );

                    this.settings[ key ] = requireRemoval[ key ];

                    //Run hook
                    ( this.hooks[ key ] ? this.hooks[ key ]( requireRemoval[ key ] ) : null );
                }
            } )
        }

        this.save();
    },

    save ( key ) {

        Storage.write( 'settings', this.settings );
        ( key ? this.runHook( key ) : null );

        Debug.log( 'Wrote settings to storage.' );
    },

    put ( key, value ) {

        this.settings[ key ] = value;

        //Run hook
        this.runHook( key );

        this.save();
    },

    //Use after saving a setting
    runHook ( key ) {
        ( this.hooks[ key ] ? this.hooks[ key ]( this.settings[ key ] ) : null );
    },

    // Reset to default settings
    reset () {
        Storage.remove( 'settings' ).then( () => {
            this.build( this.config.defaults );
            this.save();
        } );
    }
}

export default Preferences;