<template>

    <load-more
    v-if="Notifications.init"
    :model="Notifications.getModel()"
    class="notifications-manager">

        <notification-item
        @close="closeNotification( $event )"
        :from.sync="from"
        :item="selectedNotification"
        :itemState="notificationState"></notification-item>

        <notifications-list
        v-if="Notifications.init"
        @itemClicked="showNotification( $event )"
        iconColor="white"
        :items="Notifications"
        ref="notificationsList"
        :swipe="$device.isNative() || $vuetify.breakpoint.smAndDown"></notifications-list>
        
        <p v-if="this.Notifications.getTotalCount() === 0" class="text-center pa-4 grey--text">{{ t('No_notifications') }}</p>
    
    </load-more>

</template>

<script>
import LoadMore from '@/components/LoadMore.vue'
import { Notifications } from '@/components/notifications/api'
import NotificationItem from "@/components/notifications/Notification.vue"
import NotificationsList from "@/components/notifications/NotificationsList.vue"

export default {
    name: 'NotificationsManager',

    components: {
        LoadMore,
        NotificationItem,
        NotificationsList,
    },

    mixins: [ Notifications.map() ],

    data: () => ({
        from: 'manager',
        notificationState: false,
        selectedNotification: null,
    }),

    created () {
        this.$root.$refs.notificationsManager = this;
        this.$eventBus.$off('showNotification');
        this.$eventBus.$on('showNotification', ( notification ) => {
            this.showNotification( notification, true );
        });
    },

    mounted () {
        if ( this.$tasks.hasPendingTasks( 'openNativeClickedNotifications' ) ) {
            this.$tasks.dispatchTasks( 'openNativeClickedNotifications' );
        }
    },

    methods: {
        checkDeleteAll() {
            if ( this.$prefs.settings.notifications.deleteAllDialog ) {
                this.$ui.dialog.show({
                    buttons: [
                        {
                            text: "Cancel",
                            click: () => {
                                this.$ui.dialog.hide();
                            },
                        },
                        {
                            text: "Delete",
                            click: () => {
                                this.$ui.dialog.hide();
                                this.Notifications.deleteAll();
                            },
                        },
                    ],
                    description: "Notifications_delete_all_description",
                    title: "Notifications_delete_all_title",
                });
            } 
            else {
                this.Notifications.deleteAll();
            }
        },
        checkMarkAllAsRead() {
            if ( this.$prefs.settings.notifications.markAllDialog ) {
                this.$ui.dialog.show( {
                    buttons: [
                        {
                            text: "Cancel",
                            click: () => {
                                this.$ui.dialog.hide();
                            },
                        },
                        {
                            text: "Read",
                            click: () => {
                                this.$prefs.settings.notifications.markAllDialog = false;
                                this.$prefs.save();
                                this.$ui.dialog.hide();
                                this.Notifications.markAllAsRead();
                            },
                        },
                    ],
                    description: "Notifications_mark_all_as_read_description",
                    title: "Notifications_mark_all_as_read_title",
                } );
            } 
            else {
                this.Notifications.markAllAsRead();
            }
        },
        closeNotification( delay = false ) {
            if( delay ) { // change state after Drawer animation is finished
                setTimeout( () => {
                    this.notificationState = false;
                }, 300);
            }
            else {
                this.notificationState = false;
            }
        },
        closeNotifications() {
            this.$refs.notificationsList.closeAllItems();
        },
        hasNotifications() {
            return this.Notifications.init && this.Notifications.getTotalCount() > 0;
        },
        hasUnreadNotifications() {
            return this.Notifications.init ? this.Notifications.getUnreadCount() : false;
        },
        showNotification( item, widget = false ) {
            this.selectedNotification = item;
            this.notificationState = true;

            if( widget ) {
                this.$ui.notificationDrawer.toggled = true;
                this.from = 'widget';
            }
        },
    },
}
</script>

<style lang="scss">
.notifications-manager { position: relative; }
</style>