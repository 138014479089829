import 'capacitor-secure-storage-plugin';
import { Plugins } from '@capacitor/core';

const { SecureStoragePlugin } = Plugins;

const Storage = {

    available: '',
    prefix: '',
    offlineData: false,

    async construct ( config ) {

        if ( typeof localStorage == 'undefined' ) {

            Debug.warn( 'Localstorage is not available on this device.' );
        }

        this.prefix = ( config.prefix ? config.prefix : '' );

        await this.read( 'offline-data' ).then( ( local ) => {
            this.offlineData = local;
        } );
    },

    write ( k, v ) {
        Plugins.Storage.set( {
            key: this.prefix + k,
            value: window.btoa( JSON.stringify( v ) )
        } );
    },

    async remove ( k ) {
        await Plugins.Storage.remove( { key: this.prefix + k } );
    },

    setItem ( k, v ) {
        Plugins.Storage.set( {
            key: k,
            value: v
        } );
    },

    async read ( k ) {

        try {

            const v = await Plugins.Storage.get( { key: this.prefix + k } );

            return JSON.parse( window.atob( v.value ) );
        }
        catch ( e ) {

            Debug.log( 'Storage: No <' + k + '> found for `' + k + '`.' );

            //throw new Exception;
        }
    },

    async appendItem ( category, k, v ) {
        let stored = await this.read( category );
        stored[ k ] = v;

        this.write( category, stored );
    },

    async append ( category, data ) {
        let stored = await this.read( category );

        Object.keys( data ).forEach( k => {
            stored[ k ] = data[ k ];
        } );

        this.write( category, stored );
    },

    secure: {
        write ( k, v ) {
            SecureStoragePlugin.set( {
                key: Storage.prefix + k,
                value: JSON.stringify( v )
            } )
        },

        async remove ( k ) {


            await SecureStoragePlugin.remove( { key: Storage.prefix + k } );
        },

        setItem ( k, v ) {
            SecureStoragePlugin.set( {
                key: k,
                value: v
            } );
        },

        async read ( k ) {

            try {

                const v = await SecureStoragePlugin.get( { key: Storage.prefix + k } );

                return JSON.parse( v.value );
            }
            catch ( e ) {

                Debug.log( 'Secure storage: No <' + k + '> found for `' + k + '`.' );

                //throw new Exception;
            }
        },
    },

    setOfflineData ( k, v ) {
        this.read( 'offline-data' ).then( ( local ) => {
            let localStore = ( local ? local : {} );
            localStore[ k ] = v;
            this.offlineData = localStore;
            this.write( 'offline-data', localStore );
        } );
    },

    getOfflineData ( k ) {

        if ( this.offlineData !== false && this.offlineData != null && typeof this.offlineData[ k ] != 'undefined' ) {
            return ( k ? this.offlineData[ k ].data : this.offlineData );
        }
        else {
            Debug.warn( 'Storage: No <offline-data[' + k + ']> found.' );
            return false;
        }
    }
}

export default Storage;