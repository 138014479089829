import collect from 'collect.js';
import Ui from '@/system/services/Ui.service';
import '../services/Debug.service';

const UrlInterceptor = {
    localDomains: [
        'localhost',
        'thuiszorgplanner.nl',
    ],
    trustedDomains: [
        'thuiszorgsoftware.nl',
    ],
    knowledgebaseDomain: 'help.thuiszorgplanner.nl',

    registerListeners () {
        document.addEventListener( 'click', ( evt ) => {
            this._clickCallback( evt );
        } );
    },

    /**
     * setTrustedDomain
     * 
     * Adds the given domain to the trustedDomains
     * 
     * @param {String} domain 
     */
    setTrustedDomain ( domain ) {
        let domains = this.getTrustedDomains();

        if ( domains.contains( domain ) ) {
            Debug.warn( `${ domain } already set as trusted domain` );

            return false;
        }

        domains.push( domain );
    },
    /**
     * setTrustedDomains
     * 
     * Replace the existing trustedDomains with the given Array
     * 
     * @param {Array} domains 
     */
    _setTrustedDomains ( domains ) {
        this.trustedDomains = domains;
    },

    /**
     * deleteTrustedDomain
     * 
     * Deletes the given domain from the allowedDomains
     * 
     * @param {String} domain 
     */
    deleteTrustedDomain ( domain ) {
        let trustedDomains = this.getTrustedDomains();

        if ( !trustedDomains.contains( domain ) ) {
            Debug.log( `${ domain } not found as trusted domain` );
        }

        trustedDomains.each( ( trustedDomain, key ) => {
            if ( domain == trustedDomain ) {
                trustedDomains.splice( key, 1 );

                this._setTrustedDomains( trustedDomains );

                return false;
            }
        } );
    },

    getTrustedDomains () {
        return collect( this.trustedDomains );
    },

    getAllowedDomains () {
        const trusted = this.getTrustedDomains();
        let allowedDomains = trusted.concat( this.localDomains );

        return allowedDomains;
    },

    /**
     * checkAllowedDomains
     * 
     * Checks if the given URL is allowed
     * 
     * @param {String} url 
     * @returns 
     */
    checkAllowedDomains ( url ) {
        let trustedDomains = this.getAllowedDomains();
        let trustedLink = false;

        trustedDomains.each( ( trustedDomain, key ) => {
            if ( url.indexOf( trustedDomain ) > -1 ) {
                trustedLink = true;

                return false;
            }
        } );

        return trustedLink;
    },

    /**
     * checkLocalDomains
     * 
     * We need to know if the provided URL is local or not.
     * 
     * @param {String} url 
     * @returns 
     */
    _checkLocalDomains ( url ) {
        let local = collect( this.localDomains );
        let foundDomain = false;
        let newUrl = ( new URL( url ) );

        if ( newUrl.hostname !== this.knowledgebaseDomain ) {
            local.each( ( domain ) => {
                if ( url.indexOf( domain ) > -1 ) {
                    foundDomain = true;

                    return false;
                }
            } );
        }

        return foundDomain;
    },

    _clickCallback ( evt ) {
        const link = this._findLink( evt.target );
        if ( link == null ) {
            return;
        }
        evt.preventDefault();

        this.handleUrl( link );
    },

    _findLink ( el ) {
        if ( el.tagName == 'A' && el.href ) {
            return {
                href: el.href,
                target: el.target
            };
        }
        else if ( el.parentElement ) {
            return this._findLink( el.parentElement );
        }

        return null;
    },

    /**
     * handleUrl
     * 
     * Handle the given URL; show our dialog or visit direct
     * 
     * @param {Object} link
     */
    handleUrl ( link ) {
        if ( !this.checkAllowedDomains( link.href ) ) {
            this.showDialog( link );
        }
        else {
            // Local are handle by the Router
            if ( !this._checkLocalDomains( link.href ) ) {
                if ( link.target ) {
                    window.open( link.href, link.target );
                }
                else {
                    window.location.href = url;
                }

            }
        }
    },

    /**
     * showDialog
     * 
     *  Show an untrusted domain dialog
     * 
     * @param {Object} link
     */
    showDialog ( link ) {
        Ui.dialog.show( {
            buttons: [
                {
                    text: 'Cancel',
                    click: () => {
                        Ui.dialog.hide();
                    },
                },
                {
                    text: 'Continue',
                    click: () => {
                        window.open( link.href, ( link.target ? link.target : '_blank' ) );
                        Ui.dialog.hide();
                    },
                },
            ],
            description: 'Untrusted_domain_dialog_description',
            icon: {
                color: 'tzpOrange',
                key: 'mdi-alert-octagon'
            },
            title: 'Untrusted_domain_dialog_title',
            translateParams: {
                url: link.href,
            },
        } );
    }
}
export default UrlInterceptor;