<template>

    <v-stepper 
    v-model="progress"
    alt-labels
    :flat="flat"
    rounded="tzp"
    :class="wrapperClass">
        
        <v-stepper-header 
        v-if="header"
        class="elevation-0">

            <template v-for="(val, key) in steps">
                
                <v-stepper-step
                :key="`${key}-step`"
                :complete="progress > key"
                complete-icon="mdi-check"
                :step="key">
                    {{ t(val) }}
                </v-stepper-step>

                <v-divider
                v-if="parseInt(key) !== (totalSteps - 1)"
                :key="key"></v-divider>

            </template>

        </v-stepper-header>

        <v-stepper-items>

            <template v-if="header && typeof this.steps === 'object'">
                
                <v-stepper-content
                v-for="(val, key) in steps"
                :key="`${key}-content`"
                :step="key"
                :class="contentClass+($vuetify.breakpoint.smAndDown ? 'pt-0' : '')">
                    
                    <slot :name="`step${key}`"></slot>
                    
                    <v-btn
                    v-if="buttons && key !== totalSteps"
                    @click="nextStep(key)"
                    color="primary">
                        {{ t('Continue') }}
                    </v-btn>

                </v-stepper-content>
                
            </template>
            
            <template v-else>
                
                <v-stepper-content
                v-for="key in steps"
                :key="`${key}-content`"
                :step="key"
                :class="contentClass">
                    
                    <slot :name="`step${key}`"></slot>
                    
                    <v-btn
                    v-if="buttons && key !== totalSteps"
                    @click="nextStep(key)"
                    color="primary">
                        {{ t('Next') }}
                    </v-btn>

                </v-stepper-content>
                
            </template>

            <v-stepper-content 
            :step="totalSteps"
            :class="contentClass+(contentClass !='' ? ' '+responseClass : responseClass)">

                <response-message
                :description="responseDescription"
                descriptionAlign="center"
                :flat="true"
                :image="responseImage"
                :imageClasses="responseImageClasses"
                :title="responseTitle"
                :type="responseType"
                :waiting="waiting"></response-message>

                <div
                v-if="responseClose || responseButton"
                :class="'mt-8 text-'+(responseClose ? 'right' : 'center')">
                    
                    <v-btn
                    v-if="responseClose"
                    @click="reset()"
                    color="primary"
                    dark
                    text>
                        {{ t('Close') }}
                    </v-btn>

                    <template v-if="responseButton">
                        
                        <v-btn
                        v-if="'action' in responseButton"
                        @click="$emit('lastStep')"
                        :color="'color' in responseButton ? responseButton.color : 'tzpPurple'"
                        dark
                        depressed
                        large
                        ripple>
                            {{ t(responseButton.text) }}
                        </v-btn>

                        <v-btn
                        v-else
                        :color="'color' in responseButton ? responseButton.color : 'tzpPurple'"
                        dark
                        depressed
                        large
                        ripple
                        :to="responseButton.to">
                            {{ t(responseButton.text) }}
                        </v-btn>

                    </template>

                </div>

            </v-stepper-content>

        </v-stepper-items>

    </v-stepper>

</template>

<script>
import ResponseMessage from "@/components/ResponseMessage"

export default {
    name: 'Stepper',

    props: {
        autoReset: {
            type: Boolean,
            default: false,
        },
        buttons: {
            type: Boolean,
            default: false,
        },
        contentClass: {
            type: String,
            default: ''
        },       
        flat: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Boolean,
            default: false,
        },
        inDialog: {
            type: Boolean,
            default: false,
        },
        responseButton: {
            type: Boolean|Object,
            default: false
        },
        responseClass: {
            type: String,
            default: ''
        },
        responseClose: {
            type: Boolean,
            default: false
        },
        responseDescription: {
            type: Boolean|String,
            default: false
        },
        responseImage: {
            type: Boolean|String,
            default: false
        },
        responseImageClasses: {
            type: String,
            default: '',
        },
        responseTitle: {
            type: String,
            required: true
        },
        responseType: {
            type: String,
            default: 'success'
        },
        steps: {
            type: Object|Array, // Object if you need a header, else a Array
            required: true
        },
        wrapperClass: {
            type: String,
            default: ''
        },
    },

    components: { ResponseMessage },

    data: () => ({
        progress: 1,
        totalSteps: 2,
        waiting: true,
    }),

    mounted () {
        this.totalSteps = (typeof this.steps === 'object' ? Object.keys(this.steps).length : this.steps.length) + 1 // get amount based on provided props 'steps'
    },

    methods: {
        /**
         * Get progress
         * 
         */
        getProgress() {
            return this.progress
        },
        /**
         * Progress to next step
         * 
         * @param {number} k The number of the current step
         */
        nextStep (k) {
            this.progress = parseInt(k) + 1 // increase the progress to the next step

            // are we on the final step?
            if( this.progress === this.totalSteps ) {
                this.waiting = false // we're on the final step, so the waiting is over and want the icon to animate

                // props 'autoReset' set, means to auto reset the Stepper to the first step
                if ( this.autoReset ) {
                    setTimeout(() => {
                        this.reset()
                    }, 4500);
                }
            }
        },
        /**
         * Reset the Stepper to the first step
         */
        reset() {
            // Check if the stepper is in a dialog
            if( this.inDialog ) {
                                
                this.$emit('closeDialog');
                
                // Timeout need because dialog closing and reset action are hapening at the same time
                setTimeout(() => {
                    this.progress = 1
                }, 500);
            }
            else {
                this.progress = 1
            }
        }
    },
}
</script>

<style lang="scss">
.v-stepper {
    .v-sheet,
    .v-card { background-color: transparent; }
    .v-sheet.v-card:not(.v-sheet--outlined) {
        border-radius: 0;
        box-shadow: none;
    }
    .v-card__subtitle,
    .v-card__title,
    .v-card__text {
        padding-right: 0;
        padding-left: 0;
    }
    .v-card__title:not(.headline) { padding-top: 0 }
}
//.v-stepper__content { padding-bottom: $spacer * 6 !important }
.v-dialog {
    .v-stepper__content { padding: $spacer * 4 $spacer * 4 $spacer * 2 $spacer * 4; }
}
</style>