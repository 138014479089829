<template>

    <div v-if="FAQCategories.init">

        <v-text-field
        v-model="search"
        @click="$emit('search', true)"
        @click:append="clearSearch()"
        @input="filter()"
        :append-icon="searching ? 'mdi-close-circle' : ''"
        color="primary"
        :label="t('What_can_we_help_you_with')"
        single-line
        solo
        class="rounded-tzp"></v-text-field>
            
        <template v-if="searching">
            
            <v-card-title class="pt-0 muli--bold">{{ t('Results') }}</v-card-title>
            
            <v-card-subtitle>{{ searchResults.length > 1 ? tWithParams('We_found_possible_solutions', { counter: searchResults.length}) : tWithParams('We_found_possible_solution', { counter: searchResults.length}) }}</v-card-subtitle>
            
            <v-expansion-panels accordion>
                
                <sequential-entrance 
                delay="50"
                fromBottom
                tag="div">
                    
                    <v-expansion-panel
                    v-for="(question, questionIndex) in searchResults"
                    :key="questionIndex"
                    :class="'v-expansion-panel__search'+(questionIndex === 0 && questionIndex === searchResults.length - 1 ? ' rounded-tzp' : questionIndex === 0 ? ' rounded-t-tzp' : questionIndex === searchResults.length - 1 ? ' rounded-b-tzp' : '')">
                        
                        <v-expansion-panel-header class="py-5 pl-5 pr-3">{{ question.question }}</v-expansion-panel-header>

                        <v-expansion-panel-content>
                            
                            <div v-html="question.answer"></div>
                            
                        </v-expansion-panel-content>

                    </v-expansion-panel>

                </sequential-entrance>

            </v-expansion-panels>

        </template>
        
        <template
        v-else>
            
            <v-row>
                
                <v-col 
                v-for="(category, index) in FAQCategories"
                :key="index"
                cols="12"
                md="6"
                xl="4">

                    <v-card-title
                    v-if="category.title"
                    class="pt-0 muli">
                        {{ category.title }}
                    </v-card-title>
                    
                    <v-card-subtitle
                    v-if="category.subtitle"
                    class="muli">
                        {{ category.subtitle }}
                    </v-card-subtitle>

                    <v-expansion-panels 
                    accordion
                    class="rounded-tzp">

                        <v-expansion-panel
                        v-for="(question, questionIndex) in category.faqs"
                        :key="questionIndex">
                            
                            <v-expansion-panel-header class="py-5 pl-5 pr-3">
                                {{ question.question }}
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                
                                <div v-html="question.answer"></div>

                            </v-expansion-panel-content>
                            
                        </v-expansion-panel>

                    </v-expansion-panels>

                </v-col>

            </v-row>

        </template>

    </div>

    <div v-else>

        <v-card
        rounded="tzp"
        class="mb-8">

            <v-skeleton-loader
            type="list-item"
            class="rounded-tzp"></v-skeleton-loader>

        </v-card>

        <v-row>

            <v-col
            cols="12"
            md="6"
            xl="4">

                <v-skeleton-loader 
                type="heading"
                class="mb-2"></v-skeleton-loader>

                <v-skeleton-loader 
                type="text"
                class="mb-4"></v-skeleton-loader>

                <v-card
                rounded="tzp"
                class="pa-4 mb-3">

                    <v-skeleton-loader type="text@6"></v-skeleton-loader>

                </v-card>

            </v-col>

            <v-col
            cols="12"
            md="6"
            xl="4">

                <v-skeleton-loader 
                type="heading"
                class="mb-2"></v-skeleton-loader>

                <v-skeleton-loader 
                type="text"
                class="mb-4"></v-skeleton-loader>
            
                <v-card
                rounded="tzp"
                class="pa-4">

                    <v-skeleton-loader type="text@6"></v-skeleton-loader>

                </v-card>

            </v-col>

        </v-row>

    </div>

</template>

<script>
import { FAQCategories } from '@/components/faqs/api'

export default {
    name: 'FAQ',

    mixins: [ FAQCategories.map() ],

    data: () => ({
        search: '',
        searching: false,
        searchResults: [],
    }),

    created() {
        FAQCategories.api().query({include: 'faqs'}).get();
    },

    methods: {
        filter() {
            this.searching = (this.search !== '' ? true : false);

            this.searchResults = [];

            this.FAQCategories.each( (category) => {

                let categories = this.$collect(category.faqs);

                categories.each( (item) => {

                    let question = item.question.toLowerCase();
                    let answer = item.answer.toLowerCase();

                    if ( question.includes( this.search.toLowerCase() ) || answer.includes( this.search.toLowerCase() ) ) {
                        this.searchResults.push(item);
                    }
                })
            })
        },

        clearSearch() {
            this.search = '';
            this.searching = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel__search {
    width: 100%;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
        width: 550px;
    }
}
.v-expansion-panel-header { 
    line-height: inherit;

    &--active { font-weight: 700; } // font-weight-bold
}
</style>