<template>
    
    <div v-if="Notices.init">

        <template v-for="(notice, index) in Notices">

            <v-alert
            v-if="! (notice.uuid in $prefs.settings.notices.shown)"
            @input="dismiss(notice.uuid)"
            :key="index"
            :dismissible="('dismissible' in notice ? notice.dismissible : false)"
            :color="notice.color"
            :icon="('icon' in notice ? 'mdi-'+notice.icon : false)"
            dark
            rounded="tzp"
            class="notice mb-3">
                
                <div v-if="$vuetify.breakpoint.xs">
                    
                    <v-row>
                        <v-col
                        v-html="notice.title"
                        class="grow"></v-col>
                    </v-row>

                    <v-row 
                    v-if="notice.button"
                    align="center"
                    no-gutters>

                        <v-col>
                            <api-button
                            class="mt-4"
                            dark
                            small
                            outlined
                            depressed
                            :button="notice.button"/>
                        </v-col>
                        
                    </v-row>

                </div>

                <div v-else>

                    <v-row 
                    v-if="notice.button"
                    align="center"
                    no-gutters>

                        <v-col
                        v-html="notice.title"
                        class="grow"></v-col>

                        <v-col class="shrink">
                            <api-button
                            dark
                            small
                            outlined
                            depressed
                            :button="notice.button"/>
                        </v-col>

                    </v-row>

                    <span 
                    v-else
                    v-html="notice.title"></span>

                </div>

            </v-alert>

        </template>
        
    </div>

    <v-card
    v-else
    rounded="tzp"
    class="pa-3 mb-3">

        <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
    
    </v-card>

</template>

<script>
import { Notices } from '@/components/notices/api'
import ApiButton from '@/components/form/ApiButton'

export default {
    name: 'Notices',

    components: { ApiButton },

    mixins: [ Notices.map() ],

    data: () => ({ 
        notice: false
    }),

    methods: {
        dismiss(uuid) {
            this.$prefs.settings.notices.shown[uuid] = true
            this.$prefs.save()
        }
    },
};
</script>

<style lang="scss">
.notice:last-child { margin-bottom: 0; }
</style>