<template>
    
    <v-list
    v-if="Devices.init"
    two-line>
            
        <div 
        v-for="( device, key ) in Devices"
        :key="key">
            
            <v-list-item>
                
                <v-list-item-avatar>

                    <v-icon color="tzpOrange">
                        {{ deviceIcon( device ) }}
                    </v-icon>

                </v-list-item-avatar>

                <v-list-item-content>
                    
                    <v-list-item-title>{{ device.getTitle() }}</v-list-item-title>
                    
                    <v-list-item-subtitle>
                        {{ t("Last_active") }}: {{ $time.dateTime( device.last_seen_at_timestamp ) }}
                    </v-list-item-subtitle>

                    <v-list-item-subtitle
                    v-if="device.uuid === $device.uuid"
                    class="caption tzpPurple--text">
                        {{ t("Current_device") }}
                    </v-list-item-subtitle>

                </v-list-item-content>

                <v-list-item-action v-if="device.locked !== true">

                    <lock-icon
                    @lock="lock( device.uuid !== $device.uuid ? device.uuid : '' )"
                    click="lock"
                    color="grey lighten-1"></lock-icon>

                </v-list-item-action>

                <v-list-item-action v-if="device.uuid !== $device.uuid">
                    
                    <v-tooltip 
                    v-if="! $device.isNative()"
                    top>

                        <template v-slot:activator="{ on, attrs }">
                            
                            <v-btn
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogDeleteDevice( device )"
                            fab
                            icon
                            small>
                                
                                <v-icon color="grey lighten-1">mdi-delete</v-icon>
                                
                            </v-btn>

                        </template>

                        <span>{{ t('Delete') }}</span>

                    </v-tooltip>

                    <v-btn
                    v-else
                    @click="dialogDeleteDevice( device )"
                    fab
                    icon
                    small>

                        <v-icon color="grey lighten-1">mdi-delete</v-icon>

                    </v-btn>

                </v-list-item-action>

            </v-list-item>

            <v-divider
            v-if="key !== Devices.count() - 1"
            inset></v-divider>

        </div>
        
    </v-list>

    <v-skeleton-loader 
    v-else
    type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
    class="rounded-tzp"></v-skeleton-loader>

</template>

<script>
import { 
    UserDevice,
    Devices,
    devices
} from "@/components/devices/api"
import LockIcon from "@/components/icons/LockIcon.vue";

export default {
    name: "DevicesList",

    components: { LockIcon },

    mixins: [ Devices.map() ],

    data: () => ({
        icons: {
            desktop: "mdi-desktop-classic",
            mobile: "mdi-cellphone",
            tablet: "mdi-tablet",
        },
        locked: false,
    }),

    created() {
        Devices.api().get();
    },

    methods: {
        /**
         * deviceIcon 
         * 
         * @param {object} device - complete device information
         * 
         * @returns {string} - icon based on the device type
         */
        deviceIcon( device ) {
            let icon = '';

            if( device.getTitle() === 'Apple iPad' ) {
                icon = this.icons[ 'tablet' ];
            }
            else {
                icon = this.icons[ device.type ];
            }

            return icon;
        },
        /**
         * Dialog deleteDevice
         * 
         * @param {object} device - complete device information
         */
        dialogDeleteDevice( device ) {
            this.$ui.dialog.show({
                buttons: [
                    {
                        text: "Cancel",
                        click: () => {
                            this.$ui.dialog.hide();
                        },
                    },
                    {
                        text: "Delete",
                        click: () => {
                            device.api().delete();
                            this.$ui.dialog.hide();
                        },
                    },
                ],
                description: "Delete_device_dialog_description",
                title: "Delete_device_dialog_title",
                translateParams: {
                    name: device.getTitle()
                },
            });
        },
        /**
         * Lock
         * 
         * Locks a device by the given uuid
         * 
         * @param {string} uuid - uuid to identify the device
         */
        lock( uuid ) {
            if( uuid ) {
                UserDevice.api().params( { uuid: uuid } ).body( { locked: 1 } ).patch();
            }
            else {
                this.$router.push( '/lock' );
            }
        }
    },
};
</script>