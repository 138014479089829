<template>

    <div v-if="User.init">

        <v-card 
        class="mb-4" 
        rounded="tzp">
            
            <v-list 
            two-line 
            subheader 
            class="inline-fields">

                <v-subheader>{{ t("Account_data") }}</v-subheader>

                <v-list-item>
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-account</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Username") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ User.username }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

                <v-divider
                v-if="User.username && User.getActiveRole()"
                inset></v-divider>

                <v-list-item v-if="User.active_role">
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-clipboard-account</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Role") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t( User.getActiveRole() ) }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

            </v-list>

        </v-card>
        <!-- <v-divider></v-divider> -->

        <v-card class="mt-4" rounded="tzp">
            
            <v-subheader>{{ t("Security") }}</v-subheader>

            <v-list
            two-line
            class="inline-fields">

                <v-list-item v-if="User.email">
                    
                    <v-list-item-avatar>
                        
                        <v-tooltip 
                        :color="'green darken-'+($prefs.settings.darkModeEnabled ? 3 : 1)"
                        top>
                            
                            <template v-slot:activator="{ on, attrs }">
                                
                                <v-icon 
                                v-bind="attrs"
                                v-on="on"
                                :color="'green darken-'+($prefs.settings.darkModeEnabled ? 3 : 1)">
                                    mdi-email-check
                                </v-icon>

                            </template>

                            <span>{{ t('Email') }} {{ t('verified') }}</span>

                        </v-tooltip>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t("Email") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ User.email }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

                <v-divider 
                v-if="User.mobile_number"
                inset></v-divider>

                <v-list-item v-if="User.mobile_number">
                    
                    <v-list-item-avatar>
                        
                        <v-tooltip 
                        :color="'green darken-'+($prefs.settings.darkModeEnabled ? 3 : 1)"
                        top>
                            
                            <template v-slot:activator="{ on, attrs }">
                                
                                <v-icon 
                                v-bind="attrs"
                                v-on="on"
                                :color="'green darken-'+($prefs.settings.darkModeEnabled ? 3 : 1)">
                                    mdi-cellphone-check
                                </v-icon>

                            </template>

                            <span>{{ t('Mobile_number') }} {{ t('verified') }}</span>

                        </v-tooltip>

                    </v-list-item-avatar>

                    <v-list-item-content>
                        
                        <v-list-item-title>{{ t("Mobile_number") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ User.mobile_number }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

                <v-divider
                v-if="User.phone_number"
                inset></v-divider>

                <v-list-item v-if="User.phone_number">
                    
                    <v-list-item-avatar>

                        <v-icon color="green darken-1">mdi-phone-classic</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>
                        
                        <v-list-item-title>{{ t("Phone_number") }}</v-list-item-title>

                        <v-list-item-subtitle>{{ User.phone_number }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

            </v-list>

        </v-card>

        <v-card 
        rounded="tzp"
        class="mt-4">
            
            <v-list one-line>

                <v-list-item to="/profile">
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-badge-account-horizontal</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>
                        
                        <v-list-item-title>{{ t("Profile") }}</v-list-item-title>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-icon>mdi-chevron-right</v-icon>

                    </v-list-item-action>

                </v-list-item>

            </v-list>

        </v-card>

        <v-card 
        rounded="tzp"
        class="mt-4">
            
            <v-list one-line>

                <v-list-item @click="$eventBus.$emit('logout')">
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-power-standby</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>
                        
                        <v-list-item-title>{{ t("Logout") }}</v-list-item-title>

                    </v-list-item-content>

                </v-list-item>

            </v-list>

        </v-card>

    </div>
    
</template>

<script>
import { User } from "@/components/user/api"

export default {
    mixins: [ User.map() ],

    data: () => ({}),
};
</script>