<template>

    <v-app :class="'is-'+$device.os">

        <!-- App - Global components -->

        <!-- iOS System Bar -->
        <v-system-bar
        v-if="$device.os === 'ios'"
        app
        height="env(safe-area-inset-top)"
        :class="'bg--basic'+($device.isDarkMode() ? '-dark' : '')"></v-system-bar>

        <!-- Overlay -->
        <overlay></overlay>

        <!-- Dialog -->
        <Dialog />

        <!-- Toast (new global) -->
        <toast></toast>

        <!-- Error toast -->
        <error-toast></error-toast>

        <!-- Tour -->
        <tour></tour>

        <!-- Logout dialog -->
        <logout></logout>

        <!-- Router view (App) -->
        <router-view></router-view>

    </v-app>

</template>

<script>
import Dialog from '@/system/components/Dialog'
import Logout from '@/system/components/Logout'
import Overlay from '@/system/components/Overlay'
import Toast from '@/system/components/toasts/Toast'
import ErrorToast from '@/system/components/toasts/ErrorToast'
import UrlInterceptor from '@/system/helpers/UrlInterceptor'

export default {
    components: {
        Dialog,
        Logout,
        Overlay,
        Toast,
        ErrorToast,
    },

    data: () => ({}),

    mounted() {
        document.title = (this.$route.name ? this.t(this.$route.name)+' | ' : '')+this.$root.$options.head.title.inner;

        // Connect messaging service.
        if (this.$device.isNative()) {
            this.$messaging.connect('mobile_push', connector => {
                connector.subscribeToChannels();
            });
        }

        UrlInterceptor.registerListeners();
    },

    watch: {
        $route(to, from) {
            document.title = (this.t(to.name) ? this.t(to.name)+' | ' : '')+this.$root.$options.head.title.inner;
        }
    },
};
</script>