<template>

    <div>
        
        <v-alert
        v-if="MessagingUserPreferences.do_not_disturb"
        icon="mdi-alert"
        rounded="tzp"
        type="warning"
        class="mb-3">

            <div v-html="t('Notifications_do_not_disturb_message')"></div>
            
        </v-alert>

        <v-card
        v-if="MessagingUserPreferences.init"
        rounded="tzp"
        class="mb-3">

            <v-subheader>{{ t('Notifications_reception') }}</v-subheader>

            <v-list
            :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
            :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">

                <v-list-item>

                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-bell-off</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title>{{ t('Notifications_do_not_disturb_title') }}</v-list-item-title>

                        <v-list-item-subtitle>{{ t('Notifications_do_not_disturb_description') }}</v-list-item-subtitle>
                        
                    </v-list-item-content>

                    <v-list-item-action>
                        
                        <v-switch
                        v-model="MessagingUserPreferences.do_not_disturb"
                        @change="toggleDoNotDisturb( MessagingUserPreferences.do_not_disturb )"
                        :ripple="false"></v-switch>

                    </v-list-item-action>
                    
                </v-list-item>

                <v-divider
                v-if="$vuetify.breakpoint.lgAndUp"
                inset></v-divider>

                <v-list-item v-if="$vuetify.breakpoint.lgAndUp">

                    <v-list-item-avatar>

                        <v-icon :color="MessagingUserPreferences.do_not_disturb ? 'tzpOrangeDisabled' : 'tzpOrange'">mdi-bell-ring</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>

                        <v-list-item-title :class="MessagingUserPreferences.do_not_disturb ? 'text--disabled' : ''">{{ t('Notifications_sound_title') }}</v-list-item-title>

                        <v-list-item-subtitle :class="MessagingUserPreferences.do_not_disturb ? 'text--disabled' : ''">{{ t('Notifications_sound_description') }}</v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-switch
                        v-model="MessagingUserPreferences.desktop_sound_enabled"
                        @change="toggleDesktopSound(MessagingUserPreferences.desktop_sound_enabled)"
                        :disabled="MessagingUserPreferences.do_not_disturb"
                        :ripple="false"></v-switch>

                    </v-list-item-action>

                </v-list-item>

                <!-- <v-list-item>

                    <v-list-item-avatar>

                        <v-icon :color="doNotDisturb ? 'tzpOrangeDisabled' : 'tzpOrange'">mdi-calendar-range</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>
                    
                        <v-list-item-title :class="doNotDisturb ? 'text--disabled' : ''">{{ t('Notifications_day_range_title') }}</v-list-item-title>

                        <v-list-item-subtitle :class="doNotDisturb ? 'text--disabled' : ''">{{ t('Notifications_day_range_description') }}</v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item>

                <v-list-item style="min-height: auto">

                    <v-list-item-avatar class="my-0"></v-list-item-avatar>
    
                    <v-list-item-content class="py-0">
                            
                        <v-list-item-subtitle>
                            
                            <template v-for="(value, day) in days">

                                <v-hover 
                                v-if="! $device.isNative()"
                                v-slot="{ hover }"
                                :key="day">

                                    <avatar-initials
                                    @click.native="doNotDisturb ? false : setDay( day, (value ? false : true ) )"
                                    :color="value ? (hover && !doNotDisturb ? 'grey lighten-1' : 'tzpOrange'+(doNotDisturb ? 'Disabled' : '')) : (hover && !doNotDisturb ? 'tzpOrange' : 'grey lighten-'+(doNotDisturb ? '3' : '1'))"
                                    :initials="t(day)"
                                    :size="30"
                                    :class="'mr-3 mb-3'+(doNotDisturb ? '' : ' cursor-pointer')"></avatar-initials>

                                </v-hover>

                                <avatar-initials
                                v-else
                                @click.native="doNotDisturb ? false : setDay( day, (value ? false : true ) )"
                                :color="value ? 'tzpOrange'+(doNotDisturb ? 'Disabled' : '') : 'grey lighten-'+(doNotDisturb ? '3' : '1')"
                                :initials="t(day)"
                                :key="day"
                                :size="30"
                                :class="'mr-3 mb-3'+(doNotDisturb ? '' : ' cursor-pointer')"></avatar-initials>

                            </template>

                        </v-list-item-subtitle>

                    </v-list-item-content>

                </v-list-item> -->

            </v-list>

        </v-card>
        
        <template v-if="NotificationsCategories.init && MySubscriptions.init">

            <template v-for="(category) in NotificationsCategories">

                <div :key="category.uuid">

                    <v-card 
                    rounded="tzp"
                    class="mb-3">

                        <v-subheader>{{ t('Categories') }}</v-subheader>

                        <!-- <v-subheader v-html="t(category.title)"></v-subheader> -->

                        <!-- <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ t(category.title) }}</v-list-item-title>
                                <v-list-item-subtitle>{{ t(category.description) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item> -->

                        <v-list 
                        v-if="category.channels"
                        :two-line="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.smOnly"
                        :three-line="$vuetify.breakpoint.mdOnly || $vuetify.breakpoint.xsOnly">
                            
                            <v-list-item
                            v-for="(channel) in category.channels"
                            :key="channel.uuid">
                                
                                <v-list-item-content>

                                    <v-list-item-title :class="MessagingUserPreferences.do_not_disturb ? 'text--disabled' : ''">{{ t('Notifications_setting_' + channel.title) }}</v-list-item-title>

                                    <v-list-item-subtitle :class="MessagingUserPreferences.do_not_disturb ? 'text--disabled' : ''">{{ t('Notifications_setting_' + channel.description) }}</v-list-item-subtitle>

                                </v-list-item-content>

                                <v-list-item-action>

                                    <v-switch
                                    v-model="MySubscriptions.getByUuid(channel.uuid).subscribed"
                                    @change="toggleChannelSubscription(channel.uuid)"
                                    :disabled="MessagingUserPreferences.do_not_disturb"
                                    :ripple="false"></v-switch>

                                </v-list-item-action>

                            </v-list-item>
                            
                        </v-list>

                        <v-skeleton-loader 
                        v-else
                        type="list-item-two-line@3"></v-skeleton-loader>

                    </v-card>

                </div>
                
            </template>

        </template>

        <template v-else>

            <v-card 
            rounded="tzp"
            class="mb-3">

                <v-skeleton-loader
                type="list-item,list-item-two-line@3"></v-skeleton-loader>

            </v-card>

        </template>

    </div>

</template>

<script>
import AvatarInitials from '@/components/avatars/Initials';
import { MessagingUserPreferences } from '@/components/messaging/api';
import {
    NotificationsCategories,
    MySubscriptions,
    SubscriptionManager
} from '@/components/notifications/api';
import SupportHint from '@/components/support/SupportHint';

export default {
    name: 'Notifications',

    mixins: [ 
        NotificationsCategories.map(),
        MySubscriptions.map(),
        MessagingUserPreferences.map()
    ],

    components: { 
        AvatarInitials, 
        SupportHint
    },

    data: () => ({
        days: {},
    }),

    created() {
        this.days = this.MessagingUserPreferences.send_notifications_on_days;
    },

    mounted() {
        MySubscriptions.api().get().then( () => {
            NotificationsCategories.api().query( { include: 'channels' } ).get();
        });
    },

    methods: {
        toggleChannelSubscription(channelUuid) {
            let newSubStatus = this.MySubscriptions.getByUuid(channelUuid).subscribed;
            ! newSubStatus ? SubscriptionManager.unsubscribeByUuid(channelUuid) 
                : SubscriptionManager.subscribeByUuid(channelUuid);
        },
        dialogNotifications() {
            if (this.$prefs.settings.notifications.enabled === false) {
                this.$ui.dialog.show({
                    title: "Notifications_disable_dialog_title",
                    description: "Notifications_disable_dialog_description",
                    icon: {
                        key: "mdi-bell-off",
                        color: "tzpOrange",
                    },
                    buttons: [
                        {
                            text: "Cancel",
                            click: () => {
                                this.$prefs.put("notifications.enabled", true);
                                this.$ui.dialog.hide();
                            },
                        },
                        {
                            text: "Disable",
                            click: () => {
                                this.$prefs.put("notifications.enabled", false);
                                this.$ui.dialog.hide();
                            },
                        },
                    ],
                });
            }
        },
        toggleDoNotDisturb( value ) {
            this.MessagingUserPreferences.edit().set().do_not_disturb = value;

            if (value) {
                this.MessagingUserPreferences.edit().set().desktop_sound_enabled = false;
            }

            this.MessagingUserPreferences.api().patch().then(()=> {
                this.MessagingUserPreferences.desktop_sound_enabled = false;
            }).catch(() => {
                // Revert..
                this.MessagingUserPreferences.do_not_disturb = value ? false : true;
            });
        },
        toggleDesktopSound( value ) {
            this.MessagingUserPreferences.edit().set().desktop_sound_enabled = value;
            this.MessagingUserPreferences.api().patch().catch(()=> {
                // Revert..
                this.MessagingUserPreferences.desktop_sound_enabled = value ? false : true;
            });
        },
        setDay( day, value ) {
            this.days[day] = value;
            this.$prefs.settings.notificationsDays[day] = value;

            this.$prefs.save();
        },
    },
};
</script>