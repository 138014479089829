/**
 * Support API 
 * 
 * Contains API and Resource calls
 */
import Api from '@/system/services/Api.service'
import Resource from '@/system/helpers/api/Resource'

/* API */
export const support = {
    /**
     * Send a error report
     * 
     * @param {Object} reportData
     * @returns 
     */
    sendErrorReport: async ( reportData ) => {
        return Api.request( {
            method: 'POST',
            url: 'community/support/error-reports',
            data: reportData
        } )
    },
    /**
     * Send feedback
     * 
     * @param {Object} feedbackData
     * @returns 
     */
    sendFeedback: async ( feedbackData ) => {
        return Api.request( {
            method: 'POST',
            url: 'community/support/feedback',
            data: feedbackData
        } )
    }
}


/* RESOURCES */
