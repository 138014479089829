<template>
    
</template>

<script>
export default {
    name: 'Logout',

    data: () => ({}),

    created() {
        this.$eventBus.$off('logout');
        this.$eventBus.$on('logout', () => {
            this.showDialog()
        })
    },

    methods: {
        showDialog() {
            this.$ui.dialog.show({
                buttons: [
                    {
                        text: 'Cancel',
                        click: () => {
                            this.$ui.dialog.hide()
                        } 
                    },
                    {
                        text: 'Logout',
                        to: '/logout'
                    }
                ],
                description: 'Logout_dialog_description',
                title: 'Logout_dialog_title',
            });
        }
    },
}
</script>