<template>

    <v-app>

        <v-app-bar 
        app
        color="primary"
        dark
        flat>
            <v-btn 
            @click="$ui.navigate.back()"
            icon>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title>{{ t('Password_recovery') }}</v-toolbar-title>

            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main>
            
            <v-container 
            fluid 
            class="pa-0 ma-0">
                
                <v-row 
                align="center"
                justify="center"
                no-gutters>

                    <v-col
                    sm="8"
                    md="7"
                    xl="5">
                        
                        <stepper
                        :header="true"
                        :flat="true"
                        ref="stepper"
                        :responseButton="{ 
                            text: 'To_login', 
                            to: '/login'
                        }"
                        responseDescription="Password_changed_description_setup"
                        responseImage="forgot-password-completed"
                        :responseTitle="t('Password_changed')"
                        :steps="steps">
                        
                            <template v-slot:step1>

                                <v-alert
                                color="primary"
                                icon="mdi-tablet-cellphone"
                                text
                                type="info"
                                class="caption">
                                    {{ t('Password_reset_notice_1') }}
                                </v-alert>
                                
                                <v-alert
                                color="tzpOrange"
                                icon="mdi-account-question-outline"
                                text
                                type="info"
                                class="caption">
                                    {{ t('Password_reset_notice_lost_account_name') }}
                                </v-alert>
                                
                                <p class="pt-1">{{ t('Enter_username_to_start_password_reset') }}</p>

                                <v-row 
                                align="center"
                                justify="center"
                                no-gutters>

                                    <v-col
                                    sm="8"
                                    md="6"
                                    xl="5">
                                    
                                        <ValidationObserver ref="checkUsername">
                                            
                                            <v-form 
                                            @submit.prevent="checkUsernameAndSendEmail()"
                                            action="">
                                                
                                                <ValidationProvider
                                                v-slot="{ errors }"
                                                :name="t('Username')"
                                                mode="eager"
                                                :rules="{
                                                    required: true,
                                                }"
                                                slim>
                                                    
                                                    <v-text-field
                                                    v-model="username"
                                                    autocapitalize="off"
                                                    autocomplete="off"                                                    
                                                    color="primary"
                                                    :error-messages="errors"
                                                    outlined
                                                    :label="t('Username')"
                                                    type="text"
                                                    class="mt-6"></v-text-field>

                                                </ValidationProvider>
                                                
                                                <v-btn
                                                :block="$vuetify.breakpoint.xs"
                                                centered
                                                color="tzpPurple"
                                                dark
                                                elevation="0"
                                                large
                                                :loading="$ui.overlay.toggled"
                                                ripple
                                                type="submit"
                                                class="muli--bold">
                                                    {{ t('Send_email') }}
                                                </v-btn>

                                            </v-form>

                                        </ValidationObserver>

                                    </v-col>

                                </v-row>

                            </template>

                            <template v-slot:step2>

                                <v-sheet 
                                align="center">

                                    <v-avatar
                                    color="tzpViolet"
                                    size="124px">
                                        <v-icon 
                                        color="white"
                                        size="72px"
                                        class="animate__animated animate__faster animate__lightSpeedInLeft">
                                            mdi-email
                                        </v-icon>
                                    </v-avatar>

                                    <p class="pt-7 pb-4">{{ t('Email_verification_instructions') }}</p>

                                </v-sheet>

                                <v-form 
                                @submit.prevent="checkVerifiedEmail()"
                                class="text-center">
                                    
                                    <v-btn
                                    :block="$vuetify.breakpoint.xs"
                                    color="primary"
                                    dark
                                    depressed
                                    large
                                    :loading="$ui.overlay.toggled"
                                    ripple
                                    type="submit"
                                    class="muli--bold">
                                        {{ t('I_confirmed_verification_email') }}
                                    </v-btn>

                                </v-form>

                            </template>

                            <template v-slot:step3>

                                <p>{{ t('Choose_strong_password') }}</p>

                                <v-row 
                                align="center"
                                justify="center"
                                no-gutters>

                                    <v-col
                                    sm="8"
                                    md="6"
                                    xl="5">
                                
                                        <v-alert
                                        color="primary"
                                        text
                                        type="info"
                                        class="caption">
                                            <ul class="list--custom">
                                                <li :class="(tzpValidation.minChars !== '' ? (tzpValidation.minChars ? 'success--text' : 'error--text') : '')">{{ t('New_password_requirement_minlength') }}</li>
                                                <li :class="(tzpValidation.lowerCase !== '' ? (tzpValidation.lowerCase ? 'success--text' : 'error--text') : '')">{{ t('New_password_requirement_lowercase') }}</li>
                                                <li :class="(tzpValidation.upperCase !== '' ? (tzpValidation.upperCase ? 'success--text' : 'error--text') : '')">{{ t('New_password_requirement_uppercase') }}</li>
                                                <li :class="(tzpValidation.number !== '' ? (tzpValidation.number ? 'success--text' : 'error--text') : '')">{{ t('New_password_requirement_number') }}</li>
                                                <li :class="(tzpValidation.specialChar !== '' ? (tzpValidation.specialChar ? 'success--text' : 'error--text') : '')">{{ t('New_password_requirement_special') }}</li>
                                            </ul>
                                        </v-alert>
                                        
                                        <ValidationObserver ref="newPasswordForm">
                                            
                                            <v-form 
                                            @submit.prevent="checkPassword()"
                                            action="">

                                                <ValidationProvider
                                                v-slot="{ errors, valid }"
                                                :bails="false"
                                                :name="t('Password')"
                                                :rules="{
                                                    lowercase: true,
                                                    min: {
                                                        length: 12
                                                    },
                                                    number: true,
                                                    required: true,
                                                    special: true,
                                                    uppercase: true,
                                                }"
                                                vid="checkPass">

                                                    <password-field
                                                    v-model="newPassword"
                                                    autocomplete="new-password"
                                                    :errors="errors"
                                                    :label="t('New_password')"
                                                    :success="valid"
                                                    :tabindex="1"
                                                    class="mt-6"></password-field>
                                                    
                                                </ValidationProvider>

                                                <ValidationProvider
                                                v-slot="{ errors, valid }"
                                                mode="eager"
                                                :name="t('Password')"
                                                :rules="{
                                                    required: true,
                                                    confirmed: 'checkPass'
                                                }">

                                                    <password-field
                                                    v-model="newPasswordRepeat"
                                                    :errors="errors"
                                                    :label="t('Repeat_new_password')"
                                                    :success="valid"
                                                    :tabindex="2"></password-field>
                                                                   
                                                </ValidationProvider>

                                                <v-btn 
                                                :block="$vuetify.breakpoint.xs"
                                                color="tzpPurple"
                                                dark
                                                depressed
                                                large
                                                :loading="$ui.overlay.toggled"
                                                ripple
                                                type="submit"
                                                class="muli--bold">
                                                    {{ t('Next') }}
                                                </v-btn>

                                            </v-form>

                                        </ValidationObserver>

                                    </v-col>

                                </v-row>

                            </template>

                            <template v-slot:step4>

                                <v-row 
                                align="center"
                                justify="center"
                                no-gutters>

                                    <v-col
                                    sm="8"
                                    md="6"
                                    xl="5">

                                        <one-time-code
                                        @onFinish="verifySmsVerificationCode($event)"
                                        @onInput="otcError ? resetValidation() : ''"
                                        :description="t('Enter_verification_code')"
                                        :error="otcError"
                                        :loading="otcLoading"
                                        ref="oneTimeCode"
                                        :success="otcSuccess"></one-time-code>
                                        
                                        <!-- <ValidationObserver ref="smsForm">
                                            
                                            <sms-processor
                                            ref="smsProcessor"
                                            :rules="{
                                                required: true,
                                                digits: 5
                                            }"></sms-processor>

                                            <v-btn 
                                            @click="verifySmsVerificationCode()"
                                            :block="$vuetify.breakpoint.xs"
                                            color="tzpPurple"
                                            dark
                                            depressed
                                            large
                                            :loading="$ui.overlay.toggled"
                                            ripple
                                            class="muli--bold">
                                                {{ t('Controleer verificatiecode') }}
                                            </v-btn>

                                        </ValidationObserver> -->

                                    </v-col>

                                </v-row>

                            </template>

                        </stepper>

                    </v-col>

                </v-row>

            </v-container>

        </v-main>

    </v-app>

</template>

<script>
import NativeDevice from '@/system/services/Device.service'
import OneTimeCode from '@/system/components/authentication/OneTimeCode.vue'
import PasswordField from '@/components/form/Password.vue'
import Setup from '@/classes/Setup.js'
import SmsProcessor from '@/system/components/authentication/SmsProcessor.vue'
import Stepper from '@/components/Stepper.vue'
import { 
    extend,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate'
import { 
    confirmed,
    digits,
    max,
    min,
    required,
} from 'vee-validate/dist/rules'

// Use VeeValidate rules
extend('confirmed', confirmed);
extend('digits', digits);
extend('required', required);

export default {
    name: 'ForgotPassword',

    components: {
        OneTimeCode,
        PasswordField,
        SmsProcessor,
        Stepper,
        ValidationObserver, 
        ValidationProvider,
    },

    data: () => ({
        newPassword: '',
        newPasswordRepeat: '',
        otcError: false,
        otcLoading: false,
        otcSuccess: false,
        step: 1,
        steps: {
            1: 'Username',
            2: 'Email_verification',
            3: 'Password',
            4: 'SMS_verification',
        },
        stateToken: '',
        tzpValidation: {
            lowerCase: '',
            maxChars: '',
            minChars: '',
            number: '',
            specialChar: '',
            upperCase: '',
        },
        username: '',
    }),

    created() {
        this.currentDevice = NativeDevice

        // VeeValidate - Custom rules
        extend('lowercase', {
            validate: (value) => {
                if( value !== '') { // only check if we have a value
                    const lowerCaseRegex = new RegExp(/[a-z]/g);
                    this.tzpValidation.lowerCase = lowerCaseRegex.test(value);
                }
                else {
                    this.tzpValidation.lowerCase = ''; // reset for sure
                }

                return this.tzpValidation.lowerCase;
            },
            message: this.tWithParams('Message_lowercase_1', { field: '{_field_}' }),
        })
        extend('min', {
            params: ['length'],
            validate: (value, { length }) => {
                if( value !== '' && value.length > 0) { // only check if we have a value
                    this.tzpValidation.minChars = (value.length >= length);
                }
                else {
                    this.tzpValidation.minChars = ''; // reset for sure
                }

                return this.tzpValidation.minChars;
            },
            message: this.tWithParams('Message_min_length', { field: '{_field_}', chars: '{length}' }),
        })
        extend('number', {
            validate: (value) => {
                if( value !== '') { // only check if we have a value
                    const numberRegex = new RegExp(/[0-9]/g);
                    this.tzpValidation.number = numberRegex.test(value);
                }
                else {
                    this.tzpValidation.number = ''; // reset for sure
                }

                return this.tzpValidation.number;
            },
            message: this.tWithParams('Message_number_1', { field: '{_field_}' }),
        })
        extend('special', {
            validate: (value) => {
                if( value !== '') { // only check if we have a value
                    const specialCharRegex = new RegExp(/[#?!@()$%^&*=_{}[\]:;\"'|\\<>,.\/~`±§+-]/g);
                    this.tzpValidation.specialChar = specialCharRegex.test(value);
                }
                else {
                    this.tzpValidation.specialChar = ''; // reset for sure
                }

                return this.tzpValidation.specialChar;
            },
            message: this.tWithParams('Message_special_1', { field: '{_field_}' }),
        })
        extend('uppercase', {
            validate: (value) => {
                if( value !== '') { // only check if we have a value
                    const upperCaseRegex = new RegExp(/[A-Z]/g);
                    this.tzpValidation.upperCase = upperCaseRegex.test(value);
                }
                else {
                    this.tzpValidation.upperCase = ''; // reset for sure
                }

                return this.tzpValidation.upperCase;
            },
            message: this.tWithParams('Message_uppercase_1', { field: '{_field_}' }),
        })
    },

    mounted() {
        this.username = '';
        this.stateToken = '';
    },

    methods: {
        checkUsernameAndSendEmail() {
            this.$root.$refs.toast.hide();

            this.$refs.checkUsername.validate().then((valid) => {
                if (valid) {
                    this.$ui.overlay.show(true);
                    
                    Setup.forgotPassword(this.username).then(response => {
                        this.username = '';
                        this.stateToken = response.data.state;
                        this.$refs.stepper.nextStep(1);
                    },
                    (error) => {
                        this.$root.$refs.toast.error( this.t(error.response.data.message) );
                    }).finally(() => {
                        this.$ui.overlay.hide();
                    });
                }
                else {
                    this.$root.$refs.toast.error( this.t('Check_the_fields') );
                }
            });
        },

        checkVerifiedEmail() {
            this.$root.$refs.toast.hide();
            this.$ui.overlay.show(true);

            Setup.emailVerified(this.stateToken).then( response => {
                this.$refs.stepper.nextStep(2);
            },
            (error) => {
                this.$root.$refs.toast.error( this.t(error.response.data.message) );
            }).finally(() => {
                this.$ui.overlay.hide();
            });
        },
    
        checkPassword() {
            this.$root.$refs.toast.hide();
            this.$ui.overlay.show(true);

            this.$refs.newPasswordForm.validate().then( (valid) => {
                if (valid) {
                    Setup.setupPassword(this.stateToken, this.newPassword, this.newPasswordRepeat).then( response => {
                        this.newPassword = '';
                        this.newPasswordRepeat = '';
                        this.$refs.stepper.nextStep(3);
                        //this.startListeningForSms();
                        setTimeout(() => {
                            this.$refs.oneTimeCode.setFocus();
                        }, 300); // stepper transition time
                    },
                    (error) => {
                        this.$root.$refs.toast.error( this.t(error.response.data.message) );
                    }).finally(() => {
                        this.$ui.overlay.hide();
                    });
                }
                else {
                    this.$ui.overlay.hide();
                    this.$root.$refs.toast.error( this.t('Check_the_fields') );
                }
            });
        },

        // verifySmsVerificationCode() {
        //     this.$root.$refs.toast.hide();
        //     this.$ui.overlay.show(true);

        //     setTimeout(() => {
        //         Setup.verifyVerificationCode(this.stateToken, this.$refs.smsProcessor.verificationCode).then( response => {
        //             this.$refs.stepper.nextStep(4);
        //         },
        //         (error) => {
        //             this.$root.$refs.toast.error( this.t(error.response.data.message) );
        //         }).finally(() => {
        //             this.$ui.overlay.hide();
        //         });
        //     }, 1000);
        // },

        verifySmsVerificationCode( verificationCode ) {
            this.$root.$refs.toast.hide();
            this.$ui.overlay.show(true);

            setTimeout(() => {
                Setup.verifyVerificationCode(this.stateToken, verificationCode).then( response => {
                    this.otcError = false;
                    this.otcSuccess = true;
                    this.otcLoading = false;
                    this.$refs.stepper.nextStep(4);
                },
                (error) => {
                    this.otcError = true;
                    this.otcLoading = false;
                    this.$root.$refs.toast.error( this.t(error.response.data.message) );
                }).finally(() => {
                    this.$ui.overlay.hide();
                });
            }, 1000);
        }

        // startListeningForSms() {
        //     let smsReader = this.$refs.smsProcessor;
        //     smsReader.watchForSubject('TZP');
        //     smsReader.onSmsReceived( async (verificationCode) => {
        //         await Setup.verifyVerificationCode(this.stateToken, verificationCode);
        //     });
        //     smsReader.onValidSms(() => {
        //         setTimeout(() => {
        //             this.$ui.overlay.hide();
        //             this.step = 5;
        //         }, 1000);
        //     });
        //     smsReader.setTimeoutSeconds(60);
        //     smsReader.start();
        // },
    }
}
</script>