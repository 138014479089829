<template>
        
    <div>
        
        <v-card 
        rounded="tzp"
        class="mb-4">
            
            <v-card-title class="headline primary--text">{{ t('Terms') }}</v-card-title>

            <v-card-text>
                
                <div v-html="t('Terms_description')"></div>

                <v-list
                :one-line="$vuetify.breakpoint.smAndUp"
                :two-line="$vuetify.breakpoint.xs"
                class="pb-0 muli--semibold">
                    
                    <v-list-item
                    v-for="(item, index) in items"
                    :href="item.to"
                    :key="index"
                    target="_blank"
                    class="px-0">
                        
                        <v-list-item-avatar>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-if="$vuetify.breakpoint.xs">{{ t(item.title) }} <br/><small>{{ item.version ? t('Version')+' '+item.version : '' }}</small></v-list-item-title>
                            <v-list-item-title v-else>{{ t(item.title) }} <small>{{ item.version ? '- '+t('Version')+' '+item.version : '' }}</small></v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

            </v-card-text>

        </v-card>

        <v-card rounded="tzp">
            
            <v-card-title class="headline primary--text">{{ t('Disclaimer') }}</v-card-title>

            <v-card-text v-html="t('Disclaimer_description')"></v-card-text>

        </v-card>

    </div>
    
</template>

<script>
export default {
    name: 'Terms',

    data: () => ({
        items: [
            {
                icon: "mdi-file",
                title: "Terms_and_conditions",
                to: "https://www.thuiszorgsoftware.nl/algemene-voorwaarden/",
                version: '1.6 (mei 2019)',
            },
            {
                icon: "mdi-file",
                title: "Privacy_statement",
                to: "https://www.thuiszorgsoftware.nl/privacy-verklaring/",
                version: '',
            },
        ],
    }),
};
</script>