// Styles & fonts
require( 'typeface-muli' )
import 'typeface-roboto/index.css'
//import './assets/css/app.css'
import '@/assets/css/animate.min.css'
import '@/assets/sass/app.scss'

// Services
import { router } from '@/router'
import Auth from '@/system/services/Auth.service'
import Api from '@/system/services/Api.service'
import Device from '@/system/services/Device.service'
import Preferences from '@/system/services/Preferences.service'
import Proxy from '@/classes/Proxy'
import Storage from '@/system/services/Storage.service'
import Vuetify from '@/plugins/vuetify'
import ui from '@/system/services/Ui.service'

// Helpers
import EventBus from '@/system/helpers/EventBus'
import TaskQueue from '@/system/helpers/TaskQueue'

// Implementations
import GoogleFcmPushConnector from '@/system/helpers/messaging/GoogleFcmPushConnector'
import WebsocketConnector from '@/system/helpers/messaging/WebsocketConnector'

// Boot core.
import core from '@/system/boot'

// Configuration of app-wide events.
core.boot( {

    onBoot: ( Vue, data ) => {
        Vue.prototype.$proxy = Proxy;
        data.proxy = Vue.prototype.$proxy;
        Vue.prototype.$tasks = TaskQueue;
        data.tasks = Vue.prototype.$tasks;
    },

    app: {
        title: 'Thuiszorgplanner',
        version: process.env.VUE_APP_VERSION,
        csp: process.env.VUE_APP_CONTENT_SECURITY_POLICY
    },

    debug: {
        enabled: process.env.VUE_APP_DEBUG
    },

    api: {
        authBaseURL: process.env.VUE_APP_API_LOCATION
    },

    auth: {
        on: {
            accessTokenExpiration () {
                //
            },

            refreshTokenExpiration () {
                router.push( { path: '/logout' } );
            },

            authConstruct () {
                return new Promise( ( resolve, reject ) => {
                    Storage.read( 'device' ).then( device => {
                        // Redirect to lockscreen if locked and not on lockscreen already
                        if ( device && device.locked && window.location.pathname != '/lock' ) {
                            window.location.href = '/lock'; // can't use router.push, because we need to stop all further actions
                        }
                        else {
                            resolve();
                        }
                    } );
                } )
            },

            noTokensFound () {
                Auth.logout();
            },

            tokensRefreshed () {
                //
            },

            forTokenInvalidation () {
                // API - Revoke current device
                return Api.request( {
                    method: 'patch',
                    url: 'auth/device',
                    data: {
                        revoked: 1,
                    },
                    skipAuthRefresh: true
                } );
            },

            loggedOut () {
                // Reset lock status
                Storage.append( 'device', {
                    locked: false
                } );

                // Remove (instead of reset) the settings, so we can rebuild them on login
                Preferences.reset();

                // Router
                router.locked = false;
                router.push( { path: '/login' } ).catch( ( e ) => {
                    Debug.warn( e.getMessage );
                } );
            }
        }
    },

    session: {
        doWarn: ( device ) => {
            return true;
        },
        secondsBeforeWarn: ( device ) => {
            if ( device.isNative() ) {
                return 60; // 1min.
            }
            else {
                return 300; // 5min.
            }
        },
        on: {
            warning ( device ) {

                if ( device.isNative() ) {
                    router.push( { path: '/lock' } ).catch( ( e ) => {
                        Debug.warn( e.getMessage );
                    } );
                }
                else {
                    const clickAction = () => {
                        document.removeEventListener( 'keydown', clickAction );
                        document.removeEventListener( 'click', clickAction );
                        ui.dialog.hide();
                    }

                    document.addEventListener( 'keydown', clickAction, false );
                    document.addEventListener( 'click', clickAction, false );

                    ui.dialog.show( {
                        description: 'Afk_dialog_notice_description',
                        icon: {
                            key: 'mdi-sleep',
                            color: 'tzpPurple',
                        },
                        persistent: true,
                        textCenter: true,
                        title: 'Afk_dialog_are_you_still_here',
                        buttons: [
                            {
                                text: "Afk_dialog_im_not_away",
                                click: () => clickAction,
                            },
                        ],
                    } );
                }
            },

            deactivation () {
                Storage.append( 'device', {
                    locked: true
                } );
            },

            tooLongAway () {
                router.push( { path: '/lock' } ).catch( ( e ) => {
                    // Debug.warn( e.getMessage );
                } );
            },

            activation () {
                router.locked = false;
                Storage.append( 'device', {
                    locked: false
                } );

                if ( router.previousRoute.path !== null && router.previousRoute.path !== '/' ) {
                    router.go( -1 );
                    Proxy.resume();
                }
                else {
                    router.push( { path: '/dashboard' } );
                }
            },
        }
    },

    device: {
        on: {
            online () {
                ui.dialog.hide();
            },

            offline () {
                ui.dialog.show( {
                    description: 'No_connection_description',
                    icon: {
                        key: 'mdi-cloud-alert',
                        color: 'tzpOrange',
                    },
                    persistent: true,
                    textCenter: true,
                    title: 'No_connection',
                } );
            },

            toForeground () {
                //
            },

            toBackground () {
                //
            },
        }
    },

    storage: {
        prefix: 'TZP_'
    },

    preferences: {
        defaults: {
            version: process.env.VUE_APP_VERSION, // Cache control version
            languageCode: 'nl_NL',
            instructionsRead: true,
            unacknowledgedSensitiveData: true,
            darkModeEnabled: false,
            navigationDrawerDense: false,
            dashboard: {
                shortcuts: true,
                notices: true,
                news: true,
                notifications: true,
                tips: false,
            },
            fingerPrintEnabled: false,
            fingerPrintAvailable: false,
            notifications: {
                deleteAllDialog: true,
                enabled: true,
                hasUnread: false,
                markAllDialog: true,
                mute: false,
                sound: true,
            },
            notificationsDays: {
                Mon: true,
                Tue: true,
                Wed: true,
                Thu: true,
                Fri: true,
                Sat: true,
                Sun: true
            },
            notices: {
                shown: {}
            },
            tour: {
                slidesShown: {}
            },
        },
        hooks: {
            darkModeEnabled ( value ) {
                Vuetify.framework.theme.dark = value;
            }
        }
    },

    messaging: {
        enabled: true,
        connectors: ( device ) => {

            let connectors = [];

            if ( device.platform === 'web' ) {
                connectors.websockets = new WebsocketConnector;
            }
            else {
                connectors.mobile_push = new GoogleFcmPushConnector;
            }

            return connectors;
        }
    },

    guard: {
        client: {
            is: {
                unauthenticated: {
                    route: '/login'
                },
                authenticated: {
                    route: '/dashboard'
                },
                locked: {
                    route: '/lock'
                }
            },
        },
        on: {
            route: {
                '/logout': {
                    actions: () => {
                        Auth.logout();
                    },
                    next: '/login'
                },
                '/timeline': {
                    actions: () => {
                        Proxy.open( process.env.VUE_APP_PROXY_URL + '?TZPX/app/{{proxy_user_type_id}}/dag' );
                    }
                },
                '/agenda': {
                    actions: () => {
                        Proxy.open( process.env.VUE_APP_PROXY_URL + '?agenda/listview' );
                    }
                },
                '/clients': {
                    actions: () => {
                        Proxy.open( process.env.VUE_APP_PROXY_URL + '?clienten' );
                    }
                },
                '/documents': {
                    actions: () => {
                        Proxy.open( process.env.VUE_APP_PROXY_URL + '?TZPX/app/{{proxy_user_type_id}}/docs' );
                    }
                },
                '/info': {
                    actions: () => {
                        Proxy.open( process.env.VUE_APP_PROXY_URL + '?TZPX/app/{{proxy_user_type_id}}/info' );
                    }
                }
            },
            navigate: {
                away: ( to ) => {
                    EventBus.$emit( 'hideTour' );

                    // hide all UI (service) elements
                    if ( to.path === '/lock' ) { // only dialogs on the lockscreen
                        ui.dialog.hide();
                        ui.dialogError.hide();
                    }
                    else {
                        ui.hideAll();
                    }
                }
            }
        },

    },
} );
