<template>

    <div v-if="$vuetify.breakpoint.smAndUp">

        <v-dialog
        v-model="hint"
        @click:outside="hideHint()"
        :max-width="$vuetify.breakpoint.xs ? 280 : 560"
        :overlay-color="!$prefs.settings.darkModeEnabled ? 'tzpPurple' : undefined"
        overlay-opacity="0.75">

            <v-card 
            :loading="loading"
            rounded="tzp">

                <v-skeleton-loader 
                v-if="loading"
                type="card-heading,paragraph"
                class="px-3"></v-skeleton-loader>

                <v-card-title
                v-if="hintTitle"
                class="headline font-weight-regular pr-15">

                    <v-list-item 
                    class="pa-0"
                    style="min-height:auto">

                        <v-list-item-avatar class="my-0">

                            <v-icon
                            v-if="icon"
                            :color="iconStyle">
                                {{ iconName }}
                            </v-icon>

                        </v-list-item-avatar>

                        <v-list-item-content class="py-0">{{ hintTitle }}</v-list-item-content>

                    </v-list-item>

                </v-card-title>

                <v-card-text 
                v-if="hintContent"
                v-html="hintContent"></v-card-text>

                <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn 
                    color="primary"
                    text
                    @click="hideHint()">
                        {{ t('Close') }}
                    </v-btn>

                </v-card-actions>

                <v-icon 
                :color="$prefs.settings.darkModeEnabled ? 'rgba(255, 255, 255, 0.1)' : 'grey lighten-3'"
                size="40"
                class="pos-bottom pos-bottom--left">
                    mdi-information-outline
                </v-icon>

            </v-card>

        </v-dialog>

        <v-btn
        @click="showHint()"
        icon
        x-small>
            
            <v-icon>mdi-information-outline</v-icon>

        </v-btn>

    </div>

</template>

<script>
import { FAQ } from '@/components/faqs/api'

export default {
    name: 'SupportHint',

    props: {
        icon: {
            type: Boolean|String,
            default: false
        },
        iconColor: {
            type: String,
            default: undefined
        },
        uuid: { // Uuid of a FAQ item
            type: String,
            required: true
        },
    },

    mixins: [ FAQ.map() ],

    computed: {
        iconName: function() { 
            return this.error ? 'mdi-alert-octagon' : this.icon;
        },
        iconStyle: function() { 
            return this.error ? 'error' : this.iconColor;
        }
    },

    data: () => ({
        error: false,
        hint: false,
        hintContent: false,
        hintTitle: false,
        loading: true,
    }),

    methods: {
        hideHint() {
            this.hint = false;

            setTimeout(() => {
                this.resetData();
            }, 300);
        },
        showHint() {
            this.hint = true;

            FAQ.api().params({ uuid: this.uuid }).get().then( () => {
                this.loading = false;
                this.hintContent = this.FAQ.answer;
                this.hintTitle = this.FAQ.question;

            }, error => {
                this.loading = false;
                this.error = true;
                this.hintContent = this.t('Error_occured_description');
                this.hintTitle = this.t('Error_occured_title');
            } );
        },
        resetData() {
            this.error = false;
            this.hintContent= false;
            this.hintTitle= false;
            this.loading = true;
        }
    },
}
</script>