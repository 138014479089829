<template>

    <div>
        <v-card 
        v-html="t('Settings_page_feedback_description')"
        color="transparent"
        flat
        tile
        :class="($vuetify.breakpoint.smAndDown ? 'mb-3' : 'mb-6' )+' pt-0 px-4 grey--text'+($prefs.settings.darkModeEnabled ? '': ' text--darken-2')"></v-card>

        <v-alert 
        rounded="tzp"
        type="info">

            <span v-html="t('Feedback_note')"></span>
            
        </v-alert>
        
        <stepper
        contentClass="pt-2 pb-6"
        ref="stepper"
        :responseDescription="stepperResponseDescription"
        :responseTitle="stepperResponseTitle"
        :responseType="stepperResponseType"
        :steps="[1]">

            <template v-slot:step1>
                
                <ValidationObserver ref="feedbackForm">

                    <v-form 
                    @submit.prevent="submit()"
                    action="">

                        <ValidationProvider
                        v-slot="{ errors, valid }"
                        mode="eager"
                        :name="t('Message')"
                        :rules="{ 
                            max: 255,
                            required: true, 
                            regex: /^[a-zA-Z0-9?!,.:;&quot;()~`$@#’'+\-=_&€£*%\s]+$/,
                        }">
                            
                            <v-textarea
                            v-model="message"
                            autocomplete="off"
                            :counter="255"
                            :error-messages="errors"
                            :label="t('Type_your_message')"
                            noResize
                            required
                            :success="valid"
                            class="mb-3"></v-textarea>

                        </ValidationProvider>

                        <div v-html="t('Feedback_rating_description')"></div>
                        
                        <ValidationProvider
                        v-slot="{ errors }"
                        mode="eager"
                        :name="t('Rating')"
                        :rules="{
                            required: true,
                            between: { 
                                min: 1, 
                                max: 5
                            }
                        }">
                            
                            <v-rating
                            v-model="rating"
                            background-color="grey lighten-1"
                            empty-icon="mdi-star-outline"
                            full-icon="mdi-star"
                            hover
                            large
                            :length="5"></v-rating>

                            <!-- custom error field, based  -->
                            <div class="v-text-field__details">
                                
                                <div 
                                :class="'v-messages error--text mb-4'+($vuetify.dark ? ' theme--dark' : ' theme--light')"
                                role="alert">
                                    
                                    <div class="v-messages__wrapper">
                                        
                                        <div class="v-messages__message">
                                            {{ errors[0] }}
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </ValidationProvider>

                        <v-btn 
                        type="submit"
                        color="tzpPurple"
                        depressed
                        ripple
                        class="white--text">
                            {{ t('Send') }}
                        </v-btn>

                    </v-form>

                </ValidationObserver>

            </template>

        </stepper>

    </div>
    
</template>

<script>
import { support } from '@/components/support/api'
import Stepper from '@/components/Stepper'
import { 
    extend,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate';
import { 
    between,
    max,
    regex,
    required, 
} from 'vee-validate/dist/rules';

// Use VeeValidate rules
extend('required', required);
extend('max', max);
extend('regex', regex);
extend('between', between);

export default {
    name: "FeedbackForm",

    components: { 
        Stepper,
        ValidationObserver, 
        ValidationProvider,
    },

    data: () => ({
        stepperResponseDescription: 'Feedback_success_description',
        stepperResponseType: 'success',
        stepperResponseTitle: 'Feedback_success_title',
        message: '',
        rating: 0,
    }),

    methods: {
        getRating(value) {
            if( value > 0 && value < 6 ){ // Our rating has 5 stars
                this.rating = value
            }
        },
       submit() {
            // Be sure to hide our 'snacks'
            this.$ui.hideAll()

            // Validate the form by it's reference
            this.$refs.feedbackForm.validate().then( (valid) => {
                
                if( valid ) {
                    this.$ui.overlay.show(true)
                    
                    let feedbackData = {
                        message: this.message,  
                        rating: this.rating
                    }
                    // Send it to our API endpoint
                    support.sendFeedback(feedbackData).then( () => {
                        
                        this.message = ''
                        this.rating = 0

                        this.$ui.overlay.hide()
                        this.$refs.stepper.nextStep(1)

                        // Wait until the models are updated in the UI,
                        this.$nextTick(() => {
                            this.$refs.feedbackForm.reset() // reset state, flags and clear errors.
                        });
                    },(error) => {
                        this.stepperResponseDescription = this.t('Feedback_error_description')
                        this.stepperResponseType = 'error'
                        this.stepperResponseTitle = error.response.data.message
                        this.$ui.overlay.hide()
                        this.$refs.stepper.nextStep(1)

                        // Wait until the models are updated in the UI,
                        this.$nextTick(() => {
                            this.$refs.feedbackForm.reset() // reset state, flags and clear errors.
                        });
                    })
                }
            })
        },
    },
};
</script>