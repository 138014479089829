<template>
    
    <v-card 
    rounded="tzp"
    :class="'profile'+($vuetify.breakpoint.smAndDown ? ' mt-12' : ' mt-9')">

        <template v-if="company">

            <avatar-profile-icon 
            icon="mdi-office-building"
            :title="company.name"></avatar-profile-icon>

            <v-tabs 
            v-model="companyTab"
            icons-and-text>

                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                    {{ t("Contact") }}
                    <v-icon> mdi-card-account-phone </v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                    {{ t("Address") }}
                    <v-icon>mdi-map-marker-radius</v-icon>
                </v-tab>

                <v-tabs-items v-model="companyTab">
                    
                    <v-tab-item
                    :reverse-transition="false"
                    :transition="false"
                    value="tab-1">
                        
                        <v-card rounded="b-tzp">
                            
                            <v-list
                            v-for="(contact, k) in company.contacts" 
                            :key="k"
                            subheader
                            two-line 
                            class="inline-fields">

                                <v-subheader>{{ t('Company_contact_' + contact.name + '_header') }}</v-subheader>

                                <v-list-item v-if="contact.email">

                                    <v-list-item-avatar>

                                        <v-icon color="tzpOrange">mdi-email</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>{{ t("Email") }}</v-list-item-title>

                                        <v-list-item-subtitle>{{ contact.email }}</v-list-item-subtitle>

                                    </v-list-item-content>

                                </v-list-item>

                                <v-divider 
                                v-if="contact.phone_number"
                                inset></v-divider>

                                <v-list-item v-if="contact.phone_number">

                                    <v-list-item-avatar>

                                        <v-icon color="tzpOrange">mdi-phone</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>{{ t("Phone_number") }}</v-list-item-title>

                                        <v-list-item-subtitle>{{ contact.phone_number }}</v-list-item-subtitle>

                                    </v-list-item-content>

                                </v-list-item>

                                <v-divider 
                                v-if="contact.mobile_number"
                                inset></v-divider>

                                <v-list-item v-if="contact.mobile_number">

                                    <v-list-item-avatar>

                                        <v-icon color="tzpOrange">mdi-cellphone</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>{{ t("Mobile_number") }}</v-list-item-title>

                                        <v-list-item-subtitle>{{ contact.mobile_number }}</v-list-item-subtitle>

                                    </v-list-item-content>

                                </v-list-item>

                                <v-divider v-if="k != company.contacts.length - 1"></v-divider>

                            </v-list>

                        </v-card>

                    </v-tab-item>

                    <v-tab-item
                    :reverse-transition="false"
                    :transition="false"
                    value="tab-2">
                        
                        <v-card rounded="b-tzp">
                            
                            <v-list 
                            v-for="(address, k) in company.addresses" 
                            :key="k"
                            two-line
                            subheader
                            class="inline-fields">

                                <v-subheader>{{ t('Company_address_' + address.name + '_header') }}</v-subheader>

                                <v-list-item>

                                    <v-list-item-avatar>

                                        <v-icon color="tzpOrange">mdi-office-building-marker</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>{{ t("Address") }}</v-list-item-title>

                                        <v-list-item-subtitle>
                                            {{ address.address }}<br />
                                            {{ address.postal_code+' '+address.city }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    
                                </v-list-item>

                                <v-divider
                                v-if="address.country"
                                inset></v-divider>

                                <v-list-item v-if="address.country">

                                    <v-list-item-avatar>

                                        <v-icon color="tzpOrange">mdi-earth</v-icon>

                                    </v-list-item-avatar>

                                    <v-list-item-content>

                                        <v-list-item-title>{{ t("Country") }}</v-list-item-title>

                                        <v-list-item-subtitle>{{ address.country }}</v-list-item-subtitle>

                                    </v-list-item-content>

                                </v-list-item>

                                <v-divider v-if="k != company.addresses.length - 1"></v-divider>

                            </v-list>

                        </v-card>

                    </v-tab-item>

                </v-tabs-items>
                
            </v-tabs>

        </template>

        <template v-else>
            
            <div class="profile-wrap px-4">

                <div class="profile-avatar text-center mb-4">

                    <v-skeleton-loader type="avatar"></v-skeleton-loader>
                    
                </div>
                
                <v-skeleton-loader type="sentences"></v-skeleton-loader>

            </div>

            <v-divider class="profile-divider"></v-divider>

            <v-skeleton-loader type="list-item-avatar-two-line list-item-avatar-two-line list-item-avatar-two-line"></v-skeleton-loader>

        </template>

    </v-card>

</template>

<script>
import AvatarProfileIcon from "@/components/avatars/profile/Icon"
//import { Companies } from "@/components/companies/api"
//import { Company } from "@/components/companies/api"

export default {
    name: "CompanyProfile",

    props: {
        company: {},
        uuid: {
            type: String,
            required: false
        },
    },

    //mixins: [ Companies.map() ],

    components: { AvatarProfileIcon },

    data: () => ({
        //company: {},
        companyTab: "tab-1",
        //cId: "",
    }),

    // created() {
    //     if (this.uuid) {
    //         this.cId = this.uuid;
    //     } else {
    //         this.cId = this.$router.currentRoute.params.uuid;
    //     }
    // },

    mounted() { },
};
</script>
<style lang="scss">
// Fixes v-tabs / v-slide-group 'fix - https://github.com/vuetifyjs/vuetify/issues/8875
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev { display: none !important; }

// Theme Dark
.theme--dark {
    &.v-tabs { // Fixes issue with tabs on mobile, on-touch background opacity
        .v-tab--active:before,
        .v-tab--active:hover:before,
        .v-tab:focus:before { opacity: 0 !important; }
    }
}
</style>