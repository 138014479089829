<template>

    <v-card rounded="tzp">

        <devices-list></devices-list>
        
    </v-card>
    
</template>

<script>
import DevicesList from "@/components/devices/DevicesList.vue";

export default {
    name: "DevicesView",

    components: { DevicesList },

    data: () => ({ }),
};
</script>