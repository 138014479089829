<template>

    <v-btn
    @click="handleClick( button.onClick )"
    :icon="icon"
    :block="block"
    :dark="dark"
    :color="color"
    :disabled="disable"
    :small="small"
    :outlined="outlined"
    :depressed="depressed"
    :loading="loading">
        {{ 'translate_string' in button && button.translate_string !== null ? t(button.translate_string) : button.text }}
    </v-btn>

</template>

<script>
import ApiClick from '@/mixins/ApiClick'

export default {
    name: 'ApiButton',

    props: {
        block: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: undefined
        },
        dark: {
            type: Boolean,
            default: false
        },
        depressed: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        button: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: undefined
        },
        disable: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [ ApiClick ]
}
</script>