<template>

    <widget 
    v-if="Notifications.init"
    @onClick="btnClick( $event )"
    :buttons="{
        0: {
            disable: ! Notifications.getUnreadCount(),
            icon: 'mdi-email-open',
            ref: 'mark-as-read',
        }
    }"
    emptyIcon="mdi-email-fast-outline"
    emptyTitle="No_unread_notifications"
    :showEmpty="! Notifications.getUnreadCount()"
    :title="t( heading )">

        <div :style="(height ? 'height:'+height+'px;overflow-y:auto;' : '')">

            <notifications-list
            @itemClicked="$root.$refs.notificationsManager.showNotification( $event, true )"
            :headers="false"
            iconColor="tzpPurple"
            :items="Notifications.getUnread()"
            ref="notificationsList"
            :swipe="$device.isNative() || $vuetify.breakpoint.smAndDown"
            :widget="true"></notifications-list>

        </div>

        <template v-slot:create>
            <create-notifications class="my-4 text-center"></create-notifications>
        </template>

    </widget>

    <widget 
    v-else
    :buttons="{
        0: {
            disable: true,
            icon: 'mdi-email-open',
        }
    }"
    :title="t( heading )">

        <v-skeleton-loader
        type="list-item-avatar-two-line@2"
        class="pa-3"></v-skeleton-loader>

    </widget>

</template>

<script>
import { Notifications } from '@/components/notifications/api'
import CreateNotifications from '@/components/notifications/CreateNotifications'
import NotificationsList from '@/components/notifications/NotificationsList'
import Widget from '@/components/Widget'

export default {
    name: 'NotificationsWidget',

    props: {
        heading: {
            type: Boolean|String,
            default: 'Notifications'
        },
        height: {
            type: Boolean|Number,
            default: false
        },
    },

    mixins: [ Notifications.map() ],

    components: { 
        CreateNotifications,
        NotificationsList,
        Widget,
    },

    data: () => ({
        items: {}
    }),

    mounted () { },

    methods: {
        btnClick( ref ) {
            switch( ref ) {
                case 'mark-as-read':
                    this.$root.$refs.notificationsManager.checkMarkAllAsRead();
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss">
.widget .notifications .swipeout-list {
    margin-top: 0 !important;

    .swipeout-list-item:first-child .notifications__subheader { margin-top: 0; }
}
</style>