<template>

    <v-dialog
    v-model="dialog"
    @click:outside="closeDialog"
    :max-width="$vuetify.breakpoint.smAndDown ? 280 : 560"
    :overlay-color="!$prefs.settings.darkModeEnabled ? 'tzpPurple' : undefined"
    overlay-opacity="0.7">
        
        <v-card rounded="tzp">

            <stepper
            @closeDialog="dialog = false"
            :inDialog="true"
            ref="stepper"
            :responseClose="true"
            responseTitle="Settings_reset_thank_you_title"
            :steps="[1]">

                <template v-slot:step1>

                    <v-card>

                        <card-content
                        center="none"
                        :content="t('Settings_reset_dialog_description')"
                        :noPadding="true"
                        :title="t('Settings_reset_dialog_title')"></card-content>

                        <v-card-actions class="pa-0 mt-4">
                            
                            <v-spacer></v-spacer>

                            <v-btn
                            @click="dialog = false"
                            color="primary"
                            dark
                            text>
                                {{ t('Cancel') }}
                            </v-btn>

                            <v-btn
                            @click="resetSettings()"
                            color="primary"
                            dark
                            text>
                                {{ t('Continue') }}
                            </v-btn>

                        </v-card-actions>

                    </v-card>

                </template>

            </stepper>

        </v-card>

    </v-dialog>

</template>

<script>
import CardContent from '@/components/CardContent'
import Stepper from '@/components/Stepper'

export default {
    name: "ResetSettings",

    components: { 
        CardContent,
        Stepper
    },

    data: () => ({
        dialog: false,
    }),

    mounted() { },

    methods: {
        closeDialog(){
            this.dialog = false
        },
        resetSettings() {
            this.$ui.overlay.show(true)
            
            this.$storage.remove('offline-data').then( () => {
                this.$prefs.reset() // Reset settings to default values
                
                setTimeout(() => { // UX 'hack' - so the user notice a change
                    this.$ui.overlay.hide()
                    this.$refs.stepper.nextStep(1) // trigger our stepper to the next step
                }, 500);
            })
        }
    },
}
</script>