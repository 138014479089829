<template>

    <v-footer
    v-if="$vuetify.breakpoint.lgAndUp"
    app
    :color="(!$prefs.settings.darkModeEnabled ? 'white' : '')">
            
        <v-row 
        align="center"
        justify="center"
        no-gutters
        class="py-3">

            <v-col 
            cols="12"
            sm="6"
            :order="$vuetify.breakpoint.xs ? 2 : 1"
            :class="$vuetify.breakpoint.xs ? 'text-center' : ''">

                <v-img
                v-if="$prefs.settings.darkModeEnabled"
                alt="coolweb zorgsoftware logo"
                eager
                width="110px"
                height="31px"
                src="img/dark/coolweb-zorgsoftware_dark.svg"
                :transition="false"
                :class="$vuetify.breakpoint.xs ? 'mx-auto' : ''"></v-img>

                <v-img
                v-else
                alt="coolweb zorgsoftware logo"
                eager
                width="110px"
                height="31px"
                src="img/coolweb-zorgsoftware.svg"
                :transition="false"
                :class="$vuetify.breakpoint.xs ? 'mx-auto' : ''"></v-img>

            </v-col>

            <v-col 
            cols="12"
            sm="6"
            :order="$vuetify.breakpoint.xs ? 1 : 2"
            :class="'primary--text'+($vuetify.breakpoint.xs ? ' mb-4 text-center body-2' : ' text-right')">
                <span v-html="t('NEN_certified')"></span>
            </v-col>

        </v-row>

    </v-footer>
  
</template>

<script>
export default {
    name: 'TzpFooter'
}
</script>