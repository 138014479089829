export const dutch = {
    /*
     * File config.
     */
    language_code: 'nl_NL',
    Language: 'Nederlands',

    /*
     * Language strings.
     */
    User: 'Gebruiker',
    Account: 'Account',
    Role: 'Rol',
    'Too Many Attempts.': 'Tijdelijk geblokkeerd. Probeer het nog eens over een paar minuten.',
    'The given data was invalid.': 'Er ging iets fout, de aanvraag was niet juist ingediend. Begin opnieuw.',
    Invalid_request: 'We konden je aanvraag niet verwerken. Probeer het opnieuw.',
    Not_implemented: 'We konden je aanvraag niet verwerken. Deze dienst of functionaliteit is nog niet beschikbaar.',
    Network_error_message: 'Er ging iets fout, of de dienst is tijdelijk niet beschikbaar. Probeer het later opnieuw.',
    Network_failure_message: 'Door een fout konden we je verzoek niet verwerken. Probeer het later opnieuw.',
    Invalid_credentials: 'Gebruikersnaam en/of wachtwoord is onjuist.',
    Verification_time_expired: 'De maximale verificatietijd is overschreden. Ga terug en start opnieuw.',
    Check_verification_code: 'Controleer de verificatiecode',
    Verification_code_could_not_be_send: 'We konden de verificatiecode niet versturen. Probeer het later opnieuw.',
    Unsupported_user_type: 'Alleen zorgverleners kunnen op dit moment inloggen op de mobiele app.',
    Expired_process: 'De maximale aanvraag tijd is verstreken. Begin het proces waar je mee bezig was opnieuw.',
    Email_could_not_be_send: 'E-mail kon niet worden verzonden door een technische fout. Probeer het later opnieuw.',
    Switch_on: 'Inschakelen',
    Fingerprint_recognition: 'Vingerafdruk herkenning',
    Fingerprint_recognition_description: 'Gebruik vingerafdruk herkenning naast je gebruikelijke pin.',
    Scheduled_appointments: 'Geplande afspraken',
    Scheduled_appointments_description: 'Stuur mij een herinnering over mijn geplande afspraken.',
    Updates: 'Updates',
    Updates_description: 'Houdt mij op de hoogte van nieuws over updates van de Thuiszorgplanner.',
    QR_scanner: 'Scanner',
    Logout: 'Uitloggen',
    Logout_dialog_title: 'Je gaat nu uitloggen',
    Logout_dialog_description: 'Door uit te loggen moet je de volgende keer <strong>opnieuw inloggen</strong>, voordat je de Thuiszorgplanner kunt gaan gebruiken.',
    Lock: 'Vergrendelen',
    Overview: 'Overzicht',
    My_overview: 'Mijn overzicht',
    App_updates: 'App updates',
    My_appointments: 'Mijn afspraken',
    Open_reports: 'Openstaande rapportages',
    This_is_going_to_change: 'Dit gaat er binnenkort veranderen binnen uw TZP.',
    About_this_app: 'Over deze app',
    Username: 'Gebruikersnaam',
    Password: 'Wachtwoord',
    Login: 'Inloggen',
    Disable: 'Uitschakelen',
    Disabled_account: 'Je account is geblokkeerd. Neem contact op met je werkgever om dit probleem te verhelpen.',
    Account_not_found: 'Wij konden geen (actief) account vinden met deze gebruikersnaam. Neem contact op met je werkgever als het probleem zich voor blijft doen.',
    Invalid_account: 'Wij konden geen (actief) account vinden met deze gebruikersnaam. Neem contact op met je werkgever als het probleem zich voor blijft doen.',
    Invalid_credentials: 'Onjuiste gebruikersnaam en/of wachtwoord combinatie.',
    Forgot_password: 'Wachtwoord vergeten',
    Password_recovery: 'Wachtwoord instellen',
    Forgot_password_question: 'Wachtwoord vergeten?',
    Username_required: 'Gebruikersnaam mag niet leeg zijn',
    Password_required: 'Wachtwoord mag niet leeg zijn',
    Complete_all_fields: 'Vul alle velden in',
    Check_the_fields: 'Controleer alle velden',
    Sending_SMS: 'We sturen je een verificatiecode toe per SMS.',
    Sending_SMS_auto_retrieve: 'We sturen je een verificatiecode toe per SMS. Deze wordt automatisch verwerkt.',
    Auto_retrieve_SMS_failed: 'We konden niet automatisch de verificatiecode verwerken. Controleer je SMS berichten en vul de verificatiecode hieronder in.',
    Verifying_received_SMS: 'We controleren je ontvangen verificatiecode.',
    Enter_verification_code: 'Vul hier je verificatiecode in.',
    Manually_check_for_SMS: 'Controleer je SMS berichten en vul de verificatiecode hieronder in.',
    Invalid_verificationcode: 'Ongeldige verificatiecode. Probeer het opnieuw.',
    Too_many_verificationcode_attempts: 'Je hebt te vaak de verkeerde verificatiecode ingevuld. Start het proces opnieuw.',
    Verify: 'Verifieer',
    Verified: 'Geverifieerd',
    verified: 'geverifieerd',
    Waiting_for_SMS: 'Moment geduld alsjeblieft.',
    Choose_your_pin: 'Kies een 5-cijferige toegangscode.',
    Enter_your_pin: 'Voer je toegangscode in.',
    Repeat_pin: 'Herhaal de toegangscode.',
    Pin_required: 'Toegangscode vereist. Probeer het opnieuw.',
    Pin_verified: 'Toegangscode ingesteld',
    Pin_verified_description: '<p>Met de zojuist ingestelde toegangscode kun je vanaf nu <strong>de Thuiszorgplanner ontgrendelen</strong>.</p>',
    Pins_do_not_match: 'Toegangscode onjuist. Probeer het opnieuw. Je hebt %remainingAttempts% resterende poging(en).',
    Pins_do_not_match_retry: 'De toegangscodes kwamen niet overeen. Probeer het opnieuw: Kies een 5-cijferige toegangscode.',
    To_dashboard: 'Naar dashboard',
    Fingerprint_recognition_enable_description: 'Optioneel kun je vingerafdruk herkenning inschakelen. Deze gebruik je optioneel naast je toegangscode.',
    Logged_out_confirmation: 'Je bent nu uitgelogd.',
    Logged_out_confirmation_access_denied: 'De sessie op dit apparaat is ingetrokken. Je bent nu uitgelogd.',
    Logged_out_confirmation_max_login_attempts: 'Je hebt het maximaal aantal pogingen overschreden. Je bent nu uitgelogd.',
    Reset_app: 'App opnieuw instellen',
    Lockscreen_notice_title: 'Werk zorgvuldig en effectief',
    Lockscreen_notice_description: 'Vergeet nooit meer een afspraak. Plan je werkzaamheden via de Thuiszorgplanner.',
    Network_request_failed: 'We konden je aanvraag op dit moment niet verwerken. Probeer het later nog eens.',
    Sensitive_data_dialog_title: 'Dit systeem bevat vertrouwelijke gegevens!',
    Sensitive_data_dialog_text: 'Indien je door gaat met het gebruik van de Thuiszorgplanner ben jij je hier van bewust en ga je zorgvuldig met de gegevens om.',
    Sensitive_data_lock_text: 'De Thuiszorgplanner bevat <strong>vertrouwelijke</strong> gegevens.',
    I_understand: 'Ik begrijp het',
    Fingerprint_dialog_title: 'Vingerafdruk herkenning',
    Fingerprint_dialog_description: 'Bevestig je identiteit.',
    Fingerprint_dialog_hint: 'Plaats je vinger op de sensor.',
    Abort_setup_dialog_title: 'Aanmelden stoppen',
    Abort_setup_dialog_description: 'Je moet de vorige stappen van het aanmeldproces dan opnieuw uitvoeren.',
    Account_setting_force_logout: 'Logt je uit en wist bijbehorende gegevens.',
    No_connection: 'Geen internet',
    No_connection_description: 'Er is geen verbinding met het internet. Maak verbinding en probeer het opnieuw.',
    What_can_we_help_you_with: 'Waar kunnen we je mee helpen?',
    We_found_possible_solution: 'We vonden %counter% mogelijke oplossing.',
    We_found_possible_solutions: 'We vonden %counter% mogelijke oplossingen.',
    Results: 'Resultaten',
    Device_revoked_warning: 'Apparaat is ingetrokken. Je bent nu uitgelogd.',
    Locked_multiple_active_devices_warning: 'Je account kan maar actief zijn op <strong>één</strong> apparaat tegelijk.',
    Send_email: 'Verstuur e-mail',
    Signed_off: 'Afgemeld',
    Account_in_use_on_other_device: 'Je bent op dit apparaat afgemeld, omdat je account op een ander apparaat wordt gebruikt.',
    Loading_screen_error: 'Er ging iets verkeerd. Laadt de pagina opnieuw of start de app opnieuw op.',
    Lock_dialog_title: 'Wil je de Thuiszorgplanner vergrendelen?',
    Lock_dialog_description: 'Let op je gaat de Thuiszorgplanner verlaten. We raden je aan om de Thuiszorgplanner te vergrendelen.',

    // Password reset.
    Password_reset_notice_1: 'Houdt er rekening mee dat als je jouw wachtwoord wijzigt, je op al je huidige apparaten wordt uitgelogd. Ook de authenticators vervallen.',
    Password_reset_notice_lost_account_name: 'Indien je jouw gebruikersnaam bent vergeten moet je contact opnemen met je werkgever.',
    Enter_username_to_start_password_reset: 'Voer hieronder je gebruikersnaam in om te beginnen. We sturen je een e-mail met verdere instructies.',
    SMS_verification: 'SMS verificatie',
    Email_verification: 'E-mail verificatie',
    Password_do_not_match: 'De wachtwoorden kwamen niet met elkaar overeen.',
    Ready: 'Klaar',
    Choose_strong_password: 'Kies een nieuw wachtwoord dat niet eerder gebruikt is en voldoet aan de onderstaande eisen.',
    Password_reset_weak_password: 'Het gekozen wachtwoord voldoet niet aan de eisen.',
    Email_verification_instructions: 'We hebben je een e-mail gestuurd met daarin een bevestigings link. Bevestig dat je deze e-mail hebt ontvangen door in de e-mail op de knop, of link te klikken.',
    I_confirmed_verification_email: 'Ik heb de e-mail bevestigd',
    Password_reset_unverified_mail: 'Je hebt de e-mail die we naar je verzonden hebben, nog niet bevestigd. Voer de instructies hieronder uit.',
    Choose_new_password_requirements: '<ul><li>Minimaal 12 karakters lang.</li><li>Minimaal 1 hoofdletter.</li><li>Minimaal 1 kleine letter.</li><li>Minimaal 1 getal.</li><li>Minimaal 1 speciaal teken.</li><li>Maximaal 30 karakters lang.</li><li>Niet eerder gekozen/gebruikt wachtwoord.</li></ul>',
    New_password_requirement_minlength: 'Minimaal 12 karakters lang zijn.',
    New_password_requirement_uppercase: 'Minimaal één hoofdletter bevatten.',
    New_password_requirement_lowercase: 'Minimaal één kleine letter bevatten.',
    New_password_requirement_number: 'Minimaal één getal bevatten.',
    New_password_requirement_special: 'Minimaal één speciaal teken bevatten.',
    New_password_requirement_maxlength: 'Maximaal 30 karakters lang.',
    New_password_requirement_not_used: 'Moet een niet eerder gekozen/gebruikt wachtwoord zijn.',
    New_password: 'Nieuw wachtwoord',
    Repeat_new_password: 'Herhaal wachtwoord',
    Password_not_unique: 'Dit wachtwoord heb je al eerder gebruikt. Kies een uniek wachtwoord.',
    Password_changed: 'Wachtwoord gewijzigd',
    Password_changed_description_setup: '<p>Je wachtwoord is succesvol gewijzigd naar het zojuist ingevoerde nieuwe wachtwoord.</p>',
    You_can_now_login_with_new_password: 'Je kunt nu inloggen met je nieuwe wachtwoord.',
    To_login: 'Naar inloggen',

    // Confirmation actions
    Please_wait: 'Moment geduld...',
    Confirmation_actions_email_verified_title: 'E-mailadres geverifieerd',
    Confirmation_actions_email_verified_description: 'Keer terug naar de Thuiszorgplanner om verder te gaan met het proces.',
    Confirmation_actions_email_verified_error_title: 'E-mailadres niet geverifieerd',
    Confirmation_actions_email_verified_error_description: 'Het opgegeven e-mailadres kon niet worden geverifieerd. Probeer het opnieuw!',
    Confirmation_actions_device_revoke_title: 'Apparaat intrekken geslaagd.',
    Confirmation_actions_device_revoke_description: 'Het aangegeven apparaat is ingetrokken en heeft geen toegang meer.',
    Confirmation_actions_device_revoke_error_title: 'Apparaat intrekken mislukt',
    Confirmation_actions_device_revoke_error_description: 'Het aangegeven apparaat kon niet worden ingetrokken. De kans bestaat dat het apparaat nog toegang heeft.',
    Confirmation_actions_Handled_or_expired_request_title: 'Aanvraag mislukt!',
    Confirmation_actions_Handled_or_expired_request_description: 'Deze aanvraag is al eerder afgehandeld of inmiddels verlopen.',

    // General
    at: 'bij',
    Yes: 'Ja',
    yes: 'ja',
    No: 'Nee',
    no: 'nee',
    Back: 'Terug',
    Next: 'Volgende',
    Previous: 'Vorige',
    Skip: 'Overslaan',
    Reset: 'Resetten',
    Change: 'Wijzigen',
    Save: 'Opslaan',
    Edit: 'Bewerken',
    Cancel: 'Annuleren',
    Abort: 'Stoppen',
    Continue: 'Doorgaan',
    Close: 'Sluiten',
    Send: 'Versturen',
    Apply: 'Toepassen',
    Read_more: 'Lees meer',
    Delete: 'Verwijderen',
    Delete_all: 'Verwijder alles',
    Other: 'Overige',
    Read: 'Gelezen',
    Unread: 'Ongelezen',
    Mark_as_read: 'Markeer als gelezen',
    Mark_all_as_read: 'Markeer alles als gelezen',
    Mark_as_unread: 'Markeer als ongelezen',
    Search: 'Zoeken',
    Not_available_at_this_moment_title: 'Op dit moment niet beschikbaar',
    Not_available_at_this_moment_description: 'Deze functionaliteit is op dit apparaat op dit moment nog niet beschikbaar. Er wordt hard aan gewerkt om dit zo snel mogelijk in orde te maken!',
    No_items: 'Geen items',
    Error_occured_title: 'Er is een fout opgetreden',
    Error_occured_description: 'Geen informatie gevonden om weer te geven.<br>Blijft dit probleem ontstaan, neem dan contact op.',
    Published_at: 'Gepubliceerd op',
    Updated_at: 'Laatst bijgewerkt op',
    Unknown: 'Onbekend',

    // Form fields
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    verificationCode: 'Verificatiecode',
    Type_your_message: 'Typ hier je bericht',
    Message: 'Bericht',
    Message_lowercase: '%field% mag alleen kleine letter bevatten.',
    Message_lowercase_1: '%field% moet minimaal één kleine letter bevatten.',
    Message_max_length: '%field% mag minimaal %chars% karakters lang zijn.',
    Message_min_length: '%field% moet minimaal %chars% karakters lang zijn.',
    Message_number: '%field% moet een getal zijn.',
    Message_number_1: '%field% moet minimaal één getal bevatten.',
    Message_special: '%field% moet speciale tekens bevatten.',
    Message_special_1: '%field% moet minimaal één speciaal teken bevatten.',
    Message_no_special: '%field% mag geen speciale tekens bevatten.',
    Message_uppercase_1: '%field% moet minimaal één hoofdletter bevatten.',
    CapsLock_on: 'De Caps Lock toets staat aan.',

    // Days
    Mon: 'ma',
    Monday: 'maandag',
    Tue: 'di',
    Tuesday: 'dinsdag',
    Wed: 'wo',
    Wednesday: 'woensdag',
    Thu: 'do',
    Thursday: 'donderdag',
    Fri: 'vr',
    Friday: 'vrijdag',
    Sat: 'za',
    Saterday: 'zaterdag',
    Sun: 'zo',
    Sunday: 'zondag',
    Greeting_morning: 'Goedemorgen',
    Greeting_afternoon: 'Goedemiddag',
    Greeting_evening: 'Goedenavond',
    today: 'vandaag',
    Today: 'Vandaag',
    tomorrow: 'morgen',
    yesterday: 'gisteren',
    days: 'dagen',
    hour: 'uur',
    hours: 'uur',
    minute: 'minuut',
    minutes: 'minuten',
    and: 'en',
    ago: 'geleden',
    Just_now: 'Zojuist',
    just_now: 'zojuist',
    Older: 'Ouder',

    // Months
    Jan: 'jan.',
    January: 'januari',
    Feb: 'feb.',
    February: 'februari',
    Mar: 'mrt.',
    March: 'Maart',
    Apr: 'apr.',
    April: 'april',
    May: 'mei',
    June: 'juni',
    July: 'juli',
    Aug: 'aug.',
    August: 'augustus',
    Sep: 'sep.',
    September: 'september',
    Oct: 'okt.',
    October: 'oktober',
    Nov: 'nov.',
    November: 'november',
    Dec: 'dec.',
    December: 'december',

    // Revoke
    Signed_off_title: 'Afgemeld',
    Signed_off_description: 'Je bent op dit apparaat afgemeld, omdat je account op een ander apparaat wordt gebruikt.',
    Log_in_again: 'Opnieuw inloggen',
    Revoked_device: 'Apparaat toegang is geblokkeerd.',

    // Menu
    Dashboard: 'Dashboard',
    Timeline: 'Planning',
    Agenda: 'Agenda',
    Clients: 'Cliënten',
    Documents: 'Documenten',
    Settings: 'Instellingen',
    Widgets: 'Dashboard widgets',

    // News
    News: 'Nieuws',
    News_item: 'Nieuwsbericht',
    News_items: 'Nieuwsberichten',
    View_all_items: 'Bekijk alle berichten',
    No_news_items: 'Geen nieuwsberichten.',
    News_item_published_at: 'Gepubliceerd door <strong>%publisher%</strong> op <strong>%datetime%</strong>',

    // Settings
    Settings: 'Instellingen',
    Advanced_settings: 'Geavanceerde instellingen',
    Settings_page_description: 'Beheer hier alle instellingen van je account en de Thuiszorgplanner.',
    Settings_page_devices_description: 'Beheer hier al jouw apparaten die aan je account zijn toegevoegd. Wanneer je een apparaat verwijderd kan deze geen acties meer uitvoeren.',
    Settings_page_notifications_description: 'Beheer zelf de meldingen die je wilt ontvangen. De notificatie instellingen zijn gebonden aan al je apparaten binnen je account.',
    Settings_page_interface_description: 'Personaliseer jouw Thuiszorgplanner ervaring. Deze instellingen zijn apparaat gebonden.',
    Settings_page_security_description: '<p>Aanvullende beveiligingsinstellingen van de app, die door jouw beheerd kunnen worden.</p><p>Het aanpassen van deze instellingen is op <strong>eigen</strong> risico!</p>',
    Settings_page_tools_description: 'Opties ter ondersteuning om zo eenvoudig mogelijk met de Thuiszorgplanner te werken.',
    Settings_page_feedback_description: 'Deel jouw ervaring met de Thuiszorgplanner. <br>Zo weten wij hoe we de Thuiszorgplanner nog beter kunnen maken.',

    // Account
    My: 'Mijn',
    Account_data: 'Accountgegevens',
    My_details: 'Mijn gegevens',
    Profile: 'Profiel',
    Data: 'Gegevens',
    Name: 'Naam',
    Insertion: 'Tussenvoegsel',
    Email: 'E-mailadres',
    Phone_number: 'Telefoonnummer',
    Phone_number_alternative: 'Alternatief telefoonnummer',
    Mobile_number: 'Mobiele nummer',
    ICE_number: 'Noodnummer',
    Financial: 'Financieel',
    Devices: 'Apparaten',
    State: 'Status',
    Last_active: 'Laatst actief',
    Current_device: 'Dit apparaat',
    Delete_device_dialog_title: 'Apparaat verwijderen',
    Delete_device_dialog_description: 'Weet je zeker dat je <strong>%name%</strong> wilt verwijderen?',
    To_profile: 'Naar je profiel',

    // Roles
    caregiver: 'Zorgverlener',

    // Company
    Company: 'Bedrijf',
    Companies: 'Bedrijven',
    Address: 'Adres',
    Postal_code: 'Postcode',
    City: 'Plaats',
    Residence: 'Plaats',
    Country: 'Land',
    Contact_data: 'Contactgegevens',
    Company_address_default_header: 'Bezoekadres',
    Company_address_post_header: 'Postadres',
    Company_contact_default_header: 'Contactgegevens',
    Company_contact_alternative_header: 'Alternatief',

    // Notifications
    Notification: 'Notificatie',
    Notifications: 'Notificaties',
    Notifications_reception: 'Berichtgeving', //Ontvangst
    Receive_notifications: 'Notificaties ontvangen',
    Unread_notifications: 'Ongelezen notificaties',
    Categories: 'Categorieën',
    //General_title: 'Standaard',
    General_title: 'Categorieën',
    General_description: 'Dit zijn de standaard aanwezige notificatie kanalen die aanwezig zijn in de Thuiszorgplanner.',
    Account_activitity_and_workflow: 'Notificaties die betrekking hebben op jouw account of activiteiten.',
    Security_warnings_description: '',
    Open_native_notifications_settings: 'Open systeem berichtgeving',
    Notifications_disable_dialog_title: 'Notificaties uitschakelen',
    Notifications_disable_dialog_description: '<p>Weet je zeker dat je notificaties op dit apparaat wilt <strong>uitschakelen</strong>?</p>',
    No_notifications: 'Je hebt geen notificaties.',
    No_unread_notifications: 'Geen ongelezen notificaties.',
    Notifications_mark_all_as_read_title: 'Notificaties markeren als gelezen?',
    Notifications_mark_all_as_read_description: 'Na bevestiging zullen alle notificaties als gelezen gemarkeerd worden.',
    Notifications_delete_all_title: 'Notificaties verwijderen?',
    Notifications_delete_all_description: 'Na bevestiging zullen alle notificaties verwijderd worden.',
    Notifications_setting_new_login_warnings: 'Nieuwe inlogpogingen',
    Notifications_setting_new_login_warnings_description: 'Brengt je op de hoogte van nieuwe inlogpogingen op je account.',
    Notifications_sound_title: 'Geluid afspelen',
    Notifications_sound_description: 'Een geluid afspelen wanneer je een nieuwe notificatie ontvangt.',
    Notifications_day_range_title: 'Notificatie dagen',
    Notifications_day_range_description: 'Op de geselecteerde dagen wordt je direct op de hoogte gesteld van nieuwe notificaties.',
    Notifications_request_new: 'Creëer nieuwe notificaties',
    Notifications_do_not_disturb_title: 'Niet storen',
    Notifications_do_not_disturb_description: 'Ontvang slechts het minimum aantal notificaties.',
    Notifications_do_not_disturb_message: '<strong>Niet storen</strong> is ingeschakeld, notificaties zijn minder aanwezig.',
    Block_login_attempt: 'Blokkeer inlogpoging',
    Login_attempt_blocked: 'Inlogpoging geblokkeerd.',
    Websockets_disconnected: '<strong>Niet verbonden</strong>.<br>Je ontvangt geen nieuwe notificaties.',
    // Testing only - needs to be removed on production
    Button_1: 'Button 1',
    Button_2: 'Button 2',
    Button_3: 'Button 3',

    // Interface
    Interface: 'Weergave',
    Dark_mode: 'Donkere modus',
    Dark_mode_description: 'Licht of donker; kies hier jou favoriete weergave.',
    Compact_menu: 'Compact zij-menu',
    Compact_menu_description: 'Zorgt ervoor dat er minder ruimte tussen de menu items is.',
    Show_shortcuts: 'Sneltoetsen weergeven',
    Show_shortcuts_description: 'Zo kun je snel naar de hoofdonderdelen navigeren.',
    Show_notices: 'Berichten weergeven',
    Show_notices_description: 'Belangrijke informatie direct en snel te zien.',
    Show_news: 'Nieuws weergeven',
    Show_news_description: 'Het laatste nieuws, zo blijf je op de hoogte.',
    Show_notifications: 'Notificaties weergeven',
    Show_notifications_description: 'Alle ongelezen notificaties direct zichtbaar.',
    Show_tips: 'Tips weergeven',
    Show_tips_description: 'Handige informatie over het gebruik van de Thuiszorgplanner.',

    // Security
    Security: 'Beveiliging',

    // Tools
    Tools: 'Hulpmiddelen',
    Open_in_browser: 'In de browser openen',
    Open_in_browser_description: 'Bij problemen met de Thuiszorgplanner, kun je hier de Thuiszorgplanner openen in de browser.',
    Settings_reset_title: 'Instellingen resetten',
    Settings_reset_description: 'Zet de instellingen terug naar de standaard instellingen.',
    Settings_reset_dialog_title: 'Instellingen resetten?',
    Settings_reset_dialog_description: '<p>Hiermee worden de instellingen van dit apparaat teruggezet naar de standaard instellingen.</p>',
    Settings_reset_thank_you_title: 'Instellingen gereset.',
    Diagnostics: 'Diagnostiek',
    Error_report: 'Foutenrapportage',
    Error_report_send_title: 'Foutenrapportage versturen',
    Error_report_send_description: 'Verstuur de rapportage naar Coolweb Zorgsoftware voor analyse.',
    Error_report_dialog_title: 'Foutenrapportage versturen?',
    Error_report_dialog_description: 'Er wordt een rapportage verstuurd naar Coolweb Zorgsoftware met technische details ter analyse.',
    Error_report_thank_you_title: 'Foutenrapportage verstuurd',
    Error_report_thank_you_description: '<p>Bedankt, wij hebben de rapportage ontvangen en gaan hiermee aan de slag.</p>',
    Error_report_error_description: '<p>Door de opgetreden fout hebben wij geen rapportage ontvangen.<br>Ververs de pagina en probeer het opnieuw.</p>',

    // Support
    Support: 'Support',
    FAQ: 'Veelgestelde vragen',
    Share_feedback: 'Feedback verzenden',
    Feedback_note: 'Dit formulier is <strong>niet</strong> voor support vragen.',
    Feedback_rating_description: 'Hoe tevreden ben je over de Thuiszorgplanner?',
    Feedback_error_description: 'Er ging iets mis bij het verzenden. Probeer het opnieuw of neem contact met ons op.',
    Feedback_success_description: 'We hebben je feedback goed ontvangen en gaan hiermee aan de slag.',
    Feedback_success_title: 'Bedankt voor je feedback!',
    Rating: 'Waardering',
    Support_knowledgebase_description: '<p>Mocht hetgeen waar je naar opzoek bent hier niet tussen staan, dan kun je voor meer informatie kijken in onze kennisbank.</p>',
    Knowledge_base: 'Kennisbank',
    To_knowledge_base: 'Naar de kennisbank',
    Contact_us: 'Contact opnemen',

    // App
    App: 'App',
    app: 'app',
    Version: 'Versie',
    Current_version: 'Huidige versie',
    About_the_app: 'Over de app',
    About_the_TZP: 'Over de Thuiszorgplanner',
    About: 'Over',
    About_intro: '<p>De <strong>Thuiszorgplanner</strong> zorgt ervoor dat thuiszorgorganisaties maar één systeem nodig hebben. De <strong>Thuiszorgplanner</strong> bevat planning & matching, cliëntadministratie, zorgverleners overzicht, facturatie- & declaratiemodule, managementoverzicht en er kan een opdrachtgeverkoppeling gemaakt worden.<br>Hiernaast zijn er verschillende portaalmogelijkheden op gebruikersniveaus, maar ook koppelingen met boekhoudprogramma\'s.</p>',
    General: 'Algemeen',
    Terms: 'Voorwaarden',
    Terms_description: '<p>In onderstaand overzicht staan de verschillende voorwaarden en verklaringen die betrekking hebben op de Thuiszorgplanner en het gebruik hiervan.</p>',
    Terms_and_conditions: 'Algemene voorwaarden',
    Privacy_statement: 'Privacy verklaring',
    Service_level_agreement: 'Service Level Agreement (SLA)',
    Disclaimer_description: '<p>Wanneer er onjuistheden in de Thuiszorgplanner mochten voorkomen, aanvaardt Coolweb Zorgsoftware B.V. daarvoor geen aansprakelijkheid, noch voor enige directe of indirecte schade die zou kunnen ontstaan door het gebruik van de hierin aangeboden informatie. Aan de in de Thuiszorgplanner opgenomen adviezen en andere informatie kunnen geen rechten of aanspraken worden ontleend.</p><p>Wij raden u aan contact op te nemen met een leidinggevende om naar de betreffende informatie te kijken. Mocht deze informatie nog steeds onjuist zijn kan de contactpersoon van uw organisatie contact opnemen met Coolweb Zorgsoftware B.V.</p>',
    Changelog: 'Changelog',
    Product_updates: 'Productupdates',
    NEN_certified: 'Gecertificeerd volgens de norm <strong>NEN-7510</strong>',

    // Tour
    Tour_welcome_title: 'De nieuwe Thuiszorgplanner',
    Tour_welcome_description: 'Er zijn vele visuele en technische vernieuwingen doorgevoerd en de stabiliteit is verbeterd. Ook is het platform geüpgrade en voldoet dit aan de laatste veiligheidseisen.',
    Tour_feedback_title: 'Feedback',
    Tour_feedback_description: 'Wij horen graag jullie mening over de vernieuwingen.<br>Zo weten wij hoe we de Thuiszorgplanner nog beter kunnen maken!',
    Tour_notifications_title: 'Notificaties',
    Tour_notifications_description: 'Blijf op de hoogte van belangrijke gebeurtenissen, zoals aankomende afspraken, in te vullen rapportages of inlogpogingen op jouw account.',

    // Afk dialog
    Afk_dialog_im_not_away: 'Ik ben er',
    Afk_dialog_are_you_still_here: 'Ben je er nog?',
    Afk_dialog_notice_description: 'Je bent al even inactief. Wij vergrendelen automatisch jouw systeem zodra je te lang weg bent. Druk op een toets om door te gaan.',

    // Untrusted domain dialog
    Untrusted_domain_setting_title: 'Externe URLs direct open',
    Untrusted_domain_setting_description: 'Toon wel of geen waarschuwing voordat de URL geopend wordt.',
    Untrusted_domain_dialog_title: 'Weet je zeker dat je deze link wilt openen?',
    Untrusted_domain_dialog_description: '<strong class="tzpOrange--text">%url%</strong> is <strong>geen</strong> onderdeel van de Thuiszorgplanner.',

    // Changelog
    v1_0_0_description: 'Het wachten is voorbij, het is eindelijk zover; de eerste release van de **nieuwe Thuiszorgplanner**. De Thuiszorgplanner is van de grond af opnieuw opgebouwd en legt de basis voor een mooie toekomst die voor ons ligt.\n### Toegevoegd\n- Apparatenbeheer; geeft je een overzicht van de gekoppelde apparaten op jouw account en deze kunnen hier nu beheren.\n- Notificaties; ontvang een notificatie bij jouw account activity op een ander apparaat, maar dit is pas het begin!\n- Feedback; deel jouw ervaring(en), zo dat wij de Thuiszorgplanner kunenn blijven door ontwikkelen met jullie steun.\n- Tour; laat nieuwe functionaliteiten en opties zijn die zijn toegevoegd aan de Thuiszorgplanner.\n- Changelog/Over de Thuiszorgplanner; updates en informatie over de wijzigingen van iedere nieuwe versie van de Thuiszorgplanner.\n### Gewijzigd\n- Login proces volledig vernieuwd.\n- Compleet vernieuwde en uitgebreide instellingen weergave.\n- Eerste stappen gezet om de Thuiszorgplanner te personaliseren.\n- Account en profiel als losse weergave neergezet, omdat dit vanaf nu aparte onderdelen gaan zijn.\n### Beveiliging\n- Stabiliteitsverbeteringen door het nieuwe TZP-X platform\n- De Thuiszorgplanner voldoet aan de laatste release standaarden voor Android en iOS.',

    // Tips & Tricks
    Tip_1_title: 'Tip 1',
    Tip_1_description: 'Beschrijving van de tip of trick, met een maximum van 1 paragraaf.<br>Is het een uitleg dan een link toevoegen naar het item!',
    Tip_2_title: 'Tip 2',
    Tip_2_description: 'Beschrijving van de tip of trick, met een maximum van 1 paragraaf.<br>Is het een uitleg dan een link toevoegen naar het item!',

    // API responses
    Error: 'Er ging iets fout.',
    Error_try_again: 'Er ging iets fout. Probeer het opnieuw.',
    Handled_or_expired_request: 'Deze aanvraag is al afgehandeld of verlopen.',
};
