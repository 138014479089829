<template>

    <div>

        <v-app-bar
        app
        color="tzpAppBar"
        dark
        elevate-on-scroll>
            
            <v-btn
            @click="$ui.navigate.back()"
            icon>
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <v-toolbar-title>{{ t($route.meta.appbar.title.text) }}</v-toolbar-title>

        </v-app-bar>

        <CustomerSupport />

    </div>

</template>

<script>
import CustomerSupport from '@/views/gated/CustomerSupport.vue'
import SupportMenu from '@/components/support/SupportMenu.vue'

export default {
    name: 'Support',

    components: { 
        CustomerSupport,
        SupportMenu
    },

    data: () => ({ }),
}
</script>