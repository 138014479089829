<template>
    
    <v-navigation-drawer
    v-model="$ui.notificationDrawer.toggled"
    app
    :class="(! $device.isNative() ? 'navigation-drawer--scrollbar' : '')"
    color="grey darken-3"
    dark
    :permanent="$vuetify.breakpoint.xl"
    :stateless="$ui.notificationDrawer.toggled && $ui.dialog.toggled || $vuetify.breakpoint.mdAndDown && $ui.notificationDrawer.toggled"
    :temporary="$vuetify.breakpoint.lgAndDown"
    right
    width="420px">
        
        <v-app-bar
        v-if="booted"
        color="grey darken-3"
        dark
        elevation="1"
        fixed>

            <v-btn
            v-if="$vuetify.breakpoint.lgAndDown"
            @click="closeDrawer()"
            icon>
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>

            <v-toolbar-title class="muli">
                {{ t("Notifications") }} <small class="grey--text lighten-1" v-if="Notifications.init">({{ Notifications.getTotalCount() }})</small>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
             v-if="Notifications.init"
            @click="$refs.notificationsManager.checkMarkAllAsRead()"
            :disabled="! this.Notifications.getUnreadCount()"
            icon>
                <v-icon size="20">mdi-email-open</v-icon>
            </v-btn>
            
           <v-btn
            v-if="Notifications.init"
            @click="$refs.notificationsManager.checkDeleteAll()"
            :disabled="! this.Notifications.getTotalCount() > 0"
            icon>
                <v-icon size="20">mdi-delete</v-icon>
            </v-btn>

        </v-app-bar>
        
        <v-app-bar
        v-if="$messaging.connectorExists('websockets') && $messaging.getConnector('websockets').getStatus() === 'disconnected'"
        color="grey darken-3"
        dark
        elevation="1"
        fixed
        height="80px"
        style="top:56px;padding-bottom:8px">

            <!-- Status -->
            <status
            color="warning"
            :dense="true"
            :description="t( 'Websockets_disconnected' )"
            icon="mdi-alert"></status>

        </v-app-bar>

        <v-card
        flat
        :class="($messaging.connectorExists('websockets') && $messaging.getConnector('websockets').getStatus() === 'disconnected' ? 'is-disconnected' : 'pt-14 pt-md-16')+ ' transparent'"
        style="position:relative">
            
            <notifications-manager ref="notificationsManager"></notifications-manager>
            
        </v-card>

    </v-navigation-drawer>

</template>

<script>
import { Notifications } from "@/components/notifications/api";
import NotificationsManager from '@/components/notifications/NotificationsManager.vue';
import Status from '@/components/Status.vue';

export default {
    name: 'Messenger',

    components: { 
        NotificationsManager,
        Status
    },

    mixins: [ Notifications.map() ],

    data: () => ({
        booted: false,
    }),

    mounted() {
        this.booted = true // needed to use $refs functions
    },

    methods: {
        closeDrawer() {
            this.$ui.notificationDrawer.hide();
            this.$refs.notificationsManager.closeNotification( true );
            this.$refs.notificationsManager.closeNotifications();
        },
    },

    watch: {
        '$ui.notificationDrawer.toggled': function(value) {
            if( value ) {
                // Disable navigationDrawer 'watchers'
                if( this.$vuetify.breakpoint.smAndDown ) {
                    this.$ui.navigationDrawer.stateless = true;
                }
                // Empty toasts
                this.$ui.notification.toasts = [];
            }
            else {
                this.$ui.navigationDrawer.stateless = false;
                this.closeDrawer();
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.is-disconnected {
    padding-top: 136px;

    @media #{map-get($display-breakpoints, 'md-and-up')} { 
        padding-top: 144px;
    }
}
</style>