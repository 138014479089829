var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"eager":"","fullscreen":_vm.$vuetify.breakpoint.xs ? true : false,"hide-overlay":_vm.$vuetify.breakpoint.xs ? true : false,"max-width":_vm.$vuetify.breakpoint.xs ? 'none' : '500px',"persistent":"","transition":false},model:{value:(_vm.toggled),callback:function ($$v) {_vm.toggled=$$v},expression:"toggled"}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"flat":"","rounded":(_vm.$vuetify.breakpoint.xs ? '0' : 'tzp')}},[_c('v-btn',{staticClass:"pos-top pos-top--right",attrs:{"icon":""},on:{"click":function($event){return _vm.hide()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-window',{class:_vm.$vuetify.breakpoint.xs ? '0' : 'rounded-t-tzp',model:{value:(_vm.slideActive),callback:function ($$v) {_vm.slideActive=$$v},expression:"slideActive"}},_vm._l((_vm.slides),function(slide,index){return _c('v-window-item',{key:'card-'+index,style:('background-color:'+( _vm.$prefs.settings.darkModeEnabled ? '#1e1e1e' : '#e2e5f8' ))},[_c('v-card',{class:_vm.$vuetify.breakpoint.xs ? 'mt-6' : '',attrs:{"color":"transparent","flat":"","max-width":_vm.$vuetify.breakpoint.xs ? 'none' : '500px'}},[_c('v-img',{style:('height:'+( _vm.$vuetify.breakpoint.xs ? '100vw' : '500px' )),attrs:{"src":slide.img.src}})],1),_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('card-content',{attrs:{"buttons":{
                        0:{
                            label: _vm.t('Close'),
                            onClick: {
                                action: 'hide', // name of the function we want to trigger,
                                type: 'function'
                            },
                            style: {
                                color: 'tzpPurple',
                            },
                        }
                    },"center":"all","content":_vm.t(slide.description),"noPadding":false,"showActions":index == _vm.slides.length - 1 ? true : false,"title":_vm.t( slide.title )},on:{"handleBtnFunction":function($event){return _vm.btnFunction( $event )}}})],1)],1)}),1),_c('v-spacer'),_c('v-card-actions',[_c('v-item-group',{staticClass:"my-0 mx-auto",attrs:{"mandatory":""},model:{value:(_vm.slideActive),callback:function ($$v) {_vm.slideActive=$$v},expression:"slideActive"}},_vm._l((_vm.slides),function(slide,index){return _c('v-item',{key:'btn-'+index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"icon":"","input-value":active},on:{"click":toggle}},[_c('v-icon',{attrs:{"color":active ? 'secondary' : undefined}},[_vm._v(" mdi-record ")])],1)]}}],null,true)})}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }