<template>
    
    <v-text-field
    @keydown="checkKey"
    @keyup="checkKey"
    @input="$emit('input', $event)"
    @mousedown="checkKey"
    :autocomplete="autocomplete"
    :color="color"
    :error-messages="errors"
    :hint="hint"
    :label="label"
    outlined
    :success="success"
    :tabindex="tabindex"
    type="password"
    :value="password"
    :class="'textfield--password-append'"></v-text-field>

</template>

<script>
export default {
    name: 'PasswordField',

    props: {
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
        autocomplete: {
            type: String,
            default: 'on'
        },
        color: {
            type: String,
            default: 'primary'
        },
        errors: {
            type: Array|Object,
            required: true,
        },
        label: {
            type: String,
            required: true
        },
        password: {
            type: String,
            default: '',
        },
        success: {
            type: Boolean,
            default: false,
        },
        tabindex: {
            type: Number|String,
            default: 2
        },
    },

    data: () => ({
        hint: '',
    }),

    methods: {
        checkKey( event ) {
            this.checkCapsLock(event);
        },
        checkCapsLock( e ) {
            let capsLockOn = e.getModifierState('CapsLock');
            
            if ( capsLockOn ) {
                this.hint = this.t('CapsLock_on');
            }
            else {
                this.hint = '';
            }
        }
    }
}
</script>