<template>
        
    <v-dialog
    v-model="toggled"
    eager
    :fullscreen="$vuetify.breakpoint.xs ? true : false"
    :hide-overlay="$vuetify.breakpoint.xs ? true : false"
    :max-width="$vuetify.breakpoint.xs ? 'none' : '500px'"
    persistent
    :transition="false">
        
        <v-card
        flat
        :rounded="($vuetify.breakpoint.xs ? '0' : 'tzp')"
        class="d-flex flex-column">
            
            <v-btn
            @click="hide()"
            icon
            class="pos-top pos-top--right">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            
            <v-window 
            v-model="slideActive"
            :class="$vuetify.breakpoint.xs ? '0' : 'rounded-t-tzp'">
                
                <v-window-item 
                v-for="(slide, index) in slides"
                :key="'card-'+index"
                :style="'background-color:'+( $prefs.settings.darkModeEnabled ? '#1e1e1e' : '#e2e5f8' )">
                    
                    <v-card
                    color="transparent"
                    flat
                    :max-width="$vuetify.breakpoint.xs ? 'none' : '500px'"
                    :class="$vuetify.breakpoint.xs ? 'mt-6' : ''">
                        
                        <v-img
                        :src="slide.img.src"
                        :style="'height:'+( $vuetify.breakpoint.xs ? '100vw' : '500px' )"></v-img>

                    </v-card>

                    <v-divider></v-divider>

                    <v-card flat>
                        
                        <card-content
                        @handleBtnFunction="btnFunction( $event )"
                        :buttons="{
                            0:{
                                label: t('Close'),
                                onClick: {
                                    action: 'hide', // name of the function we want to trigger,
                                    type: 'function'
                                },
                                style: {
                                    color: 'tzpPurple',
                                },
                            }
                        }"
                        center="all"
                        :content="t(slide.description)"
                        :noPadding="false"
                        :showActions="index == slides.length - 1 ? true : false"
                        :title="t( slide.title )"></card-content>

                    </v-card>

                </v-window-item>

            </v-window>

            <v-spacer></v-spacer>

            <v-card-actions>
                
                <v-item-group
                v-model="slideActive"
                mandatory
                class="my-0 mx-auto">
                    
                    <v-item
                    v-for="(slide, index) in slides"
                    v-slot:default="{ active, toggle }"
                    :key="'btn-'+index">
                        
                        <v-btn
                        @click="toggle"
                        icon
                        :input-value="active">
                            
                            <v-icon :color="active ? 'secondary' : undefined">
                                mdi-record
                            </v-icon>
                            
                        </v-btn>

                    </v-item>

                </v-item-group>

            </v-card-actions>
            
        </v-card>

    </v-dialog>

</template>

<script>
import CardContent from '@/components/CardContent'

export default {
    name: 'Tour',

    components: { 
        CardContent
    },

    data: () => ({
        slideActive: 0,
        slides: [],
        toggled: false,
    }),

    created() {
        this.$eventBus.$off('showTour');
        this.$eventBus.$on('showTour', ( path ) => {
            if( path ) {
                this.showByPath( path )
            }
            else {
                this.show()
            }
        })
        this.$eventBus.$off('hideTour');
        this.$eventBus.$on('hideTour', () => {
            this.hide()
        })
    },

    mounted() {
        this.create()
    },

    watch: {
        $route(to, from) {
            if( to.path in this.read() && this.$prefs.settings.tour.slidesShown[to.path] != true ) {
                this.showByPath( to.path )
            }
        }
    },

    methods: {
        btnFunction( methodName ) {
            this[methodName](); // local method to handle
        },
        read() {
            // All slideshow options for the Tour we have
            return {
                // Route specific
                '/dashboard': [
                    {
                        description: 'Tour_welcome_description',
                        img: {
                            src: 'img/tour/welcome.png',
                        },
                        title: 'Tour_welcome_title',
                    },
                    {
                        description: 'Tour_notifications_description',
                        img: {
                            src: 'img/tour/notifications.png',
                        },
                        title: 'Tour_notifications_title',
                    },
                    {
                        description: 'Tour_feedback_description',
                        img: {
                            src: 'img/tour/feedback.png',
                        },
                        title: 'Tour_feedback_title',
                    },
                ],
                // Full Tour
                '/full': [
                    {
                        description: 'Tour_welcome_description',
                        img: {
                            src: 'img/tour/welcome.png',
                        },
                        title: 'Tour_welcome_title',
                    },
                    {
                        description: 'Tour_notifications_description',
                        img: {
                            src: 'img/tour/notifications.png',
                        },
                        title: 'Tour_notifications_title',
                    },
                    {
                        description: 'Tour_feedback_description',
                        img: {
                            src: 'img/tour/feedback.png',
                        },
                        title: 'Tour_feedback_title',
                    },
                ]
            }
        },
        create( path ) {
            // All slides
            let allSlides = this.read()

            // Create slideshow based on defined key
            if( path ) {
                this.show()
                this.slides = allSlides[path]
            }
            // Check if the path exists in the slides and is not seen before
            else if( this.$route.path in allSlides && this.$prefs.settings.tour.slidesShown[this.$route.path] != true ) {
                this.show()
                this.slides = allSlides[this.$route.path]
            }
        },
        hide() {
            if( this.toggled == true ) {
                this.toggled = false
                this.slideActive = 0; // Reset active slide to first slide
                
                // Mark the path in the Tour settings as seen
                if( this.$route.path in this.read() ) {
                    Object.assign(this.$prefs.settings.tour.slidesShown, { [this.$route.path]: true });
                    this.$prefs.save();
                }
            }
        },
        show() {
            this.toggled = true
        },
        showByPath( param ) {
            if( param ) {
                this.create( param )
                this.show()
            }
        },
    },
}
</script>