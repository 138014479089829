<template>

    <div v-if="model">

        <slot></slot>

        <slot 
        v-if="loading"
        name="loader">

            <v-skeleton-loader
            type="list-item-two-line@3"
            class="pa-3"></v-skeleton-loader>
            
        </slot>
        
        <div
        v-intersect="onIntersect"
        style="height:10px"></div>

    </div>

</template>

<script>
export default {
    name: 'LoadMore',

    props: {
        batchAmount: {
            type: Number,
            default: 5
        },
        model: {
            type: Object,
            required: true
        },
    },

    data: () => ({
        loading: false,
        intersectCounter: 0
    }),

    methods: {
        onIntersect() {
            if( ! this.loading && this.intersectCounter > 0 ) {
                this.loading = true;
                let itemsCount = this.model.read().count();
                let meta = this.model.read().getMeta();

                if( itemsCount < meta.total_items ) {
                    this.model.loadNextBatch( this.batchAmount ).then( () => {
                        this.loading = false;  
                    });

                    this.intersectCounter++;
                }
                else {
                    this.loading = false;
                }
            }

            if( this.intersectCounter == 0 ) {
                this.intersectCounter++;
            }
        },
    },
}
</script>