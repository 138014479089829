<template>
    
    <div>

        <v-card 
        rounded="tzp"
        :class="'profile mb-4'+($vuetify.breakpoint.smAndDown ? ' mt-12' : ' mt-9')">
            
            <template v-if="User.init && User.getFullnameInitials() && Companies.init">
                
                <avatar-profile-initials
                :initials="User.getFullnameInitials()"
                :subtitle="t( User.getActiveRole() ) +' '+ Companies.all()[0].name"
                :title="User.full_name"></avatar-profile-initials>
                
            </template>

            <div 
            v-else 
            class="profile-wrap px-4">
                
                <div class="profile-avatar text-center mb-4">

                    <v-skeleton-loader type="avatar"></v-skeleton-loader>
                    
                </div>

                <v-skeleton-loader type="sentences"></v-skeleton-loader>

            </div>

            <contact-data></contact-data>
            
        </v-card>

        <v-card rounded="tzp">

            <companies-list></companies-list>

        </v-card>

        <v-card 
        rounded="tzp"
        class="mt-4">
            
            <v-list one-line>

                <v-list-item to="/settings/account">
                    
                    <v-list-item-avatar>

                        <v-icon color="tzpOrange">mdi-account</v-icon>

                    </v-list-item-avatar>

                    <v-list-item-content>
                        
                        <v-list-item-title>{{ t("Account") }}</v-list-item-title>

                    </v-list-item-content>

                    <v-list-item-action>

                        <v-icon>mdi-chevron-right</v-icon>

                    </v-list-item-action>

                </v-list-item>

            </v-list>

        </v-card>

    </div>

</template>

<script>
import AvatarProfileInitials from "@/components/avatars/profile/Initials"
import CompaniesList from "@/components/companies/Companies"
import { Companies } from "@/components/companies/api"
import ContactData from "@/components/user/Contact"
import { User } from "@/components/user/api"

export default {
    name: "Profile",

    mixins: [ 
        User.map(), 
        Companies.map(),
    ],

    components: {
        AvatarProfileInitials,
        CompaniesList,
        ContactData,
    },

    data: () => ({ }),

    mounted() { },
};
</script>