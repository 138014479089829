// import VeeValidate, { Validator } from 'vee-validate'
import VeeValidate from 'vee-validate'
import Preferences from './Preferences.service'
import Device from './Device.service'
import Vue from 'vue'

//Validation
// Vue.use(VeeValidate, {
//     mode: 'lazy',
//     classes: true
// });

// We need a vue instance to handle reactivity
var vm = null;

// The plugin
const VueTranslate = {

    // Install the method
    install(Vue) {
        const version = Vue.version[0];

        if (!vm) {
            vm = new Vue({
                data() {
                    return {
                        current: '',
                        locales: {}
                    };
                },

                computed: {
                    // Current selected language
                    lang() {
                        return this.current;
                    },

                    // Current locale values
                    locale() {
                        if (!this.locales[this.current])
                            return null;

                        return this.locales[this.current];
                    }
                },

                methods: {
                    // Set a language as current
                    setLang(val) {
                        if (this.current !== val) {
                            if (this.current === '') {
                                this.$emit('language:init', val);
                            } else {
                                this.$emit('language:changed', val);
                            }
                        }

                        this.current = val;

                        this.$emit('language:modified', val);
                    },

                    // Set a locale tu use
                    setLocales(locales) {
                        if (!locales)
                            return;

                        let newLocale = Object.create(this.locales);

                        for (let key in locales) {
                            if (!newLocale[key])
                                newLocale[key] = {};

                            Vue.util.extend(newLocale[key], locales[key]);
                        }

                        this.locales = Object.create(newLocale);

                        this.$emit('locales:loaded', locales);
                    },

                    text(t) {
                        if (!this.locale || !this.locale[t]) {
                            return t;
                        }

                        return this.locale[t];
                    },

                    textWithParams(t, params = null) {

                        Object.keys(params).forEach((key) => {
                            t = t.replace(`%${key}%`, params[key]);
                        });

                        return t;
                    }
                }
            });

            Vue.prototype.$translate = vm;
        }

        // Mixin to read locales and add the translation method and directive
        Vue.mixin({
            [version === '1' ? 'init' : 'beforeCreate']() {
                this.$translate.setLocales(this.$options.locales);
            },

            methods: {
                // An alias for the .$translate.text method
                t(t) {
                    return this.$translate.text(t);
                },

                tWithParams(t, params) {
                    return this.$translate.textWithParams(this.$translate.text(t), params);
                }
            },

            directives: {
                translate: function (el) {
                    if (!el.$translateKey)
                        el.$translateKey = el.innerText;

                    let text = this.$translate.text(el.$translateKey);

                    el.innerText = text;
                }.bind(vm)
            }
        });

        // Global method for loading locales
        Vue.locales = (locales) => {
            vm.$translate.setLocales(locales);
        };

        // Global method for setting languages
        Vue.lang = (lang) => {
            vm.$translate.setLang(lang);
        };
    }
};

if (typeof exports === 'object') {
    module.exports = VueTranslate; // CommonJS
} else if (typeof define === 'function' && define.amd) {
    define([], function () { return VueTranslate; }); // AMD
} else if (window.Vue) {
    window.VueTranslate = VueTranslate; // Browser (not required options)
    Vue.use(VueTranslate);
}

Vue.use(VueTranslate);

//Imported language files
import { dutch } from '@/languages/dutch'
import nl from 'vee-validate/dist/locale/nl.json'
import { localize } from 'vee-validate'

const Language = {

    defaultLanguageCode: 'nl_NL',
    languages: {
        nl_NL: dutch,
        nl: dutch //alias of nl_NL
    },

    validationLanguages: {
        nl_NL: {
            name: 'nl',
            lib: nl
        },
        nl: {
            name: 'nl',
            lib: nl
        }, //alias of nl_NL
    },

    construct: function (callback) {

        Vue.locales(this.languages);

        //Has a language been saved in settings?
        if (Preferences.settings.languageCode !== null) {

            let l = Preferences.settings.languageCode;

            //Language still available?
            if (typeof this.languages[l] != 'undefined') {
                
                //Restore from settings
                this.setLanguageByLanguageCode(Preferences.settings.languageCode, true);
            }
            else {

                //Reset to default
                this.setLanguageByLanguageCode(this.defaultLanguageCode);
            }
        }
        else {

            //Decide language based on device language
            let l = Device.languageCode.replace('-', '_');

            if (typeof this.languages[l] != 'undefined') {

                this.setLanguageByLanguageCode(this.languages[l].language_code);
            }
            else {

                //Or fall back
                this.setLanguageByLanguageCode(this.defaultLanguageCode);
            }
        }

        (callback ? callback() : null);
    },

    setLanguageByLanguageCode(languageCode, noSave) {

        let validationData = this.validationLanguages[languageCode];

        // Add our language file to the attributes of Vee-validate.
        validationData.lib.attributes = dutch;

        Vue.prototype.$translate.setLang(languageCode);
        //Validator.localize(validationData.name, validationData.lib);
        localize(validationData.name, validationData.lib);
        Preferences.settings.languageCode = languageCode;

        (noSave !== true ? Preferences.save() : null);
    }
}

export default Language;