import Auth from '@/system/services/Auth.service'
import Device from '@/system/services/Device.service'
import Session from '@/system/services/Session.service'
import { store } from '@/system/store/store'
import Time from '@/system/helpers/Time'
import Ui from '@/system/services/Ui.service'
import UrlInterceptor from '@/system/helpers/UrlInterceptor'
import '@/system/services/Debug.service';

const Proxy = {

    browser: null,
    browserOptionsAndroid: 'location=no,hidden=yes,zoom=no,mediaPlaybackRequiresUserAction=yes,fullscreen=no',
    browserOptionsiOS: 'location=no,hidden=yes,toolbar=no,mediaPlaybackRequiresUserAction=yes',
    running: false,
    resumable: false,
    lastAuthAt: 0,

    open ( url ) {

        if ( typeof cordova != 'undefined' && typeof cordova.InAppBrowser != 'undefined' ) {

            Ui.overlay.show( true );

            //Do we need to authenticate?
            if ( this.lastAuthAt < Time.now() ) {

                //Make sure we have a valid at
                Auth.refresh().then( () => {

                    const options = ( Device.os === 'android' ? this.browserOptionsAndroid : this.browserOptionsiOS )

                    this.browser = cordova.InAppBrowser.open(
                        'https://dev.mijn.thuiszorgplanner.nl/?login/validateJWT/' + Auth.accessToken,
                        '_blank',
                        options
                    );

                    //After authentication has been loaded
                    this.browser.addEventListener( 'loadstop', () => {
                        //Open intended URL
                        this.lastAuthAt = Time.now() + 600; //Require re-authentication after +10mins
                        this.openIntentURL( url );
                    } );
                },
                    ( error ) => {

                        Ui.overlay.hide();
                        Ui.error.show( 'Er ging iets fout. Sluit de app en probeer het opnieuw.' + error );
                    } );
            }
            else {
                this.openIntentURL( url );
            }
        }
        else {

            if ( !Device.isNative() ) {

                const dialogParams = {
                    buttons: [
                        {
                            text: 'Close',
                            click: () => {
                                Ui.dialog.hide()
                            }
                        },
                    ],
                    description: 'Not_available_at_this_moment_description',
                    icon: {
                        animate: 'animate__headShake',
                        color: 'info',
                        key: 'mdi-cloud-alert',
                    },
                    textCenter: true,
                    title: 'Not_available_at_this_moment_title'
                }

                Ui.dialog.show( dialogParams );
            }

            Debug.warn( 'Proxy not available. Proposed location: ' + this.parseURL( url ) );
        }
    },

    startSession () {

        if ( typeof cordova != 'undefined' && typeof cordova.InAppBrowser != 'undefined' ) {

            if ( !this.running ) {

                if ( this.lastAuthAt < Time.now() ) {
                    const options = ( Device.os === 'android' ? this.browserOptionsAndroid : this.browserOptionsiOS );

                    this.browser = cordova.InAppBrowser.open(
                        'https://dev.mijn.thuiszorgplanner.nl/?login/validateJWT/' + Auth.accessToken,
                        '_blank',
                        options
                    );

                    //After authentication has been loaded
                    this.browser.addEventListener( 'loadstop', () => {
                        this.lastAuthAt = Time.now() + 1800; //Require re-authentication after +10mins
                    } );
                }

            }
        }
        else {
            Debug.warn( 'Proxy not available.' );
        }
    },

    openIntentURL ( url ) {
        let options = ( Device.os === 'android' ? this.browserOptionsAndroid : this.browserOptionsiOS );
        options = options + ',beforeload=yes';

        this.browser = cordova.InAppBrowser.open(
            this.parseURL( url ),
            '_blank',
            options
        );

        this.browser.addEventListener( 'beforeload', this.beforeloadCallBack );

        this.browser.addEventListener( 'loadstop', () => {

            Ui.overlay.hide( true );
            this.browser.show();

            //Resume normal browse activities
            this.browser.addEventListener( 'loadstart', this.loadStartCallBack );
            this.browser.addEventListener( 'loadstop', this.loadStopCallBack );
            this.browser.addEventListener( 'loaderror', this.loadErrorCallBack );
            this.browser.addEventListener( 'exit', this.exitCallBack );
            this.browser.addEventListener( 'message', this.messageCallBack );

            this.browser.executeScript( {
                code: 'document.addEventListener("touchstart", () => { var messageObj = {tzp: "activity"}; var stringifiedMessageObj = JSON.stringify(messageObj); webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj); }, false);document.getElementById("closeInAppBrowser").addEventListener("touchstart", () => { var messageClose = "closeBrowser"; var messageCloseObj = {tzpAction: "closeBrowser"}; var stringifiedMessageCloseObj = JSON.stringify(messageCloseObj); webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageCloseObj); }, false);'
            } );

            // Insert CSS - assets/css/inappbrowser.css
            // this.browser.insertCSS( {
            //     code: ''
            // } );

            this.running = true;
        } );
    },

    parseURL ( url ) {
        //Parse the URL to the correct format

        let User = store.getters.getStore( 'User' );
        return url.replace( '{{proxy_user_type_id}}', User.proxy_user_type_id );
    },

    messageCallBack ( params ) {
        //Handle event listeners from the browser window.

        if ( params.data.tzpAction == 'closeBrowser' ) {
            Proxy.browser.close();

            Proxy.running = false;
        }

        Session.measureActivity();
    },

    show () {
        //Displays the browser window that was opened hidden.

        Proxy.browser.show();
    },

    resume () {

        if ( Proxy.running ) {

            if ( Proxy.resumable ) {

                Proxy.browser.show();
                Proxy.resumable = false;
            }
        }
    },

    hide ( r ) {
        //Hides the browser window.

        if ( Proxy.running ) {

            Ui.overlay.hide();

            if ( r ) {

                Proxy.resumable = true;
            }
            else {

                Proxy.resumable = false;
            }

            Proxy.browser.hide();
        }
    },

    close () {
        //Closes the browser window.

        Proxy.browser.close();
    },

    exitCallBack () {
        //Gets called when the window is closed.

        Proxy.running = false;
        Ui.overlay.hide();
    },

    loadStartCallBack () {
        //Gets called on every page load.

        Ui.overlay.show( true );
    },

    loadStopCallBack () {
        //Gets called after every page load.

    },

    loadErrorCallBack () {
        //Gets called when a error occured while loading

        Ui.overlay.hide();
        Ui.error.show( 'Er ging iets fout. Probeer het opnieuw.' );
    },

    beforeloadCallBack ( params, callback ) {
        //Gets called before loading the URL into the browser window

        if ( params.url.startsWith( 'https://maps.google.com' ) || params.url.startsWith( 'https://www.google.com/maps/' ) ) {
            Debug.log( `open a location on Google Maps` );
            window.location.href = params.url;
        }
        else if ( UrlInterceptor.checkAllowedDomains( params.url ) ) {
            Debug.log( `${ params.url } is a allowed domain` );
            callback( params.url );
        }
        else {
            Debug.error( `${ params.url } is not a allowed domain` );
        }
    },
}

export default Proxy;