<template>

    <v-timeline
    v-if="items && ! $vuetify.breakpoint.xs"
    :align-top="alignTop"
    :dense="true">

        <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        :color="'color' in item ? item.color : 'grey'"
        :icon="'icon' in item ? item.icon : undefined"
        small>

            <h2 :class="('color' in item ? item.color : 'grey')+'--text'">
                {{ item.title }}
                <small class="ml-1"> {{ item.date }}</small>
            </h2>

            <div 
            v-html="item.description"
            class="pt-3"></div>

        </v-timeline-item>

    </v-timeline>

    <div v-else>

        <template v-for="(item, index) in items">

            <div :key="item.title">

                <h3 :class="('color' in item ? item.color : 'grey')+'--text'">
                    {{ item.title }}
                    <small class="ml-1"> {{ item.date }}</small>
                </h3>

                <div 
                v-html="item.description"
                :class="items.length - 1 != index ? 'py-3': 'pt-3'"></div>

            </div>

            <v-divider
            v-if="items.length - 1 != index"
            :key="index"
            class="pb-3"></v-divider>

        </template>

    </div>

</template>

<script>
export default {
    name: 'Timeline',

    props: {
        alignTop: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        items: [
            {
                date: '28-07-2021',
                description: '<p>Hasellus dolor dui, pellentesque eget efficitur ut, laoreet quis eros. Ut placerat justo at molestie finibus. Aliquam ut facilisis ex. Nunc feugiat mattis sapien sit amet lacinia. Integer ornare tempus ligula, quis dapibus mauris auctor id.</p>',
                title: 'Update 1',
            },
            {
                color: 'tzpPurple',
                date: '12-01-2022',
                description: '<p>Hasellus dolor dui, pellentesque eget efficitur ut, laoreet quis eros. Ut placerat justo at molestie finibus. Aliquam ut facilisis ex. Nunc feugiat mattis sapien sit amet lacinia. Integer ornare tempus ligula, quis dapibus mauris auctor id.</p>',
                title: 'Beta release',
            },
            {
                color: 'tzpOrange',
                date: '25-03-2022',
                description: '<p>Hasellus dolor dui, pellentesque eget efficitur ut, laoreet quis eros. Ut placerat justo at molestie finibus. Aliquam ut facilisis ex. Nunc feugiat mattis sapien sit amet lacinia. Integer ornare tempus ligula, quis dapibus mauris auctor id.</p>',
                title: 'Milestone 1',
            }
        ]
    })
}
</script>