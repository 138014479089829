/**
 * FAQ API 
 * 
 * Contains API and Resource calls
 */
//import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

/* API */
export const faqs = {

}


/* RESOURCES */

export const FAQCategories = new Resource( {
    location: 'community/faqs/categories',
    state: 'FAQCategories',
    persist: true,
    autoFill: false,
    authenticate: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {

    } ),
    entity: new Entity( {

    } )
} )

export const FAQs = new Resource( {
    location: 'community/faqs',
    state: 'FAQs',
    persist: true,
    autoFill: false,
    authenticate: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {
    } ),
    entity: new Entity( {

    } )
} )

export const FAQ = new Resource( {
    location: 'community/faqs/{{uuid}}',
    state: 'FAQ',
    persist: false,
    autoFill: false,
    authenticate: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {

    } ),
    entity: new Entity( {

    } )
} )