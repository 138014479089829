var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',[_c('v-app-bar',{staticClass:"no-transition--background-color",attrs:{"app":"","color":_vm.color,"dark":"","elevation":_vm.isIntersecting ? 0 : 3}},[(_vm.$vuetify.breakpoint.smAndDown && _vm.$route.path === '/dashboard')?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$ui.navigationDrawer.toggle()}}}):(_vm.$route.meta.appbar.back || (_vm.$vuetify.breakpoint.smAndDown && _vm.$route.path !== '/dashboard'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$ui.navigate.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1):_vm._e(),(_vm.title)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$vuetify.breakpoint.smAndDown ? _vm.t(_vm.$route.name) : _vm.t(_vm.data.title.text)))]):_vm._e(),_c('v-spacer'),(!_vm.$vuetify.breakpoint.xs)?_c('div',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,3240048398)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{attrs:{"to":"/customer-support"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-lifebuoy")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('FAQ')))])],1),_c('v-list-item',{attrs:{"href":"https://help.thuiszorgplanner.nl/","target":"_blank"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-file-question-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('Knowledge_base')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$eventBus.$emit('showTour', '/full')}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-gift-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('Product_updates')))])],1),_c('v-list-item',{attrs:{"to":"/feedback"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-message-text-outline")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.t('Feedback')))])],1)],1)],1)],1):_vm._e(),((_vm.$vuetify.breakpoint.smAndUp && _vm.$vuetify.breakpoint.xl === false) || (_vm.$vuetify.breakpoint.smAndDown && _vm.$route.path === '/dashboard'))?_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){return _vm.$ui.notificationDrawer.toggle()}}},[(_vm.Notifications.init && _vm.Notifications.getUnreadCount() > 0)?_c('v-badge',{attrs:{"color":"tzpOrange","content":_vm.Notifications.getUnreadCount() > 99 ? '99+' : _vm.Notifications.getUnreadCount(),"overlap":""}},[_c('v-icon',{staticClass:"icon--unread"},[_vm._v("mdi-bell")])],1):_c('v-icon',[_vm._v("mdi-bell")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.xs && _vm.User.init && _vm.Companies.init)?_c('div',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"tzpAppBar","icon":""}},on),[(_vm.User.getFullnameInitials())?[_c('avatar-initials',{attrs:{"initials":_vm.User.getFullnameInitials(),"size":32}})]:_c('v-skeleton-loader',{attrs:{"type":"avatar"}})],2)]}}],null,false,3164734334)},[_c('v-card',{attrs:{"max-width":"340px"}},[(_vm.User.getFullnameInitials())?[_c('avatar-list-initials',{attrs:{"initials":_vm.User.getFullnameInitials(),"subtitle":_vm.t( _vm.User.getActiveRole() ) +' '+ _vm.Companies.all()[0].name,"title":_vm.User.full_name}})]:_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}}),_c('div',{staticClass:"ml-15"},[_c('v-btn',{staticClass:"mt-n3",attrs:{"color":"primary","small":"","text":"","to":"/settings/account"}},[_vm._v(" "+_vm._s(_vm.t('Account'))+" ")]),_c('v-btn',{staticClass:"mt-n3",attrs:{"color":"primary","small":"","text":"","to":"/profile"}},[_vm._v(" "+_vm._s(_vm.t('Profile'))+" ")])],1),_c('v-divider'),_c('v-card',{staticClass:"d-flex flex-row-reverse",attrs:{"flat":"","tile":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"grey","fab":"","icon":"","small":""},on:{"click":function($event){return _vm.$eventBus.$emit('logout')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-power")])],1)]}}],null,false,1558714280)},[_c('span',[_vm._v(_vm._s(_vm.t("Logout")))])]),_c('lock-icon',{attrs:{"classes":"ma-1","variant":"outline","to":"/lock","tooltipPos":"bottom"}})],1)],2)],1)],1):_vm._e()],1),(_vm.data.intersector)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}],staticStyle:{"position":"absolute","top":"30px"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }