<template>
    
    <v-toolbar
    v-if="visible"
    flat
    :height="($vuetify.breakpoint.mdAndUp ? '48px' : '40px')"
    :class="($vuetify.breakpoint.mdAndUp ? 'mt-16 navigation-drawer--permanent' : 'mt-14')">
        <v-breadcrumbs 
        :items="items"
        class="pl-0 text-uppercase">
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                :href="item.href"
                :disabled="item.disabled"
                class="caption">
                    {{ item.text }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        
    </v-toolbar>

</template>

<script>
export default {
    name: 'Breadcrumbs',

    props: {
        data: {
            type: Object
        }
    },

    data: () => ({
        items: [],
        visible: false,
    }),

    mounted() {
        this.checkVisibility(this.data)
    },

    watch: {
        data: function (val, oldVal) {
            this.checkVisibility(val)
        },
    },

    methods: {
        checkVisibility(data) {
            if( typeof data != 'undefined' && 'show' in data ) {
                this.visible = this.$ui.breadcrumbs.show = data.show
                if( this.visible ) {
                    this.createBreadcrumbs()
                }           
            }
            else { // reset to be sure
                this.visible = this.$ui.breadcrumbs.show = false
            }
        },

        createBreadcrumbs() {
            // Route items
            let pathArray = this.$route.path.split('/')
            pathArray.shift()
            let breadcrumbs = pathArray.reduce((breadcrumbsArray, path, id) => {
                let text = this.t(pathArray[id][0].toUpperCase() + pathArray[id].slice(1).replaceAll('-',' '))
                if( typeof this.data != 'undefined' && 'items' in this.data && id in this.data.items ) {
                    text = this.t(this.data.items[id])
                }
                breadcrumbsArray.push({
                    disabled: (id === pathArray.length - 1 ? true : false),
                    href: '/' + path,
                    text: text,
                });

                return breadcrumbsArray
            }, [])

            // Dashboard item
            if( typeof this.data != 'undefined' && 'dashboard' in this.data && this.data.dashboard ) {
                let breadcrumbDashboard = {
                    disabled: false,
                    href: '/dashboard',
                    text: this.t('Dashboard')
                }
                breadcrumbs.unshift(breadcrumbDashboard);
            }

            this.items = breadcrumbs
        },
    },
}
</script>

<style lang="scss">
.v-toolbar {
    &.navigation-drawer--permanent { padding-left: 270px }
}
</style>>