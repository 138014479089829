import { Plugins } from '@capacitor/core'
import Storage from '@/system/services/Storage.service'

const Device = {

    uuid: '',
    online: true,
    platform: '',
    model: '',
    languageCode: '',
    osVersion: '',
    notificationsChannels: '',

    construct: async function ( config ) {
        const capacitorDeviceInfo = await Plugins.Device.getInfo();
        //const capacitorDeviceBatteryInfo = await Plugins.Device.getBatteryInfo();

        let languageCode = await Plugins.Device.getLanguageCode();
        let network = await Plugins.Network.getStatus();
        let storedDevice = await Storage.read( 'device' );

        this.online = network.connected;
        ( network.connected ? ( config.on.online ? config.on.online() : null ) : ( config.on.offline ? config.on.offline() : null ) );

        this.connectionType = network.connectionType;
        this.languageCode = languageCode.value;
        this.install_id = capacitorDeviceInfo.uuid;
        this.uuid = typeof storedDevice != 'undefined' ? storedDevice.uuid : null;
        this.os = capacitorDeviceInfo.operatingSystem;
        this.platform = capacitorDeviceInfo.platform;
        this.model = capacitorDeviceInfo.model;
        this.osVersion = capacitorDeviceInfo.osVersion;

        // Improved device naming for iOS and Android 7.1 and up
        if ( this.os === 'ios' && typeof capacitorDeviceInfo.name !== 'undefined' ) {
            this.model = capacitorDeviceInfo.name + ' (' + this.model + ')';
        }
        else if ( this.osVersion.indexOf( 'Android' ) >= 0 ) {
            let numericVersion = this.osVersion.replace( 'Android', '' ).replace( /\s/g, "" );
            this.osVersion = numericVersion; // only the numeric part

            if ( Number.parseFloat( this.osVersion ).toFixed( 1 ) >= parseFloat( 7.1 ) && typeof capacitorDeviceInfo.name !== 'undefined' ) {
                this.model = capacitorDeviceInfo.name + ' (' + this.model + ')';
            }
        }

        //this.batteryLevel = capacitorDeviceBatteryInfo.batteryLevel;
        //this.isCharging = capacitorDeviceBatteryInfo.isCharging;

        Plugins.Network.addListener( 'networkStatusChange', ( status ) => {

            if ( status.connected ) {
                ( config.on.online ? config.on.online() : null );
                this.online = true;
            }
            else {
                ( config.on.offline ? config.on.offline() : null );
                this.online = false;
            }
        } );

        Plugins.App.addListener( 'appStateChange', ( state ) => {
            if ( state.isActive ) {
                config.on.toForeground ? config.on.toForeground( this ) : null;
            } else {
                config.on.toBackground ? config.on.toBackground( this ) : null;
            }
        } );
    },

    isNative () {
        return Device.platform !== 'web';
    },

    setUuid ( id ) {
        Device.uuid = id
    },

    /**
     * DarkMode detection
     * 
     * Uses the matchMedia method to check for the media query string 'prefers-color-scheme'
     * 
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
    */
    isDarkMode () {
        const darkScheme = window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches;
        //const lightScheme = window.matchMedia && window.matchMedia( '(prefers-color-scheme: light)' ).matches;

        return darkScheme;
    }
}

export default Device;