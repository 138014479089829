import Resource from '@/system/helpers/api/Resource'

export const Docs = new Resource({
    location: 'docs/{{version}}',
    state: 'Docs',
    autoFill: false,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: []
})