/**
 * News API 
 * 
 * Contains API and Resource calls
 */
//import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

/* API */
export const news = {

}

/* RESOURCES */

/**
 * News
 * 
 * Get all news items and store them
 */
export const News = new Resource( {
    location: 'community/news',
    state: 'News',
    persist: true,
    autoFill: true,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {
        limit ( amount ) {
            const collection = this.clone();
            const chunk = collection.take( amount );
            return chunk.all();
        },
        getBySlug ( slug ) {
            return this.clone().where( 'slug', slug ).first();
        },
    } ),
    entity: new Entity( {

    } )
} )

export const NewsItem = new Resource( {
    location: 'community/news/{{uuid}}',
    state: 'News',
    persist: true,
    autoFill: true,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    entity: new Entity( {

    } )
} )