<template>

    <div class="notifications">
        
        <div 
        v-if="items"
        class="rounded-b-tzp"
        :data-updated="notificationsTimeUpdated">

            <v-list 
            v-if="!swipe"
            class="pt-0">

                <div 
                v-for="(item, index) in items"
                :key="index">
                    
                    <template v-if="headers">
                    
                        <v-subheader 
                        v-if="index === indexToday"
                        class="notifications__subheader">{{ t('Today') }}</v-subheader>
                        
                        <v-subheader 
                        v-if="index === indexEarlier"
                        class="notifications__subheader">{{ t('Older') }}</v-subheader>

                    </template>

                    <v-list-item
                    three-line
                    :class="'notification__wrap'+(item.read ? ' opacity--6' : '')">

                        <v-list-item-avatar @click="itemClicked( item )">
                            
                            <v-icon :color="iconColor">
                                {{ getIcon(item.channel) }}
                            </v-icon>

                        </v-list-item-avatar>
                        
                        <v-list-item-content @click="itemClicked( item )">
                            
                            <v-list-item-title :class="!item.read ? 'font-weight-medium' : ''">{{ item.message.subject }}</v-list-item-title>

                            <v-list-item-subtitle>{{ item.message.title }}</v-list-item-subtitle>

                            <v-list-item-subtitle :class="'caption text-right'">
                                {{ item.local().received_time ? item.local().received_time : $time.dateTime(item.created_at_timestamp, true, false) }}
                            </v-list-item-subtitle>

                        </v-list-item-content>

                        <v-list-item-action v-if="! swipe">

                            <v-hover v-slot="{ hover }">
                                <v-btn
                                @click="deleteItem( item )"
                                :color="hover ? 'red': ''"
                                icon>
                                    <v-icon size="20">mdi-close</v-icon>
                                </v-btn>
                            </v-hover>

                        </v-list-item-action>

                    </v-list-item>

                    <v-divider 
                    v-if="index != items.count() - 1"
                    class="mx-4"></v-divider>

                </div>

            </v-list>

            <swipe-list
            v-else-if="swipe"
            :items="items.toArray()"
            ref="swipeList"
            class="mt-2">

                <template v-slot="{ index, item }">

                    <div :class="'notification__wrap'+(item.read ? ' opacity--6' : '')">

                        <v-list-item three-line>
            
                            <v-list-item-avatar @click="itemClicked( item )">
                                
                                <v-icon :color="iconColor">
                                    {{ getIcon(item.channel) }}
                                </v-icon>

                            </v-list-item-avatar>
                            
                            <v-list-item-content @click="itemClicked( item )">
                                
                                <v-list-item-title :class="!item.read ? 'font-weight-medium' : ''">{{ item.message.subject }}</v-list-item-title>

                                <v-list-item-subtitle>{{ item.message.title }}</v-list-item-subtitle>

                                <v-list-item-subtitle :class="'caption text-right'">
                                    {{ item.local().received_time ? item.local().received_time : $time.dateTime(item.created_at_timestamp, true, false) }}
                                </v-list-item-subtitle>

                            </v-list-item-content>

                        </v-list-item>

                    </div>

                    <v-divider 
                    v-if="index != items.count() - 1"
                    class="mx-4"></v-divider>

                </template>

                <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
                <template v-slot:left="{ index, item }">
                    <div
                    @click="markBySwipe( index, item )"
                    class="swipeout-action green darken-1">
                        <v-icon color="white">
                           {{ item.read ? 'mdi-email' : 'mdi-email-open' }}
                        </v-icon>
                    </div>
                </template>

                <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
                <template v-slot:right="{ item }">
                    <div
                    @click="deleteItem( item )"
                    class="swipeout-action red">
                        <v-icon color="white">
                            mdi-delete
                        </v-icon>
                    </div>
                </template>
                
            </swipe-list>

        </div>

    </div>

</template>

<script>
import { Notifications } from '@/components/notifications/api'
import ApiClick from '@/mixins/ApiClick'
import { 
    SwipeList,
    SwipeOut
} from 'vue-swipe-actions';

export default {
    name: 'NotificationsList',
    
    props: {
        headers: {
            type: Boolean,
            default: true,
        },
        iconColor: {
            type: String,
            default: 'tzpPurple'
        },
        items: {
            type: Array|Object,
            required: true
        },
        swipe: {
            type: Boolean,
            default: true,
        },
        widget: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        SwipeList,
        SwipeOut,
    },

    mixins: [ 
        ApiClick,
        Notifications.map()
    ],

    data: () => ({
        notificationsTimeUpdated: 0,
    }),

    computed: {
        indexEarlier() {
            let foundKey = null;
            this.items.each( (item, key) => {
                if ( ! this.$time.checkToday( item.created_at_timestamp ) ) {
                    foundKey = key;
                    
                    return false;            
                }
            });

            return foundKey;
        },
        indexToday() {
            let foundKey = null;
            this.items.each( (item, key) => {
                if ( this.$time.checkToday( item.created_at_timestamp ) ) {
                    foundKey = key;

                    return false;
                }
            });

            return foundKey;
        }
    },

    mounted () {
        this.updateNotificationsTime();
    },

    methods: {
        closeItem( index ) {
            this.$refs.swipeList.closeActions( index );
        },
        closeAllItems() {
            if( ( this.$device.isNative() || this.$vuetify.breakpoint.smAndDown ) && this.items.count() > 0 ) {
                this.$refs.swipeList.closeActions();
            }
        },
        deleteItem( item ) {          
            item.api().delete().then( () => {
                if( ! item.read ) {
                    this.Notifications.decreaseUnreadCount();
                }
                this.Notifications.decreaseTotalCount();
            } );
        },
        getIcon( channel ) {
            let icon;
            
            switch ( channel ) {
                case 'account.security':
                    icon = 'mdi-security';
                    break;
            
                default:
                    icon = 'mdi-bell-ring';
                    break;
            }

            return icon;
        },
        itemClicked( item ) {
            if( 'onClick' in item.message && item.message.onClick !== null && item.message.onClick.type !== null ) {
                if( ! item.read ) {
                    item.markToggle().then( () => {
                        this.Notifications.decreaseUnreadCount();
                    });
                }

                this.handleClick( item.message.onClick );
            }
            else {
                this.$emit('itemClicked', item);
            }
        },
        markBySwipe( index, item ) {
            this.closeItem( index );
            item.markToggle().then( () => {
                if( item.read ) {
                    this.Notifications.decreaseUnreadCount();
                }
                else {
                    this.Notifications.increaseUnreadCount();
                }
            });
        },
        updateNotificationsTime() {
            setTimeout( () => {
                this.items.each( ( item, key ) => {
                    item.local().received_time = this.$time.dateTime(item.created_at_timestamp, true, false);
                })
                this.notificationsTimeUpdated = this.notificationsTimeUpdated + 1;
                this.updateNotificationsTime();
            }, 60000 );
        },
    },
};
</script>

<style lang="scss">
//.notifications .v-list div:last-child .v-divider { display: none; }
.notifications__subheader { 
    height: auto;
    margin-top: 3 * $spacer
}
.notification__wrap {
    .v-list-item__avatar,
    .v-list-item__content { @include cursor(pointer); }
}
.swipeout-list {
    display: flex;
    flex-direction: column;
}
.swipeout {
    position: relative;
    overflow: hidden;
    display: flex;

    &.swipeout--no-transition {
        .swipeout-action,
        .swipeout-content { transition: none!important }
    }

    .swipeout-left,
    .swipeout-right {
        position: absolute;
        display: flex;
        height: 100%;
        z-index: 1
    }
    .swipeout-left {
        left: 0;
        transform: translateX(-100%)
    }
    .swipeout-right {
        right: 0;
        transform: translateX(100%)
    }
    .swipeout-action,
    .swipeout-content {
        transition: transform .2s;
        will-change: transform
    }
    .swipeout-content { 
        width: 100%;
        @include cursor(pointer);
    }
}
.swipeout-non-selectable { user-select: none!important }
.swipeout-no-pointer-events { pointer-events: none!important }
.swipeout-action {
    left: 0;
    display: flex;
    padding: 0 3rem;
    align-items: center;
    @include cursor(pointer);
}
.swipeout-list-item { flex: 1; }
.transition-right { transform: translate3d(100%,0,0)!important }
.transition-left { transform: translate3d(-100%,0,0)!important }
</style>