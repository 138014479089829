<template>

    <v-overlay 
    v-if="loading"
    :color="$prefs.settings.darkModeEnabled ? '#212121' : 'white'"
    :opacity="1"
    z-index="205"
    class="loading-screen">
                
        <v-row 
        class="mx-auto"
        :style="'width:'+($vuetify.breakpoint.xs ? 80 : 130)+'px'">

            <v-img 
            v-if="!$prefs.settings.darkModeEnabled"
            eager
            src="img/thuiszorgplanner-icon.svg"
            :width="$vuetify.breakpoint.xs ? 80 : 130"
            :height="$vuetify.breakpoint.xs ? 57 : 92"></v-img>
            
            <v-img 
            v-else
            eager
            src="img/dark/thuiszorgplanner-icon_dark.svg"
            :width="$vuetify.breakpoint.xs ? 80 : 130"
            :height="$vuetify.breakpoint.xs ? 57 : 92"></v-img>

        </v-row>

        <v-row
        class="pt-10 mx-auto"
        :style="'width:'+($vuetify.breakpoint.xs ? 80 : 130)+'px'">

            <v-progress-linear
            v-if="!$prefs.settings.darkModeEnabled"
            color="tzpPurple"
            indeterminate
            rounded></v-progress-linear>

            <v-progress-linear
            v-else
            color="white"
            indeterminate
            rounded></v-progress-linear>

        </v-row>

        <v-row 
        v-if="error"
        class="pt-10">

            <v-col :class="$vuetify.breakpoint.xs ? 'mx-3' : ''">
                <v-alert type="error">{{ t('Loading_screen_error') }}</v-alert>
            </v-col>

        </v-row>

    </v-overlay>

</template>

<script>
export default {
    name: 'LoadingScreen',

    data: () => ({
        error: false,
        loading: true
    }),

    created () {
        this.$root.$refs.loadingScreen = this;
    },

    methods: {
        disable() {
            this.loading = false;
        },
        enable() {
            this.loading = true;
        },
        showError() {
            this.error = true;
        }
    },
}
</script>

<style>
.loading-screen .v-responsive__sizer { transition: none; }
</style>