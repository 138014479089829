<template>
  
    <v-snackbar 
    v-model="state"
    :bottom="bottom"
    :color="color"
    :timeout="timeout"
    :top="top">
        
        <div v-html="message"></div>
        
        <template v-slot:action="{ attrs }">
            <v-btn
            v-bind="attrs"
            @click="state = false"
            color="white"
            icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

    </v-snackbar>

</template>

<script>
export default {
    name: 'Toast',

    data: () => ({
        bottom: false,
        color: undefined,
        message: '',
        state: false,
        timeout: 5000,
        top: true,
    }),

    created () {
        this.$root.$refs.toast = this
    },

    mounted() { },

    methods: {
        /**
         * Default - toast
         * 
         * @param {string} message - message to show
         * @param {number} time - time in ms to wait for hidding
         */
        default( message, time = 5000 ) {
            this._show( message, undefined, 'bottom', time );
        },
        /**
         * Info - toast
         * 
         * @param {string} message - message to show
         * @param {string} position - where to place the toast: top or bottom
         * @param {number} time - time in ms to wait for hidding
         */
        info( message, position = 'bottom', time = 5000 ) {
            this._show( message, 'info', position, time );
        },
        /**
         * Error - toast
         * 
         * @param {string} message - message to show
         * @param {string} position - where to place the toast: top or bottom
         * @param {number} time - time in ms to wait for hidding
         */
        error( message, position = 'top', time = 5000 ) {
            this._show( message, 'error', position, time );            
        },
        /**
         * Success - toast
         * 
         * @param {string} message - message to show
         * @param {string} position - where to place the toast: top or bottom
         * @param {number} time - time in ms to wait for hidding
         */
        success( message, position = 'top', time = 5000 ) {
            this._show( message, 'success', position, time );
        },
        /**
         * Warning - toast
         * 
         * @param {string} message - message to show
         * @param {string} position - where to place the toast: top or bottom
         * @param {number} time - time in ms to wait for hidding
         */
        warning( message, position = 'top', time = 5000 ) {
            this._show( message, 'warning', position, time );
        },
        // Check for potential open toast
        checkState() {
            return this.state;
        },
        /**
         * Show
         * 
         * @param {string} message - message to show
         * @param {string} color - color of the toast
         * @param {string} position - where to place the toast: top or bottom
         * @param {number} time - time (in ms) to wait until the toast is automatically hidden, use -1 for indefinitely (close manually)
         */
        _show( message, color = undefined, position, time = 5000 ) {
            if( this.checkState() ) {
                this.state = false;

                setTimeout( () => {
                    this.bottom = position == 'bottom' ? true : false;
                    this.color = color;
                    this.message = message;
                    this.timeout = time;
                    this.top = position == 'top' ? true : false;
                    this.state = true;
                }, 300);
            }
            else {
                this.bottom = position == 'bottom' ? true : false;
                this.color = color;
                this.message = message;
                this.timeout = time;
                this.top = position == 'top' ? true : false;
                this.state = true;
            }
        },
        hide() {
            this.state = false;
        }
    },
}
</script>