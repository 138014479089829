import { Plugins } from '@capacitor/core'
import Connector from './Connector'

export default class GoogleFcmPushConnector extends Connector {

    async connect () {
        await new Promise( ( resolve, reject ) => {

            // Request permission to use push notifications
            // iOS will prompt user and return if they granted permission or not
            // Android will just grant without prompting
            Plugins.PushNotifications.requestPermission().then( result => {

                if ( result.granted ) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    Plugins.PushNotifications.register();
                    this.setConnectedStatus();
                    resolve();
                }
                else {
                    this.setDisconnectedStatus();
                    reject( 'GoogleFcmPushConnector: Unable to register, no permission granted.' );
                }
            } ).catch( ( error ) => {
                this.setDisconnectedStatus();
                reject( 'GoogleFcmPushConnector: ' + error );
            } );
        } );
    }

    async disconnect () {
        //
    }

    async subscribeToChannels () {
        // On success, we should be able to receive notifications.
        Plugins.PushNotifications.addListener( 'registration', ( token ) => {

            this.setAuth( token.value );

            // Remove default channels
            Plugins.PushNotifications.deleteChannel( { id: 'default' } );
            Plugins.PushNotifications.deleteChannel( { id: 'fcm_fallback_notification_channel' } );

            Plugins.PushNotifications.addListener( 'pushNotificationReceived', fcmNotification => {
                // Original message is a JSON string, convert it.
                let notification = JSON.parse( fcmNotification.data.json );

                this.publishEvent( notification.message.event, notification, 'pushNotificationReceived' );
                this.publishChannelEvent( notification.channel, notification, 'pushNotificationReceived' );
            } );

            Plugins.PushNotifications.addListener( 'pushNotificationActionPerformed', capNotification => {

                let fcmNotification = capNotification.notification;

                // Original message is a JSON string, convert it.
                let notification = JSON.parse( fcmNotification.data.json );

                this.publishEvent( notification.message.event, notification, 'pushNotificationActionPerformed' );
                this.publishChannelEvent( notification.channel, notification, 'pushNotificationActionPerformed' );
            } );

            // Add channels
            Plugins.PushNotifications.createChannel( {
                id: 'tzp-general',
                name: 'Algemeen',
                description: 'Algemene meldingen voor de dagelijkse workflow.',
                sound: 'default',
                importance: 4,
                visibility: 1,
                lights: true,
                vibration: true
            } )
            Plugins.PushNotifications.createChannel( {
                id: 'tzp-silent',
                name: 'Silent',
                description: 'Achtergrond meldingen.',
                importance: 1,
                visibility: -1,
                lights: false,
                vibration: false
            } )
        } );
    }

    async unsubscribeFromChannels () {
        //
    }

    async subscribeToChannelByID ( id ) {
        //
    }

    async unsubscribeFromChannelByID ( id ) {
        //
    }
}
