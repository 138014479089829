<template>
    
    <v-dialog
    v-model="$ui.dialog.toggled"
    @click:outside="!$ui.dialog.persistent ? $ui.dialog.hide(): ''"
    :fullscreen="$ui.dialog.fullscreen"
    :hide-overlay="$ui.dialog.hideOverlay"
    :max-width="$vuetify.breakpoint.xs ? 280 : 560"
    :overlay-color="!$prefs.settings.darkModeEnabled ? 'tzpPurple' : undefined"
    overlay-opacity="0.75"
    :persistent="$ui.dialog.persistent">
    
        <template v-if="$ui.dialog.fullscreen">

            <v-card>

                <v-toolbar
                dark
                color="tzpAppBar"
                class="v-toolbar--fixed">

                    <v-btn
                    icon
                    dark
                    @click="$ui.dialog.hide()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title>{{ $ui.dialog.translateParams ? tWithParams($ui.dialog.title, $ui.dialog.translateParams) : t($ui.dialog.title) }}</v-toolbar-title>
            
                </v-toolbar>
                        
                <p v-if="$ui.dialog.icon" class="text-center ma-0 pt-5">
                    <v-icon
                    :color="$ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"
                    :size="$ui.dialog.icon.size ? $ui.dialog.icon.size : '60'"
                    :class="'text-justify'+($ui.dialog.icon.animate ? ' animate__animated '+$ui.dialog.icon.animate : '')">
                        {{ $ui.dialog.icon.key }}
                    </v-icon>
                </p>

                <div v-if="$ui.dialog.translate">
                    
                    <card-content
                    :center="($ui.dialog.icon && ! $ui.dialog.textCenter ? 'title' : ($ui.dialog.textCenter ? 'all' : 'none'))"
                    :content="$ui.dialog.description ? ($ui.dialog.translateParams ? tWithParams($ui.dialog.description,$ui.dialog.translateParams) : t($ui.dialog.description)) : false"
                    :noPadding="false"
                    :title="$ui.dialog.translateParams ? tWithParams($ui.dialog.title, $ui.dialog.translateParams) : t($ui.dialog.title)"
                    :titleColor="$ui.dialog.icon && $ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"></card-content>
                
                </div>

                <div v-else-if="!$ui.dialog.translate">

                    <card-content
                    :center="($ui.dialog.icon ? 'title' : ($ui.dialog.textCenter ? 'all' : 'none'))"
                    :content="$ui.dialog.description ? $ui.dialog.description : false"
                    :noPadding="false"
                    :title="$ui.dialog.title"
                    :titleColor="$ui.dialog.icon && $ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"></card-content>
                
                </div>

                <v-card-actions 
                v-if="$ui.dialog.buttons"
                :class="$vuetify.breakpoint.xs ? 'v-card__actions--stacked' : ''">

                    <template v-for="(button, index) in ($vuetify.breakpoint.xs ? $collect($ui.dialog.buttons).reverse().all() : $ui.dialog.buttons)">
                        
                        <v-btn
                        v-if="button.to"
                        :key="index"
                        :block="$vuetify.breakpoint.xs"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        text
                        :to="button.to"
                        :class="$vuetify.breakpoint.xs ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>

                        <v-btn
                        v-else-if="button.href"
                        :key="index"
                        :block="$vuetify.breakpoint.xs"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        :href="button.href"
                        :target="button.target"
                        text
                        :class="$vuetify.breakpoint.xs ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>

                        <v-btn
                        v-else
                        @click="button.click()"
                        :key="index"
                        :block="$vuetify.breakpoint.xs"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        text
                        :class="$vuetify.breakpoint.xs ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>
                        
                    </template>

                </v-card-actions>

            </v-card>

        </template>

        <template v-else>
            
            <v-card rounded="tzp">

                <p v-if="$ui.dialog.icon" class="text-center ma-0 pt-5">
                    <v-icon
                    :color="$ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"
                    :size="$ui.dialog.icon.size ? $ui.dialog.icon.size : '60'"
                    :class="'text-justify'+($ui.dialog.icon.animate ? ' animate__animated '+$ui.dialog.icon.animate : '')">
                        {{ $ui.dialog.icon.key }}
                    </v-icon>
                </p>

                <div v-if="$ui.dialog.translate">

                    <card-content
                    :center="($ui.dialog.icon && ! $ui.dialog.textCenter ? 'title' : ($ui.dialog.textCenter ? 'all' : 'none'))"
                    :content="$ui.dialog.description ? ($ui.dialog.translateParams ? tWithParams($ui.dialog.description,$ui.dialog.translateParams) : t($ui.dialog.description)) : false"
                    :noPadding="false"
                    :title="$ui.dialog.translateParams ? tWithParams($ui.dialog.title, $ui.dialog.translateParams) : t($ui.dialog.title)"
                    :titleColor="$ui.dialog.icon && $ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"></card-content>
                
                </div>

                <div v-else-if="!$ui.dialog.translate">

                    <card-content
                    :center="($ui.dialog.icon ? 'title' : ($ui.dialog.textCenter ? 'all' : 'none'))"
                    :content="$ui.dialog.description ? $ui.dialog.description : false"
                    :noPadding="false"
                    :title="$ui.dialog.title"
                    :titleColor="$ui.dialog.icon && $ui.dialog.icon.color ? $ui.dialog.icon.color : 'primary'"></card-content>
                
                </div>

                <v-card-actions 
                v-if="$ui.dialog.buttons"
                :class="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2 ? 'v-card__actions--stacked' : ''">
                    
                    <v-spacer></v-spacer>

                    <template v-for="(button, index) in ($vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2 ? $collect($ui.dialog.buttons).reverse().all() : $ui.dialog.buttons)">
                        
                        <v-btn
                        v-if="button.to"
                        :block="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        depressed
                        :key="index"
                        text
                        :to="button.to"
                        :class="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2 ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>

                        <v-btn
                        v-else-if="button.href"
                        :block="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        depressed
                        :href="button.href"
                        :key="index"
                        :target="button.target"
                        text
                        :class="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2 ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>

                        <v-btn
                        v-else
                        @click="button.click()"
                        :block="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2"
                        :color="button.color ? button.color : 'primary'"
                        dark
                        depressed
                        :key="index"
                        text
                        :class="$vuetify.breakpoint.xs && $ui.dialog.buttons.length > 2 ? 'ml-0 text-right' : 'text-center'">
                            {{ t(button.text) }}
                        </v-btn>
                        
                    </template>

                </v-card-actions>

            </v-card>

        </template>

    </v-dialog>

</template>

<script>
import CardContent from '@/components/CardContent.vue'

export default {
    name: 'Dialog',

    components: {
        CardContent,
    },
}
</script>

<style lang="scss">
.v-dialog--fullscreen { 
    background-color: white;
    overflow-x: hidden;
}
.theme--dark {
    .v-dialog--fullscreen { background-color: #272727; }
}
</style>