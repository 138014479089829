<template>

    <v-container>

        <v-card
        flat
        rounded="tzp">

            <div class="text-center">
                
                <decorative-image name="list-bulleted-completed"></decorative-image>

            </div>

            <card-content
            :center="'all'"
            :content="t('Pin_verified_description')"
            :noPadding="true"
            :title="t('Pin_verified')"
            titleColor="success"></card-content>

            <div class="mt-8 text-center">

                <v-btn
                @click="click()"
                color="tzpPurple"
                dark
                depressed
                large
                ripple>
                    {{ t('Continue') }}
                </v-btn>

            </div>

        </v-card>

    </v-container>

</template>

<script>
import CardContent from '@/components/CardContent.vue';
import DecorativeImage from '@/components/DecorativeImage.vue';

export default {
    name: 'PinVerified',

    components: {
        CardContent,
        DecorativeImage
    },

    methods: {
        click() {
            this.$ui.overlay.show( true );

            setTimeout(() => {
                this.$ui.progressBar.setValue( 100 );
            }, 250);

            setTimeout(() => {
                this.$ui.overlay.hide();

                this.$emit('nextStep', 4);
            }, 1250);
        }
    },
}
</script>