<template>
    
    <div :class="'wrapper'+($route.name !== 'Dashboard' ? ' basic' : '')">
        
        <!-- Main menu -->
        <navigation-drawer></navigation-drawer>

        <!-- App bar -->
        <app-bar :data="$route.meta.appbar"></app-bar>

        <!-- Breadcrumbs -->
        <breadcrumbs :data="$route.meta.breadcrumbs"></breadcrumbs>

        <!-- Router view -->
        <router-view></router-view>

        <!-- Messenger -->
        <messenger ref="messenger"></messenger>

        <!-- Notification snackbars -->
        <notifications-snackbar v-if="$vuetify.breakpoint.mdAndUp && ! $ui.notificationDrawer.toggled "></notifications-snackbar>

        <loading-screen></loading-screen>

    </div>

</template>

<script>
import AppBar from "@/system/components/AppBar"
import Breadcrumbs from "@/system/components/Breadcrumbs"
import { Companies } from "@/components/companies/api"
import { FAQs } from '@/components/faqs/api'
import LoadingScreen from "@/system/components/LoadingScreen"
import NavigationDrawer from "@/components/NavigationDrawer"
import { MessagingUserPreferences } from '@/components/messaging/api';
import Messenger from "@/components/Messenger"
import { Notifications } from "@/components/notifications/api"
import NotificationsSnackbar from "@/components/notifications/NotificationsSnackbar"
import { User } from "@/components/user/api"

export default {
    name: 'Main',
    
    components: {
        AppBar,
        Breadcrumbs,
        LoadingScreen,
        Messenger,
        NavigationDrawer,
        NotificationsSnackbar,
    },

    data: () => ({ }),

    mounted() {
        // User
        User.api().get().then( () => {
            this.$root.$refs.loadingScreen.disable();
            this.$proxy.startSession();
        }).catch( error => {
            Debug.error({error});
            this.$root.$refs.loadingScreen.showError();
        });

        // MessagingPreferences
        MessagingUserPreferences.api().get();
        
        // Companies
        let sessionData = this.$session.getData()
        Companies.api().params( { uuid: sessionData.user } ).get();

        // Notifications
        Notifications.api().query( { limit: 15 } ).get();

        // If available, connect the websockets.
        this.$messaging.connect( 'websockets', connector => {
            connector.subscribeToChannels( [
                'users.' + this.$session.data.user,
                'devices.' + this.$session.data.device,
            ] );
        });
    },

    beforeDestroy() {
        this.$messaging.disconnect( 'websockets' );
    },
};
</script>
<style lang="scss">
.wrapper { min-height: 100vh; }
.theme--light.tzpDashboardBG {
    background: linear-gradient(
        90deg,
        rgba(144, 158, 205, 1) 0%,
        rgba(208, 214, 236, 1) 100%
    );
}
.theme--dark.tzpDashboardBG {
    background: linear-gradient(
        90deg,
        rgba(42, 42, 42, 1) 0%,
        rgba(64, 64, 64, 1) 100%
    );
}
// Custom scrollbar
.navigation-drawer--scrollbar {
    .v-navigation-drawer__content {
        &::-webkit-scrollbar { width: 0 }
    }
}
</style>