<template>
    <div>
        <v-card 
        flat 
        class="pa-6">
            <div class="text-h4 mb-3 font-weight-bold">{{ article.name }}</div>
            <div class="text-h5 grey--text">This article describes the {{ article.name }} endpoints.</div>
        
            <div class="title mt-6">In this article</div>

            <div v-for="endpoint in article.endpoints" :key="endpoint.data.id">
                 <v-btn 
                 color="transparent" 
                 class="body-2 text-none primary--text" 
                 x-small 
                 depressed 
                 :to="$route.path + '#' + endpoint.data.slug" 
                 @click="scrollToArticle()">
                    {{endpoint.data.title}}
                </v-btn>
            </div>
        </v-card>
        
        <v-card 
        flat 
        class="mb-12 px-6 py-0"
        v-for="(endpoint, k) in article.endpoints" 
        :key="k"
        :id="endpoint.data.slug">

            <v-divider class="my-12" v-if="k != 0"></v-divider>
           
            <Endpoint class="mt-10" :endpoint="endpoint.data" />
            
            <div class="caption mt-8 text-right grey--text">
                <div>Revision {{ endpoint.data.revision }} <span v-if="endpoint.data.status">({{ endpoint.data.status }})</span>.</div>
                <div>Last update: {{ $time.dateTime(endpoint.updated_at) }}.</div>
            </div>

        </v-card>
    </div>
</template>

<script>
import Endpoint from '@/packages/docs/components/Endpoint'

export default {

    name: 'Article',

    props: {
        article: {
            type: Object,
            required: true
        }
    },

    methods: {
        scrollToArticle() {
            if(this.$route.hash) {
                const el = document.querySelector(this.$route.hash);
                el && el.scrollIntoView();
                this.subNavSelect = this.$route.hash;
            }
        },
    },

    components: { Endpoint }
}
</script>