<template>

    <div>

        <v-app-bar
        app
        color="primary"
        dark
        elevation="3">
            
            <v-btn
            icon
            to="/dashboard">

                <v-icon>mdi-arrow-left</v-icon>

            </v-btn>

            <v-toolbar-title>Playground</v-toolbar-title>

        </v-app-bar>

        <v-main>
        
            <v-container>

                <v-card
                flat
                text-center
                class="mx-auto mb-4 animate__animated animate__faster animate__fadeIn transparent"
                max-width="300">

                    <one-time-code
                    @onFinish="finish($event)"
                    @onInput="error ? resetValidation() : ''"
                    :description="t('Enter_verification_code')"
                    :error="error"
                    :loading="loading"
                    ref="oneTimeCode"
                    :success="success"></one-time-code>

                </v-card>

            </v-container>

            <!-- <v-container>

                <notifications-list
                v-if="Notifications.init"
                iconColor="white"
                :items="Notifications.all()"
                ref="notificationsList"
                :swipe="$device.isNative() || $vuetify.breakpoint.smAndDown"></notifications-list>

                <v-skeleton-loader
                v-if="loading"
                type="list-item-avatar-two-line@3"
                class="pa-3"></v-skeleton-loader>
                
                <v-list
                three-line
                tile>

                    <v-list-item
                    v-for="item in items"
                    :key="item.id">

                        <v-list-item-content>

                            <v-list-item-title>{{item.title}}</v-list-item-title>

                            <v-list-item-subtitle>{{item.body}}</v-list-item-subtitle>

                        </v-list-item-content>

                    </v-list-item>

                    <v-skeleton-loader
                    v-if="loading"
                    type="list-item-three-line@3"></v-skeleton-loader>

                </v-list>

            </v-container> -->

            <!-- <div 
            v-if="Notifications.init"
            v-intersect="onIntersect"
            style="height:50px"></div> -->

        </v-main>

    </div>

</template>

<script>
import { Notifications } from '@/components/notifications/api'
import NotificationsList from '@/components/notifications/NotificationsList'
import OneTimeCode from '@/system/components/authentication/OneTimeCode'

export default {
    name: 'Playground',

    components: {
        NotificationsList,
        OneTimeCode,
    },

    mixins: [ Notifications.map(), ],

    data: () => ({
        expectedPincode: '12345',
        error: false,
        //isIntersecting: false,
        loading: false,
        //items: [],
        //itemsCount: 0,
        pincode: '',
        //page: 1,
        success: false
    }),

    mounted() {
        // Notifications.api().query( { limit: 10 } ).get().then( () => {
        //     this.loading = false;
        // } );
    },

    methods: {
        finish( code ) {
            this.loading = true;

            if( code === this.expectedPincode ) {
                setTimeout( () => {
                    this.error = false;
                    this.success = true;
                    this.loading = false;
                    this.$root.$refs.toast.success( 'Valid code entered', 'bottom' );
                    
                    setTimeout( () => {
                        this.resetState();
                    }, 1500);
                }, 1500);
            }
            else {
                this.error = true;
                this.loading = false;
            }
        },
        resetState() {
            this.resetValidation();
            this.$refs.oneTimeCode.reset();
            this.$root.$refs.toast.hide();
        },
        resetValidation() {
            Debug.log('reset validation');
            this.error = false;
            this.success = false;
        }

        // onIntersect (entries, observer) {
        //     // More information about these options
        //     // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //     this.isIntersecting = entries[0].isIntersecting;
        //     this.loading = true;

        //     Debug.ok('Intersect triggered');

        //     setTimeout(() => {
        //         this.loading = false;

        //         Notifications.loadNextBatch( 5 );

        //         Debug.log( 'Items:', this.Notifications.count() );
        //         Debug.log( 'Page:', this.page++ ); 
        //     }, 500);
        // },
        /**
         * {JSON} Placeholder - free fake API for testing and prototyping
         * 
         * @link https://jsonplaceholder.typicode.com/
         */
        // async getItems() {
        //     if( ! this.loading ) {
        //         Debug.log(`Get items - ${this.page}`);
        //         this.loading = true;

        //         const res = await fetch(`https://jsonplaceholder.typicode.com/posts?_page=${this.page}&_limit=15`);
        //         const items = await res.json();

        //         if( items ) {
        //             this.page++;

        //             setTimeout(() => {
        //                 this.items = [...this.items, ...items];
        //                 this.isIntersecting = false;
        //                 this.loading = false;
        //             }, 500);
        //         }
        //     }
        // },
    },
}
</script>

<style>

</style>