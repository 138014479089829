
//Credits: https://github.com/sunnykgupta/jsLogger/blob/master/README.md

window.console = Debug = ( function ( origConsole ) {

    if ( !window.console || !origConsole ) {
        origConsole = {};
    }

    var isDebug = false, isSaveLog = true,
        logArray = {
            logs: [],
            errors: [],
            warns: [],
            infos: [],
            exceptions: []
        };

    return {

        construct: function ( config ) {

            isDebug = config.enabled;
        },

        log: function () {

            this.addLog( arguments, "logs" );
            isDebug && origConsole.log && origConsole.log.apply( origConsole, arguments );
        },

        warn: function () {

            this.addLog( arguments, "warns" );
            isDebug && origConsole.warn && origConsole.warn.apply( origConsole, arguments );
        },

        error: function () {

            this.addLog( arguments, "errors" );
            isDebug && origConsole.error && origConsole.error.apply( origConsole, arguments );
        },

        info: function ( v ) {

            this.addLog( arguments, "infos" );
            isDebug && origConsole.info && origConsole.info.apply( origConsole, arguments );
        },

        ok: function ( v ) {
            const ok = [ 'background: green', 'color: white', 'display: block' ].join( ';' );
            isDebug && origConsole.info( '%c' + v, ok )
        },

        saveLog: function ( bool ) {

            isSaveLog = bool;
        },

        addLog: function ( args, array ) {

            if ( !isSaveLog ) {

                return;
            }

            logArray[ array || "logs" ].push( args );
        },

        logArray: function () {

            return logArray;
        },

        exception: function () {

            this.addLog( arguments, "exceptions" );
        }
    };

}( window.console ) );