<template>
        
    <v-container class="pa-0">
        
        <v-alert 
        color="primary"
        text
        type="info">
            {{ feedback }}
        </v-alert>
        
        <ValidationProvider
        v-slot="{ errors }"
        :name="t('verificationCode')"
        mode="eager"
        :rules="rules">

            <v-text-field 
            v-model="verificationCode"
            aria-autocomplete="one-time-code"
            color="primary"
            :disabled="disableInput"
            :error-messages="errors"
            inputmode="numeric"
            :label="feedbackLabel"
            :loading="disableInput"
            outlined
            pattern="\d*"
            type="text"
            class="mt-6 px-0"></v-text-field>

        </ValidationProvider>

    </v-container>

</template>

<script>
import Setup from '@/classes/Setup'
import { ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';
import { 
    digits,
    required,
} from 'vee-validate/dist/rules'

// Use VeeValidate rules
extend('required', required);
extend('digits', digits);

export default {
    components: { ValidationProvider },

    props: {
        rules: {
            type: [Object, String],
            default: ''
        },
    },

    data: () => ({
        feedbackLabel: '',
        disableInput: true,
        feedback: '',
        verificationCode: '',
        timeout: 20000,
        timer: null,
        isWatching: false,
        successCallback: null,
        receivedCallback: null,
        inputEnabledCallback: null
    }),

    mounted() {
        //this.feedback = this.t('Sending_SMS')
        //this.feedbackLabel = this.t('Waiting_for_SMS')
        this.verificationCode = '';
        this.enableInput()
    },
    
    beforeDestroy(){
        // if (this.isWatching) {
        //     this.endSmsWatch();
        // }
    },

    methods: {
        // start() {
        //     this.$ui.overlay.hide();

        //     const platform = this.$device.platform.toLowerCase();
        //     if (platform === 'android') {

        //         if (typeof window.SMSReceive == 'object') {

        //             this.startSmsWatch();

        //             document.addEventListener('onSMSArrive', (e) => {

        //                 let IncomingSMS = e.data;

        //                 if (IncomingSMS.address === this.subject) {

        //                     let smsBody = IncomingSMS.body.split(': ');
        //                     let smsVerificationCode = String(smsBody[1]);

        //                     if (smsVerificationCode.length === 5) {

        //                         this.verificationCode = smsVerificationCode;

        //                         this.endSmsWatch();

        //                         this.feedback = this.t('Verifying_received_SMS');
                                
        //                         this.$ui.overlay.show();

        //                         this.receivedCallback(this.verificationCode).then( () => {
        //                             this.successCallback();
        //                         }, (error) => {
        //                             this.enableInput();
        //                             this.$root.$refs.toast.error( this.t(error.message) );
        //                         });
        //                     }
        //                 }
        //             },(error) => {});
        //         }
        //         else {
        //             // Android: Unable to check for SMS.
        //             this.enableInput();
        //         }
        //     }
        //     else {
        //         // iOS or web.
        //         this.enableInput();
        //     }
        // },

        // Proxy validator.
        // validateVerificationCodeField() {
        //     return this.$validator.validateAll();
        // },

        watchForSubject(subject) {
            this.subject = subject;
        },

        // Must be a (http/axios) promise.
        onSmsReceived(c) {
            this.receivedCallback = c;
        },

        onValidSms(c) {
            this.successCallback = c;
        },

        onInputEnabled(c) {
            this.inputEnabledCallback = c;
        },

        setTimeoutSeconds(seconds){
            this.timeout = (seconds * 1000);
        },

        enableInput() {
            this.disableInput = false;
            this.feedback = this.t('Manually_check_for_SMS');
            this.feedbackLabel = this.t('Enter_verification_code');
            this.inputEnabledCallback != null ? this.inputEnabledCallback() : null;
        },
        resetInput() {
            this.verificationCode = ''
        }

        // Start listening for incoming SMS for x seconds, then cancel it.
        // startSmsWatch() {
        //     this.isWatching = true;
        //     this.feedback = this.t('Sending_SMS_auto_retrieve');

        //     window.SMSReceive.startWatch();
            
        //     this.timer = setTimeout(() => {
        //         this.feedback = this.t('Auto_retrieve_SMS_failed');
        //         this.endSmsWatch();
        //         this.enableInput();
        //     }, this.timeout);
        // },

        // endSmsWatch() {
        //     this.isWatching = false;
        //     clearTimeout(this.timer);
        //     window.SMSReceive.stopWatch();
        // }
    }
}
</script>