<template>
  
    <v-snackbar 
    v-model="$ui.error.toggled"
    color="error"
    :timeout="$ui.error.timeout"
    :top="$ui.error.top"
    :class="$ui.error.top ? '' : 'mt-12 pt-2'">
        
        <div v-html="$ui.error.message"></div>
        
        <template v-slot:action="{ attrs }">
            <v-btn
            v-bind="attrs"
            @click="$ui.error.toggled = false"
            color="white"
            icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

    </v-snackbar>

</template>

<script>
export default {
    name: 'ToastError',
}
</script>