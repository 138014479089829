<template>
    
    <v-main :class="$prefs.settings.darkModeEnabled ? 'tzp-lockscreen-bg dark' : 'tzp-lockscreen-bg'">

        <v-app-bar
        absolute
        color="transparent"
        flat>
            
            <v-spacer></v-spacer>
            
            <SupportMenu />

        </v-app-bar>

        <v-container @click="$refs.pinForm.showKeyboard()">
            
            <v-row 
            cols="12"
            justify="center">
            
                <v-col  
                sm="6"
                md="6"
                lg="4"
                xl="3">
        
                    <v-layout 
                    align-center 
                    justify-center>
                        
                        <v-img 
                        v-if="$prefs.settings.darkModeEnabled"
                        contain
                        src="img/dark/thuiszorgplanner-square_dark.svg"
                        :class="($vuetify.breakpoint.width <= 320 || $vuetify.breakpoint.height <= 540  ? 'my-4' : 'mb-8 mt-10')"
                        :max-width="($vuetify.breakpoint.width <= 320 || $vuetify.breakpoint.height <= 540  ? 150 : 180)"></v-img>

                        <v-img 
                        v-else
                        contain
                        src="img/thuiszorgplanner-square.svg"
                        :class="($vuetify.breakpoint.width <= 320 || $vuetify.breakpoint.height <= 540 ? 'my-4' : 'mb-8 mt-10')"
                        :max-width="($vuetify.breakpoint.width <= 320 || $vuetify.breakpoint.height <= 540 ? 140 : 180)"></v-img>
                        
                    </v-layout>

                </v-col>

            </v-row>

            <v-row 
            v-if="$ui.alert.model"
            cols="12"
            justify="center">
            
                <v-col  
                sm="6"
                md="6"
                lg="4"
                xl="3">

                    <tzp-alert class="mt-n5"></tzp-alert>

                </v-col>

            </v-row>

            <Pin ref="pinForm" />

        </v-container>

    </v-main>

</template>

<script>
import Pin from '@/system/components/authentication/Pin.vue'
import Proxy from '@/classes/Proxy.js'
import SupportMenu from '@/components/support/SupportMenu.vue'
import TzpAlert from '@/system/components/TzpAlert'

export default {

    components: { 
        Pin, 
        SupportMenu,
        TzpAlert,
    },

    data: () => ({ }),

    mounted() {
        // Watch only for expiring refresh token.
        this.$session.setInactive();

        //Let backend the app (soft) locked. The backend will apply a hard lock
        if (this.$router.locked === false ) {
            this.$auth.lock()
        }

        //Stop the browser from navigating
        this.$router.locked = true;

        //Hide the navigationDrawer if its open
        this.$ui.navigationDrawer.hide();

        //Hide the proxy, there is no other way
        Proxy.hide(true);

        //Popup the fingerprint scanner if it has been enabled previously
        // if (this.$prefs.settings.fingerPrintEnabled && this.$auth.isloggedIn()) {

        //     this.startFingerprintScanner();
        // }
    },

    beforeDestroy() {
        this.$root.$refs.loadingScreen.disable();
        this.$ui.alert.hide();
        // Watch for all expiring timers.
        // this.$session.setActive();
        
        //this.dismissFingerprintScanner();
    },

    methods: {
        // async startFingerprintScanner() {
        //     try {
        //         const token = await this.$storage.read('fingerPrint');
        //         FingerprintAuth.decrypt({
        //             clientId: 'TZP',
        //             username: this.$device.uuid,
        //             token: token,
        //             dialogTitle: this.t('Fingerprint_dialog_title'),
        //             dialogMessage: this.t('Fingerprint_dialog_description'),
        //             dialogHint: this.t('Fingerprint_dialog_hint')
        //         },
        //         (result) => {

        //             this.$refs.pinForm.pincode = result.password;
        //             this.$refs.pinForm.approvePin();
        //         },
        //         (error) => {
        //         });
        //     }
        //     catch (e) {
        //         console.log(e);
        //     }
        // },

        // dismissFingerprintScanner() {
        //     if (this.$prefs.settings.fingerPrintAvailable) {
        //         FingerprintAuth.dismiss();
        //     }
        // },
    }
}
</script>
<style lang="scss" scoped>
.tzp-lockscreen-bg {
    background-image: url(~@/assets/img/lockscreen_bottom.png);
    background-position: bottom left;
    background-size: 50%;

    @media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
        background-position: bottom center;
        background-size: 100%;
    }
    &.dark { background-image: url(~@/assets/img/dark/lockscreen_bottom.png); }
}
</style>