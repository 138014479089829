<template>
    
    <v-list-item :to="to">
        
        <v-list-item-avatar
        v-if="initials"
        color="tzpOrange"
        :size="size">
            
            <span class="font-weight-regular white--text">{{ initials }}</span>

        </v-list-item-avatar>

        <v-list-item-content v-if="title">

            <v-list-item-title class="title">{{ title }}</v-list-item-title>

            <v-list-item-subtitle
            v-if="subtitle"
            class="caption">{{ subtitle }}</v-list-item-subtitle>

        </v-list-item-content>

    </v-list-item>

</template>

<script>
export default {
    name: 'AvatarListInitials',
    
    props: {
        initials: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
        },
        size: {
            type: Number,
            default: 40,
        },
        to: {
            type: String,
            required: false
        }
    },
}
</script>