<template>
    
    <v-alert
    v-if="description"
    :color="color == 'default' ? ( $prefs.settings.darkModeEnabled ? 'grey darken-3' :'grey lighten-2' ) : color"
    :dark="color != 'default' ? true : false"
    :dense="dense"
    :icon="icon"
    rounded="0"
    width="100%"
    class="mb-0">
        
        <span 
        v-html="description"
        class="body-2"></span>

    </v-alert>

</template>

<script>
export default {
    name: 'Status',
    
    props: {
        color: {
            type: String,
            validator( value ) {
                // The value must match one of colors
                const colors = ['success', 'warning', 'info', 'error', 'tzpOrange', 'tzpPurple', 'default'];
                return colors.includes( value );
            },
            default: 'default'
        },
        dense: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            required: true,
        },
        icon: {
            type: Boolean|String,
            default: undefined
        }
    }
}
</script>