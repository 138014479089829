var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:(_vm.$vuetify.breakpoint.smAndDown ? 'mb-3' : 'mb-6' )+' pt-0 px-4 grey--text'+(_vm.$prefs.settings.darkModeEnabled ? '': ' text--darken-2'),attrs:{"color":"transparent","flat":"","tile":""},domProps:{"innerHTML":_vm._s(_vm.t('Settings_page_feedback_description'))}}),_c('v-alert',{attrs:{"rounded":"tzp","type":"info"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.t('Feedback_note'))}})]),_c('stepper',{ref:"stepper",attrs:{"contentClass":"pt-2 pb-6","responseDescription":_vm.stepperResponseDescription,"responseTitle":_vm.stepperResponseTitle,"responseType":_vm.stepperResponseType,"steps":[1]},scopedSlots:_vm._u([{key:"step1",fn:function(){return [_c('ValidationObserver',{ref:"feedbackForm"},[_c('v-form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('ValidationProvider',{attrs:{"mode":"eager","name":_vm.t('Message'),"rules":{ 
                        max: 255,
                        required: true, 
                        regex: /^[a-zA-Z0-9?!,.:;"()~`$@#’'+\-=_&€£*%\s]+$/,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('v-textarea',{staticClass:"mb-3",attrs:{"autocomplete":"off","counter":255,"error-messages":errors,"label":_vm.t('Type_your_message'),"noResize":"","required":"","success":valid},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}])}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.t('Feedback_rating_description'))}}),_c('ValidationProvider',{attrs:{"mode":"eager","name":_vm.t('Rating'),"rules":{
                        required: true,
                        between: { 
                            min: 1, 
                            max: 5
                        }
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-rating',{attrs:{"background-color":"grey lighten-1","empty-icon":"mdi-star-outline","full-icon":"mdi-star","hover":"","large":"","length":5},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}}),_c('div',{staticClass:"v-text-field__details"},[_c('div',{class:'v-messages error--text mb-4'+(_vm.$vuetify.dark ? ' theme--dark' : ' theme--light'),attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])])]}}])}),_c('v-btn',{staticClass:"white--text",attrs:{"type":"submit","color":"tzpPurple","depressed":"","ripple":""}},[_vm._v(" "+_vm._s(_vm.t('Send'))+" ")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }