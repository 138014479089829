<template>

    <one-time-code
    @onFinish="submit($event)"
    @onInput="error ? resetValidation() : ''"
    :description="t('Enter_verification_code')"
    :error="error"
    :loading="loading"
    ref="oneTimeCode"
    :success="success"></one-time-code>
    
    <!-- <ValidationObserver ref="smsForm">

        <v-form 
        @submit.prevent="submit"
        action="">
            
            <sms-processor 
            ref="smsProcessor"
            :rules="{
                required: true,
                digits: 5
            }"></sms-processor>

            <v-btn 
            block
            color="tzpPurple"
            class="muli--bold"
            :dark="!disableInput"
            :disabled="disableInput"
            elevation="0"
            large
            :loading="$ui.overlay.toggled"
            ripple
            type="submit">
                {{ t('Check_verification_code') }}
            </v-btn>

        </v-form>

    </ValidationObserver> -->

</template>

<script>
import OneTimeCode from '@/system/components/authentication/OneTimeCode.vue'
// import SmsProcessor from '@/system/components/authentication/SmsProcessor.vue'
import Setup from '@/classes/Setup.js'
// import { 
//     extend,
//     ValidationObserver
// } from 'vee-validate'
// import { 
//     digits,
//     required,
// } from 'vee-validate/dist/rules'

// Use VeeValidate rules
// extend('required', required);
// extend('digits', digits);

export default {
    name: 'SMS',

    components: {
        OneTimeCode,
        // SmsProcessor,
        // ValidationObserver,
    },

    data: () => ({
        //disableInput: false
        error: false,
        loading: false,
        success: false
    }),

    mounted() {
        //this.startListeningForSms();
    },

    methods: {
        // startListeningForSms() {
        //     this.smsReader = this.$refs.SmsProcessor;
        //     this.smsReader.setTimeoutSeconds(60);
        //     this.smsReader.watchForSubject('TZP');
        //     this.smsReader.onSmsReceived( async (verificationCode) => {
        //         await Setup.verifySMS(verificationCode);
        //     });
        //     this.smsReader.onValidSms(() => {
        //         setTimeout(() => {
        //             this.$router.push({ path: '/login/pin/setup' });
        //         }, 1000);
        //     });
        //     this.smsReader.onInputEnabled(() => {
        //         this.disableInput = false;
        //     });
        //     this.smsReader.start();
        // },

        // submit() {
        //     this.$root.$refs.toast.hide();
            
        //     this.$refs.smsForm.validate().then( valid => {
        //         if (valid) {
                    
        //             this.$ui.overlay.show();
                    
        //             setTimeout(() => {
        //                 Setup.verifySMS(this.$refs.smsProcessor.verificationCode).then(() => {
        //                     // Update the progress bar
        //                     this.$ui.progressBar.setValue( (100/6) * 2 );

        //                     this.$ui.overlay.hide();

        //                     // Go to next step
        //                     this.$emit('nextStep', 2);
        //                 },
        //                 (error) => {
        //                     this.$root.$refs.toast.error( this.t(error.response.data.message) );
        //                     if (error.message == 'Too_many_verificationcode_attempts') {
        //                         this.$ui.progressBar.setValue(0) // Progressbar reset
        //                     }
        //                     this.$ui.overlay.hide();
        //                 });
        //             }, 1000);
        //         }
        //     });

        //     // this.smsReader.validateVerificationCodeField().then( valid => {
        //     //     Setup.verifySMS(this.$refs.smsProcessor.verificationCode).then(() => {
        //     //         // Update the progress bar
        //     //         this.$ui.progressBar.setValue( (100/6) * 2 );

        //     //         this.$ui.overlay.hide();

        //     //         // Go to next step
        //     //         this.$emit('nextStep', 2);
        //     //     },
        //     //     (error) => {
        //     //         this.$ui.overlay.hide();
        //     //
        //     //         this.$root.$refs.toast.error( this.t(error.response.data.message) );
        //     //
        //     //         if (error.message == 'Too_many_verificationcode_attempts') {
        //     //             this.$ui.progressBar.setValue(0) // Progressbar reset
        //     //         }
        //     //     });
        //     // });
        // },

        // reset() {
        //     this.$refs.smsProcessor.resetInput();
        //     this.$refs.smsForm.reset();
        // }

        submit( verificationCode ) {
            this.$root.$refs.toast.hide();
            this.loading = true;
            this.$ui.overlay.show(true);
            
            setTimeout(() => {
                Setup.verifySMS( verificationCode ).then( () => {
                    this.error = false;
                    this.success = true;
                    this.loading = false;
                    
                    // Update the progress bar
                    this.$ui.progressBar.setValue( (100/6) * 2 );

                    // Go to next step
                    this.$emit('nextStep', 2);
                },
                (error) => {
                    this.error = true;
                    this.loading = false;
                    this.$root.$refs.toast.error( this.t(error.response.data.message) );

                    if (error.message == 'Too_many_verificationcode_attempts') {
                        this.$ui.progressBar.setValue(0) // Progressbar reset
                    }
                }).finally(() => {
                    this.$ui.overlay.hide();
                });
            }, 1000);
        },
        reset() {
            this.resetValidation();
            this.$refs.oneTimeCode.reset();
            this.$root.$refs.toast.hide();
        },
        resetValidation() {
            this.error = false;
            this.loading = false;
            this.success = false;
        },
        setFocusOnOTC() {
            this.$refs.oneTimeCode.setFocus();
        }
    }
}
</script>