<template>
    <v-app class="white" v-if="docs">

        <v-app-bar
        v-if="$vuetify.breakpoint.mdAndDown"
        color="#D50D47"
        class="font-weight-light"
        dark
        app>
            <v-app-bar-nav-icon @click="showNavigation ? showNavigation = false : showNavigation = true"></v-app-bar-nav-icon>
            
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <!-- <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn> -->
        </v-app-bar>

        <v-navigation-drawer 
        app 
        width="350"
        v-model="showNavigation">
            <v-toolbar
            v-if="$vuetify.breakpoint.mdAndUp"
            color="#D50D47"
            class="font-weight-light"
            flat
            dark>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>

            <v-list>
                <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">{{ api.product }}</v-list-item-title>
                    <v-list-item-subtitle>{{ api.title }}</v-list-item-subtitle>
                </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>
            
            <v-list>
                <v-list-item 
                :color="navSelect === false ? 'primary' : 'black'"
                :to="'/docs/' + api.version"
                @click="navSelect = false"
                link>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">Overview</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                v-model="navSelect">
                
                    <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">Reference</v-list-item-title>
                    </v-list-item-content>
                    </template>

                    <v-list-item 
                    link
                    :to="'/docs/' + api.version + '/' + doc.slug"
                    class="pl-8"
                    v-for="doc in docs.content"
                    :key="doc.name">
                    <v-list-item-content>
                        <v-list-item-title v-text="doc.name" class="font-weight-regular"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
        
        <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">
            <v-container>
                <v-row cols="12" align="center" justify="center">
                    <v-col cols="11">
                        
                        <v-card 
                        v-if="article"
                        flat
                        class="pa-0" >
                            <Article :article="article" />
                        </v-card>

                        <v-card 
                        v-else
                        class="pa-12 body-1">

                            <div class="display-1 mb-6">{{ api.product }} {{ api.title }}</div>

                            <div class="title mt-8">Introduction</div>
                            <p>
                                Welcome and thank you for considering implementation of our software. You can use the {{ api.product }} {{ api.title }} 
                                to create calls to get the data you need to integrate with the Thuiszorgplanner.
                            </p>
                            <p>
                                We currently only maintain one version of the API, v1. For stability of your implementation, 
                                we recommend to carefully check the status of each endpoint before implementing them.
                            </p>

                            <v-alert
                            color="yellow darken-3"
                            border="left"
                            colored-border
                            type="info"
                            elevation="1">
                                Note that this documentation can be restricted based on your organisation's needs or interests. Not all available endpoints are visible.
                            </v-alert>

                            <div class="title mt-8">API client</div>
                            <p>
                                Coolweb does not provide an API client at this time. Your organisation will need to create one yourself. 
                                We have created a list of best practices to guide your developers.
                            </p>
                            <ul class="body-1">
                                <li>Refresh your access token in advance (before the previous token expires).</li>
                                <li>Do NOT refresh your access token EVERY call. Be efficient.</li>
                                <li>Always keep the client credentials secret and tokens secret, treat them as passwords.</li>
                                <li>Store the client credentials and token in safe place. For example; to a database or in a text file outside your public folder.</li>
                                <li>Keep in mind we do throttle requests. Stop making calls if the limit has been reached.</li>
                            </ul>

                            <div class="title mt-8">Authentication flow</div>
                            <p>
                                Authentication happens through OAuth2. To obtain client credentials you will need to contact 
                                our support team. You can then request a JWT access token (authenticate) and add your access token to every call.
                            </p>
                        </v-card>

                    </v-col>
                </v-row>
                <div class="text-center">
                    <div class="pt-12 pr-12 grey--text">Powered by Coolweb. Build: {{ docs.tag }}-{{ docs.release.build }}.</div>
                </div>
            </v-container>
        </v-main>
    </v-app>
    <v-app v-else>
        <v-overlay>
            <v-progress-circular
                indeterminate
                size="64">
            </v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import { Docs } from '@/packages/docs/resources.js'
import Article from '@/packages/docs/components/Article.vue'

export default {

    name: 'Docs',

    components: { Article },

    mixins: [Docs.map()],

    data: () => ({
        showNavigation: true,
        title: 'Coolweb Docs',
        api: {
            product: 'Thuiszorgplanner',
            title: 'REST API',
            version: ''
        },
        docs: null,
        navSelect: false,
        subNavSelect: false,
        article: null
    }),

    mounted() {
        this.api.version = this.$route.params.version;
        
        let queryData = {
                version: this.api.version
        };

        Docs.api().params(queryData).get().then(() => {
            this.docs = this.Docs.data;
            this.api.title += ' ' + this.docs.tag;
            this.loadArticle(this.$route.params.category);
        }).catch((e) => {
            this.$ui.dialog.show({
                translate: false,
                title: 'Unable to load docs',
                description: e.response.data.message
            });
        });
    },

    updated() {
        this.$nextTick(() => {
            // On reload scroll to #..
            this.scrollToArticle();
        })
    },

    watch: {
        '$route.params.category': {
            handler(name) {
                this.loadArticle(name);
            },
        }
    },

    methods: {
        loadArticle(name) {
            this.article = this.docs.content[name];
            this.navSelect = true;
        },

        scrollToArticle() {
            if(this.$route.hash) {
                const el = document.querySelector(this.$route.hash);
                el && el.scrollIntoView();
                this.buzzArticle(el);
                this.subNavSelect = this.$route.hash;
            }
        },

        buzzArticle(el) {
            el.classList.add('animate__animated');
            el.classList.add('animate__faster');
            el.classList.add('animate__pulse');

            setTimeout(() => {
                el.classList.remove('animate__animated');
                el.classList.remove('animate__faster');
                el.classList.remove('animate__pulse');
            }, 800);
        }
    }
}
</script>