/**
 * Notices API 
 * 
 * Contains API and Resource calls
 */
//import Api from '@/system/services/Api.service'
import Entity from '@/system/helpers/api/Entity'
import EntityCollection from '@/system/helpers/api/EntityCollection'
import Resource from '@/system/helpers/api/Resource'

/* API */
export const notices = {

}

/* RESOURCES */

/**
 * Notices
 * 
 * Get all notices and store them
 */
export const Notices = new Resource( {
    location: 'community/notices',
    state: 'Notices',
    persist: true,
    autoFill: true,
    forceRefill: false,
    methods: [
        'get'
    ],
    data: {},

    collection: new EntityCollection( {

    } ),
    entity: new Entity( {

    } )
} )