<template>

    <v-snackbars
    bottom
    max-width="420px"
    :messages.sync="$ui.notification.toasts"
    right>
        
        <template v-slot:default="{ message }">
            <div 
            @click="itemClicked( message );"
            class="cursor-pointer">
                
                <v-list-item class="px-0">
                
                    <v-list-item-avatar class="my-0">
                        
                        <v-badge
                        color="tzpOrange"
                        dot
                        overlap>
                            <v-icon color="white">{{ getIcon( message.message.event ) }}</v-icon>
                        </v-badge>

                    </v-list-item-avatar>
                    
                    <v-list-item-content class="py-0">

                        <v-list-item-title>{{ message.message.subject }}</v-list-item-title>

                        <v-list-item-subtitle>{{ message.message.title }}</v-list-item-subtitle>
                        
                    </v-list-item-content>

                </v-list-item>

            </div>

        </template>

        <template v-slot:action="{ close }">
            <v-btn
            @click="close()"
            color="white"
            icon>
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>

    </v-snackbars>

</template>

<script>
import VSnackbars from "v-snackbars"
import { Notifications } from '@/components/notifications/api'
import ApiClick from '@/mixins/ApiClick'

export default {
    name: 'NotificationsSnackbar',

    components: {
        "v-snackbars": VSnackbars,
    },

    mixins: [
        ApiClick,
        Notifications.map()
    ],

    data: () => ({ }),

    mounted () { },

    methods: {
        getIcon( event ) {
            let icon;
            
            switch (event) {
                case 'auth_new_login':
                    icon = 'mdi-security'
                    break;
            
                default:
                    icon = 'mdi-bell-ring'
                    break;
            }

            return icon
        },
        itemClicked( item ) {
            if( item.message.onClick !== null && item.message.onClick.type !== null ) {

                if( ! item.read ) {
                    item.markToggle().then( () => {
                        this.Notifications.decreaseUnreadCount();
                    });
                }

                this.handleClick( item.message.onClick );
            }
            else {
                this.$root.$refs.notificationsManager.showNotification( item, true )
            }
        }
    },
}
</script>

<style>
.v-snack__content { width: calc(100% - 44px); /* minus v-snack__action */ }
.v-snack__content .v-list-item__content { display: block; }
</style>