import Connector from './Connector'
import Api from '../../services/Api.service';

export default class WebsocketConnector extends Connector {

    async connect() {
        await new Promise((resolve, reject) => {
    
            window.Pusher = require('pusher-js');
            
            this.pusher = new Pusher('thuiszorgplanner', { 
                cluster: 'eu',
                wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
                wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
                wsPath: process.env.VUE_APP_WEBSOCKET_PATH,
                encrypted: false,
                enabledTransports: ['ws'],
                forceTLS: process.env.VUE_APP_WEBSOCKET_TLS
            });
            
            this.pusher = this.pusher.connection;
            
            // Bind to pusher's events.
            this.pusher.bind('connected', () => {
                this.setConnectedStatus();
                resolve();
            })
            .bind('unavailable', () => {
                this.setDisconnectedStatus();
                reject();
            })
            .bind('failed', () => {
                this.setDisconnectedStatus();
                reject();
            })
            .bind('disconnected', () => {
                this.setDisconnectedStatus();
                reject();
            });

            // Listener
            this.pusher.bind_global((eventName, event) => {
                // Only check for actions on the correct event.
                if (typeof event != 'undefined' && typeof event.event != 'undefined') {
                    if (event.event === 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated') {
                        // If an action is found, execute it and pass on the event data.
                        
                        this.publishEvent(event.data.message.event, event.data, 'socketNotification');
                        this.publishChannelEvent(event.data.channel, event.data, 'socketNotification');
                    }
                }
            });
        });
    }

    async disconnect() {
        await this.pusher.disconnect();
    }

    async subscribeToChannels(channels) {
        channels.forEach(channelName => {
            let channel = 'private-' + channelName;
            let requestData = {
                url: 'messaging/broadcasting/auth',
                method: 'post',
                data: {
                    socket_id: this._getSocketId(),
                    channel_name: channel
                }
            };
            
            Api.request(requestData).then(auth => {
                
                let subData = auth.data;
                subData.channel = channel;
                this.pusher.send_event('pusher:subscribe', subData);
            });
        });
    }

    async unsubscribeFromChannels() {
        //
    }

    async subscribeToChannelByID(id) {
        //
    }

    async unsubscribeFromChannelByID(id) {
        //
    }

    _getSocketId() {
        return this.pusher.connection.id;
    }
}
