<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">

        <v-container class="container--tzp">
            
            <v-row 
            v-if="$vuetify.breakpoint.mdAndUp"
            no-gutters>
                
                <v-col
                md="5"
                lg="4"
                class="pr-4">
                    
                    <Menu 
                    ref="menu" 
                    class="animate__animated animate__fadeInLeft animate__faster" />

                </v-col>

                <v-col
                md="7"
                lg="8">
                    
                    <div class="animate__animated animate__fadeIn animate__faster">
                        
                        <div v-if="$vuetify.breakpoint.mdAndUp">
                            
                            <decorative-image 
                            v-if="$route.name == 'Settings'"
                            name="settings"
                            title="Settings_page_description"
                            class="mb-4"></decorative-image>
                            
                            <p
                            v-if="$route.name != 'Settings'"
                            :class="'mt-4 pt-4 px-4 text-h4 font-weight-light'+($prefs.settings.darkModeEnabled ? ' white--text': ' grey--text text--darken-2')">
                                {{ t($route.name) }}
                            </p>
                            
                            <v-card 
                            v-if="$route.meta.description"
                            v-html="t($route.meta.description)"
                            color="transparent"
                            flat
                            tile
                            :class="'mb-6 pt-0 px-4 grey--text'+($prefs.settings.darkModeEnabled ? '': ' text--darken-2')"></v-card>

                        </div>

                        <router-view></router-view>

                    </div>

                </v-col>
                
            </v-row>

            <v-row 
            v-else
            no-gutters>

                <v-col 
                cols="12"
                class="pa-0">
                    
                    <Menu v-if="$route.path === '/settings'"></Menu>

                    <router-view v-else></router-view>
                    
                </v-col>
                
            </v-row>

        </v-container>

    </v-main>

</template>

<script>
import DecorativeImage from '@/components/DecorativeImage.vue'
import Menu from '@/components/settings/SettingsMenu'

export default {
    name: 'Settings',

    components: { 
        DecorativeImage,
        Menu,
    },

    data: () => ({
        activeItem: '',
    }),
};
</script>