<template>

    <v-container>
        
        <v-alert
        v-if="description"
        color="primary"
        text
        type="info">
            {{ description }}
        </v-alert>

        <v-otp-input
        ref="otp"
        v-model="code"
        @input="$emit('onInput')"
        @finish="$emit('onFinish', code)"
        aria-autocomplete="one-time-code"
        :disabled="loading"
        :error="error"
        :length="fields"
        :pattern="(type === 'number' ? '[0-9]*' : '')"
        :success="success"
        :type="type"></v-otp-input>

    </v-container>

</template>

<script>
export default {
    name: 'OneTimeCode',

    props: {
        description: {
            type: Boolean|String,
            default: false
        },
        error: {
            type: Boolean,
            default: false,
        },
        fields: {
            type: Number,
            default: 5,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'number',
            validator: (prop) => [
                'text',
                'password',
                'number',
            ].includes(prop)
        }
    },

    data: () => ({
        code: '',
    }),

    methods: {
        reset() {
            this.code = '';
        },
        setFocus() {
            this.$refs.otp.focus();
        }
    }
}
</script>