<template>

    <v-list
    v-if="User.init"
    two-line
    class="inline-fields">
        
        <v-list-item v-if="User.email">
            
            <v-list-item-avatar>

                <v-icon color="tzpOrange">mdi-email</v-icon>

            </v-list-item-avatar>

            <v-list-item-content>

                <v-list-item-title>{{ t("Email") }}</v-list-item-title>

                <v-list-item-subtitle>{{ User.email }}</v-list-item-subtitle>

            </v-list-item-content>

        </v-list-item>

        <v-divider 
        v-if="User.mobile_number"
        inset></v-divider>

        <v-list-item v-if="User.mobile_number">

            <v-list-item-avatar>

                <v-icon color="tzpOrange">mdi-cellphone</v-icon>

            </v-list-item-avatar>

            <v-list-item-content>

                <v-list-item-title>{{ t("Mobile_number") }}</v-list-item-title>

                <v-list-item-subtitle>{{ User.mobile_number }}</v-list-item-subtitle>

            </v-list-item-content>

        </v-list-item>

        <v-divider
        v-if="User.phone_number"
        inset></v-divider>

        <v-list-item v-if="User.phone_number">

            <v-list-item-avatar>

                <v-icon color="tzpOrange">mdi-phone-classic</v-icon>

            </v-list-item-avatar>

            <v-list-item-content>

                <v-list-item-title>{{ t("Phone_number") }}</v-list-item-title>

                <v-list-item-subtitle>{{ User.phone_number }}</v-list-item-subtitle>

            </v-list-item-content>

        </v-list-item>

        <v-divider
        v-if="User.ice_number"
        inset></v-divider>

        <v-list-item v-if="User.ice_number">

            <v-list-item-avatar>

                <v-icon color="tzpOrange">mdi-lifebuoy</v-icon>

            </v-list-item-avatar>

            <v-list-item-content>

                <v-list-item-title>{{ t("ICE_number") }}</v-list-item-title>

                <v-list-item-subtitle>{{ User.ice_number }}</v-list-item-subtitle>

            </v-list-item-content>

        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item to="/info">
                    
            <v-list-item-avatar>

                <v-icon color="tzpOrange">mdi-badge-account-horizontal</v-icon>

            </v-list-item-avatar>

            <v-list-item-content>
                
                <v-list-item-title>{{ t("My_details") }}</v-list-item-title>

            </v-list-item-content>

            <v-list-item-action>

                <v-icon>mdi-chevron-right</v-icon>

            </v-list-item-action>

        </v-list-item>

    </v-list>
    
</template>

<script>
import { User } from "@/components/user/api"

export default {
    mixins: [ User.map() ],

    data: () => ({ }),

    mounted() { },
};
</script>