<template>

    <v-card
    rounded="tzp"
    class="widget">

        <v-subheader
        class="pr-2">
            
            <span v-if="title">{{ t(title) }}</span>

            <v-spacer v-if="buttons"></v-spacer>
            
            <template v-if="buttons">

                <template v-for="(button, index) in buttons">

                    <v-btn
                    @click="$emit('onClick', button.ref)"
                    :disabled="button.disable"
                    icon
                    :key="index">
                        <v-icon size="20">{{ button.icon }}</v-icon>
                    </v-btn>

                </template>

            </template>

        </v-subheader>

        <slot v-if="! showEmpty"></slot>

        <div
        v-if="showEmpty"
        class="text-center mt-4 mb-8">
        
            <v-icon
            v-if="emptyIcon"
            color="grey"
            size="120">
                {{ emptyIcon }}
            </v-icon>

            <p 
            v-if="emptyTitle"
            class="pa-4 grey--text">
            {{ t( emptyTitle ) }}
            </p>

        </div>

        <slot name="create"></slot>

    </v-card>

</template>

<script>

export default {
    name: 'Widget',

    props: {
        buttons: {
            type: Boolean|Object,
            default: false
        },
        emptyIcon: {
            type: Boolean|String,
            default: false
        },
        emptyTitle: {
            type: String,
            default: 'No_items'
        },
        showEmpty: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.widget {
    overflow: hidden;

    .v-list {
        padding-top: 0;
        padding-bottom: 0;

        div:first-of-type > .v-subheader { margin-top: 0; }
    }
}
</style>