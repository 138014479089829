<template>
    
    <div>
        
        <v-bottom-sheet
        v-model="toggled"
        hide-overlay>
            
            <v-sheet
            color="tzpKeyboard"
            class="text-center">

                <v-btn 
                @click="hide()"
                block
                color="tzpKeyboardButton"
                dark
                elevation="0"
                :ripple="false"
                small
                tile>
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                
                <v-card
                flat 
                max-width="420px" 
                id="keyboard" 
                class="mx-auto">
                    
                    <v-row no-gutters>
                        
                        <v-col>
                            
                            <v-btn 
                            @click="input(1)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">1</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn
                            @click="input(2)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">2</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn
                            @click="input(3)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">3</div>
                            </v-btn>


                        </v-col>
                    </v-row>

                    <v-row
                    no-gutters
                    cols="4">

                        <v-col>

                            <v-btn
                            @click="input(4)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">4</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn
                            @click="input(5)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">5</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn 
                            @click="input(6)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">6</div>
                            </v-btn>

                        </v-col>

                    </v-row>

                    <v-row no-gutters>

                        <v-col>

                            <v-btn 
                            @click="input(7)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">7</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn 
                            @click="input(8)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">8</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn 
                            @click="input(9)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">9</div>
                            </v-btn>

                        </v-col>

                    </v-row>

                    <v-row no-gutters>

                        <v-col>

                            <v-btn 
                            @click="extraAction()"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            height="60px">
                                <div class="headline">
                                    <v-icon v-if="$prefs.settings.fingerprintEnabled">mdi-fingerprint</v-icon>
                                </div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn 
                            @click="input(0)"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            x-large
                            height="60px">
                                <div class="headline">0</div>
                            </v-btn>

                        </v-col>

                        <v-col>

                            <v-btn 
                            @click="remove()"
                            block
                            color="tzpKeyboardButton"
                            dark
                            elevation="1"
                            tile
                            x-large
                            height="60px">
                                <div class="headline">
                                    <v-icon>mdi-backspace</v-icon>
                                </div>
                            </v-btn>

                        </v-col>
                        
                    </v-row>

                </v-card>

            </v-sheet>
            
        </v-bottom-sheet>
        
        <!--Visible when keyboard is closed-->
        <v-footer
        bottom
        fixed
        padless>
            
            <v-btn
            @click="show()"
            block
            color="tzpKeyboard"
            dark
            depressed
            large
            tile>
                <v-icon>mdi-keyboard</v-icon>
            </v-btn>

        </v-footer>

    </div>
    
</template>

<script>
export default {
    name: 'Keyboard',

    props: {
        mode: {
            type: String,
            default: ''
        },
    },

    data: () => ({
        toggled: false,
        callbackAction: []
    }),

    mounted() {

        if( this.mode != 'setup' ) {
            this.show();
        }
    },

    beforeDestroy() {

        this.hide();
    },
    
    methods: {

        show() {
            document.onkeyup = (e) => {

                //if (e.keyCode === 8) { // deprecated
                if (e.key === "Backspace") {

                    this.remove();
                }
                else {

                    this.input(e.key);
                }
            };

            this.toggled = true;

            (this.callbackAction['show'] ? this.callbackAction['show']() : null);
        },

        hide() {

            document.onkeyup = null;

            this.toggled = false;

            (this.callbackAction['hide'] ? this.callbackAction['hide']() : null);
        },

        input(i) {
            
            (this.callbackAction['input'] ? this.callbackAction['input'](i) : null);
        },

        remove() {

            (this.callbackAction['remove'] ? this.callbackAction['remove']() : null);
        },

        extraAction() {

            (this.callbackAction['extra'] ? this.callbackAction['extra']() : null);
        },

        addListener(k, v) {

            this.callbackAction[k] = v;
        }
    }
}
</script>
<style scoped>
#keyboard .v-ripple__container { opacity: 0.2 !important; }
</style>