import { Plugins } from '@capacitor/core'
import Time from '../helpers/Time'
import Auth from './Auth.service'
import Storage from './Storage.service'

const Session = {

    data: null,
    device: null,
    active: false,
    sessionPrefix: 'SESS1_',
    lastActivity: 0,

    callbackAction: [],

    cron: null,
    cronTimer: 1,

    toBackgroundAt: 0,
    toForegroundAt: 0,

    doWarn: null,
    hasBeenWarned: false,
    doWarnAfter: 120,
    doLockAfter: 180,
    waitingForWarn: 0,

    construct ( config, device ) {

        this.device = device;
        this.lastActivity = Time.now();

        if ( typeof config != 'undefined' ) {

            this.doWarn = ( typeof config.doWarn != 'undefined' ? config.doWarn( device ) : false );

            if ( typeof config.on != 'undefined' ) {
                this.callbackAction = config.on;
            }
        }

        this.attachListeners();
    },

    attachListeners () {
        Plugins.App.addListener( 'appStateChange', ( state ) => {
            if ( Auth.isBoot() ) {
                if ( state.isActive ) {
                    Auth.syncTokensFromStorage().then( () => {
                        //App started OR returned to foreground

                        Session.measureActivity();

                        let timeNow = Time.now();
                        let timeInactive = Time.now() - Session.toBackgroundAt;

                        if ( Auth.rtExpired() ) {
                            //Refresh token expired
                            Session.setRefreshTokenExpired();
                        }
                        else if ( Auth.rtGapExpired() ) {
                            // Pin required..
                            Session.showTooLongAway();
                        }
                        else if ( timeInactive > Session.doLockAfter ) {
                            // Lock
                            Session.showTooLongAway();
                        }
                        else {
                            Session.startCronjob();
                        }

                        Session.toForegroundAt = timeNow;
                    } );
                } else {
                    // App to background
                    this.stopCronjob();
                    this.toBackgroundAt = Time.now();
                }
            }
        } );

        //Add mouse ands touch activity listeners
        if ( this.device.isNative() ) {
            document.addEventListener( 'touchstart', this.measureActivity, false );
            document.addEventListener( 'keydown', this.measureActivity, false );
        }
        else {
            document.addEventListener( 'mousemove', this.measureActivity, false );
            document.addEventListener( 'mousedown', this.measureActivity, false );
            document.addEventListener( 'keydown', this.measureActivity, false );
            document.addEventListener( 'touchstart', this.measureActivity, false );
            document.addEventListener( 'click', this.measureActivity, false );
        }
    },

    start () {
        this.active = true;
        Debug.log( 'Session: Started' );
        this.startCronjob();
    },

    destroy ( stopCron ) {
        this.active = false;
        if ( stopCron ) {
            //On logout, force the cron to halt

            this.stopCronjob();
        }
    },

    setInactive ( reason ) {
        if ( this.active ) {
            this.active = false;
            Debug.log( 'Session: Inactive <' + ( reason ? reason : 'Timed-out' ) + '>' );
            this.callback( 'deactivation' );
        }
    },

    setActive () {
        this.active = true;
        Debug.log( 'Session: Activated' );
        this.callback( 'activation' );
    },

    shouldWarn ( time ) {
        if ( this.doWarn && this.active ) {
            let rtgExpireSeconds = ( Auth.rtgExpireTime - time );
            let warnAfterSeconds = Math.ceil( rtgExpireSeconds / 2 );
            let lastActivitySecondsAgo = ( time - this.lastActivity );

            return lastActivitySecondsAgo > warnAfterSeconds;
        }

        return false;
    },

    sessionWatcher () {
        Session.waitingForWarn += Session.cronTimer;

        // console.log(Session.waitingForWarn);
        // console.warn('Session status: ' + (Session.active ? 'active' : 'inactive'));
        // console.warn('Auto refresh allowed for: ' + Auth.getRtGap());
        // console.warn('access token expires in: ' + Auth.atExpiresIn());

        if ( Session.active ) {
            // Activity watcher
            if ( Session.waitingForWarn >= Session.doWarnAfter ) {
                if ( Session.waitingForWarn > Session.doLockAfter ) {
                    // Lock
                    Session.showTooLongAway();
                }
                else {
                    // Warn
                    if ( Session.doWarn && !Session.hasBeenWarned ) {
                        Session.showWarning( 'Cron: Pop-Warning' );
                        Session.hasBeenWarned = true;
                    }
                }
            }

            // Default actions
            if ( Auth.rtGapExpired() ) {
                // Pin required..
                Session.showTooLongAway();
            }
            else if ( Auth.atExpired() ) {
                // Access token expired but can still freely auto-refresh..
                Auth.getRefreshInstance().then( () => {
                    Debug.info( 'Tokens auto-refreshed..' );
                } );
            }
        }

        if ( Auth.rtExpired() ) {
            Session.setRefreshTokenExpired();
        }
    },

    startCronjob () {

        // Halt previous
        this.stopCronjob();

        // Start new
        this.cron = setInterval( this.sessionWatcher, ( this.cronTimer * 1000 ) );
    },

    stopCronjob () {
        window.clearInterval( this.cron );
    },

    // Expired completely.
    setRefreshTokenExpired () {
        Debug.info( 'Session: Expired' );
        this.destroy( true );
        Auth.callback( 'refreshTokenExpiration' );
    },

    measureActivity () {
        Session.lastActivity = Time.now();
        Session.waitingForWarn = 0;
        Session.hasBeenWarned = false;
    },

    /*
     * Let system know to show a warning.
     */
    showWarning ( reason ) {
        Debug.log( 'Session: Warning <' + ( reason ? reason : 'Timed-out' ) + '>' );
        this.callback( 'warning', this.device );
    },

    /*
     * Let system know that the session has expired.
     */
    showTooLongAway ( reason ) {
        this.setInactive( reason );
        this.callback( 'tooLongAway' );
    },

    halt () {
        this.showTooLongAway( 'halted' );
    },

    callback ( action, data ) {
        ( this.callbackAction[ action ] ? this.callbackAction[ action ]( data ) : null );
    },

    setData ( data ) {
        this.data = JSON.parse( data )
    },

    getData () {
        return this.data
    },

    setSessionData ( data ) {
        Storage.write( this.sessionPrefix, data );
    },

    getSessionData () {
        return Storage.read( this.sessionPrefix );
    }
}

export default Session;

