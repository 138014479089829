<template>

    <widget 
    v-if="News.init"
    emptyIcon="mdi-newspaper-variant-outline"
    emptyTitle="No_news_items"
    :showEmpty="News.count() === 0"
    :title="t('News_items')">

        <news-items
        :items="News.limit( amount )"
        class="news-widget"></news-items>
        
        <!-- <div 
        v-if="buttonAll && News.count() > amount">
            
            <v-divider class="mx-4"></v-divider>

            <v-btn 
            color="tzpPurple"
            depressed
            to="/news"
            class="white--text my-4 ml-4">
                {{ t('View_all_items') }}
            </v-btn>

        </div>

        <v-icon 
        :color="$prefs.settings.darkModeEnabled ? 'rgba(255, 255, 255, 0.1)' : 'grey lighten-3'"
        size="50"
        class="pos-bottom pos-bottom--right">
            mdi-newspaper-variant-outline
        </v-icon> -->

    </widget>

    <widget
    v-else
    :title="t('News_items')">

        <v-skeleton-loader
        type="article@2"
        class="mx-auto mt-2"></v-skeleton-loader>

    </widget>

</template>

<script>
import { News } from '@/components/news/api'
import NewsItems from '@/components/news/NewsItems'
import Widget from '@/components/Widget'

export default {
    name: 'NewsWidget',

    props: {
        amount: {
            type: Number,
            default: 2
        },
        buttonAll: {
            type: Boolean,
            default: true
        },
        heading: {
            type: Boolean,
            default: true,
        },
    },

    mixins: [ News.map() ],

    components: { 
        NewsItems,
        Widget,
    },
};
</script>