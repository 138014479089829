<template>

    <p>
        <v-btn
        @click="requestNotifications()"
        color="tzpPurple"
        dark
        depressed>
            {{ t('Notifications_request_new') }}
        </v-btn>
    </p>

</template>

<script>
import { notifications } from '@/components/notifications/api.js';

export default {
    name: 'CreateNotifications',

    mounted () {},

    methods: {
        requestNotifications() {
            notifications.getTestNotifications();
        }
    },

}
</script>