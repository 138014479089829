<template>

    <v-main :class="$ui.breadcrumbs.show ? 'pt-0' : ''">

        <v-container :class="($route.meta.page && 'width' in $route.meta.page ? ($route.meta.page.width === 'md' || $route.meta.page.width === 'sm' ? 'container--tzp-'+$route.meta.page.width : 'container--tzp') : 'container--tzp')">

            <v-card 
            v-if="$vuetify.breakpoint.mdAndUp && $route.meta.description"
            v-html="t($route.meta.description)"
            color="transparent"
            flat
            tile
            class="mb-6 pt-0 px-4 grey--text text--darken-2"></v-card>

            <v-card 
            v-if="$route.meta.page && $route.meta.page.wrapper"
            rounded="tzp"
            style="overflow:hidden">
            
                <router-view></router-view>

            </v-card>

            <router-view v-else></router-view>

        </v-container>

    </v-main>

</template>

<script>
export default {
    name: 'Page',

    data: () => ({ }),
};
</script>