import Api from '@/system/services/Api.service'
import Auth from '@/system/services/Auth.service'
import Device from '@/system/services/Device.service'
import Messaging from '@/system/services/Messaging.service'
import Storage from '@/system/services/Storage.service'

const Setup = {

    loginWithCredentials ( username, password ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/device/app/login',
            token: false,
            credentials: false,
            data: {
                username: username,
                password: password,
                install_id: Device.install_id,
                fcm: Messaging.getAuthForConnector( 'mobile_push' )
            }
        } );
    },

    async cancelDevice () {

        try {

            const device = await Storage.read( 'device' )

            return Api.request( {
                method: 'POST',
                url: 'auth/device/app/cancel',
                token: false,
                data: {
                    revoke_token: device.revoke_token
                }
            } );
        }
        catch ( e ) {
            Debug.log( e );
        }
    },

    verifySMS ( verificationCode ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/device/app/verify',
            token: false,
            data: {
                verification_code: verificationCode,
                uuid: Device.uuid,
            }
        } );
    },

    async createPin ( pin ) {
        const response = await Api.request( {
            method: 'POST',
            url: 'auth/device/app/pin-setup',
            token: false,
            data: {
                pin: pin,
                uuid: Device.uuid,
            }
        } );

        Auth.setLoggedIn( response.data );
    },

    // Forgot password process.
    forgotPassword ( username ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/password/reset',
            token: false,
            data: {
                username: username
            }
        } );
    },

    emailVerified ( state ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/password/email/is-verified',
            token: false,
            data: {
                state: state
            }
        } );
    },

    setupPassword ( state, password, password_confirm ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/password/setup',
            token: false,
            data: {
                state: state,
                password: password,
                password_confirmation: password_confirm
            }
        } );
    },

    verifyVerificationCode ( state, verification_code ) {
        return Api.request( {
            method: 'POST',
            url: 'auth/password/sms-verification',
            token: false,
            data: {
                state: state,
                verification_code: verification_code
            }
        } );
    },
}

export default Setup;